import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Landing from "./landingPage/landing";
// import Home from "./home";
import Login from "./loginAndRegistration/login";
import Vendors from "./vendor/vendors";
import NotFound from "../components/error404";
import Salespersons from "./salesPerson/salesPersons";
// import ViewSPVendorList from "./salesPerson/viewSPVendorList";
import Plans from "../components/plan";
import OrdersAndQueries from "./ordersAndQueries";
import SetPassword from "../screens/loginAndRegistration/setPassword";
import Products from "../screens/products/products";
// import Orders from "./components/orders";
import Activities from "./activities";
// import Settings from "./components/setting";
// import billingHistory from "./components/billingHistory";
// import Profile from "./components/Profile";
import Overview from "./profile/overview";
// import addExistingVendor from "./vendor/addExistingVendor";
import ViewVendor from "./vendor/viewVendor";
// import Notification from "./components/notification";
import Dashboard from "./dashboard";
import ProtectedRoute from "../components/protected_route_component";
import ForgotPassword from "./loginAndRegistration/forgotPassword";
import ResetPassword from "../screens/loginAndRegistration/resetPassword";
import ViewSalesPerson from "./salesPerson/view";
import MarkLocation from "../components/MarkLocation";
// import MyPlans from "./components/myPlans";
import Subscriptions from "./subscriptions";
// import UpgradePLan from "./components/upgradePlan";
// import BuyPlan from "../components/buyPlan";
// import Enquiries from "./components/enquiries";
import SideNav from "../components/sideNav";
import BuyPlan from "./plansAndBilling/buyPlan";
import PaymentComplete from "./plansAndBilling/paymentComplete";
import SubscriptionDetails from "./subscriptionDetail";
import OrderDetails from "./ordersAndQueries/orderDetails";
import Visits from "./visits";
import Transactions from "./transactions";
import BulkImportSalespersons from "./salesPerson/bulkImport";
import PlansAndBilling from "./profile/plansAndBilling";
import SignInAndSecurity from "./profile/signInAndSecurity";
import Register from "./loginAndRegistration/register";
import Pricing from "./landingPage/pricing";
import Learn from "./landingPage/learn";
import Features from "./landingPage/features";
import Company from "./landingPage/company";
import ContactUs from "./landingPage/contactUs";
import TopBar from "../components/topBar";
import { useDispatch, useSelector } from "react-redux";
import BulkImportVendors from "./vendor/bulkImport";
import BulkImportProducts from "./products/bulkImport";
import SetSalespersonPassword from "../screens/salesPerson/setPassword";
import ProfileSideNav from "../components/profileSideNav";
import PasswordSet from "../screens/salesPerson/passwordSetMessage";
import Policy from "./termsAndPolicy/policy";
import Terms from "./termsAndPolicy/terms";
import Notifications from "./notifications";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logout } from "../redux store/actions/loginAndRegistration";

// How-it-works imports.
import MainScreen from "./howItWorks/Components/MainScreen/MainScreen";

const checkProfilePaths = (path) => {
  if (
    path.startsWith("/sign-in-and-security") ||
    path.startsWith("/plans-and-billing") ||
    path.startsWith("/overview")
  )
    return true;
  else return false;
};

const checkOtherPaths = (path) => {
  if (
    path.startsWith("/buy-plan") ||
    path.startsWith("/salesperson/set-password") ||
    path.startsWith("/payment-complete") ||
    path.startsWith("/pricing") ||
    path.startsWith("/password-set")
  )
    return true;
  else return false;
};

const Routes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state?.login?.token);

  const location = useLocation();

  // useEffect(() => {
  //   if (!token) {
  //     console.log("401 token expired");
  //     dispatch(logout());

  //     history.push("/");
  //   }
  // }, []);

  console.log(
    "checkOtherPaths",
    checkOtherPaths("/buy-plan"),
    location.pathname,
    "buy-plan"
  );
  if (token) {
    return (
      <>
        {0 ? null : <TopBar />}
        <div className="flex">
          <div className="grow"></div>
        </div>

        <div className="flex">
          {checkOtherPaths(location.pathname) ? null : checkProfilePaths(
              location.pathname
            ) ? (
            <ProfileSideNav />
          ) : (
            <SideNav />
          )}
          <div className="grow">
            <Switch>
              {/* <Route path="/enquiries" component={Enquiries} />
          <Route path="/upgradePLan" component={UpgradePLan} />
          <Route path="/myPlans" component={MyPlans} /> */}
              <ProtectedRoute
                path="/sign-in-and-security"
                component={SignInAndSecurity}
              />
              <ProtectedRoute
                path="/plans-and-billing"
                component={PlansAndBilling}
              />
              <ProtectedRoute path="/overview" component={Overview} />
              <Route
                exact
                path="/get-started"
                render={() => {
                  return token ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Redirect to="/get-started" />
                  );
                }}
              />
              <ProtectedRoute path="/dashboard" component={Dashboard} />
              <ProtectedRoute exact path="/" component={Dashboard} />
              <ProtectedRoute path="/salespersons" component={Salespersons} />
              <ProtectedRoute
                path="/view-subscription"
                component={SubscriptionDetails}
              />
              <ProtectedRoute path="/vendors" component={Vendors} />
              <ProtectedRoute path="/visits" component={Visits} />
              <ProtectedRoute
                path="/view-salesperson/:id"
                component={ViewSalesPerson}
              />
              <ProtectedRoute
                path="/salesperson/bulk-import"
                component={BulkImportSalespersons}
              />
              <ProtectedRoute
                path="/vendor/bulk-import"
                component={BulkImportVendors}
              />
              <ProtectedRoute
                path="/product/bulk-import"
                component={BulkImportProducts}
              />
              <ProtectedRoute path="/notifications" component={Notifications} />
              <ProtectedRoute path="/products" component={Products} />
              <ProtectedRoute path="/orders" component={OrdersAndQueries} />
              <ProtectedRoute path="/activities" component={Activities} />
              <ProtectedRoute path="/transactions" component={Transactions} />
              <ProtectedRoute path="/location" component={MarkLocation} />
              <ProtectedRoute path="/buy-plan" component={BuyPlan} />
              {/* <ProtectedRoute path="/setting" component={Settings} /> */}
              {/* <ProtectedRoute path="/billingHistory" component={billingHistory} /> */}
              {/* <ProtectedRoute path="/profile" component={Profile} /> */}
              <ProtectedRoute path="/subscriptions" component={Subscriptions} />
              {/* <ProtectedRoute
                  path="/addExistingVendor"
                  component={addExistingVendor}
                /> */}
              <ProtectedRoute path="/view-vendor/:id" component={ViewVendor} />
              {/* <ProtectedRoute path="/notification" component={Notification} /> */}
              <ProtectedRoute path="/view-order/:id" component={OrderDetails} />
              <ProtectedRoute path="/pricing" component={Pricing} />
              <Route exact path="/learn" component={Learn} />
              <Route exact path="/learn/:category/:id" component={MainScreen} />
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Route exact path="/" component={Landing} />
        <Route path="/get-started" component={Login} />
        <Route path="/plans" component={Plans} />
        <Route path="/register" component={Register} />
        <Route path="/set-password" component={SetPassword} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/features" component={Features} />
        <Route path="/company" component={Company} />
        <Route path="/payment-complete" component={PaymentComplete} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/buy-plan" component={BuyPlan} />
        <Route path="/reset-password/:token" component={ResetPassword} />
        <Route path="/policy" component={Policy} />
        <Route path="/terms" component={Terms} />
        {/* <Route
          path="/salesperson/set-password"
          component={SetSalespersonPassword}
        /> */}
        <Route exact path="/learn" component={Learn} />
        <Route exact path="/learn/:category/:id" component={MainScreen} />
        <Route path="/password-set" component={PasswordSet} />
        {/* <Route path="*">
          <Login />
        </Route> */}
        <Route path="/payment-complete" component={PaymentComplete} />
        <Route path="/password-set" component={PasswordSet} />
        {/* <Route path="/buy-plan" component={BuyPlan} /> */}
        <Route
          path="/salesperson/set-password"
          component={SetSalespersonPassword}
        />{" "}
        {/* How-it-works routing starts here */}
      </>
    );
  }
};

export default Routes;
