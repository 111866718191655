import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";

import * as processingAnimation from "../../assets/lottie/import.json";
import * as doneAnimation from "../../assets/lottie/bulk_done.json";
import * as failedAnimation from "../../assets/lottie/failed_import.json";
import BaseButton from "../common/baseButton";
import { BulkImportType } from "../../constants/common";
import { bulkUploadProducts } from "../../services/productsApi";
import { addBulkVendors } from "../../services/vendorsApi";
import { addBulkSalespersons } from "../../services/salespersonApi";


const BulkAdd = ({ processedData, bulkImportType, done, uploadAnother,link }) => {
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);

  const uploadData = async () => {
    let resp;
    switch (bulkImportType) {
      case BulkImportType.Product:
        resp = await bulkUploadProducts(processedData);
        if (!resp) {
          setFailed(true);
        }
        break;
      case BulkImportType.Vendor:
        resp = await addBulkVendors(processedData);
        if (!resp) {
          setFailed(true);
        }
        break;
      case BulkImportType.Salesperson:
        resp = await addBulkSalespersons(processedData);
        if (!resp) {
          setFailed(true);
        }
        break;
      default:
        break;
    }
    setLoading(false);
  };

  useEffect(() => {
    if (processedData) {
      uploadData();
    }
  }, []);

  return (
    <div className="mt-4">
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loading
            ? processingAnimation
            : failed
            ? failedAnimation
            : doneAnimation,
          rendererSettings: {
            // preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={240}
        width={240}
      />
      <div className="flex flex-col items-center text-neutralText1">
        <p className="font-black text-lg mb-2">
          {loading
            ? "Your data is being imported"
            : failed
            ? "Something went wrong"
            : "Successfully uploaded data"}
        </p>
        {/* <p className="font-light text-xs mb-4">
          You can comeback later to check the in the below section
        </p> */}
      </div>
      {!loading && (
           <div className="flex flex-row justify-center text-neutralText1 ">
            <BaseButton
              text={failed ? "Try Again" : "Upload Another"}
              onClick={uploadAnother}
              type={"mr-2 bg-secondary hover:bg-secondaryLight"}
              textColor="neutralBg1"
              width={"w-40"}
            />

            <Link to={link}>
              <BaseButton
              text={"Return"}
              type="border-neutralText2 hover:bg-neutralText2 hover:text-white"
              textColor="neutralText2"
              outline
              width={"w-40"}
              />
           </Link>
        </div>
        )}
      {/* <div className="flex justify-end mt-3">
        <BaseButton text={"Done"} onClick={done} width={"w-40"} />
      </div> */}
    </div>
  );
};

export default BulkAdd;
