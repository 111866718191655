import {GET_ENQUIRIES,RESET_STATE} from "../actions/actionTypes";


export const getEnquiries = (
    orders = [{ list: [], isLast: false, status: null }],
    action
  ) => {
    switch (action.type) {
      case GET_ENQUIRIES:
        return (orders = [
          {
            list: [...orders[0].list, ...action.payload.list],
            isLast: action.payload.isLast,
            status: action.payload.responseStatus
          }
        ]);
  
      case RESET_STATE:
        return (orders = [{ list: [], isLast: false, status: null }]);
  
      default:
        return orders;
    }
  };