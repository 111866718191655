import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast, ToastContainer } from "react-toastify";
import * as Unicons from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";

import {
  addNewProduct,
  editProduct,
} from "../../redux store/actions/productAndOrders";
import { addProductSchema } from "../validationSchema";
import ValidationErrors from "../common/validationErrors";
import CustomInput, {
  CustomSelect,
  CustomTextField,
} from "../common/customInput";
import BaseButton from "../common/baseButton";
import { getProductDetails } from "../../services/api";
import { createVisit } from "../../services/visitsApi";
import { createTransaction } from "../../services/transactionsApi";
import { CustomDateInputPicker } from "../common/customDatePicker";
import { cancelOrCompleteOrder } from "../../services/ordersApi";

const options = [
  { key: "Select Transaction Type", value: "" },
  { key: "Sales", value: "SALES" },
  { key: "Collection", value: "COLLECTION" },
  { key: "Return", value: "RETURN" },
];

const CompleteOrderForm = ({ orderDetails, handleClose }) => {
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  return (
    <Formik
      initialValues={{
        orderId: orderDetails?.id,
        vendorId: orderDetails?.vendor?.id,
        date: new Date(),
        type: "COMPLETED",
        amount: orderDetails?.amount,
      }}
      // validationSchema={addProductSchema}
      onSubmit={async (values) => {
        setLoading(true);
        const resp = await cancelOrCompleteOrder({
          ...values,
          date: parseInt(values.date.getTime() / 1000, 10),
        });
        if (resp) {
          handleClose();
        }
        setLoading(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          {/* <TextField
            type="date"
            classes={{
              root: "w-full outline-none !border-0 !border-neutralBg4 !bg-neutralBg1 !rounded-sm !py-2  !text-sm !text-neutralText1 !focus:border-infoText5 ",
            }}
            name="name"
            // text={values.name || ""}
            // onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            // setFormValue={(name) => {
            //   setFieldValue("name", name);
            // }}
            onChange={(e) => setFieldValue(e.target.value)}
          /> */}
          {touched.name && errors.name && (
            <ValidationErrors errors={`${errors.name}`} />
          )}

          <CustomDateInputPicker
            date={values.date}
            onChange={(selectedDate) => {
              setFieldValue("date", selectedDate);
            }}
          />

          <CustomInput
            icon={
              <Unicons.UilRupeeSign className="text-successText5 h-5 w-5" />
            }
            allowNumOnly
            placeholder="INR 2000"
            containerClasses="py-2"
            label="Sale Value"
            type="number"
            name="amount"
            text={values.amount || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.amount}
          />

          <div className=" pt-12 flex justify-end  m-auto">
            <BaseButton
              text={"Complete Order"}
              loading={loading}
              onClick={() => handleSubmit()}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
export default CompleteOrderForm;
