import * as Unicons from "@iconscout/react-unicons";
import { BiTargetLock } from "react-icons/bi";
import BaseButton from "../common/baseButton";

import LandingHeader from "./landingHeader";
import SmallCard from "./smallCards";
import VendorMapping from "../../assets/VendorMapping.png";
import BalanceRegistry from "../../assets/BalanceRegistry.png";
import SalesRegister from "../../assets/SalesRegistry.png";
import VisitTracking from "../../assets/VisitTracking.png";
import OrderTracking from "../../assets/OrderTracking.png";
import ProgressStatistics from "../../assets/ProgressStatistics.png";
import DailyVisits from "../../assets/DailyVisits.png";
import BalanceLog from "../../assets/BalanceLog.png";
import ManageOrders from "../../assets/ManageOrders.png";
import ManageVendors from "../../assets/ManageVendors.png";
import SalesMonitor from "../../assets/SalesMonitor.png";
import TargetGoals from "../../assets/TargetGoals.png";
import { useState } from "react";

const HowBusyBeeHelps = () => {
  const [tab, setTab] = useState("organisation");
  const [img, setImg] = useState(VendorMapping);
  return (
    <>
      <div className="pt-10 md:pt-24 xl:pt-36 text-center">
        <div className="2xl:text-[4rem] lg:text-[3rem] sm:text-[3rem] text-[1.9rem]  pb-4 text-transparent bg-clip-text font-black bg-gradient-radial-sm-text from-successBg5 to-neutralText1 leading-[70px]">
          Why Choose BusyBee?
        </div>
        <div className="flex mt-4 justify-center m-auto  border-4 border-secondary w-min overflow-hidden rounded-full">
          <div
            className={`px-10 py-3  font-black cursor-pointer    ${
              tab === "organisation"
                ? "bg-secondary text-neutralBg1 rounded-full transition ease-linear duration-500"
                : "gradient-radial-sm-text"
            } rounded-full`}
            onClick={() => {
              setTab("organisation");
              setImg(VendorMapping);
            }}
          >
            Organisations
          </div>
          <div
            className={`px-10 py-3 font-medium  font-black cursor-pointer overflow-hidden   ${
              tab === "salesperson"
                ? "bg-secondary text-neutralBg1 rounded-full transition ease-linear duration-500"
                : "gradient-radial-sm-text"
            }`}
            onClick={() => {
              setTab("salesperson");
              setImg(DailyVisits);
            }}
          >
            Salespersons
          </div>
        </div>
      </div>
      <div className="text-[36px] sm:w-3/4 xl:w-1/2 px-10 pt-10 m-auto lg:ml-0 2xl:mr-auto  2xl:text-left font-black w-full text-transparent bg-clip-text bg-gradient-radial-text from-successBg5 to-neutralText1 leading-[40px]">
        BusyBee packs handy tools for overseeing outdoor sales
      </div>
      <div className=" w-full m-auto pt-14 grid grid-cols-1 lg:grid-cols-2 px-10 items-center">
        <div className="col-span-1">
          <div className="flex flex-col-reverse 2xl:flex-row items-center">
            <div className="w-full  grid grid-cols-1 md:grid-cols-2 gap-x-8 items-center">
              {tab === "organisation" ? (
                <>
                  <SmallCard
                    icon={<Unicons.UilLocationPinAlt size={40} />}
                    label="Retailer Mapping"
                    info="Plot and view physical locations of retailers"
                    onClick={() => setImg(VendorMapping)}
                  />
                  <SmallCard
                    icon={<Unicons.UilMoneyBill size={40} />}
                    label="Sales Registry"
                    info="Build and maintain sales records automatically"
                    onClick={() => setImg(SalesRegister)}
                  />
                  <SmallCard
                    icon={<Unicons.UilCoins size={40} />}
                    label="Balance Registry"
                    info="Don’t forget those balance figures"
                    onClick={() => setImg(BalanceRegistry)}
                  />
                  <SmallCard
                    icon={<Unicons.UilBusSchool size={40} />}
                    label="Sales Visit Tracking"
                    info="See whose visiting when and how often."
                    onClick={() => setImg(VisitTracking)}
                  />
                  <SmallCard
                    icon={<Unicons.UilChart size={40} />}
                    label="Progress Statistics"
                    info="See how well your sales team is performing"
                    onClick={() => setImg(ProgressStatistics)}
                  />
                  <SmallCard
                    icon={<Unicons.UilFileCheckAlt size={40} />}
                    label="Order Tracking"
                    info="Make sure your restocking orders are delivered"
                    onClick={() => setImg(OrderTracking)}
                  />
                </>
              ) : (
                <>
                  <SmallCard
                    icon={<Unicons.UilCalendarAlt size={40} />}
                    label="Daily Visits"
                    info="Plan your day ahead by finding your daily visits"
                    onClick={() => setImg(DailyVisits)}
                  />
                  <SmallCard
                    icon={<Unicons.UilCoins size={40} />}
                    label="Balance log"
                    info="Know who owes you and how much"
                    onClick={() => setImg(BalanceLog)}
                  />
                  <SmallCard
                    icon={<Unicons.UilFileCheckAlt size={40} />}
                    label="Manage Orders"
                    info="Get notified for restocking retailers, and deliver at your ease"
                    onClick={() => setImg(ManageOrders)}
                  />
                  <SmallCard
                    icon={<Unicons.UilMoneyBill size={40} />}
                    label="Transaction Monitor"
                    info="View and manage all your transactions"
                    onClick={() => setImg(SalesMonitor)}
                  />
                  <SmallCard
                    icon={<Unicons.UilUsersAlt size={40} />}
                    label="Manage Retailers"
                    info="Keep your retailer profiles always up to date"
                    onClick={() => setImg(ManageVendors)}
                  />
                  <SmallCard
                    icon={<BiTargetLock size={40} />}
                    label="Target Goals"
                    info="See sales targets set for you and track your growth"
                    onClick={() => setImg(TargetGoals)}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full col-span-1 lg:ml-4  self-center">
          <img src={img} className="" alt=""></img>
        </div>
      </div>
    </>
  );
};
export default HowBusyBeeHelps;
