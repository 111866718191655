import { FiSearch, FiMail, FiPhone } from "react-icons/fi";

import CustomInput from "../../components/common/customInput";
import BaseButton from "../../components/common/baseButton";
import LandingFooter from "../../components/landingComponents/landingFooter";
import LandingHeader from "../../components/landingComponents/landingHeader";
import LandingNav from "../../components/landingComponents/landingNav";
import LearnSteps from "../../components/landingComponents/learnSteps";
import { Link } from "react-router-dom";

// {HowItWorks imports}
import CustomCard from "../howItWorks/CustomCard/CustomCard";
import * as Unicons from "@iconscout/react-unicons";
import { useState } from "react";
import links from "../howItWorks/Components/Links/links";
import AutoComplete from "../howItWorks/Components/AutoComplete/AutoComplete";

// {HowItWorks array of objects}
const cardLinks = [
  {
    heading: "Orders",
    subheading: "subheading for Orders.",
    link: "/learn/Orders/1",
    icon: <Unicons.UilFileCheckAlt width={30} height={30} color={"#2BB596"} />,
  },
  {
    heading: "Visits",
    subheading: "subheading for Visits.",
    link: "/learn/Visits/1",
    icon: <Unicons.UilLocationPoint width={30} height={30} color={"#2BB596"} />,
  },
  {
    heading: "Registration",
    subheading: "subheading for Registration.",
    link: "/learn/Registration/1",
    icon: <Unicons.UilUser width={30} height={30} color={"#2BB596"} />,
  },
  {
    heading: "Account",
    subheading: "subheading for Account.",
    link: "/learn/Account/1",
    icon: <Unicons.UilSetting width={30} height={30} color={"#2BB596"} />,
  },
  {
    heading: "Retailers",
    subheading: "subheading for Retailers.",
    link: "/learn/Retailer/1",
    icon: <Unicons.UilUsersAlt width={30} height={30} color={"#2BB596"} />,
  },
  {
    heading: "Products",
    subheading: "subheading for Products.",
    link: "/learn/Products/1",
    icon: <Unicons.UilLayerGroup width={30} height={30} color={"#2BB596"} />,
  },
  {
    heading: "Imports",
    subheading: "subheading for Imports.",
    link: "/learn/Imports/1",
    icon: <Unicons.UilImport width={30} height={30} color={"#2BB596"} />,
  },
  {
    heading: "Salesperson",
    subheading: "subheading for Salesperson.",
    link: "/learn/Salesperson/1",
    icon: <Unicons.UilUserSquare width={30} height={30} color={"#2BB596"} />,
  },
  {
    heading: "Transactions",
    subheading: "subheading for Transactions.",
    link: "/learn/Transactions/1",
    icon: <Unicons.UilTransaction width={30} height={30} color={"#2BB596"} />,
  },
];

const Learn = () => {
  // {HowItWorks variables}
  let result = [];
  // {HowItWorks useState}
  const [searchKeyword, setSearchKeyword] = useState("");
  const [content, setContent] = useState([result]);
  const [hideCards, setHideCards] = useState(false);

  // {HowItWorks functions}
  function handleSearch(event) {
    console.log("OnInput", event.target.value);
    let value = event.target.value;
    setSearchKeyword(value);
    result = links.filter((el) => {
      return el.text.toLowerCase().includes(value.toLowerCase());
    });
    if (value === "" || value === " ") {
      setHideCards(false);
    } else {
      setHideCards(true);
    }
    setContent([...result]);
    console.log("Result", result);
  }
  return (
    <div className="contain bg-backgroundLogoV bg-right-top bg-no-repeat">
      <LandingNav />
      <div className="text-center pb-12 sm:pb-0 pt-10 px-6 sm:px-0 lg:pt-24">
        <div className="sm:w-8/12 xl:w-1/2 m-auto">
          <LandingHeader
            header="WELCOME TO BUSYBEE"
            body="Start using busybee in less than 30 minutes"
          />
        </div>
        <div className="sm:w-3/4 md:w-1/2 xl:w-1/4 m-auto text-neutralText2 text-lg">
          Your sales team knows your retailer best. Let them create and maintain
          their details for you.
        </div>
      </div>
      <LearnSteps />
      <div className="text-center">
        <div className="2xl:text-[4rem] lg:text-[3rem] sm:text-[3rem] text-[1.9rem] sm:w-3/4 lg:w-1/2 m-auto  py-4 text-transparent bg-clip-text font-black bg-gradient-radial-text from-successBg5 to-neutralText1 leading-[38px] sm:leading-[48px] lg:leading-[55px] xl:leading-[60px] 2xl:leading-[70px]">
          Find help with illustrative examples{" "}
        </div>
        <div className="w-10/12 sm:w-6/12 m-auto sm:my-8 text-neutralText1 flex items-center border-2 border-neutralText5 bg-neutralBg1 rounded-sm px-2 py-1 focus-within:border-infoText5 transition ">
          <FiSearch size={38} />
          <input
            placeholder="Search articles and help"
            className={
              "bg-transparent text-xl text-neutralText2 px-2 sm:py-3 w-full outline-none"
            }
            onInput={handleSearch}
          />
        </div>
      </div>
      {/* How It Works starts */}

      {hideCards ? (
        <AutoComplete
          content={content}
          setContent={setContent}
          searchKeyword={searchKeyword}
        />
      ) : (
        <div className=" FAQs grid grid-rows-1 flex items-stretch sm:grid-cols-2 md:grid-cols-3 md:grid-rows-3 sm:gap-2 lg:gap-5grid-flow-row md:grid-flow-col w-3/6 mx-auto h-full">
          {cardLinks.map((item) => {
            return (
              <Link to={item.link}>
                <CustomCard
                  heading={item.heading}
                  subheading={item.subheading}
                  icon={item.icon}
                />
              </Link>
            );
          })}
        </div>
      )}

      {/* How It Works ends */}
      <div className="text-center py-20">
        <div className="2xl:text-[4rem] lg:text-[3rem] sm:text-[3rem] text-[1.4rem]  sm:pb-4 text-transparent bg-clip-text font-black bg-gradient-radial-sm-text from-successBg5 to-neutralText1 leading-[38px] sm:leading-[48px] lg:leading-[55px] xl:leading-[60px] 2xl:leading-[70px] ">
          Cannot Find Answer?
        </div>
        <div className="text-neutralText5 text-base sm:text-2xl">
          Contact us through below options
        </div>
        <div className=" text-left grid md:grid-cols-2 lg:grid-cols-3 gap-8 w-11/12 2xl:w-9/12 m-auto py-12">
          <div className="bg-neutralBg1 text-neutralBg5 font-light p-8 xl:p-14 rounded-2xl">
            <FiMail className="h-14 w-14" />
            <div className="sm:pt-8 xl:w-10/12 sm:text-[2.7rem] text-[1.9rem]  py-4 text-transparent bg-clip-text font-bold bg-gradient-radial-sm-text from-successBg5 to-neutralText1 leading-[38px] sm:leading-[48px]">
              Send us a Message
            </div>
            <div className="text-neutralText5 text-xl xl:text-2xl pb-6 font-semibold">
              Send us an email we will get back to you
            </div>
            <BaseButton
              text={"Go to Learn"}
              className=" py-3 px-10 text-neutralText1 text-xl font-semibold bg-primary"
            />
          </div>
          <div className="bg-neutralBg1 text-neutralBg5 font-light p-8 xl:p-14 rounded-2xl">
            <FiPhone className="h-14 w-14" />
            <div className="sm:pt-8 sm:w-8/12 lg:w-11/12 xl:w-10/12 sm:text-[2.7rem] text-[1.9rem]  py-4 text-transparent bg-clip-text font-bold bg-gradient-radial-sm-text from-successBg5 to-neutralText1 leading-[38px] sm:leading-[48px]">
              Give us a Call
            </div>
            <div className="text-neutralText5 md:w-10/12 lg:w-full text-xl xl:text-2xl pb-6 font-semibold">
              Contact us through below options
            </div>
            <BaseButton
              text={"Go to Learn"}
              className=" py-3 px-10 text-neutralText1 text-xl font-semibold bg-primary"
            />
          </div>{" "}
          <div className="bg-neutralBg1 text-neutralBg5 font-light p-8 xl:p-14 rounded-2xl">
            <FiPhone className="h-14 w-14" />
            <div className="sm:pt-8 xl:w-10/12 sm:text-[2.7rem] text-[1.9rem]  py-4 text-transparent bg-clip-text font-bold bg-gradient-radial-sm-text from-successBg5 to-neutralText1 leading-[38px] sm:leading-[48px]">
              Schedule a demo
            </div>
            <div className="text-neutralText5 text-xl xl:text-2xl pb-6 font-semibold">
              Contact us through below options
            </div>
            <BaseButton
              text={"Go to Learn"}
              className=" py-3 px-10 text-neutralText1 text-xl font-semibold bg-primary"
            />
          </div>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};
export default Learn;
