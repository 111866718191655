import React, { useState } from "react";
import { useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import AutoComplete from "../AutoComplete/AutoComplete";
import SearchAutoComplete from "../AutoComplete/SearchAutoComplete";
import links from "../Links/links";
const Search = function (props) {
  const [isHide, setIsHide] = useState(false);

  let result = [];
  const [searchKeyword, setSearchKeyword] = useState("");
  const [content, setContent] = useState(result);

  function handleSearch(event) {
    let value = event.target.value;
    setSearchKeyword(value);
    result = links.filter((el) => {
      return el.text.toLowerCase().includes(value.toLowerCase());
    });
    setContent([...result]);
    if (value === "") {
      setIsHide(false);
    }
  }

  return (
    //Search Bar.
    <>
      <div className=" flex overflow-scroll relative items-center w-fit">
        <div className="absolute  ml-2">
          <FiSearch className="w-6 h-6" />
        </div>
        <input
          className="search py-1 w-20 px-10 items-center 2xl:w-100 xl:w-96 lg:w-96 sm:w-40 md:w-60"
          type="search"
          value={searchKeyword}
          placeholder="Search articles"
          onInput={handleSearch}
        />
      </div>

      {searchKeyword && !isHide && (
        <SearchAutoComplete
          result={content}
          setSearchKeyword={setSearchKeyword}
          searchKeyword={searchKeyword}
          setIsHide={setIsHide}
        />
      )}
    </>
  );
};
export default Search;
