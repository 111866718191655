import React, { useState, useRef, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import * as Unicons from "@iconscout/react-unicons";

import CustomInput from "./customInput";
import { FilterType } from "../../constants/common";
import CustomAutocomplete from "./customAutocomplete";

function Filters({
  filters,
  setFilters,
  activeFilters,
  enableSearch,
  onSearch,
  searchValue,
}) {
  const [showFilters, setShowFilters] = useState(false);
  const [insideFilters, setInsideFilters] = useState(filters);
  const [currentFilter, setCurrentFilter] = useState();
  const toggle_ref = useRef(null);
  const contentRef = useRef(null);

  const handleAppliedFilters = (filterValue, filterKey, value) => {
    const f = activeFilters.filter((el) => el.filterKey === filterKey);
    let arr = [...activeFilters];

    if (f?.length > 0) {
      arr = activeFilters.map((el) => {
        if (el.filterKey === filterKey) {
          el.filterValue = filterValue;
          el.value = value;
        }
        return el;
      });
    } else {
      arr.push({ filterKey, filterValue, value });
    }
    setFilters(arr);
  };
  useEffect(() => {
    const listener = document.addEventListener("mousedown", (e) => {
      // user click toggle
      if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      } else {
        // user click outside toggle and content
        if (contentRef.current && !contentRef.current.contains(e.target)) {
          setShowFilters(false);
        }
      }
    });
    return document.removeEventListener("mousedown", listener);
  }, [activeFilters]);
  const handleShowfilters = (currFilter) => {
    setCurrentFilter(currFilter);
    setShowFilters(!showFilters);
  };

  return (
    <>
      {enableSearch && (
        <CustomInput
          icon={<Unicons.UilSearch className="text-neutralText1" />}
          placeholder="Search"
          containerClasses="mr-2"
          onChange={onSearch}
          value={searchValue}
        />
      )}
      <div className="flex text-neutralText1 text-sm">
        <div ref={toggle_ref} className="flex">
          {filters?.map((el, i) => {
            return el.filterType === FilterType.Autocomplete ? (
              <div key={`index-${i}`} className="px-1">
                <CustomAutocomplete
                  icon={el.filterIcon}
                  placeholder={el?.filterPlaceholder}
                  value={el?.selectedFilter ?? null}
                  onChange={(event, newValue) => {
                    el?.onFilterChange(newValue);
                  }}
                  filterOptions={(x) => x}
                  getOptionLabel={(option) =>
                    option ? option[el?.optionValue] : ""
                  }
                  options={el?.filterValues}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  onInputChange={(event, newInputValue) => {
                    el?.onFilterInputChange(newInputValue);
                  }}
                  loading={el?.filterLoading || false}
                  inputContainerClasses="!w-44"
                  inputClasses={`!border ${
                    el?.selectedFilter
                      ? "!border-infoText4 !bg-infoBg1"
                      : "!border-neutralText5"
                  } `}
                />
              </div>
            ) : (
              <div key={`index-${i}`} className="px-1">
                <div
                  onClick={() => handleShowfilters(el.filterKey)}
                  className={`flex ${
                    el?.selectedKey && el?.selectedKey[0]?.value
                      ? "bg-infoBg1 border-infoText4"
                      : "bg-neutralBg1 border-neutralBg5"
                  } items-center border border-0.5 p-2 py-1 cursor-pointer w-[12rem]`}
                >
                  {el.filterIcon}
                  <div className="flex justify-between items-center w-full">
                    <div className="px-2 text-sm text-[]">
                      {/* {appliedFilters.filter(
                      (i) => i.filterKey === el.filterKey
                    )[0]?.value || el.filterLabel} */}
                      {(el?.selectedKey && el?.selectedKey[0]?.value) ||
                        el?.filterLabel}
                    </div>
                    {showFilters && currentFilter === el.filterKey ? (
                      <Unicons.UilAngleUp height={24} width={24} />
                    ) : (
                      <Unicons.UilAngleDown height={24} width={24} />
                    )}
                  </div>
                </div>
                {showFilters && currentFilter === el.filterKey ? (
                  <div
                    ref={contentRef}
                    className="absolute bg-neutralBg1 p-4 mt-2 shadow-md z-10"
                  >
                    {el.filterValues.map((item, index) => {
                      return (
                        <div
                          key={`index-${index}`}
                          onClick={() => {
                            handleAppliedFilters(
                              item.key,
                              el.filterKey,
                              item.value
                            );
                            handleShowfilters();
                          }}
                          className="p-2 hover:bg-neutralBg2 cursor-pointer"
                        >
                          {item?.value}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Filters;

// {openCalendar ? (
//   <>
//     <div
//       // className="fixed w-full min-h-screen z-10 inset-0"
//       onClick={() => handleCalendar()}
//     ></div>
//     <div className=" bg-white z-30 absolute border border-gray-200 rounded right-0 lg:top-124 top-112 md:right-20 lg:right-44 md:px-0">
//       <DateRange
//         editableDateInputs={true}
//         onChange={(item) => setDate([item.selection])}
//         ranges={date}
//         moveRangeOnFirstSelection={false}
//       />
//       <div className="flex items-center justify-center py-2">
//         <button
//           className="bg-blue-500 text-white text-sm px-2 rounded mr-2"
//           onClick={() => filtering()}
//         >
//           Filter
//         </button>
//         <button
//           className="bg-gray-500 text-white text-sm px-2 rounded ml-2"
//           onClick={() => handleCalendar()}
//         >
//           Close
//         </button>
//       </div>
//     </div>
//   </>
// ) : (
//   ""
// )}
