import React, { useState, useEffect, useRef } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";

import SideNavItem from "./common/sideNavItem";

const ProfileSideNav = () => {
  const [sideMenu, setSideMenu] = useState(false);
  const toggle_ref = useRef(null);
  const contentRef = useRef(null);
  const history = useHistory();

  const toggleDropdown = () => {
    setSideMenu(!setSideMenu);
    console.log("dropdown in toggle finc", sideMenu);
  };

  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
    } else {
      // user click outside toggle and content
      if (contentRef.current && !contentRef.current.contains(e.target)) {
        setSideMenu(false);
      }
    }
  });

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        if (window.innerWidth > 1024) {
          setSideMenu(false);
        } else {
          console.log("lesser screen");
        }
      },
      true
    );

    // return () =>
  }, []);

  console.log("dropdown", history);
  return (
    <>
      <div
        ref={contentRef}
        className={`pr- pt-4 z-40 fixed h-full transform transition ease-linear duration-500 border-r border-r-neutralBg3 ${
          sideMenu
            ? "-translatex-0  lg:w-52  "
            : "-translate-x-full  lg:-translate-x-0 lg:w-52 lg:block"
        }`}
      >
        <div className="w-full">
          <button
            className="px-6 pt-6 lg:hidden"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <FiX />
          </button>
          <div className="flex flex-col justify-between items-center text-xs h-full overflow-y-auto max-h-screen">
            <div className=" pl-8 text-left w-full h-screen">
              <Link
                to="/dashboard"
                className="flex my-6 pr-10 items-center text-neutralText1"
              >
                <Unicons.UilArrowLeft />
                <div className="px-2">Back to Dashboard</div>
              </Link>
              <SideNavItem
                icon={<Unicons.UilDashboard size={20} />}
                name={"Overview"}
                link={"/overview"}
              />
              <SideNavItem
                icon={<Unicons.UilTransaction size={20} />}
                name={"Plans & Billing"}
                link={"/plans-and-billing"}
              />
              <SideNavItem
                icon={<Unicons.UilUsersAlt size={20} />}
                name={"Sign in & Security"}
                link={"/sign-in-and-security"}
              />
            </div>
            {/* <div>
              <img className="p-4 pt-8 m-auto" src={bottomLogo} alt="" />
              <div className="flex justify-center">
                <Link to="/plans">
                  <button className="text-xs bg-primary p-2 px-4 rounded-full text-secondary-text">
                    <div className="flex items-center">
                      <span className="px-2"> Upgrade to premium</span>{" "}
                      <FiArrowUp />
                    </div>
                  </button>
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="transform transition ease-linear w-0 lg:w-52  duration-500 -translate-x-full lg:-translate-x-0"></div>
      <div
        className={` z-20 h-16 flex items-center px-5 fixed transform transition  ${
          sideMenu ? "hidden" : "block w-1/12 lg:w-0 lg:hidden"
        } `}
      >
        <button ref={toggle_ref} onClick={() => setSideMenu(!sideMenu)}>
          {sideMenu ? null : <FiMenu />}
        </button>
      </div>
    </>
  );
};
export default ProfileSideNav;
