import React, { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useHistory } from "react-router-dom";

import { getVendorList } from "../../services/vendorsApi";
import SearchVendor from "../common/searchVendor";
import AddProducts from "./addProducts";
import ConfirmOrder from "./confirmOrder";

const steps = ["Select Retailer", "Add Products", "Confirm"];

function CreateOrder({ vendorDetails, orderDetails, handleClose }) {
  const history = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [vendor, setVendor] = useState();
  const [vendorList, setVendorList] = useState();

  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [count, setCount] = useState();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeFilters, setActiveFilters] = useState([]);

  const getData = async ({ page, rowsPerPage, query, status }) => {
    setLoading(true);
    setTableLoading(true);
    setPage(page);

    const resp = await getVendorList({
      offset: page,
      limit: rowsPerPage,
      query,
      status: "ACTIVE",
    });
    setVendorList(resp.result);
    setCount(resp.count);
    setLoading(false);
    setTableLoading(false);
  };

  const applyFilters = ({ page }) => {
    const filterBuilder = {};

    if (activeFilters.length > 0) {
      for (const item of activeFilters) {
        filterBuilder[item.filterKey] = item.filterValue;
      }
    }
    getData({ page, rowsPerPage, query, ...filterBuilder });
  };

  useEffect(() => {
    applyFilters({ page: 0 });
    if (vendorDetails) {
      setVendor(vendorDetails);
      setActiveStep(1);
    } else if (orderDetails) {
      setVendor(orderDetails.vendor);
      orderDetails.products.map((el) => {
        console.log("selectedProducts,.,", el);
        selectedProducts.push({
          id: el?.product?.id,
          name: el?.product?.name,
          unit: el?.product?.unit,
          value: el?.quantity,
        });
      });
      setActiveStep(1);
    }
  }, [activeFilters, rowsPerPage, query]);

  console.log("selectedProducts,.,", selectedProducts);

  return (
    <div className="pt-6">
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    active: "!fill-infoText3",
                    completed: "!fill-successBg5",
                    text: "!font-mulish !font-light",
                    root: "!p-[4px]",
                  },
                }}
                classes={{
                  label:
                    "!text-neutralText3 !font-mulish !font-light !text-xs !-ml-1",
                  active: "!text-neutralText3 !font-mulish !font-light",
                  completed: "!text-neutralText3 !font-mulish !font-light",
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === 0 ? (
        <SearchVendor
          vendorList={vendorList}
          onSearch={setQuery}
          next={() => setActiveStep(1)}
          onNewClick={() => history.push("/vendors")}
          setVendor={setVendor}
          selectedVendor={vendor}
          loading={loading}
        />
      ) : activeStep === 1 ? (
        <AddProducts
          next={() => setActiveStep(2)}
          prev={() => setActiveStep(0)}
          vendorDetails={vendorDetails}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      ) : activeStep === 2 ? (
        <ConfirmOrder
          selectedVendor={vendor}
          products={selectedProducts}
          setProducts={setSelectedProducts}
          next={() => setActiveStep(2)}
          prev={() => setActiveStep(1)}
          handleClose={handleClose}
          edit={orderDetails}
        />
      ) : null}
    </div>
  );
}

export default CreateOrder;
