import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import getSymbolFromCurrency from "currency-symbol-map";
import BaseButton from "../components/common/baseButton";

const CheckoutForm = ({ currency, price }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://example.com/order/123/complete",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <BaseButton
        className={
          "w-full mt-4 text-neutralBg1 font-extrabold bg-successText5 py-2 hover:bg-successBg4"
        }
        text={`Pay ${getSymbolFromCurrency(currency)}${price} `}
      />
    </form>
  );
};

export default CheckoutForm;
