import { GET_PROFILE } from "../actions/actionTypes";

//  Get organizer profile details
export const getProfileDetails = (response = {}, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return action.payload;
    default:
      return response;
  }
};
