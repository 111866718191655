import { APIClient } from "./apiClient";

export const getPlans = async (currencyCode) => {
  console.log("currencyCode", currencyCode);
  const params = {
    offset: 1,
    limit: 10,
    currency: currencyCode,
  };
  try {
    const response = await APIClient.get(`/org/subscription/plans`, { params });

    return response.data;
  } catch (err) {
    console.log("error in getPlans..", err);
  }
};

export const getOrderId = async (details) => {
  console.log("buyPlan..", details);
  try {
    const response = await APIClient.post(`/org/subscription/plan`, details);

    return response.data;
  } catch (err) {
    console.log("error in getOrderId..", err);
  }
};

export const addSeats = async ({ currency, users }) => {
  console.log("addseats", currency, users);
  const params = {
    currency: currency,
    spCount: users,
  };
  try {
    const response = await APIClient.post(`/org/subscription/salesperson`, {
      currency: currency,
      spCount: users,
    });

    return response.data;
  } catch (err) {
    console.log("error in getOrderId..", err);
  }
};

export const getSubscriptionBills = async (
  offset = 1,
  limit = 10,
  fromDate = "",
  toDate = "",
  status = "",
  sortField = "",
  sortOrder = ""
) => {
  const params = {
    offset: offset,
    limit: limit,
    fromDate: fromDate,
    toDate: toDate,
    status: status,
    sortField: sortField,
    sortOrder: sortOrder,
  };
  console.log("buyPlan..");
  try {
    const response = await APIClient.get(`/org/subscriptions`, { params });

    return response.data;
  } catch (err) {
    console.log("error in getOrderId..", err);
  }
};
export const getCurrentPlan = async (details) => {
  console.log("buyPlan..", details);
  try {
    const response = await APIClient.get(`/org/subscription`, details);

    return response.data;
  } catch (err) {
    console.log("error in getOrderId..", err);
  }
};
