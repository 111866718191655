import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

import Routes from "./screens/routes";
import "./theme/App.css";
import "./theme/calendar.css";
import "./theme/navBar.css";
import { applyTheme } from "./theme/utils";
import { lightPallette, darkPallette } from "./theme/base";
import { Theme } from "./constants/common";

function App() {
  let history = useHistory();
  const theme = useSelector((state) => state.theme);

  useEffect(() => {
    var DEBUG = true;
    if (!DEBUG) {
      if (!window.console) window.console = {};
      var methods = ["log", "debug", "warn", "info", "error"];
      for (var i = 0; i < methods.length; i++) {
        console[methods[i]] = function () {};
      }
    }
    applyTheme(theme === Theme.Dark ? darkPallette : lightPallette);
    history.listen((location, action) => {
      window.scrollTo(0, 0);
    });
  }, [theme]);

  return (
    <div className="App font-mulish bg-neutralBg2">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Routes />
    </div>
  );
}

export default App;
