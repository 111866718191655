import React from "react";
// import { FiX } from "react-icons/fi";
import { polygon, centroid } from "@turf/turf";

import BaseButton from "../common/baseButton";

function ListTerritories({
  gMap,
  gMaps,
  polygons,
  territories,
  onCreateClick,
  onTerritoryClick,
}) {
  return (
    <>
      <div className="px-4 py-4 bg-neutralBg1 border-b-[0.3px] border-neutralBg4">
        <div className="flex justify-between items-center text-neutralText1 mb-2">
          <p className=" font-bold text-lg">Territories</p>
          {/* <FiX
            onClick={toggleTerritories}
            className="cursor-pointer"
            size={18}
          /> */}
        </div>
        <BaseButton text="Create" onClick={onCreateClick} />
      </div>
      {territories.map((el, i) => {
        return (
          <div
            key={`ind-${i}`}
            style={{
              borderLeftColor: `${el.color}`,
            }}
            onClick={() => {
              const centre = centroid(polygon([el.coordinates]));

              gMap.current.panTo({
                lat: centre.geometry.coordinates[1],
                lng: centre.geometry.coordinates[0],
              });
              gMap.current.setZoom(12);

              onTerritoryClick(el, i);
            }}
            className={`flex justify-between items-center py-4 px-4 bg-neutralBg1 border-b-[0.3px] border-b-neutralBg4 cursor-pointer hover:bg-neutralBg2 border-l-4 `}
          >
            <p className="text-neutralText1 font-bold text-[0.7rem]">
              {el.name}
            </p>
            <div className="text-neutralText1 flex flex-col items-center">
              <p className={`text-lg font-bold`}>{el.vendorCount}</p>
              <p className={`text-[0.7rem]`}>Retailers</p>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default ListTerritories;
