import React from "react";

import BulkImport from "../../components/bulkImport";
import { BulkImportType } from "../../constants/common";

const steps = ["Select File", "Match Fields", "Review Data", "Add Products"];
const links = [
  { title: "Home", url: "/" },
  { title: "Retailers", url: "/vendors" },
  { title: "Bulk Import", url: "/bulk-import" },
];

const vendorFields = ["businessName", "locationName", "amount"];

const BulkImportVendors = () => {
  return (
    <BulkImport
      steps={steps}
      bulkImportType={BulkImportType.Vendor}
      links={links}
      busybeeFields={vendorFields}
    />
  );
};

export default BulkImportVendors;
