import * as React from "react";
import { useEffect, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FiSliders, FiX } from "react-icons/fi";

import { getOrders } from "../../services/ordersApi";
import CustomTable from "../../components/common/table";
import OuterDiv from "../../components/common/outerDiv";
import CustomTitle from "../../components/common/customTitle";
import CustomModal from "../../components/common/customModal";
import CustomModalTitle from "../../components/common/customModalTitle";
import Filters from "../../components/common/filters";
import OrderSidebar from "../../components/common/orderSidebar";
import BaseButton from "../../components/common/baseButton";
import CreateOrder from "../../components/createOrder";
import ConvertTimestamp from "../../components/convertTimestamp";
import CustomStatus from "../../components/common/customStatus";
import { getSalesPersons } from "../../services/salespersonApi";
import { getVendorList } from "../../services/vendorsApi";
import { FilterType } from "../../constants/common";
import noOrders from "../../assets/noOrders.svg";
import EmptyFallBack from "../../components/common/emptyFallback";
import { CustomDateRangePicker } from "../../components/common/customDatePicker";

const columns = [
  { key: "orderNo", name: "ORDER NO." },
  { key: "vendorName", name: "RETAILER" },
  { key: "createdAt", name: "ORDER DATE" },
  { key: "salesperson", name: "SALESPERSON" },
  { key: "status", name: "STATUS" },
  { key: "chevron", name: "", minWidth: 50, width: 50 },
];

const OrdersAndQueries = () => {
  const dataGridRef = React.useRef(null);

  const [modifiedData, setModifiedData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState();
  const [tableLoading, setTableLoading] = useState(false);
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeFilters, setActiveFilters] = useState([]);
  const [openFormModal, setOpenFormModal] = useState(false);

  const [selectedSalesperson, setSelectedSalesperson] = useState();
  const [searchedSalespersons, setSearchedSalespersons] = useState([]);
  const [salespersonsLoading, setSalespersonsLoading] = useState(false);

  const [selectedVendor, setSelectedVendor] = useState();
  const [searchedVendors, setSearchedVendors] = useState([]);
  const [vendorsLoading, setVendorsLoading] = useState(false);

  const dateStart = new Date();
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateStart.setHours(0, 0, 0, 0);

  const dateEnd = new Date();
  dateEnd.setHours(23, 59, 59, 99);

  const [date, setDate] = useState([
    {
      startDate: dateStart,
      endDate: dateEnd,
      key: "selection",
    },
  ]);

  const currentUser = useSelector((s) => s.login);

  const searchSalespersons = async (query) => {
    setSalespersonsLoading(true);
    const resp = await getSalesPersons({
      offset: 1,
      limit: 5,
      query,
    });
    setSearchedSalespersons(resp.result || []);
    setSalespersonsLoading(false);
  };

  const searchVendors = async (query) => {
    setVendorsLoading(true);
    const resp = await getVendorList({
      offset: 1,
      limit: 5,
      query,
    });
    setSearchedVendors(resp.result || []);
    setVendorsLoading(false);
  };

  const getOptionsList = async () => {
    const spResp = await getSalesPersons({
      offset: 1,
      limit: 5,
    });
    setSearchedSalespersons(spResp.result || []);
    const vendorResp = await getVendorList({
      offset: 1,
      limit: 5,
      status: "ACTIVE",
    });
    setSearchedVendors(vendorResp.result || []);
  };

  const filters = [
    {
      filterKey: "status",
      filterLabel: "Status",
      selectedFilter: activeFilters.length > 0,
      selectedKey: activeFilters,
      filterType: FilterType.Default,
      filterIcon: <Unicons.UilCheck />,
      filterValues: [
        {
          key: "NEW",
          value: "New",
        },
        {
          key: "PROCESSING",
          value: "Processing",
        },
        {
          key: "COMPLETED",
          value: "Completed",
        },
        {
          key: "CANCELLED",
          value: "Cancelled",
        },
      ],
    },
    {
      filterKey: "vendor",
      filterLabel: "Retailer",
      filterType: FilterType.Autocomplete,
      selectedFilter: selectedVendor,
      onFilterChange: setSelectedVendor,
      onFilterInputChange: searchVendors,
      filterLoading: vendorsLoading,
      optionValue: "businessName",
      filterPlaceholder: "Retailer",
      filterIcon: <Unicons.UilUsersAlt size={24} />,
      filterValues: searchedVendors,
    },
    {
      filterKey: "salesperson",
      filterLabel: "Salesperson",
      filterType: FilterType.Autocomplete,
      selectedFilter: selectedSalesperson,
      onFilterChange: setSelectedSalesperson,
      onFilterInputChange: searchSalespersons,
      filterLoading: salespersonsLoading,
      optionValue: "name",
      filterPlaceholder: "Salesperson",
      filterIcon: <Unicons.UilUser size={24} />,
      filterValues: searchedSalespersons,
    },
  ];

  const getData = async ({ refresh = true, page, rowsPerPage, ...filters }) => {
    setTableLoading(refresh);
    setOffset(page - 1);

    const data = await getOrders({
      offset: page,
      limit: rowsPerPage,
      query,
      spId: selectedSalesperson?.id,
      vendorId: selectedVendor?.id,
      startDate: parseInt(date[0].startDate / 1000, 10),
      endDate: parseInt(date[0].endDate / 1000, 10),
      ...filters,
    });
    setCount(data.count);
    setOrders(data?.result || []);

    setTableLoading(false);
  };

  const applyFilters = ({ refresh = true, page }) => {
    const filterBuilder = {};

    if (activeFilters.length > 0) {
      for (const item of activeFilters) {
        filterBuilder[item.filterKey] = item.filterValue;
      }
    }
    getData({ refresh, page, rowsPerPage, ...filterBuilder });
  };

  useEffect(() => {
    applyFilters({ page: 1 });
    getOptionsList();
  }, [
    activeFilters,
    rowsPerPage,
    query,
    selectedSalesperson,
    selectedVendor,
    selectedOrder,
    date,
  ]);

  const clearFilters = () => {
    setSelectedSalesperson(null);
    setSelectedVendor(null);
    setSearchedSalespersons([]);
    setSearchedVendors([]);
    setActiveFilters([]);
    setDate([
      {
        startDate: dateStart,
        endDate: dateEnd,
        key: "selection",
      },
    ]);
  };

  useEffect(() => {
    const temp = orders?.map((el) => {
      return {
        ...el,
        vendorName: (
          <div className="text-infoText4 font-normal truncate">
            <Link to={`/view-vendor/${el.vendor?.id}`}>
              {el.vendor?.businessName}
            </Link>
          </div>
        ),
        salesperson: (
          <div className="text-infoText4 font-normal truncate">
            <Link to={`/view-salesperson/${el?.salesperson?.id}`}>
              {el?.salesperson?.name}
            </Link>
          </div>
        ),
        orderNo: (
          <div className="text-neutralText3 font-normal truncate">
            {el.id.slice(0, 8)}
          </div>
        ),
        createdAt: (
          <ConvertTimestamp
            timestamp={el.createdAt}
            className=" text-neutralText1 font-medium truncate"
          />
        ),
        status: <CustomStatus status={el.status} time={el.updatedAt} />,
        chevron: (
          <Unicons.UilAngleRightB className="text-neutralText1" size="20px" />
        ),
      };
    });
    setModifiedData(temp);
  }, [orders]);

  const handleOrder = (row) => {
    setSelectedOrder(row.id);
  };
  const closeOrder = () => {
    setSelectedOrder(null);
  };

  return (
    <>
      <div className="flex flex-row">
        {/* <SideNav /> */}
        {false ? (
          <div className="m-auto h-screen flex items-center">
            {/* <CircularProgress color="white" size={60} /> */}
          </div>
        ) : (
          <div className="w-full grid grid-cols-10  pb-12 pl-6">
            <div className="flex justify-between col-span-7 items-center pt-12">
              <CustomTitle
                title="Orders"
                description="Manage and Track Orders"
              />
              <CustomModal
                title={
                  <CustomModalTitle
                    title="Add new Order"
                    description="Enter product information and package unit"
                  />
                }
                boxStyle={{ p: 0, width: 550 }}
                topContainerStyle={"px-[32px] pt-[32px]"}
                bottomContainerStyle={
                  "px-[32px] pb-[32px] bg-neutralBg3 rounded-b mt-2"
                }
                button={<BaseButton text="Add New Order" />}
                openModal={openFormModal}
                setOpenModal={setOpenFormModal}
              >
                <CreateOrder
                  handleClose={() => {
                    setOpenFormModal(false);
                    applyFilters({ refresh: false, page: offset + 1 });
                  }}
                />
              </CustomModal>
            </div>

            <div
              className={
                // backdrop for ordersidebar
                `${
                  selectedOrder
                    ? "fixed w-10/12 h-full flex justify-end z-10"
                    : "col-span-3"
                }`
              }
              onClick={(e) => closeOrder()}
            >
              <div
                className={`fixed z-10 w-3/12 bg-neutralBg1 shadow-[-27px_-10px_52px_-41px_rgba(0,0,0,0.64)] px-6 py-8 pb-20 h-screen scrollbar scrollbar-thumb-neutralBg1 scrollbar-track-neutralBg1 scrollbar-thin hover:scrollbar-thumb-neutralBg5 transform transition duration-300 ${
                  selectedOrder
                    ? "opacity-1 translate-x-0 "
                    : "translate-x-full opacity-0 "
                }`}
                //added, so that ordersidebar should not close on clicking on it
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <OrderSidebar
                  orderId={selectedOrder}
                  closeOrder={closeOrder}
                  onClose={() =>
                    applyFilters({ refresh: false, page: offset + 1 })
                  }
                />
              </div>
            </div>
            <div className="col-span-7">
              <OuterDiv className={``}>
                <div className="pt-8 pl-8 sm:pl-6">
                  <div className="flex flex-col justify-between  pb-4">
                    <div className="text-base text-neutralText1 font-medium mb-2">
                      List of Orders
                    </div>
                    <div className="flex items-center">
                      <div className=" mr-2">
                        {filters
                          .map((el) => !!el.selectedFilter)
                          .includes(true) ||
                        date[0].startDate / 1000 !== dateStart / 1000 ||
                        date[0].endDate / 1000 !== dateEnd / 1000 ? (
                          <button onClick={() => clearFilters()}>
                            <div className="flex items-center justify-center text-infoText4 text-xs">
                              <FiX size={14} />
                              Clear
                            </div>
                          </button>
                        ) : (
                          <FiSliders className=" text-neutralText1" size={18} />
                        )}
                      </div>
                      <CustomDateRangePicker
                        date={date}
                        setDate={setDate}
                        changed={
                          date[0].startDate / 1000 !== dateStart / 1000 ||
                          date[0].endDate / 1000 !== dateEnd / 1000
                        }
                      />
                      <Filters
                        setFilters={setActiveFilters}
                        activeFilters={activeFilters}
                        filters={filters}
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      // Fix for `cellFocus (select) breaks input functionality`
                      dataGridRef.current.selectCell(
                        {
                          idx: 0,
                          rowIdx: -1,
                          mode: "SELECT",
                        },
                        true
                      );
                    }}
                  >
                    <CustomTable
                      dataGridRef={dataGridRef}
                      columns={columns}
                      rows={modifiedData}
                      emptyFallBack={
                        <EmptyFallBack
                          image={noOrders}
                          text="Sorry couldn’t find any orders"
                          containerClass={"mt-14"}
                          imageClass={"h-44"}
                        />
                      }
                      bodyRowProps={{
                        onClick: (row) => handleOrder(row),
                        isSelected: (row) => row?.id === selectedOrder,
                      }}
                      count={count}
                      page={offset}
                      onPageChange={(_, page) =>
                        applyFilters({ page: page + 1 })
                      }
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={(e) =>
                        setRowsPerPage(e.target.value)
                      }
                      loading={tableLoading}
                    />
                  </div>
                </div>
              </OuterDiv>
            </div>

            {/* <div className="col-span-3">
              <div
                className={`fixed w-3/12 h-[50.5rem] scrollbar scrollbar-thumb-neutralText4 scrollbar-track-neutralBg3 scrollbar-thin hover:scrollbar-thumb-neutralText4 ${
                  selectedOrder
                    ? "opacity-1 col-span-3 my-6 bg-neutralBg3 px-6 py-8"
                    : "width-0 opacity-0 col-span-3 my-6 bg-neutralBg3 px-6 py-8"
                }`}
              >
                <OrderSidebar
                  orderId={selectedOrder}
                  closeOrder={closeOrder}
                  onClose={() =>
                    applyFilters({ refresh: false, page: offset + 1 })
                  }
                />
              </div>
            </div> */}
          </div>
        )}
      </div>
    </>
  );
};
export default OrdersAndQueries;
