import * as Unicons from "@iconscout/react-unicons";

const SmallCard = ({ icon, label, info, onClick }) => {
  return (
    <button
      className={`flex  bg-neutralBg1 px-3  py-6 rounded-xl hover:shadow-xl mb-6 ${
        onClick ? "cursor-pointer focus:shadow-xl" : ""
      }`}
      onClick={onClick}
    >
      <div className="text-secondary p-3 rounded-xl bg-successBg1">{icon}</div>
      <div className="pl-4 text-left text-sm">
        <div className="text-secondary font-extrabold text-md">{label}</div>
        <div className="text-neutralText5 2xl:w-3/4">{info}</div>
      </div>
    </button>
  );
};
export default SmallCard;
