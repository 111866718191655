import { io } from "socket.io-client";
import { store } from "..";
// import { SOCKET_URL } from "../constants/config";

export const startSocket = () => {
  const state = store.getState();
  const authToken = state.login.token;

  const socket = io(process.env.REACT_APP_SOCKET_URL, {
    auth: {
      token: authToken,
    },
    transports: ["websocket"],
  });

  socket.on("connect", () => {
    console.log("socket connected", socket.id);
  });

  socket.on("connect_error", () => {
    console.log("socket error");
  });

  return socket;
};
