const EmptyFallBack = ({ image, text, containerClass, imageClass }) => {
  return (
    <div className={containerClass}>
      <div className={"flex justify-center m-auto " + imageClass}>
        <img src={image} alt="noRetailers" />
      </div>
      <div className="pt-4 text-neutralBg5 font-black">{text}</div>
    </div>
  );
};
export default EmptyFallBack;
