import GoogleMap from "google-map-react";
import { MAPS_API_KEY } from "../constants/config";

const Map = ({ mapRef, ...props }) => (
  <GoogleMap
    bootstrapURLKeys={{
      key: MAPS_API_KEY,
      libraries: ["places"],
    }}
    center={[34.0837, 74.7973]} // TODO: Set based on all territories
    zoom={12}
    {...props}
  >
    {props.children}
  </GoogleMap>
);
export default Map;
