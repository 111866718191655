import {
  GET_DASHBOARD_STATS,
  GET_DASHBOARD_RANK_LIST
} from "../actions/actionTypes";

//for getting earnings and other stats
export const dashboardStats = (response = null, action) => {
  switch (action.type) {
    case GET_DASHBOARD_STATS:
      return action.payload;
    default:
      return response;
  }
};

//for getting rank list
export const dashboardRankList = (response = null, action) => {
  switch (action.type) {
    case GET_DASHBOARD_RANK_LIST:
      return action.payload;
    default:
      return response;
  }
};
