import React, { useState } from "react";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";
function SearchAutoComplete({
  result,
  searchKeyword,
  setIsHide,
  setSearchKeyword,
}) {
  // var Highlight = require("react-highlighter");

  return (
    <>
      <div
        style={{ width: "28%" }}
        className="bg-white absolute right-0 top-40 shadow shadow-2xl overflow-scroll p-4 mx-16 h-80 text-justify mx-auto py-4 mt-6"
      >
        {result.length > 0 ? (
          result.map((item, index) => {
            return (
              <div
                key={index}
                className="px-4 mb-4 text-[#60A5FA] hover:cursor-pointer  hover:text-[#999999]"
              >
                <Link
                  onClick={() => {
                    setIsHide(true);
                    setSearchKeyword("");
                  }}
                  className="underline underline-offset-4 pr-6"
                  to={"/learn/" + item.category + "/" + item.id}
                >
                  {/* <Highlight search={searchKeyword}>{item.text}</Highlight> */}
                  <Highlighter
                    highlightClassName="YourHighlightClass"
                    searchWords={[...searchKeyword]}
                    autoEscape={true}
                    textToHighlight={item.text}
                  />
                </Link>

                <span className=" no-underline bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                  {item.category}
                </span>
              </div>
            );
          })
        ) : (
          <div className="text-center mx-auto text-red-900 font-bold">
            No results found
          </div>
        )}
      </div>
    </>
  );
}
export default SearchAutoComplete;
