import React from "react";
import * as Unicons from "@iconscout/react-unicons";

import Avatar from "./common/avatar";

function SelectVendorCard({ search, vendor, setVendor, checked }) {
  return (
    <div
      className="flex items-center bg-neutralBg1 py-2 px-3 cursor-pointer"
      // onClick={() => setVendor(el.name)}
      onClick={() => setVendor(vendor)}
    >
      <Avatar imageClassName={"w-12  h-12"} />
      <div className="flex justify-between w-full items-center">
        <div className="px-2 font-light">
          <div className="text-left text-neutralText2 text-xs">
            {vendor?.businessName}
          </div>
          <div className="flex text-infoText4 mt-1 items-center">
            <Unicons.UilLocationPoint size="15" />
            <div className=" text-neutralText4 text-[9px] ml-0.5">
              {vendor?.locationName}
            </div>
          </div>
        </div>
        {search ? (
          <input
            type="radio"
            name="ans"
            onChange={() => setVendor(vendor)}
            checked={checked}
          />
        ) : null}
      </div>
    </div>
  );
}

export default SelectVendorCard;
