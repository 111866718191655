import React, { useState } from "react";

import BulkImport from "../../components/bulkImport";
import { BulkImportType } from "../../constants/common";

const steps = [
  "Select File",
  "Match Fields",
  "Review Data",
  "Add Salespersons",
];
const links = [
  { title: "Home", url: "/" },
  { title: "Salespersons", url: "/salespersons" },
  { title: "Bulk Import", url: "/bulk-import" },
];

const salespersonFields = ["name", "locationName", "contact"];

const BulkImportSalespersons = () => {
  return (
    <BulkImport
      steps={steps}
      bulkImportType={BulkImportType.Salesperson}
      links={links}
      busybeeFields={salespersonFields}
    />
  );
};

export default BulkImportSalespersons;
