import React, { useState } from "react";
import Dashboard from "./Dashboard";

import links from "../Links/links";
import { useParams } from "react-router-dom";
import LandingHeader from "../../../../components/landingComponents/landingHeader";
import LandingNav from "../../../../components/landingComponents/landingNav";
import LandingFooter from "../../../../components/landingComponents/landingFooter";
import Navbar from "../Navbar/Navbar";

const MainScreen = function () {
  const params = useParams();
  let content = [];
  let id = params.id;
  let category = params.category;
  switch (category) {
    case "Orders":
      content = links.filter((el) => {
        return el.category === "Orders";
      });
      break;
    case "Account":
      content = links.filter((el) => {
        return el.category === "Account";
      });
      break;
    case "Imports":
      content = links.filter((el) => {
        return el.category === "Imports";
      });
      break;
    case "Visits":
      content = links.filter((el) => {
        return el.category === "Visits";
      });
      break;
    case "Retailer":
      content = links.filter((el) => {
        return el.category === "Retailer";
      });
      break;
    case "Salesperson":
      content = links.filter((el) => {
        return el.category === "Salesperson";
      });
      break;
    case "Registration":
      content = links.filter((el) => {
        return el.category === "Registration";
      });
      break;
    case "Products":
      content = links.filter((el) => {
        return el.category === "Products";
      });
      break;
    case "Transactions":
      content = links.filter((el) => {
        return el.category === "Transactions";
      });
      break;
    default:
      content = [];
  }

  return (
    <>
      <LandingNav />
      <div className="xl:container xl:mx-auto">
        <Navbar category={category} content={content} />
        <div className="flex  flex-row">
          <Dashboard content={content} id={id} />
        </div>
      </div>
      <LandingFooter />
    </>
  );
};
export default MainScreen;
