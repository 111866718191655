import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FiHelpCircle } from "react-icons/fi";
import getSymbolFromCurrency from "currency-symbol-map";

import CustomTitle from "../../components/common/customTitle";
import OuterDiv from "../../components/common/outerDiv";
import BaseButton from "../../components/common/baseButton";
import {
  getSubscriptionBills,
  getCurrentPlan,
} from "../../services/plansAndBilling";
import ConvertTimestamp from "../../components/convertTimestamp";
import CustomTable from "../../components/common/table";
import EmptyFallBack from "../../components/common/emptyFallback";
import getCurrency from "../../utils/getCurrency";
import { useSelector } from "react-redux";
import FullscreenLoader from "../../components/common/fullscreenLoader";

const columns = [
  { key: "date", name: "DATE", minWidth: 50 },
  { key: "description", name: "DESCRIPTION", minWidth: 80 },
  { key: "spLimit", name: "SEATS", minWidth: 200 },
  { key: "amount", name: "INVOICES TOTAL", minWidth: 80 },
  { key: "status", name: "STATUS", minWidth: 120 },
];

function PlansAndBilling() {
  const currentUser = useSelector((state) => state?.getProfileDetails);

  const [modifiedData, setModifiedData] = useState([]);
  const [currentPlan, setCurrentPlan] = useState([]);
  const [loader, setLoader] = useState(true);
  const [plans, setPlans] = useState([]);
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeFilters, setActiveFilters] = useState([]);

  const getData = async () => {
    setLoader(true);
    const resp = await getSubscriptionBills();
    const data = await getCurrentPlan();
    setCurrentPlan(data);
    setPlans(resp?.data || []);
    console.log("bills", resp?.data, data);
    setLoader(false);
  };

  const applyFilters = ({ refresh = true, page }) => {
    const filterBuilder = {};

    if (activeFilters?.length > 0) {
      for (const item of activeFilters) {
        filterBuilder[item?.filterKey] = item?.filterValue;
      }
    }
    getData({ refresh, page, rowsPerPage, ...filterBuilder });
  };

  useEffect(() => {
    applyFilters({ page: 1 });
  }, [activeFilters, rowsPerPage]);

  useEffect(() => {
    const temp = plans?.map((el) => {
      return {
        ...el,
        description: (
          <div className="text-neutralText1 font-extrabold truncate">
            {el?.planType}
          </div>
        ),
        date: <ConvertTimestamp timestamp={el?.createdAt} />,
        amount: (
          <div className="text-neutralText4">
            {getSymbolFromCurrency(el?.currency)} {el.amount / 100 || 0}
          </div>
        ),
        description: <div>{el?.plan?.planName || "TRIAL"}</div>,
        spLimit: <div>{el?.spLimit || 1}</div>,
      };
    });
    setModifiedData(temp);
  }, [plans]);
  console.log("cueeOlan");
  return (
    <>
      {loader ? (
        <div className="m-auto h-screen flex items-center">
          <FullscreenLoader className="!text-neutralText3" />
        </div>
      ) : (
        <div className="flex flex-row">
          <div className="w-full pb-12 grid grid-cols-4 px-6">
            <div className="col-span-3 w-11/12 pt-12">
              <CustomTitle
                title="Plans And Billing"
                description="Manage your BusyBee Plans"
              />
              <OuterDiv>
                <div className="px-6 pt-6">
                  <CustomTitle
                    title="Your Plans"
                    description="Available sales person seats for montly plan"
                  />
                </div>
                <div className="grid grid-cols-3 w-10/12 px-6 pt-4 pb-8">
                  <div className="px-6 py-4 border-2 border-neutralBg3">
                    <div className="flex items-center text-xs text-neutralText4">
                      <div className="pr-1">Billling Cycle</div>
                      <FiHelpCircle />
                    </div>
                    <div className="text-[26px] text-neutralText1 font-black">
                      {" "}
                      {currentPlan?.plan?.planName || "TRIAL"}
                    </div>
                  </div>
                  <div className="px-6 py-4 border-y-2 border-neutralBg3">
                    <div className="flex items-center text-xs text-neutralText4">
                      <div className="pr-1">Total Seats</div>
                      <FiHelpCircle />
                    </div>
                    <div className="text-[26px] text-neutralText1 font-black flex items-center justify-between">
                      {" "}
                      {currentPlan?.spLimit || 1}{" "}
                      <Link
                        to={
                          !currentPlan || currentPlan?.planType === "TRIAL"
                            ? {
                                pathname: "/pricing",
                                state: { fromBills: true },
                              }
                            : {
                                pathname: "/buy-plan",
                                state: { buyPlan: currentPlan },
                              }
                        }
                      >
                        <BaseButton
                          text={
                            !currentPlan || currentPlan?.planType === "TRIAL"
                              ? "Subscribe"
                              : "Add Seats"
                          }
                          type="border border-infoText5 hover:bg-infoText5 hover:text-white rounded-full !px-3 !py-0.5 text-[10px]"
                          textColor="infoText5"
                          height=" "
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="px-6 py-4 border-2 border-neutralBg3">
                    <div className="flex items-center text-xs text-neutralText4">
                      <div className="pr-1">Seats used</div>
                      <FiHelpCircle />
                    </div>
                    <div className="text-[26px] text-neutralText1 font-black">
                      {currentUser?.activeSalespersons}
                    </div>
                  </div>
                </div>
              </OuterDiv>
              <OuterDiv>
                <div className="px-6 pt-6">
                  <CustomTitle
                    title="Billing"
                    description="Manage your BusyBee Plans"
                  />
                </div>
                <div className="grid grid-cols-3 px-6 py-8">
                  <div className="px-6 py-4 border-2 border-neutralBg3">
                    <div className="flex items-center text-xs text-neutralText4">
                      <div className="pr-1">Next Payment Date</div>
                      <FiHelpCircle />
                    </div>
                    <div className="text-[26px] text-neutralText1 font-black">
                      {" "}
                      {<ConvertTimestamp timestamp={currentPlan?.endDate} />}
                    </div>
                  </div>
                  <div className="px-6 py-4 border-y-2 border-neutralBg3">
                    <div className="flex items-center text-xs text-neutralText4">
                      <div className="pr-1">Current Rate</div>
                      <FiHelpCircle />
                    </div>
                    <div className="text-[26px] text-neutralText1 font-black">
                      {getSymbolFromCurrency(currentPlan?.currency)}
                      {currentPlan?.pricePerSp || 0}/
                      <span className="text-sm text-neutralText3">
                        per seat
                      </span>
                    </div>
                  </div>
                  <div className="px-6 py-4 border-2 border-neutralBg3">
                    <div className="flex items-center text-xs text-neutralText4">
                      <div className="pr-1">Billing contact</div>
                      <FiHelpCircle />
                    </div>
                    <div className="text-[26px] text-neutralText1 font-black">
                      <div className="text-neutralText1 text-sm">
                        {currentUser?.email}
                      </div>
                      <div className="text-xs font-light text-infoText4">
                        {/* change */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-neutralText1 font-black text-lg px-6">
                  Invoices
                </div>
                <div className="px-6 py-4">
                  <CustomTable
                    // dataGridRef={dataGridRef}
                    columns={columns}
                    rows={modifiedData}
                    // emptyFallBack={
                    //   <EmptyFallBack
                    //     image={noSalesperson}
                    //     text="No Salespersons found"
                    //     containerClass={"mt-14 mb-4"}
                    //     imageClass={"w-56"}
                    //   />
                    // }
                    // bodyRowProps={{
                    //   path: "/view-salesperson",
                    // }}
                    count={count}
                    page={offset}
                    onPageChange={(_, page) => applyFilters({ page: page + 1 })}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
                    // loading={tableLoading}
                  />
                </div>
              </OuterDiv>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PlansAndBilling;
