import React, { useEffect, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import tailwindConfig from "../../theme/tailwindConfig";
import ConvertTimestamp from "../convertTimestamp";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 7,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: tailwindConfig.theme.colors.neutralText1,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: tailwindConfig.theme.colors.successText5,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1.5,
    border: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 15,
  height: 15,
  display: "flex",
  borderRadius: "50%",
  // justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: tailwindConfig.theme.colors.successText5,
  }),
  ...(ownerState.completed && {
    backgroundColor: tailwindConfig.theme.colors.successText5,
  }),
}));

function ProcessState({ processState, processDate }) {
  const [currentStep, setCurrentStep] = useState(1);

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons =
      processState === "CANCELLED"
        ? processDate?.processedAt
          ? {
              1: <Unicons.UilCheck />,
              2: <Unicons.UilCheck />,
              3: (
                <div className="text-xs rounded-full bg-errorBg5">
                  <Unicons.UilTimes size={15} />
                </div>
              ),
            }
          : {
              1: <Unicons.UilCheck />,
              2: (
                <div className="text-xs rounded-full bg-errorBg5">
                  <Unicons.UilTimes size={15} />
                </div>
              ),
            }
        : {
            1: <Unicons.UilCheck />,
            2: <Unicons.UilCheck />,
            3: <Unicons.UilCheck />,
          };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const steps =
    processState === "CANCELLED"
      ? processDate?.processedAt
        ? [
            <div className="text-[10px] text-neutralText3 -mt-2">
              <ConvertTimestamp timestamp={processDate?.createdAt} />{" "}
            </div>,
            <div className="text-[10px] text-neutralText3 -mt-2">
              <ConvertTimestamp timestamp={processDate?.processedAt} />
            </div>,
            <div className="text-[10px] text-neutralText3 -mt-2">
              <ConvertTimestamp timestamp={processDate?.updatedAt} />
            </div>,
          ]
        : [
            <div className="text-[10px] text-neutralText3 -mt-2">
              <ConvertTimestamp timestamp={processDate?.createdAt} />{" "}
            </div>,
            <div className="text-[10px] text-neutralText3 -mt-2">
              <ConvertTimestamp timestamp={processDate?.updatedAt} />
            </div>,
          ]
      : [
          <div className="text-[10px] text-neutralText3 -mt-2">
            <ConvertTimestamp timestamp={processDate?.createdAt} />
          </div>,
          <div className="text-[10px] text-neutralText3 -mt-2">
            {processDate?.processedAt ? (
              <ConvertTimestamp timestamp={processDate?.processedAt} />
            ) : (
              "--"
            )}
          </div>,
          <div className="text-[10px] text-neutralText3 -mt-2">
            {processState === "COMPLETED" ? (
              <ConvertTimestamp timestamp={processDate?.updatedAt} />
            ) : (
              "--"
            )}
          </div>,
        ];

  useEffect(() => {
    if (processState === "COMPLETED") {
      setCurrentStep(3);
    } else if (processState === "PROCESSING") {
      setCurrentStep(2);
    } else if (processState === "CANCELLED" && processDate?.processedAt) {
      setCurrentStep(2);
    } else {
      setCurrentStep(1);
    }
  }, [processState]);

  return (
    <div className="py-2">
      <Box sx={{ width: "100%" }}>
        <div
          className={`flex justify-around ${
            processState === "CANCELLED" && !processDate?.processedAt ? "" : ""
          } pb-2 text-sm text-neutralText3`}
        >
          <div className="rounded-full ml-2">Placed</div>
          {processState === "CANCELLED" ? (
            <>
              {processDate?.processedAt ? (
                <div className="rounded-full pl-2">Processed</div>
              ) : (
                ""
              )}
              <div className="rounded-full pl-2">Cancelled</div>
            </>
          ) : (
            <>
              <div className="rounded-full pl-2">Processed</div>
              <div className="rounded-full">Complete</div>
            </>
          )}
        </div>
        <Stepper
          activeStep={currentStep}
          alternativeLabel
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step key={`label-${index}`}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                classes={{ label: "text-[10px]" }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {/* 
      <div className="flex justify-between items-center text-neutralBg1 px-4">
        <div className="rounded-full bg-successText5 border border-successText5">
          <Unicons.UilCheck size={15} />
        </div>
        <div className="border-t border-t-successText5 w-full" />
        <div className="rounded-full bg-successText5 border border-successText5">
          <Unicons.UilCheck size={15} />
        </div>
        <div className="border-t  border-t-successText5 w-full" />
        <div className="rounded-full bg-successTex p-1.5 border border-successText5"> */}
      {/* <Unicons.UilCheck size={15} />
        </div>
      </div> */}
    </div>
  );
}

export default ProcessState;
