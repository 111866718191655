import { DropzoneAreaBase } from "mui-file-dropzone";
import React from "react";
import { FiFileText } from "react-icons/fi";
import { toast } from "react-toastify";
import CsvParser from "papaparse";

import { BulkImportType } from "../../constants/common";
import BaseButton from "../common/baseButton";

const SelectFile = ({
  files,
  selectFiles,
  setParsed,
  next,
  bulkImportType,
}) => {
  const onAddFiles = (f) => {
    selectFiles(f);
    if (f && f.length > 0) {
      const reader = new FileReader();

      reader.onload = () => {
        const str = reader.result;

        const parsed = CsvParser.parse(str, {
          header: true,
          quoteChar: '"',
          escapeChar: '"',
          skipEmptyLines: true,
        });
        console.log("parsed", parsed, str);
        setParsed(parsed);
      };
      reader.readAsText(f[0].file);
    }
  };

  return (
    <div>
      <p className="text-neutralText1 font-bold text-lg mt-2">
        Select the type of Data to Import
      </p>
      <p className="text-neutralText1 font-light text-xs">
        Need Help?{" "}
        <a
          href={
            bulkImportType === BulkImportType.Salesperson
              ? "/formats/salesperson_format.csv"
              : bulkImportType === BulkImportType.Vendor
              ? "/formats/vendor_format.csv"
              : bulkImportType === BulkImportType.Product
              ? "/formats/product_format.csv"
              : "/"
          }
          download
          className="text-infoText4"
        >
          Download our CSV template
        </a>{" "}
        to make sure your CSV is formatted correctly.
      </p>

      <DropzoneAreaBase
        key={"dropzone"}
        required
        fileObjects={files}
        filesLimit={1}
        dropzoneParagraphClass="hidden"
        dropzoneText={""}
        useChipsForPreview
        previewChipProps={{
          classes: {
            root: "!text-neutralText1",
            deleteIcon: "!text-neutralText3",
          },
        }}
        previewGridClasses={{ container: "justify-center mt-2" }}
        Icon={() => (
          <div className="flex items-center justify-center text-neutralText1 p-0">
            <FiFileText size={28} className="mr-2 " />
            <div className="flex flex-col items-start font-light text-[0.60rem] leading-3	">
              <p className="text-neutralText4">
                DRAG AND DROP YOUR CSV FILE HERE OR
              </p>
              <p className="text-infoText4">SELECT A CSV FILE TO UPLOAD</p>
            </div>
            {/* <img src={UploadIcon} alt="icon" /> */}
          </div>
        )}
        maxFileSize={50000000}
        dropzoneClass="flex flex-col justify-center mt-4 !border-0 !bg-neutralBg2 !min-h-[156px]"
        acceptedFiles={[
          "text/csv",
          // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          // "application/vnd.ms-excel",
        ]}
        onAdd={onAddFiles}
        onDelete={() => selectFiles([])}
      />
      <div className="flex justify-end mt-3">
        <BaseButton
          text={"Proceed"}
          onClick={() => {
            if (files.length === 0) {
              toast.error("Please select a file first");
              return;
            }
            next();
          }}
          type={"bg-secondary hover:bg-secondaryLight"}
          textColor="neutralBg1"
          width={"w-40"}
        />
      </div>
    </div>
  );
};

export default SelectFile;
