import React from "react";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";

import MapIcon from "../../assets/mapIcon.png";
import tailwindConfig from "../../theme/tailwindConfig";

// const K_SCALE_NORMAL = 0.65;
const K_NORMAL_SIZE = 36;

function Marker({ gMap, lat, lng, data }) {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: tailwindConfig.theme.colors.neutralBg1,
      color: tailwindConfig.theme.colors.neutralText1,
      boxShadow: `0px 2px 10px 0px rgba(143,143,143,1);`,
      fontSize: 12,
      maxWidth: 220,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: tailwindConfig.theme.colors.neutralBg1,
    },
  }));

  return (
    <LightTooltip
      title={
        <div className="flex items-center p-1 flex-col">
          <p className="mr-2 ">{data?.name}</p>
          <p className="mr-2 ">Bal: {data?.balance}</p>
          <Link to={`/view-vendor/${data?.id}`} target="_blank">
            <Unicons.UilExternalLinkAlt size={16} fill />
          </Link>
        </div>
      }
      arrow
    >
      <div
        className="cursor-pointer"
        onClick={() => {
          gMap.current.panTo({
            lat,
            lng,
          });
          gMap.current.setZoom(16);
        }}
      >
        <img
          src={MapIcon}
          alt="map_icon"
          className="h-auto w-auto"
          style={{
            position: "absolute",
            width: K_NORMAL_SIZE,
            height: K_NORMAL_SIZE,
            left: -K_NORMAL_SIZE / 2,
            top: -K_NORMAL_SIZE / 2,
          }}
        />
        <div />
      </div>
    </LightTooltip>
  );
}

export default Marker;
