import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/landingPageLogo.svg";
import altLogo from "../../assets/alternateLogo.svg";

const LandingFooter = () => {
  return (
    <div className="bg-neutralText1 z-20 px-6 sm:px-14 md:px-14 lg:px-20 xl:px-38 2xl:44">
      <div className="py-8 md:py-16">
        <img src={Logo} />
      </div>
      <hr className="bg-secondary-text border-0 " style={{ height: "1px" }} />
      <div className="grid sm:grid-cols-2 gap-y-12 md:grid-cols-4 py-3 w-full flex-row sm:justify-around text-white pb-14 md:pb-28 border-b border-neutralText4">
        <div className="w-1/4 min-w-[160px] text-base text-neutralbg2">
          <div className="text-neutralBg4 text-xs py-1">FEATURES</div>
          <Link to="/features">
            <div className="py-1">Retailer Mapping</div>
          </Link>
          <Link to="/features">
            <div className="py-1">Sales Registry </div>
          </Link>
          <Link to="/features">
            <div className="py-1">Balance Registry</div>
          </Link>
          <Link to="/features">
            <div className="py-1">Order Tracking</div>
          </Link>
          <Link to="/features">
            <div className="py-1">Sales Visit Tracking</div>
          </Link>
        </div>
        {/* <div className="w-1/4 min-w-[160px] text-base text-neutralbg2">
          <div className="text-neutralBg4 text-xs py-1">SUPPORT</div>
          <div className="py-1">Get Help</div>
          <div>Tutorials</div>
          <div>Team Training</div>
        </div> */}
        <div className="w-1/4 min-w-[160px] text-base text-neutralbg2">
          <div className="text-neutralBg4 text-xs py-1">COMPANY</div>
          <Link to={"/company"}>
            <div className="py-1">About us</div>
          </Link>
          <Link to="/pricing">
            <div>Pricing</div>
          </Link>
        </div>
        <div className="w-1/4 min-w-[160px] text-base text-neutralbg2">
          <div className="text-neutralBg4 text-xs py-1">CONTACT US</div>
          <div className="py-1"> +91 700 600 1600</div>
          {/* <div>Schedule a tour</div> */}
          <div>info@thebusybee.app</div>
        </div>
      </div>
      <div className="text-xs text-neutralText4 justify-between flex md:flex-row pb-6 pt-12 items-center">
        <div className="flex flex-row items-center ">
          <img src={altLogo} className="sm:p-3"></img>
          <div>© {new Date().getFullYear()} Graphicweave</div>
        </div>
        <div className="flex justify-end w-1/2">
          <Link to="/terms">
            <div className="text-left">Terms </div>
          </Link>
          <div className="px-1">|</div>
          <Link to="/policy">
            <div className="text-left">Privacy Policy</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingFooter;
