import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Unicons from "@iconscout/react-unicons";

import { createTransactionSchema } from "../validationSchema";
import ValidationErrors from "../common/validationErrors";
import CustomInput, { CustomSelect } from "../common/customInput";
import BaseButton from "../common/baseButton";
import {
  createTransaction,
  updateTransaction,
} from "../../services/transactionsApi";
import { CustomDateInputPicker } from "../common/customDatePicker";
import { getVendorList } from "../../services/vendorsApi";
import CustomAutocomplete from "../common/customAutocomplete";
import getCurrency from "../../utils/getCurrency";

const options = [
  { key: "Select Transaction Type", value: "" },
  { key: "Sales", value: "SALES" },
  { key: "Collection", value: "COLLECTION" },
  { key: "Return", value: "RETURN" },
];

const TransactionsForm = ({ transaction, handleClose, vendor }) => {
  const [loading, setLoading] = useState(false);

  const [searchedVendors, setSearchedVendors] = useState([]);
  const [vendorsLoading, setVendorsLoading] = useState(false);

  const searchVendors = async (query) => {
    setVendorsLoading(true);
    const resp = await getVendorList({
      offset: 1,
      limit: 5,
      query,
      status: "ACTIVE",
    });
    setSearchedVendors(resp.result || []);
    setVendorsLoading(false);
  };

  const handleOnSubmit = async (values) => {
    setLoading(true);

    const details = {
      ...values,
      date: parseInt(values.date?.getTime() / 1000, 10),
      vendorId: values.vendorId,
      vendor: undefined,
    };

    const resp = transaction
      ? await updateTransaction(details)
      : await createTransaction(details);
    if (resp) {
      toast.success(
        transaction
          ? "Successfully updated transaction"
          : "Successfully added transaction"
      );
      setLoading(false);
      handleClose();
    }

    setLoading(false);
  };
  const getOptionsList = async () => {
    // const spResp = await getSalesPersons({
    //   offset: 1,
    //   limit: 5,
    // });
    // setSalespersons(spResp.result || []);
    const vendorResp = await getVendorList({
      offset: 1,
      limit: 5,
      status: "ACTIVE",
    });
    setSearchedVendors(vendorResp.result || []);
  };

  useEffect(() => {
    getOptionsList();
  }, []);

  console.log("vendorDetails", vendor);

  return (
    <Formik
      initialValues={{
        txnId: transaction?.id || "",
        vendor: transaction?.vendor || vendor || null,
        vendorId: transaction?.vendor?.id || vendor?.id || "",
        date:
          (transaction && new Date(transaction?.updatedAt * 1000)) ||
          new Date(),
        transactionType: transaction?.transactionType || "",
        amount: +transaction?.amount || "",
      }}
      // enableReinitialize
      validationSchema={createTransactionSchema}
      onSubmit={handleOnSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,

        /* and other goodies */
      }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <CustomAutocomplete
            // icon={el.filterIcon}
            disabled={!!transaction || vendor}
            placeholder={"Search Retailer"}
            value={values.vendor}
            onChange={(event, newValue) => {
              setFieldValue("vendor", newValue);
              setFieldValue("vendorId", newValue?.id);
            }}
            filterOptions={(x) => x}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.businessName
            }
            options={searchedVendors}
            autoComplete
            filterSelectedOptions
            onInputChange={(event, newInputValue) => {
              if (newInputValue) {
                setFieldValue("vendor", newInputValue);
                searchVendors(newInputValue);
              }
            }}
          />

          {touched.vendorId && errors.vendorId && (
            <ValidationErrors errors={errors.vendorId} className="mt-1" />
          )}

          <CustomDateInputPicker
            date={values.date}
            onChange={(selectedDate) => {
              setFieldValue("date", selectedDate);
            }}
          />
          {touched.date && errors.date && (
            <ValidationErrors errors={errors.date} className="mt-1" />
          )}

          <CustomSelect
            disabled={!!transaction}
            label="Transaction Type"
            containerClasses="py-2"
            type="text"
            options={options}
            name="transactionType"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.transactionType}
          />
          {touched.transactionType && errors.transactionType && (
            <ValidationErrors
              errors={errors.transactionType}
              className="mt-1"
            />
          )}

          <CustomInput
            icon={
              <div className="text-successText5 text-lg font-bold">
                {getCurrency()}
              </div>
            }
            placeholder="2000"
            containerClasses="py-2"
            label="Amount"
            allowNumOnly={true}
            name="amount"
            type="number"
            text={+values.amount || ""}
            onChange={(e) => {
              setFieldValue("amount", parseInt(e.target.value));
            }}
            onBlur={handleBlur}
            value={+values.amount}
            // onChange={(e) => setFieldValue(e.target.value)}
          />
          {touched.amount && errors.amount && (
            <ValidationErrors errors={`${errors.amount}`} />
          )}

          <div className=" pt-12 flex justify-end  m-auto">
            <BaseButton
              loading={loading}
              text={transaction ? "Edit Transaction" : "Add Transaction"}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
export default TransactionsForm;
