import React from "react";

function OuterDiv(props) {
  return (
    <div
      className={
        "bg-neutralBg1 border border-neutralBg3 rounded my-6 " +
        props?.className
      }
    >
      {props.children}
    </div>
  );
}

export default OuterDiv;
