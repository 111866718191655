import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import startCase from "lodash/startCase";
import { FiX } from "react-icons/fi";
import { Checkbox } from "@mui/material";

import ValidationErrors from "../common/validationErrors";
import BaseButton from "../common/baseButton";
import { createVisit } from "../../services/visitsApi";
import { CustomDateInputPicker } from "../common/customDatePicker";
import { getVendorList } from "../../services/vendorsApi";
import { addVisitSchema } from "../validationSchema";
import SelectInput from "../common/selectInput";
import CustomAutocomplete from "../common/customAutocomplete";
import {
  getAllTerritories,
  getVendorsInTerritories,
} from "../../services/territoriesApi";
import CustomModal from "../common/customModal";
import CustomModalTitle from "../common/customModalTitle";

const VisitsForm = ({ handleCloseModal }) => {
  const [vendors, setVendors] = useState([]);

  const [searchedVendors, setSearchedVendors] = useState([]);
  const [vendorsLoading, setVendorsLoading] = useState(false);

  // const [selectedTerritory, setSelectedTerritory] = useState();
  const [searchedTerritories, setSearchedTerritories] = useState([]);
  const [territoryLoading, setTerritoryLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createVisitBy, setCreateVisitBy] = useState("vendor");
  const [viewVendorModal, setViewVendorModal] = useState(false);

  const handleVisit = async (values) => {
    console.log("visit values", values);
    setLoading(true);
    const details = {
      ...values,
      date: parseInt(values.date.getTime() / 1000, 10),
      visitType: values.visitType.split(" ").join("_").toUpperCase(),
    };
    delete details.territoryId;

    const vendorDetails = {
      vendorIds: values.vendorIds,
      date: parseInt(values.date.getTime() / 1000, 10),
      visitType: values.visitType.split(" ").join("_").toUpperCase(),
    };

    const resp = await createVisit(
      createVisitBy === "vendor" ? vendorDetails : details
    );
    setLoading(false);
    if (resp) {
      toast.success("Visit Assigned Successfully");
      handleCloseModal();
    }
  };

  const searchVendors = async (query) => {
    setVendorsLoading(true);
    const resp = await getVendorList({
      offset: 1,
      limit: 5,
      query,
      status: "ACTIVE",
    });
    setSearchedVendors(resp.result || []);
    setVendorsLoading(false);
  };

  const getVendors = async (territoryId, setFieldValue) => {
    const resp = await getVendorsInTerritories(territoryId);
    setVendors(resp || []);
    setFieldValue(
      "vendorIds",
      resp.map((el) => el.vendorId)
    );
  };

  const searchTerritory = async (query) => {
    if (!query) {
      return;
    }
    // console.log("Territory Searching", query);
    setTerritoryLoading(true);
    const resp = await getAllTerritories(query);

    setSearchedTerritories(resp || []);
    setTerritoryLoading(false);
  };

  const getVendorsOptions = async () => {
    const data = await getAllTerritories();
    setSearchedTerritories(data || []);
    const resp = await getVendorList({
      offset: 1,
      limit: 5,
      status: "ACTIVE",
    });
    setSearchedVendors(resp.result || []);
  };

  useEffect(() => {
    getVendorsOptions();
  }, [createVisitBy]);

  return (
    <Formik
      initialValues={{
        vendor: "",
        vendorId: [],
        vendorIds: [],
        visitType: "",
        territoryId: {},
        date: new Date(),
      }}
      validationSchema={addVisitSchema}
      onSubmit={handleVisit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <CustomDateInputPicker
            date={values.date}
            minDate={new Date()}
            onChange={(selectedDate) => {
              setFieldValue("date", selectedDate);
            }}
          />
          {touched.date && errors.date && (
            <ValidationErrors errors={`${errors.date}`} className="mt-1" />
          )}
          <label className="text-xs py-1 block font-light mt-2">
            Visit Type
          </label>
          <SelectInput
            label="Select a visit type"
            options={["COLLECTION", "NEW_ORDER", "RETURN"].map((el) =>
              startCase(el.toLowerCase())
            )}
            onChange={handleChange("visitType")}
          />
          {touched.visitType && errors.visitType && (
            <ValidationErrors errors={`${errors.visitType}`} className="mt-1" />
          )}

          <label className="text-xs py-1 block font-light mt-2">
            Create Visit by
          </label>
          <div className="flex items-center text-sm text-neutralText1">
            {" "}
            <div className="flex items-center mr-6">
              <input
                type="radio"
                name="vendor"
                onChange={() => setCreateVisitBy("vendor")}
                checked={createVisitBy === "vendor"}
              />
              <div className="px-2">Retailer</div>
            </div>{" "}
            <div className="flex items-center">
              <input
                type="radio"
                name="territory"
                onChange={() => setCreateVisitBy("territory")}
                checked={createVisitBy === "territory"}
              />
              <div className="px-2">Territory</div>
            </div>{" "}
          </div>

          {createVisitBy === "vendor" ? (
            <>
              <label className="text-xs py-1 block font-light mt-2">
                Retailer
              </label>
              <CustomAutocomplete
                // icon={el.filterIcon}
                // disabled={!!transaction}
                placeholder={"Search Retailer"}
                value={values.vendor}
                name="vendor"
                onChange={(event, newValue) => {
                  console.log("vendor123", newValue);
                  setFieldValue("vendor", newValue);
                  newValue
                    ? setFieldValue("vendorIds", [newValue?.id])
                    : setFieldValue("vendorIds", []);
                }}
                filterOptions={(x) => x}
                getOptionLabel={(option) => {
                  return typeof option === "string"
                    ? option
                    : option.businessName;
                }}
                options={searchedVendors}
                autoComplete
                filterSelectedOptions
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) {
                    setFieldValue("vendor", newInputValue);
                    searchVendors(newInputValue);
                  }
                }}
              />
              {touched.vendorIds && errors.vendorIds && (
                <ValidationErrors
                  errors={"please select a retailer"}
                  className="mt-1"
                />
              )}
              {values?.vendor?.mapped === false && (
                <ValidationErrors
                  errors={"Cannot assign visit to unmapped retailer"}
                  className="mt-1"
                />
              )}
            </>
          ) : (
            <>
              <label className="text-xs py-1 block font-light mt-2">
                Territory
              </label>
              <CustomAutocomplete
                // icon={el.filterIcon}
                placeholder={"Territory"}
                value={values?.territoryId}
                onChange={(event, newValue) => {
                  setFieldValue("territoryId", newValue);
                  if (newValue?.territoryId) {
                    getVendors(newValue.territoryId, setFieldValue);
                  }
                }}
                filterOptions={(x) => x}
                getOptionLabel={(option) =>
                  option["name"] ? option["name"] : ""
                }
                options={searchedTerritories}
                autoComplete
                filterSelectedOptions
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) {
                    // setFieldValue("territoryId", newInputValue);

                    searchTerritory(newInputValue);
                  }
                }}
                loading={territoryLoading}
                // inputClasses="!border !border-neutralBg5"
              />
              {touched.territoryId && errors.territoryId && (
                <ValidationErrors
                  errors={`${errors.territoryId}`}
                  className="mt-1"
                />
              )}

              {touched.vendorIds && errors.vendorIds && (
                <ValidationErrors
                  errors={`${errors.vendorIds}`}
                  className="mt-1"
                />
              )}
              <div className="flex justify-between p-2 bg-neutralBg2 text-sm font-light mt-2">
                {vendors.length > 0 ? (
                  <>
                    <p className="text-neutralText5 ">
                      Selected{" "}
                      <span className="font-bold">
                        {values.vendorIds.length} retailers
                      </span>
                    </p>
                    <div className="flex items-center">
                      <span
                        className="text-infoText4 mr-1 cursor-pointer"
                        onClick={() => setViewVendorModal((o) => !o)}
                      >
                        View
                      </span>
                      <FiX
                        className="cursor-pointer"
                        size={18}
                        onClick={() => {
                          setFieldValue("vendorIds", []);
                          setVendors([]);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <p className="text-neutralText5 ">
                    Please select a territory with retailers in it
                  </p>
                )}
              </div>
            </>
          )}
          <CustomModal
            title={
              <CustomModalTitle
                title={
                  <span>Selected retailers - {values.vendorIds.length}</span>
                }
                description="Select / deselect retailers below"
                onCrossPress={() => setViewVendorModal(false)}
              />
            }
            openModal={viewVendorModal}
            setOpenModal={setViewVendorModal}
          >
            <div className="h-64 overflow-auto">
              {vendors.map((el, i) => {
                return (
                  <div key={`index-${i}`}>
                    <Checkbox
                      checked={values.vendorIds.includes(el.vendorId)}
                      onChange={(event) => {
                        let newArray = [];
                        if (!event.target.checked) {
                          newArray = values.vendorIds.filter(
                            (e) => e !== el.vendorId
                          );
                        } else {
                          newArray = [...values.vendorIds, el.vendorId];
                        }
                        setFieldValue("vendorIds", newArray);
                      }}
                    />
                    {el.businessName}
                  </div>
                );
              })}
            </div>
            <div className="flex justify-end pt-6">
              <BaseButton
                text="OK"
                type="bg-infoText4 hover:bg-infoText5"
                textColor="neutralBg1"
                width="w-32"
                onClick={() => setViewVendorModal(false)}
              />
            </div>
          </CustomModal>
          <div className=" pt-12 flex justify-end  m-auto">
            <BaseButton
              disabled={values?.vendor?.mapped === false}
              // onClick={handleSubmit}
              // disabled={isSubmitting}
              loading={loading}
              text={"Create Visit"}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
export default VisitsForm;
