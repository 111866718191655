import * as React from "react";
import { useEffect, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { SelectColumn } from "react-data-grid";
import { Link, useHistory } from "react-router-dom";
import { FiSliders, FiX } from "react-icons/fi";

import CustomTable from "../../components/common/table";
import Filters from "../../components/common/filters";
import CustomTitle from "../../components/common/customTitle";
import CustomModal from "../../components/common/customModal";
import CustomModalTitle from "../../components/common/customModalTitle";
import BaseButton from "../../components/common/baseButton";
import AddVendor from "../../components/addVendor";
import {
  Tabs,
  TabsList,
  TabPanel,
  Tab,
} from "../../components/common/customTabs";
import { getVendorList } from "../../services/vendorsApi";
import FullscreenLoader from "../../components/common/fullscreenLoader";
import AssignSalespersonModal from "../../components/assignSalespersonModal";
import { getSalesPersons } from "../../services/salespersonApi";
import { FilterType } from "../../constants/common";
import VendorsMapTab from "./vendorsMapTab";
import EmptyFallBack from "../../components/common/emptyFallback";
import noVendors from "../../assets/noVendors.svg";
import { getAllTerritories } from "../../services/territoriesApi";
import AssignVisitModal from "../../components/assignVisitModal";
import millify from "millify";
import getCurrency from "../../utils/getCurrency";
import { toast } from "react-toastify";

const columns = [
  SelectColumn,
  { key: "name", name: "RETAILER", minWidth: 200 },
  { key: "sales", name: "SALE", minWidth: 120, width: 120 },
  { key: "address", name: "ADDRESS", minWidth: 200 },
  { key: "collection", name: "PAID", minWidth: 120, width: 120 },
  { key: "balance", name: "BALANCE", minWidth: 120, width: 120 },
  { key: "salesperson", name: "ASSIGNED TO", minWidth: 200 },
  { key: "status", name: "STATUS", minWidth: 200 },
];

const Vendors = () => {
  const dataGridRef = React.useRef(null);

  const history = useHistory();

  const [modifiedData, setModifiedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [selectedVendors, setSelectedVendors] = useState(() => new Set());
  const [openAssignmentModal, setOpenAssignmentModal] = useState(false);
  const [openVisitModal, setOpenVisitModal] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeFilters, setActiveFilters] = useState([]);
  const [query, setQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [inactiveVendors, setInactiveVendors] = useState([]);
  const [selectedSalesperson, setSelectedSalesperson] = useState();
  const [searchedSalespersons, setSearchedSalespersons] = useState([]);
  const [salespersonsLoading, setSalespersonsLoading] = useState(false);

  const [selectedTerritory, setSelectedTerritory] = useState();
  const [searchedTerritories, setSearchedTerritories] = useState([]);
  const [territoryLoading, setTerritoryLoading] = useState(false);

  const searchSalespersons = async (query) => {
    setSalespersonsLoading(true);
    const resp = await getSalesPersons({
      offset: 1,
      limit: 5,
      query,
    });
    setSearchedSalespersons(resp.result || []);
    setSalespersonsLoading(false);
  };

  const searchTerritory = async (query) => {
    setTerritoryLoading(true);
    const resp = await getAllTerritories(query);
    setSearchedTerritories(resp || []);
    setTerritoryLoading(false);
  };

  const getOptionsList = async () => {
    const spResp = await getSalesPersons({
      offset: 1,
      limit: 5,
    });
    setSearchedSalespersons(spResp.result || []);
    const resp = await getAllTerritories(query);
    setSearchedTerritories(resp || []);
  };

  const filters = [
    {
      filterKey: "assignedStatus",
      filterLabel: "Assignment",
      selectedFilter: activeFilters.length > 0,
      selectedKey: activeFilters?.filter((el) => {
        if (el?.filterKey === "assignedStatus") return el?.filterValue;
      }),
      filterType: FilterType.Default,
      filterIcon: <Unicons.UilUsersAlt />,
      filterValues: [
        {
          key: "ASSIGNED",
          value: "Assigned",
        },
        {
          key: "UNASSIGNED",
          value: "Unassigned",
        },
      ],
    },
    {
      filterKey: "mapped",
      filterLabel: "Location",
      selectedFilter: activeFilters.length > 0,
      selectedKey: activeFilters?.filter((el) => {
        if (el?.filterKey === "mapped") return el?.filterValue;
      }),
      filterType: FilterType.Default,
      filterIcon: <Unicons.UilMap />,
      filterValues: [
        {
          key: "MAPPED",
          value: "Mapped",
        },
        {
          key: "NOT_MAPPED",
          value: "Unmapped",
        },
      ],
    },
    {
      filterKey: "territory",
      filterLabel: "Territory",
      filterType: FilterType.Autocomplete,
      selectedFilter: selectedTerritory,
      onFilterChange: setSelectedTerritory,
      onFilterInputChange: searchTerritory,
      filterLoading: territoryLoading,
      optionValue: "name",
      filterPlaceholder: "Territory",
      filterIcon: <Unicons.UilMap />,
      filterValues: searchedTerritories,
    },
    {
      filterKey: "salesperson",
      filterLabel: "Salesperson",
      filterType: FilterType.Autocomplete,
      selectedFilter: selectedSalesperson,
      onFilterChange: setSelectedSalesperson,
      onFilterInputChange: searchSalespersons,
      filterLoading: salespersonsLoading,
      optionValue: "name",
      filterPlaceholder: "Salesperson",
      filterIcon: <Unicons.UilUser />,
      filterValues: searchedSalespersons,
    },
  ];

  const getData = async ({ refresh = true, page, rowsPerPage, ...filters }) => {
    setTableLoading(refresh);
    setOffset(page - 1);

    const resp = await getVendorList({
      offset: page,
      limit: rowsPerPage,
      query,
      spId: selectedSalesperson?.id,
      sortField: "businessName",
      sortOrder: "asc",
      territoryId: selectedTerritory?.territoryId,
      ...filters,
    });
    console.log(resp?.result, "vend");
    setVendors(resp.result || []);
    setCount(resp.count);
    setLoading(false);
    setTableLoading(false);
  };

  const applyFilters = ({ refresh = true, page }) => {
    const filterBuilder = {};

    if (activeFilters.length > 0) {
      for (const item of activeFilters) {
        filterBuilder[item.filterKey] = item.filterValue;
      }
    }
    getData({ refresh, page, rowsPerPage, ...filterBuilder });
    setInactiveVendors([]);
  };

  useEffect(() => {
    if (history.location.state?.openModal) {
      setTimeout(() => {
        setOpenModal(true);
      }, 500);
    }
    history.replace("/vendors");
  }, []);

  useEffect(() => {
    applyFilters({ page: 1 });

    getOptionsList();
  }, [
    activeFilters,
    rowsPerPage,
    query,
    selectedSalesperson,
    selectedTerritory,
  ]);

  const clearFilters = () => {
    setSelectedSalesperson(null);
    setSelectedTerritory(null);
    setSearchedSalespersons([]);
    setSearchedTerritories([]);
    setQuery("");
    setActiveFilters([]);
  };

  useEffect(() => {
    const temp = vendors.map((el) => {
      return {
        ...el,
        sales: (
          <div className="truncate">
            {getCurrency()}{" "}
            {millify(el?.sales || 0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </div>
        ),
        collection: (
          <div className="px-3 py-0.5 rounded-full text-neutralText4 text-xs bg-successBg2 truncate">
            {getCurrency()}{" "}
            {millify(el?.collection || 0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </div>
        ),
        balance: (
          <div className="px-3 py-0.5 rounded-full text-neutralText4 text-xs bg-warningBg2 truncate">
            {getCurrency()} {millify(el.balance || 0)}
          </div>
        ),
        name: (
          <span className="font-bold text-neutralText1 flex flex-row items-center truncate">
            {el?.businessName}
          </span>
        ),
        address:
          el.location && el.location?.coordinates?.length > 0 ? (
            <span className="text-infoText4 truncate">{el?.locationName}</span>
          ) : (
            <span className="text-neutralText1 flex items-center truncate">
              <Unicons.UilExclamationTriangle className="mr-1 text-warningText4 w-[18px] min-w-[18px] min-h-[18px]" />

              <span className="truncate">{el?.locationName} (Not Mapped)</span>
            </span>
          ),
        salesperson: el?.salesperson ? (
          <div className="truncate">
            <Link
              to={`/view-salesperson/${el?.salesperson?.id}`}
              className="text-infoText4"
            >
              {el?.salesperson?.name}
            </Link>
          </div>
        ) : (
          <div>Unassigned</div>
        ),
        status:
          el?.status === "ACTIVE" ? (
            <div className="bg-successBg2 text-successText3 text-xs px-2 py-0.5 rounded-full">
              {el?.status}
            </div>
          ) : (
            <div className="bg-errorBg2 text-errorText3 text-xs px-2 py-0.5 rounded-full">
              {el?.status}
            </div>
          ),
      };
    });
    setModifiedData(temp);
  }, [vendors]);

  useEffect(() => {
    setInactiveVendors(
      vendors
        .filter(
          (elem) =>
            elem?.status !== "ACTIVE" || elem?.salesperson?.status !== "ACTIVE"
        )
        .map((el) => el.id)
    );
    console.log(JSON.stringify(selectedVendors, null, 2), "MUFTI");
  }, [vendors]);

  return (
    <>
      {/* <TopBar value="Vendor" /> */}
      <div className="flex flex-row">
        {/* <SideNav /> */}

        {loading ? (
          <FullscreenLoader className="!text-neutralText3" />
        ) : (
          <div className="w-full pb-12 grid grid-cols-4 px-6">
            <div className="col-span-4">
              <div className="flex justify-between items-center pt-12">
                <CustomTitle
                  title="Retailers"
                  description="Organise and Manage all your Retailers"
                />
                <div className="flex">
                  <div className="mr-2">
                    <Link to={"/vendor/bulk-import"}>
                      <BaseButton
                        text={"Import CSV"}
                        outline
                        type={
                          "border-successText5 hover:bg-successText5 hover:text-white"
                        }
                        textColor="successText5"
                        width={"w-36"}
                      />
                    </Link>
                  </div>
                  <CustomModal
                    title={
                      <CustomModalTitle
                        title="Add New Retailer"
                        description="Add new retailer from Busybee database or create new"
                      />
                    }
                    boxStyle={{ p: 0, width: 650 }}
                    topContainerStyle={"px-[32px] pt-[32px]"}
                    bottomContainerStyle={
                      "px-[32px] pb-[32px] bg-neutralBg2 rounded-b mt-2"
                    }
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    button={<BaseButton text="Add New Retailer" />}
                  >
                    <AddVendor
                      handleCloseModal={() => {
                        setOpenModal(false);
                        setTableLoading(true);
                        setTimeout(() => {
                          applyFilters({ refresh: false, page: offset + 1 });
                        }, 1000);
                      }}
                    />
                  </CustomModal>
                </div>
              </div>

              <Tabs
                onChange={(e, newValue) => setTabValue(newValue)}
                className="rounded-lg mt-6"
                defaultValue={0}
              >
                <TabsList style={{ marginBottom: -1 }}>
                  <Tab>
                    <div className="flex items-center m-auto justify-center">
                      <Unicons.UilListUl size="20" />
                      <div className="px-2">List View </div>
                    </div>
                  </Tab>
                  <Tab>
                    <div className="flex items-center m-auto justify-center">
                      <Unicons.UilMap size="20" />
                      <div className="px-2">Map View </div>
                    </div>
                  </Tab>
                </TabsList>
                <TabPanel className="  rounded-t-xl	rounded-b-xl" value={0}>
                  <div className="flex flex-row justify-between bg-neutralBg1 items-center pt-8 border-x border-x-neutralBg4 px-6">
                    <div className="text-base w-1/4 text-neutralText1 font-medium">
                      List of Retailers
                    </div>

                    <div className="flex items-center">
                      {" "}
                      <div className=" mr-2">
                        {filters
                          .map((el) => !!el.selectedFilter)
                          .includes(true) || query.length > 0 ? (
                          <button onClick={() => clearFilters()}>
                            <div className="flex items-center justify-center text-infoText4 text-xs">
                              <FiX size={14} />
                              Clear
                            </div>
                          </button>
                        ) : (
                          <FiSliders className=" text-neutralText1" size={18} />
                        )}
                      </div>
                    </div>

                    <Filters
                      filters={filters}
                      setFilters={setActiveFilters}
                      activeFilters={activeFilters}
                      enableSearch
                      searchValue={query}
                      onSearch={(e) => setQuery(e.target.value)}
                    />
                  </div>
                  <div
                    className="py-4 sm:px-6 bg-neutralBg1 border border-neutralBg4 border-t-0 rounded-b"
                    onClick={() => {
                      // Fix for `cellFocus (select) breaks input functionality`
                      dataGridRef.current.selectCell(
                        {
                          idx: 0,
                          rowIdx: -1,
                          mode: "SELECT",
                        },
                        true
                      );
                    }}
                  >
                    {/* <div className="flex flex-row justify-between items-center mb-6">
                      <div className="text-base w-1/4 text-neutralText1 font-medium">
                        List of Vendors
                      </div>

                      <div className="flex items-center">
                        {" "}
                        <div className=" mr-2">
                          {filters
                            .map((el) => !!el.selectedFilter)
                            .includes(true) || query.length > 0 ? (
                            <button onClick={() => clearFilters()}>
                              <div className="flex items-center justify-center text-infoText4 text-xs">
                                <FiX size={14} />
                                Clear
                              </div>
                            </button>
                          ) : (
                            <FiSliders
                              className=" text-neutralText1"
                              size={18}
                            />
                          )}
                        </div>
                      </div>

                      <Filters
                        filters={filters}
                        setFilters={setActiveFilters}
                        activeFilters={activeFilters}
                        enableSearch
                        searchValue={query}
                        onSearch={(e) => setQuery(e.target.value)}
                      />
                    </div> */}
                    <AssignSalespersonModal
                      onClose={() => {
                        setOpenAssignmentModal(false);
                        applyFilters({ refresh: false, page: offset + 1 });
                      }}
                      openModal={openAssignmentModal}
                      setOpenModal={setOpenAssignmentModal}
                      vendors={Array.from(selectedVendors)}
                    />
                    <AssignVisitModal
                      openModal={openVisitModal}
                      setOpenModal={setOpenVisitModal}
                      vendors={Array.from(selectedVendors)}
                      onClose={() =>
                        applyFilters({ refresh: false, page: offset + 1 })
                      }
                    />
                    <div
                      className={`transition-all duration-300 bg-infoBg1  ${
                        selectedVendors.size > 0
                          ? " opacity-1 py-4 px-4 flex flex-row justify-between items-center"
                          : "h-0 opacity-0"
                      }`}
                    >
                      <span className="font-light text-[10px] text-neutralText1 ">
                        SELECTED {selectedVendors.size} RETAILER
                        {selectedVendors.size > 1 ? "S" : ""}
                      </span>
                      <div className="flex flex-row">
                        <BaseButton
                          text="Assign Salesperson"
                          type="bg-infoText4 hover:bg-infoText5"
                          textColor="neutralBg1"
                          onClick={() => {
                            setOpenAssignmentModal(true);
                          }}
                        />
                        <BaseButton
                          text="Assign Visit"
                          type="bg-infoText5 hover:bg-infoText4"
                          textColor="neutralBg1"
                          onClick={() => {
                            const check = Array.from(selectedVendors);
                            console.log(check, "gaz");
                            for (const element of check) {
                              if (inactiveVendors.includes(element)) {
                                toast.error(
                                  "Some of Vendor(s) or Salesperson(s) are not active"
                                );
                                return false;
                              }
                            }

                            setOpenVisitModal(true);
                          }}
                        />
                      </div>
                    </div>

                    <CustomTable
                      dataGridRef={dataGridRef}
                      columns={columns}
                      rows={modifiedData}
                      emptyFallBack={
                        <EmptyFallBack
                          image={noVendors}
                          text="No Retailers found"
                          containerClass={"mt-14 mb-4"}
                          imageClass={"h-44"}
                        />
                      }
                      bodyRowProps={{
                        path: "/view-vendor",
                        id: "vendorId",
                      }}
                      selectedRows={selectedVendors}
                      setSelectedRows={setSelectedVendors}
                      count={count}
                      page={offset}
                      onPageChange={(_, page) =>
                        applyFilters({ page: page + 1 })
                      }
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={(e) =>
                        setRowsPerPage(e.target.value)
                      }
                      onVersio
                      loading={tableLoading}
                    />
                  </div>
                </TabPanel>
                <TabPanel className=" rounded-t-xl	rounded-b-xl" value={1}>
                  <VendorsMapTab />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Vendors;
