import styled from "@emotion/styled";
import { LinearProgress, linearProgressClasses } from "@mui/material";
import tailwindConfig from "../../theme/tailwindConfig";
// import tailwindConfig from "../../theme/tailwindConfig";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 12,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: tailwindConfig.theme.colors.neutralBg4,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: tailwindConfig.theme.colors.successText5,
  },
}));

function CustomLinearProgress({ value, hideValue, classes }) {
  return (
    <div className="flex items-center relative w-full">
      {!hideValue && (
        <div className="absolute z-10 w-full text-center text-xs text-neutralText2">
          {value}
        </div>
      )}
      <div className="w-full ">
        <BorderLinearProgress
          variant="determinate"
          value={value}
          classes={classes}
        />
      </div>
    </div>
  );
}

export default CustomLinearProgress;
