import * as Unicons from "@iconscout/react-unicons";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import BaseButton from "../../components/common/baseButton";
import OuterDiv from "../../components/common/outerDiv";

const PasswordSet = () => {
  const history = useHistory();

  useEffect(() => {
    history.replace("/password-set");
  }, []);

  return (
    <div className="flex justify-center items-center h-screen bg-loginBg bg-center bg-cover bg-no-repeat">
      <OuterDiv className="2xl:w-3/12 mx-auto text-center py-12">
        <div className="flex justify-center pt-8 pb-4">
          <Unicons.UilCheckCircle className="text-successBg5" size={50} />
        </div>
        <div className="text-neutralText3 text-xl font-extrabold pb-2">
          Password set successfully
        </div>
        <div className="text-sm text-neutralText4 w-9/12 m-auto pb-8">
          Login to salesperson app with your id and password
        </div>
        {/* <div className="py-8">
          <BaseButton onClick={() => handleClick()} text={"Go to Dashboard"} />
        </div> */}
      </OuterDiv>
    </div>
  );
};
export default PasswordSet;
