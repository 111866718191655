import React from "react";

import dummy from "../../assets/dummy.png";

export default function Avatar({ image, className, imageClassName }) {
  return (
    <span className={className}>
      <img
        src={`${image ? process.env.REACT_APP_BUCKET_URL + image : dummy}`}
        alt="avatar"
        className={"rounded-full object-cover " + imageClassName || " "}
      />
    </span>
  );
}
