import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import * as Unicons from "@iconscout/react-unicons";

import OuterDiv from "../../components/common/outerDiv";
import RegisterationForm from "../../components/forms/registerationForm";
import BusyBeeLogo from "../../assets/BusyBeeLogo.svg";
import GradientBackground from "../../components/common/gradientBackground";
import LandingNav from "../../components/landingComponents/landingNav";
import LandingHeader from "../../components/landingComponents/landingHeader";
import RegisterImg from "../../assets/registrationImg.svg";
import LandingFooter from "../../components/landingComponents/landingFooter";

const Register = () => {
  const history = useHistory();
  const buyPlan = history.location.state;
  return (
    <div className="contain bg-backgroundLogoV bg-right-top bg-no-repeat">
      <LandingNav />
      <div
        className={`lg:flex justify-center items-start px-4 ${
          buyPlan ? "md:px-12 pt-0" : "md:p-12"
        } pb-28`}
      >
        {!buyPlan && (
          <div className="w-full z-10 self-center pt-4 sm:pt-0 pb-12 lg:pb-0">
            <div className="md:pl-12 xl:pl-24 text-center lg:text-left ">
              <div className="sm:w-11/12 md:w-9/12 2xl:w-7/12 mx-auto lg:mx-0 ">
                <LandingHeader body="Start using busybee in less than 30 minutes" />
              </div>
              <div className="text-neutralText4 text-xl sm:text-3xl">
                No credit required, no software to install.
              </div>
            </div>
            <div className="pt-6 sm:pt-12 flex-col 2xl:flex-row flex justify-center">
              <img
                src={RegisterImg}
                alt=""
                className="bg-transparent 2xl:w-5/12"
              />
              <div className="pt-6 sm:pl-10 xl:pl-20 2xl:pl-0 2xl:w-7/12">
                <div className="text-neutralText3 text-[24px] font-black">
                  With your 30 days free trial, you get:
                </div>
                <div className="text-neutralText1 pt-4 text-[16px]">
                  <div className="flex items-start py-2">
                    {" "}
                    <Unicons.UilCheckCircle
                      size={24}
                      className="mr-5 bg-primary min-w-[1.5rem] rounded-full"
                    />
                    Unlimited Retailers
                  </div>
                  <div className="flex items-start py-2">
                    {" "}
                    <Unicons.UilCheckCircle
                      size={24}
                      className="mr-5 bg-primary min-w-[1.5rem] rounded-full"
                    />
                    Unlimited Salespersons
                  </div>
                  <div className="flex items-start py-2">
                    {" "}
                    <Unicons.UilCheckCircle
                      size={24}
                      min-w-6
                      className="mr-5 bg-primary min-w-[1.5rem] rounded-full"
                    />
                    Guided experiences for sales reps, leaders, and
                    administrators
                  </div>
                  <div className="flex items-start py-2">
                    {" "}
                    <Unicons.UilCheckCircle
                      size={24}
                      className="mr-5 bg-primary min-w-[1.5rem] rounded-full"
                    />
                    Online training.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={` ${
            buyPlan
              ? "mx-auto sm:w-9/12 lg:w-1/2 xl:w-5/12 2xl:w-1/3"
              : "m-auto lg:m-0 sm:w-9/12 md:w-8/12 lg:w-9/12 xl:w-8/12 2xl:w-7/12"
          }`}
        >
          {buyPlan && (
            <div className="py-6 col-span-2  sm:col-span-3 text-transparent text-xl sm:text-3xl font-black bg-clip-text bg-gradient-radial-sm-text from-successBg5 to-neutralText1">
              Register to start using busybee.
            </div>
          )}
          <OuterDiv
            className={` mx-auto ${
              buyPlan ? "lg:mx-auto " : "lg:mx-0 s"
            }  z-10 my-0 h-fit`}
          >
            <div className="p-3 sm:p-12">
              {/* <div className="flex justify-center">
            <img src={BusyBeeLogo} />
            BUSYBEE
          </div>
          <div className="py-6">
            <div className="text-xl text-center font-semibold text-neutralText1">
              Sign up now to start your free trial
            </div>
            <div className="text-xs text-neutralText4 text-center py-1">
              No credit required, no software to install.
            </div>
          </div> */}
              <RegisterationForm buyPlan={buyPlan} />
              <div>
                Already have an account?{" "}
                <Link to="get-started">
                  <span className="text-infoText4 cursor-pointer hover:text-primary-light">
                    Login
                  </span>
                </Link>
              </div>
            </div>
          </OuterDiv>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};
export default Register;
