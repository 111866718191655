import { APIClient } from "./apiClient";

export const getAllTerritories = async (query = "") => {
  try {
    const res = await APIClient.get("/org/territories", {
      params: {
        query,
      },
    });
    return res.data;
  } catch (err) {
    console.log("Error  getAllTerritories", err);
  }
};

export const getVendorsInTerritories = async (territoryId) => {
  try {
    const res = await APIClient.get("/org/territory", {
      params: {
        territoryId,
      },
    });
    return res.data;
  } catch (err) {
    console.log("Error  getVendorsInTerritories", err);
  }
};

export const createTerritory = async (details) => {
  try {
    const res = await APIClient.post("/org/territory", details);
    return res.data;
  } catch (err) {
    console.log("Error createTerritory", err);
  }
};

export const deleteTerritory = async (id) => {
  try {
    const res = await APIClient.delete(`/org/territory?territoryId=${id}`);
    return res.data;
  } catch (err) {
    console.log("Error deleteTerritory", err);
  }
};

export const updateTerritory = async (details) => {
  try {
    const res = await APIClient.put("/org/territory", details);
    return res.data;
  } catch (err) {
    console.log("Error updateTerritory", err);
  }
};
