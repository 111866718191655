import React, { useEffect, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { FiEdit2, FiTrash2, FiX } from "react-icons/fi";

import {
  deleteTerritory,
  getVendorsInTerritories,
} from "../../services/territoriesApi";
import ConfirmationModal from "../common/confirmationModal";
import { toast } from "react-toastify";

function ListTerritoryVendors({
  gMap,
  gMaps,
  activeTerritory,
  onBackClick,
  onEdit,
}) {
  const [vendors, setVendors] = useState([]);
  const getData = async () => {
    const resp = await getVendorsInTerritories(activeTerritory?.territoryId);
    setVendors(resp || []);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className=" px-4 py-4 ">
        <div className="flex justify-between items-center text-neutralText1 mb-1">
          <div className="flex items-center">
            <Unicons.UilArrowLeft
              onClick={onBackClick}
              className="mr-1 cursor-pointer"
            />
            <div>
              <p className=" font-bold text-lg">{activeTerritory?.name}</p>
            </div>
          </div>
          {/* <FiX
            onClick={toggleTerritories}
            className="cursor-pointer"
            size={18}
          /> */}
        </div>
        <div className="flex items-center text-neutralText1 pt-[5px] cursor-pointer ml-6">
          <FiEdit2 size={16} onClick={onEdit} />
          <div className="px-1 pt-[2px] text-xs font-light">Edit</div>
          <ConfirmationModal
            title="Are your sure?"
            subtitle="Are you sure you want to remove this territory."
            positiveLabel="Yes"
            negativeLabel="No"
            onPositiveClick={async () => {
              const resp = await deleteTerritory(activeTerritory?.territoryId);
              if (resp) {
                toast.success("Territory deleted successfully");
                onBackClick();
              }
            }}
            button={(onClick) => (
              <FiTrash2
                onClick={onClick}
                size={16}
                className="cursor-pointer text-errorText5"
              />
            )}
          />
        </div>
      </div>
      <p className="py-2 px-4 border-y-[0.3px] border-neutralBg4 text-neutralText1 font-light text-[0.7rem] ">
        {activeTerritory?.vendorCount} Retailers
      </p>
      {vendors?.map((el, i) => {
        return (
          <div
            key={`ind-${i}`}
            className={` py-4 px-4  border-b-[0.3px] border-b-neutralBg4 cursor-pointer hover:bg-neutralBg2 `}
            onClick={() => {
              gMap.current.panTo({
                lat: el.location?.coordinates[1],
                lng: el.location?.coordinates[0],
              });
              gMap.current.setZoom(16);
            }}
          >
            <p className="text-neutralText1 font-bold text-[0.7rem]">
              {el?.businessName}
            </p>
            <p className="text-neutralText3 font-light text-[0.6rem]">
              {el?.locationName}
            </p>
          </div>
        );
      })}
    </>
  );
}

export default ListTerritoryVendors;
