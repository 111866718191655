import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as Unicons from "@iconscout/react-unicons";
// import { CircularProgress } from "@mui/material";

import {
  register,
  checkUserExistance,
} from "../../redux store/actions/loginAndRegistration";
import { RegisterSchema } from "../validationSchema";
import ValidationErrors from "../common/validationErrors";
import CustomInput from "../common/customInput";
import BaseButton from "../common/baseButton";

const AccountSettingForm = (props) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const registrationResponse = useSelector((state) => state.register);
  const dispatch = useDispatch();

  return (
    <>
      <Formik
        initialValues={{
          businessName: "",
          firstName: "",
          lastName: "",
          email: "",
          jobTitle: "",
          employees: "",
          password: "",
          confirmPassword: "",
          contact: "",
        }}
        validationSchema={RegisterSchema}
        onSubmit={async (values) => {
          if (!values.contact) values.contact = "";
          if (typeof values.contact === "string")
            values.contact = values.contact;
          if (typeof values.contact === "number")
            values.contact = JSON.stringify(values.contact);
          setLoader(true);
          const resp = await checkUserExistance(values.email);
          // console.log("user exists response1", resp);
          // if (resp?.status == "exists") {
          //   console.log("user exists response2", resp);
          //   toast.error("User already exists");
          // } else {
          console.log("user doesnt exists response", resp);
          if (resp === "email already exists") {
          } else {
            history.push({
              pathname: "/set-password",
              state: { values: values, buyPlan: props.buyPlan },
            });
          }
          setLoader(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <CustomInput
              icon={<Unicons.UilUser className="text-neutralText5=1 h-5 w-5" />}
              containerClasses={"py-2 text-neutralText1"}
              label="Business Name"
              placeholder="Business Name"
              type="text"
              name="businessName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.businessName}
            />
            {touched.businessName && errors.businessName && (
              <ValidationErrors
                errors={`${errors.businessName}`}
              ></ValidationErrors>
            )}
            <div className="flex">
              <div className="w-full mr-4">
                <CustomInput
                  icon={
                    <Unicons.UilUser className="text-neutralText5=1 h-5 w-5" />
                  }
                  containerClasses={"py-2 text-neutralText1"}
                  label="First Name"
                  placeholder="First Name"
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                />
                {touched.firstName && errors.firstName && (
                  <ValidationErrors
                    errors={`${errors.firstName}`}
                  ></ValidationErrors>
                )}
              </div>
              <div className="w-full">
                <CustomInput
                  icon={
                    <Unicons.UilUser className="text-neutralText5=1 h-5 w-5" />
                  }
                  containerClasses={"py-2 text-neutralText1"}
                  label="Last Name"
                  placeholder="Last Name"
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                />
                {touched.lastName && errors.lastName && (
                  <ValidationErrors
                    errors={`${errors.lastName}`}
                  ></ValidationErrors>
                )}
              </div>
            </div>
            <CustomInput
              icon={<Unicons.UilUser className="text-neutralText5=1 h-5 w-5" />}
              containerClasses={"py-2 text-neutralText1"}
              label="Email"
              placeholder="mail@abc.com"
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {touched.email && errors.email && (
              <ValidationErrors errors={`${errors.email}`}></ValidationErrors>
            )}
            <div className="flex">
              <div className="w-full mr-4">
                <CustomInput
                  containerClasses={"py-2 text-neutralText1"}
                  label="Job Title"
                  placeholder="Founder, CEO"
                  type="text"
                  name="jobTitle"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.jobTitle}
                />
                {touched.jobTitle && errors.jobTitle && (
                  <ValidationErrors
                    errors={`${errors.jobTitle}`}
                  ></ValidationErrors>
                )}
              </div>
              <div className="w-full">
                <CustomInput
                  containerClasses={"py-2 text-neutralText1"}
                  label="Employees"
                  placeholder="1 - 10"
                  type="text"
                  name="employees"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.employees}
                />
                {touched.employees && errors.employees && (
                  <ValidationErrors
                    errors={`${errors.employees}`}
                  ></ValidationErrors>
                )}
              </div>
            </div>
            <CustomInput
              icon={
                <Unicons.UilPhoneAlt className="text-neutralText5=1 h-5 w-5" />
              }
              containerClasses={"py-2 text-neutralText1 mr-4"}
              label="Phone Number"
              placeholder="0123456789"
              type="number"
              allowNumOnly
              name="contact"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.contact}
            />
            {touched.contact && errors.contact && (
              <ValidationErrors errors={`${errors.contact}`}></ValidationErrors>
            )}
            {/* <CustomInput
              containerClasses={"py-2 text-neutralText1"}
              label="Country"
              placeholder="India"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            /> */}

            <div className="py-3 sm:py-8">
              {/* <Link to="/plans"> */}
              <BaseButton
                text="Start Free Trial"
                type="bg-primary w-full text-neutralText2 p-2 text-lg text-sm hover:bg-primaryLight"
                height={"full"}
                // onClick={() => handleSubmit()}
              />

              {/* </Link> */}
            </div>
          </form>
        )}
      </Formik>
    </>
    //     <Formik
    //     initialValues={{ email: "", password: "" }}
    //     validate={(values) => {
    //       const errors = {};
    //       if (!values.email) {
    //         errors.email = "Required";
    //       } else if (
    //         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    //       ) {
    //         errors.email = "Invalid email address";
    //       }
    //       if (!formValues.name) {
    //         errors.name = "Please enter your name";
    //       }
    //       if (!formValues.number) {
    //         errors.number = "Please enter your number";
    //       }
    //       if (!formValues.password) {
    //         errors.password = "Please enter your password";
    //       }
    //       if (!formValues.confirmPassword) {
    //         errors.confirmPassword = "Please enter your password again  ";
    //       }
    //       if (formValues.confirmPassword !== formValues.password) {
    //         errors.confirmPassword = "Passwords do not match ";
    //       }
    //       return errors;
    //     }}
    //     onSubmit={(values) => {
    //       console.log("formlalues", values);
    //       dispatch(login(values));
    //     }}
    //   >
    //     {({
    //       values,
    //       errors,
    //       touched,
    //       handleChange,
    //       handleBlur,
    //       handleSubmit,
    //       isSubmitting,
    //       /* and other goodies */
    //     }) => (
    //       <form onSubmit={handleSubmit}>
    //           <div className="py-1">Name</div>
    //         <input
    //           className="w-full outline-none border rounded-sm p-2"
    //           placeholder="Your Name"
    //           type="text"
    //           name="name"
    //           onChange={handleChange}
    //           onBlur={handleBlur}
    //           value={values.email}
    //         />
    //         {errors.name && touched.name && errors.name}
    //         <div className="py-1">Email</div>
    //         <input
    //           className="w-full outline-none border rounded-sm p-2"
    //           placeholder="mail@abc.com"
    //           type="email"
    //           name="email"
    //           onChange={handleChange}
    //           onBlur={handleBlur}
    //           value={values.email}
    //         />
    //         {errors.email && touched.email && errors.email}
    //         <div className="py-1">Number</div>
    //         <input
    //           className="w-full outline-none border rounded-sm p-2"
    //           placeholder="mail@abc.com"
    //           type="number"
    //           name="number"
    //           onChange={handleChange}
    //           onBlur={handleBlur}
    //           value={values.email}
    //         />
    //         {errors.number && touched.number && errors.number}
    //         <div className="pt-4 pb-1">Password</div>
    //         <input
    //           className="w-full outline-none border rounded-sm p-2"
    //           placeholder="********"
    //           type="password"
    //           name="password"
    //           onChange={handleChange}
    //           onBlur={handleBlur}
    //           value={values.password}
    //         />
    //         {errors.password && touched.password && errors.password}
    //         <div className="pt-4 pb-1">Confirm Password</div>
    //         <input
    //           className="w-full outline-none border rounded-sm p-2"
    //           placeholder="********"
    //           type="password"
    //           name="confirmPassword"
    //           onChange={handleChange}
    //           onBlur={handleBlur}
    //           value={values.password}
    //         />
    //         {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
    //         <div className="py-8">
    //           <button
    //             className="bg-primary w-full text-white hover:bg-primary-light px-3 p-2 rounded text-xs font-extrabold "
    //             type="submit"
    //             disabled={isSubmitting}
    //           >
    //             Register
    //           </button>
    //         </div>
    //       </form>
    //     )}
    //   </Formik>
  );
};
export default withRouter(AccountSettingForm);
