import axios from "axios";
import { toast } from "react-toastify";
import startCase from "lodash/startCase";

import { logout } from "../redux store/actions/loginAndRegistration";
import { store } from "../index";
import { SERVER_URL } from "../constants/config";

export const APIClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL


});

APIClient.interceptors.request.use(function (config) {
  const token = store.getState().login?.token;
  console.log("token", token);
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

APIClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error123", error);
    if (error.response.status === 422) {
      toast.error("Please check given information");
    } else if (error.response.status === 500) {
      toast.error(startCase(error.response.data));
    } else if (error.response.status === 400) {
      toast.error(startCase(error.response.data));
    } else if (error.response.status === 401) {
      localStorage.clear();
      window.location = "/get-started";
    }

    return Promise.reject(error);
  }
);

// APIClient.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     if (error?.response?.status === 403) {
//       logger.error("Error 403");

//       Clean store
//     }
//     return Promise.reject(error);
//   }
// );
