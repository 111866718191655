import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Unicons from "@iconscout/react-unicons";
import { Formik } from "formik";

import { editBalanceSchema, LoginSchema } from "../validationSchema";
import { login } from "../../redux store/actions/loginAndRegistration";
import ValidationErrors from "../common/validationErrors";
import CustomInput from "../common/customInput";
import BaseButton from "../common/baseButton";
import { editBalance } from "../../services/vendorsApi";
import getCurrency from "../../utils/getCurrency";

const EditBalanceForm = ({ id, vendorId, openingBalance, handleClose }) => {
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className="">
      <Formik
        initialValues={{ amount: openingBalance || 0 }}
        validationSchema={editBalanceSchema}
        // validate={(values)
        //   => {
        //   const errors = {};
        //   if (!values.email) {
        //     errors.email = "Required";
        //   } else if (
        //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        //   ) {
        //     errors.email = "Invalid emai••••••l address";
        //   }
        //   if (!values.password) {
        //     errors.password = "Required";
        //   }
        //   return errors;
        // }}
        onSubmit={async (values) => {
          setLoader(true);
          const res = await editBalance({
            amount: values.amount,
            vendorId: vendorId,
            id: id,
          });
          console.log("res", res);
          if (res) {
            setLoader(false);
            handleClose();
          }
          setLoader(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <CustomInput
                icon={
                  <div className="text-neutralText5 h-5 w-5 flex justify-center items-center">
                    {getCurrency()}
                  </div>
                }
                // containerClasses="py-2"
                label="Opening Balance"
                placeholder="60090"
                type="number"
                allowNumOnly
                name="amount"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.amount}
              />
              {touched.amount && errors.amount && (
                <ValidationErrors
                  errors={`${errors.amount}`}
                ></ValidationErrors>
              )}
            </div>
            <div className=" flex justify-end mt-6">
              <BaseButton
                buttonProps={{ type: "submit" }}
                text={"Save"}
                width={"w-36"}
                type={"bg-secondary hover:bg-secondaryLight"}
                textColor="neutralBg1"
                loading={loader}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditBalanceForm;
