import React, { useState, useRef, useMemo, useEffect } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import 'leaflet/dist/leaflet.css'
import L from "leaflet";
import { useFormikContext } from 'formik';
import mapIcon from "../assets/mapIcon.png";

export const reverseGeocode = async (lat, lng) => {
  const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);
  const data = await response.json();
  return data.display_name;
};

function DraggableMarker({lat=34.1067185, lng=74.8067551, onPositionChange}) {
  const center = {
    lat,
    lng,
  };
  const [position, setPosition] = useState(center);
  const markerRef = useRef(null);
  const leafletIcon = new L.Icon({
    iconUrl: mapIcon,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [-3, -76],
  });
  const eventHandlers = useMemo(
    () => ({
      async dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          const newPosition = marker.getLatLng();
          setPosition(newPosition);
          const address = await reverseGeocode(newPosition.lat, newPosition.lng);
          onPositionChange(newPosition, address); // Call the callback with new position and address
        }
      },
    }),
    [onPositionChange] // Include onPositionChange in dependencies
  );
  // const toggleDraggable = useCallback(() => {
  //   setDraggable((d) => !d);
  // }, []);

  useEffect(()=> {
    console.log(position)
  },[position])

  return (
    <Marker
      draggable={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={leafletIcon}
    >
    </Marker>
  );
}

const MarkLocation = ({lat=34.1067185, lng=74.8067551}) => {
  const { setFieldValue } = useFormikContext();

  const handlePositionChange = (newPosition, address) => {
    setFieldValue('location', [newPosition.lat, newPosition.lng]);
    setFieldValue('locationName', address);
    return {lat: newPosition.lat, lng: newPosition.lng}
  };

  return (
    <MapContainer style={{ width: "100%", height: "40vh" }} center={[lat, lng]} zoom={12} scrollWheelZoom={true}>
      <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <DraggableMarker lat={lat} lng={lng} onPositionChange={handlePositionChange}  />
  </MapContainer>
  );
};

export default MarkLocation;
