import * as React from "react";
import BaseModal from "./baseModal";

export default function CustomModal({
  title,
  button,
  boxStyle,
  topContainerStyle,
  bottomContainerStyle,
  children,
  openModal,
  setOpenModal,
  showHow,
}) {
  const [open, setOpen] = React.useState(openModal || false);

  const handleOpen = () => {
    if (setOpenModal) {
      setOpenModal(true);
    }
    setOpen(true);
  };

  const handleClose = () => {
    if (setOpenModal) {
      setOpenModal(false);
    }
    setOpen(false);
  };

  React.useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  return (
    <div>
      {button && <button onClick={handleOpen}>{button}</button>}
      <BaseModal open={open} handleClose={handleClose} boxStyle={boxStyle}>
        <>
          <div className={`pb-4 ${topContainerStyle}`}>{title}</div>
          <div
            className={
              bottomContainerStyle || "py-6 border-t border-t-neutralBg4"
            }
          >
            {children}
          </div>
          {showHow && (
            <div className="text-center">
              <button
                className="bg-primary hover:bg-primaryLight text-neutralText3 px-8 py-1 my-4"
                onClick={() => setOpen(false)}
              >
                Close
              </button>
            </div>
          )}
        </>
      </BaseModal>
    </div>
  );
}
