import { APIClient } from "./apiClient";

//for adding new product
export const addNewProduct = async (productDetail) => {
  try {
    const res = await APIClient.post("/org/product", productDetail);
    return res;
  } catch (err) {
    console.log("error in addNewProduct", err);
  }
};

export const bulkUploadProducts = async (details) => {
  try {
    const res = await APIClient.post("/org/products", {
      products: details,
    });
    return res.data;
  } catch (err) {
    console.log("error in bulkUpload", err);
  }
};

export const editProduct = async (productDetail) => {
  try {
    const res = await APIClient.put("/org/product", productDetail);
    return res.data;
  } catch (err) {
    console.log("error in product update", err);
  }
};

export const getProductStats = async (id) => {
  try {
    const res = await APIClient.get(`/org/product/stats?orgId=${id}`);
    console.log("getProductStats", res);
    return res.data;
  } catch (err) {
    console.log("getProductStats", err);
  }
};

export const removeProduct = async (id) => {
  let details = { productId: id };
  try {
    const res = await APIClient.delete("/org/product", { params: details });
    return res.data;
  } catch (err) {
    console.log("getProductStats", err);
  }
};

export const getProductDetails = async (id) => {
  console.log("getQueryDetails", id);
  try {
    const res = await APIClient.get(`/org/product?productId=${id}`);
    console.log("getProductStats", res);
    return res;
  } catch (err) {
    console.log("getProductStats", err);
  }
};

export const getProductList = async ({
  offset = 0,
  limit = 10,
  query = "",
}) => {
  try {
    const response = await APIClient.get("/org/products/list", {
      params: {
        offset,
        limit,
        query,
      },
    });
    console.log("getProductDetails response", response.data.result);
    return response.data;
  } catch (err) {
    console.log("get product list error", err);
  }
};
