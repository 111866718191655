import { useEffect, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";

import CustomModalTitle from "./common/customModalTitle";
import OuterDiv from "./common/outerDiv";
import CustomTable from "./common/table";
import { getVendorsOverview } from "../services/vendorsApi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import noVendors from "../assets/noVendors.svg";
import EmptyFallBack from "./common/emptyFallback";
import getCurrency from "../utils/getCurrency";
import millify from "millify";

const columns = [
  { key: "vendor", name: "Retailer", minWidth: 180 },
  { key: "sale", name: "Sale", minWidth: 120, width: 120 },
  { key: "paid", name: "Paid", minWidth: 120, width: 120 },
  { key: "balance", name: "Balance", minWidth: 120, width: 120 },
  { key: "chevron", name: "", minWidth: 50, width: 80 },
];

const TopVendors = ({ date }) => {
  const [topVendors, setTopVendors] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modifiedData, setModifiedData] = useState([]);

  const getData = async () => {
    setTableLoading(true);
    const data = await getVendorsOverview(
      parseInt(date.startDate / 1000, 10),
      parseInt(date.endDate/ 1000, 10)
    );
    setTopVendors(data?.data || []);
    setTableLoading(false);
  };

  useEffect(() => {
    getData();
  }, [date]);

  useEffect(() => {
    const temp = topVendors?.map((el) => {
      console.log(el?.status);
      return {
        ...el,
        vendor: (
          <span className="text-neutralText1 font-bold truncate">
            {el?.businessName}
          </span>
        ),
        sale: (
          <span className="text-neutralText1 font-normal truncate">
            {getCurrency()} {millify(el?.sale || 0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </span>
        ),
        paid: (
          <span className="text-successText5 font-normal truncate">
            {getCurrency()} {millify(el?.collection || 0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </span>
        ),
        balance: (
          <span className="text-errorText5 font-normal truncate">
            {getCurrency()} {millify(el?.balance || 0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </span>
        ),
        chevron: (
          <Unicons.UilAngleRightB className="text-neutralText1" size="20px" />
        ),
      };
    });
    setModifiedData(temp);
  }, [topVendors]);

  return (
    <OuterDiv>
      <div className="p-6 h-[324px]">
        <div className="flex justify-between text-neutralText1">
          <CustomModalTitle
            title="Your Retailers' Overview"
            description={
              <>
                {/* Showing Data for the month of{" "}
                <span className="text-infoText4">february</span> */}
              </>
            }
          />
          <Link to="/vendors" target="_blank">
            <Unicons.UilExternalLinkAlt size={22} />
          </Link>
        </div>
        <div className="pt-4">
          <CustomTable
            columns={columns}
            rows={modifiedData}
            emptyFallBack={
              <EmptyFallBack
                image={noVendors}
                text="No Retailers Found"
                containerClass={"mt-4"}
              />
            }
            bodyRowProps={{
              path: "/view-vendor",
              id: "vendorId",
            }}
            loading={tableLoading}
          />
        </div>
      </div>
    </OuterDiv>
  );
};
export default TopVendors;
