// import { result } from 'lodash';
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// var Highlight = require("react-highlighter");
import Highlighter from "react-highlight-words";
function AutoComplete({ content, setContent, searchKeyword }) {
  return (
    <div className="bg-white overflow-scroll h-80 shadow shadow-2xl w-1/2 mx-auto py-4 mt-0">
      {content.length > 0 ? (
        content.map((item, index) => {
          return (
            <div
              key={index}
              className="px-4 mb-4 text-[#60A5FA] hover:cursor-pointer  hover:text-[#999999]"
            >
              <Link
                className="underline underline-offset-4 pr-6"
                to={"/learn/" + item.category + "/" + item.id}
              >
                {/* <Highlight search={searchKeyword}>{item.text}</Highlight> */}
                <Highlighter
                  highlightClassName="YourHighlightClass"
                  searchWords={[...searchKeyword]}
                  autoEscape={true}
                  textToHighlight={item.text}
                />
              </Link>

              <span className=" no-underline bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                {item.category}
              </span>
            </div>
          );
        })
      ) : (
        <div className="text-center mx-auto text-red-900 font-bold">
          No results found
        </div>
      )}
    </div>
  );
}
export default AutoComplete;
