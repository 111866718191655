import React, { useEffect, useRef, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import BaseButton from "../common/baseButton";
import LandingHeader from "./landingHeader";
import image1 from "../../assets/featuresImage1.svg";
import image2 from "../../assets/featuresImage2.svg";
import image3 from "../../assets/featuresImage3.svg";
import image4 from "../../assets/featuresImage4.svg";
import sliderImage1 from "../../assets/sliderImage1.svg";
import SliderVideo from "./SliderVideo";

const Features = () => {
  const [swiperRef, setSwiperRef] = useState(null);
  const [slide, _] = useState([
    {
      heading: "FEATURES",
      body: (
        <div>
          Standardise <br />
          Retailers
        </div>
      ),
      description:
        "Add your retailers and have their locations mapped by salespersons.",
    },
    {
      heading: "FEATURES",
      body: (
        <div>
          Organise
          <br /> Sales Teams
        </div>
      ),
      description:
        "Add new salespersons, edit existing ones. Monitor daily activity on your fingertips.",
    },
    {
      heading: "FEATURES",
      body: (
        <div>
          Assign
          <br /> Daily Visits
        </div>
      ),
      description:
        "Allocate retailers to salespersons, assign visits, order deliveries. collections and more,",
    },
    {
      heading: "FEATURES",
      body: (
        <div>
          Monitor <br /> Performance
        </div>
      ),
      description:
        "Track sales, balance and credit details from one place Get insights into performance with key statistics.",
    },
  ]);

  let appendNumber = 4;
  let prependNumber = 1;
  const [i, si] = useState(0);

  useEffect(() => {
    console.log(slide[i], "slid");
  }, [i]);

  return (
    <>
      <div className="m-auto pb-8 lg:pb-36 xl:pb-40 pt-8 lg:pt-0 bg-backgroundLogoV bg-[right_top_18rem] bg-no-repeat text-center lg:text-left">
        <div className="block lg:absolute pb-0 lg:pl-10 xl:pl-20 2xl:pl-28 py-4 lg:py-8 lg:pb-12 xl:pb-24 2xl:py-20 2xl:pb-32 z-20 w-11/12 lg:w-6/12 xl:w-5/12  m-auto lg:m-0 lg:mr-auto bg-gradient-to-r from-neutralBg2 via-neutralBg2 to-transparent">
          <LandingHeader
            header={slide[i].heading}
            body={slide[i].body}
            bodyClass={"max-w-96"}
          />
          <div className="sm:w-8/12 xl:w-10/12 2xl:w-9/12 text-neutralText2 pb-4 2xl:pt-4 2xl:pb-10  m-auto lg:m-0 lg:mr-auto">
            {slide[i].description}
          </div>
          <div className="flex text-neutralText1 justify-center lg:justify-start">
            {/* <button
              className="prev border-2 p-1 border-secondary rounded-full h-14 w-14 flex items-center justify-center hover:bg-secondary"
              onClick={(e) => {
                if (i === 0) {
                  return false;
                }
                si((prev) => prev - 1);
              }}
            >
              <FiChevronLeft size={""} className="text-2xl" />
            </button>
            <button
              onClick={(e) => {
                if (i === 3) {
                  return false;
                }
                si((prev) => prev + 1);
              }}
              className="next border-2 p-1 border-secondary rounded-full ml-4 h-14 w-14 flex items-center justify-center hover:bg-secondary"
            >
              <FiChevronRight size={""} className="text-2xl" />
            </button> */}
            {/* {i > 0 && ( */}
            <button
              className="prev border-2 p-1 border-secondary rounded-full h-14 w-14 flex items-center justify-center hover:bg-secondary"
              onClick={(e) => {
                if (i === 0) {
                  return false;
                }
                si((prev) => prev - 1);
              }}
            >
              <FiChevronLeft size={""} className="text-2xl" />
            </button>
            {/* )} */}
            {/* {i < 3 && ( */}
            <button
              onClick={(e) => {
                if (i === 3) {
                  return false;
                }
                si((prev) => prev + 1);
              }}
              className="next border-2 p-1 border-secondary rounded-full ml-4 h-14 w-14 flex items-center justify-center hover:bg-secondary"
            >
              <FiChevronRight size={""} className="text-2xl" />
            </button>
            {/* )} */}
          </div>
        </div>
        <div className="lg:ml-[17rem] xl:ml-[16rem] 2xl:ml-[20rem] flex items-center">
          {/* <div className="flex items-center justify-right w-full"> */}
          <Swiper
            onSwiper={setSwiperRef}
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={120}
            navigation={{
              prevEl: ".prev",
              nextEl: ".next",
            }}
            modules={[Navigation]}
            className="mySwiper"
            speed={1000}
            watchSlidesProgress={true}
            effect="fade"
            breakpoints={{
              768: {
                slidesPerView: 1.9,
              },
              640: {
                slidesPerView: 1.2,
              },
            }}
          >
            <SwiperSlide>
              {({ isActive }) => (
                <div className="flex items-center justify-center px-10 sm:px-16 md:px-0 py-8  w-full">
                  <div className="w-full border-8 border-t-[1.8rem] border-[#C8C7FF] h-full shadow-xl rounded-md overflow-hidden">
                    <SliderVideo
                      isActive={isActive}
                      src={"standardise_vendor.mp4"}
                    />
                  </div>
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {({ isActive }) => (
                <div className="flex items-center justify-center px-10 sm:px-16 md:px-0 py-8  w-full ">
                  <div className="w-full border-8 border-t-[1.8rem] border-[#DAECFC] h-full shadow-xl rounded-md overflow-hidden">
                    <SliderVideo isActive={isActive} src={"salesperson.mp4"} />
                  </div>
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {({ isActive }) => (
                <div className="flex items-center justify-center px-10 sm:px-16 md:px-0 py-8  w-full ">
                  <div className="w-full border-8 border-t-[1.8rem] border-[#FCFFD0] h-full shadow-xl rounded-md overflow-hidden">
                    <SliderVideo
                      isActive={isActive}
                      src={"assign_daily_visits.mp4"}
                    />
                  </div>
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {({ isActive }) => (
                <div className="flex items-center justify-center px-10 sm:px-16 md:px-0 py-8  w-full">
                  <div className="w-full border-8 border-t-[1.8rem] border-[#FAEBD7] h-full shadow-xl rounded-md overflow-hidden">
                    <SliderVideo
                      isActive={isActive}
                      src={"monitor_performance.mp4"}
                    />
                  </div>
                </div>
              )}
            </SwiperSlide>
          </Swiper>
          {/* </div> */}
        </div>
      </div>
      <div className="m-auto pt-8 2xl:pt-20 pb-12 2xl:pb-36 bg-primary bg-opacity-[0.6] px-4 sm:px-[3rem] xl:px-[5rem] 2xl:px-[5rem] justify-center lg:flex">
        <div className="lg:w-8/12 xl:w-8/12 flex justify-center ">
          <img
            src={image1}
            alt="vid"
            className="absolute w-[250px] sm:w-[300px] md:w-[370px] xl:w-[410px] 2xl:w-[500px] -ml-8 sm:-ml-14 sm:mr-0 -mt-24 2xl:-mt-40"
          ></img>
          <img
            src={image2}
            alt="vid"
            className="absolute w-[260px] sm:w-[320px]  md:w-[400px] xl:w-[422px] 2xl:w-[511px] -mt-9 sm:-mt-4 md:-mt-0 lg:-mt-8 xl:-mt-4 ml-4 sm:ml-32 lg:ml-24 xl:ml-36 2xl:-mt-16 2xl:ml-52"
          ></img>
          <img
            src={image3}
            alt="vid"
            className="absolute w-[255px] sm:w-[310px] md:w-[390px]  xl:w-[470px] 2xl:w-[555px] -ml-20 lg:-ml-36 xl:-ml-40  2xl:-ml-32 mt-16 sm:mt-24 md:mt-40 lg:mt-32 2xl:mt-36"
          ></img>
          <img
            src={image4}
            alt="vid"
            className="absolute w-[250px] sm:w-[300px] md:w-[370px] xl:w-[410px] 2xl:w-[500px] mt-32 sm:mt-44 md:mt-64 lg:mt-48 sm:ml-24 xl:mt-56 lg:ml-16 xl:ml-24 2xl:mt-56 2xl:ml-36"
          ></img>
        </div>
        <div className="text-lg md:w-7/12 pt-[24rem] sm:pt-[30rem] md:pt-[40rem] lg:pt-0 xl:w-8/12 2xl:w-6/12">
          <div className="w-11/12">
            <LandingHeader
              header="FEATURES"
              body="Give your sales team wings !"
            />
          </div>
          <div className="2xl:w-11/12 text-neutralText1">
            Your sales team knows your retailers best. Let them create and
            maintain their details for you.
          </div>
          <div className="2xl:w-11/12 text-neutralText1 pt-6 ">
            Your sales team adds retailers and their transactions,so you know who
            is buying from you and how much they owe you.
          </div>
          <div className="text-neutralText1 py-6">
            You can assign tasks to your team, and see how they are progressing
            from your finger tips.
          </div>
          <Link to={"/register"}>
            <BaseButton
              text={
                <div className="flex justify-between text-neutralBg1 items-center text-xl font-semibold">
                  <div>Try for free</div>
                  <Unicons.UilArrowRight size={24} className="mr-1" />
                </div>
              }
              type="h-[52px] bg-neutralText1  hover:bg-neutralText2"
              width=""
            />
          </Link>
        </div>
      </div>
      {/* <div className="pt-36 text-center">
        <div className="text-[64px] pb-4 text-transparent bg-clip-text font-black bg-gradient-radial-sm-text from-successBg5 to-neutralText1 leading-[70px]">
          How BusyBee Helps?
        </div>
        <div className="flex mt-4 justify-center m-auto rounded-full border-4 border-secondary w-min">
          <div className="px-10 py-3 text-neutralBg1 font-black bg-secondary rounded-full">
            Organisations
          </div>
          <div className="px-6 py-3 font-medium text-transparent bg-clip-text bg-gradient-radial-text from-successBg5 to-neutralText1">
            Salespersons
          </div>
        </div>
      </div>
      <div className="px-36 pt-14">
        <div className="text-[36px] font-black w-5/12 text-transparent bg-clip-text bg-gradient-radial-text from-successBg5 to-neutralText1 leading-[40px]">
          BusyBee packs handy tools for overseeing outdoor sales
        </div>
        <div className="flex py-8">
          <div className="w-1/2 grid grid-cols-2 gap-x-8">
            <SmallCard
              icon={<Unicons.UilBringBottom size={40} />}
              label="Vendor Mapping"
              info="Plot and view physical locations of vendors"
            />
            <SmallCard
              icon={<Unicons.UilBringBottom size={40} />}
              label="Sales Registry"
              info="Build and maintain sales records automatically"
            />
            <SmallCard
              icon={<Unicons.UilBringBottom size={40} />}
              label="Balance Registry"
              info="Don’t forget those balance figures"
            />
            <SmallCard
              icon={<Unicons.UilBringBottom size={40} />}
              label="Sales Visit Tracking"
              info="See whose visiting when and how often."
            />
            <SmallCard
              icon={<Unicons.UilBringBottom size={40} />}
              label="Progress Statistics"
              info="See how well your sales team is performing"
            />
            <SmallCard
              icon={<Unicons.UilBringBottom size={40} />}
              label="Order Tracking"
              info="Make sure your vendors receive their ordered"
            />
          </div>
          <div className="w-1/2"></div>
        </div>
      </div> */}
    </>
  );
};
export default Features;
