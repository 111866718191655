import { APIClient } from "./apiClient";

export const getVendorList = async ({
  offset = 0,
  limit = 10,
  status = "",
  query = "",
  mapped,
  spId,
  sortField = "businessName",
  sortOrder = "asc",
  assignedStatus,
  territoryId,
  startDate,
  endDate,
}) => {
  try {
    const response = await APIClient.get("/org/vendor/list", {
      params: {
        offset,
        status,
        query,
        limit,
        mapped,
        spId,
        sortField,
        sortOrder,
        territoryId,
        startDate,
        endDate,
        assignedStatus,
      },
    });

    return response.data;
  } catch (err) {
    console.log("get Vendor list error", err);
  }
};

export const getVendorMapList = async ({ query, territoryId, spId }) => {
  try {
    const resp = await APIClient.get("org/vendors/map", {
      params: { query, territoryId, spId },
    });
    return resp.data;
  } catch (err) {
    console.log("Error getVendorMapList", err);
  }
};

export const addVendor = async (details) => {
  try {
    const response = await APIClient.post("/org/vendor/add", details);

    console.log("Response: ", response.data);
    return response.data;
  } catch (err) {
    console.log("Error addVendor: ", err);
  }
};

export const addExistingVendor = async (details) => {
  try {
    const response = await APIClient.post("/org/vendor/association", details);

    console.log("Response: ", response.data);
    return response.data;
  } catch (err) {
    console.log("Error addExistingVendor: ", err);
  }
};

export const checkBulkVendors = async (details) => {
  try {
    const response = await APIClient.post("/org/vendor/bulk-check", details);
    return response.data;
  } catch (err) {
    console.log("Error checkBulkVendors: ", err);
  }
};

export const addBulkVendors = async (details) => {
  try {
    const response = await APIClient.post("/org/vendor/bulk", details);

    return response.data;
  } catch (err) {
    console.log("Error addBulkVendors: ", err);
  }
};

export const updateVendor = async (details) => {
  try {
    const response = await APIClient.put("org/vendor/profile", details);

    return response.data;
  } catch (err) {
    console.log("Error updateVendor: ", err);
  }
};

export const getVendor = async (id) => {};

export const searchVendors = async (details) => {
  try {
    const response = await APIClient.get("/general/org/vendors", {
      params: details,
    });

    console.log("Response: ", response.data);
    return response.data;
  } catch (err) {
    console.log("Error updateVendor: ", err);
  }
};

export const assignSalesperson = async (details) => {
  try {
    const response = await APIClient.post("/org/vendor/assign-sp", details);

    console.log("Response: ", response.data);
    return response.data;
  } catch (err) {
    console.log("Error assignSalesperson: ", err);
  }
};

export const getVendorsOverview = async (startDate, endDate) => {
  try {
    const res = await APIClient.get("/org/top-vendors", {
      params: { offset: 1, startDate: startDate, endDate: endDate, limit: 3 },
    });
    return res.data;
  } catch (err) {
    console.log("Error getting getVendorsOverview", err);
  }
};

export const getVendorStats = async (startDate, endDate, id) => {
  try {
    const res = await APIClient.get("/org/vendor/stats", {
      params: { startDate: startDate, endDate: endDate, vendorId: id },
    });
    return res.data;
  } catch (err) {
    console.log("Error getting getVendorsOverview", err);
  }
};

export const getVendorInteraction = async (startDate, endDate) => {
  try {
    const res = await APIClient.get("/org/vendor/no-interaction", {
      params: { offset: 1, fromDate: startDate, toDate: endDate },
    });
    return res.data;
  } catch (err) {
    console.log("Error getting getVendorsOverview", err);
  }
};


export const editBalance =async ({id,amount,vendorId})=>{
  try{
    const res = await APIClient.put("/org/opening-balance", {
      id:id,
      amount:amount,
      vendorId:vendorId
    });
    return res.data
  } catch (err) {
    console.log("Error edit balance", err);
  }
}