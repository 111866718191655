import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";

import CustomTitle from "../../components/common/customTitle";
import OuterDiv from "../../components/common/outerDiv";
import Avatar from "../../components/common/avatar";
import BaseButton from "../../components/common/baseButton";
import CustomModal from "../../components/common/customModal";
import CustomModalTitle from "../../components/common/customModalTitle";
import AccountSettingForm from "../../components/forms/accountSettingForm";
import getCurrency from "../../utils/getCurrency";
import { getProfileDetails } from "../../redux store/actions/organizerProfile";
import { getCurrentPlan } from "../../services/plansAndBilling";

const Overview = () => {
  const dispatch = useDispatch();

  const orgDetails = useSelector((state) => state.getProfileDetails);
  const [currentPlan, setCurrentPlan] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [profileField, setProfileField] = useState("");

  const getData = async () => {
    dispatch(getProfileDetails());
    const data = await getCurrentPlan();
    setCurrentPlan(data);
  };

  useEffect(() => {
    getData();
  }, []);

  console.log("currPlan", currentPlan);

  return (
    <>
      <CustomModal
        // buttonText="Add New Vendor"
        title={
          <CustomModalTitle
            title="Edit Profile"
            description="Edit your profile details"
          />
        }
        boxStyle={{ p: 0, width: 650 }}
        topContainerStyle={"px-[32px] pt-[32px]"}
        bottomContainerStyle={
          "px-[32px] pb-[32px] pt-[25px] bg-neutralBg2 rounded-b mt-2"
        }
        openModal={openModal}
        setOpenModal={setOpenModal}
      >
        <AccountSettingForm
          field={profileField}
          text={orgDetails}
          handleCloseModal={() => {
            getData();
            setOpenModal(false);
          }}
        />
      </CustomModal>
      <div className="flex flex-row">
        <div className="w-full pb-12 grid grid-cols-4 px-12">
          <div className="col-span-3 w-10/12">
            <div className="flex justify-between items-center pt-12">
              <CustomTitle
                title="Overview"
                description="Here's your Organisation Profile"
              />
            </div>
            <OuterDiv>
              <div className="p-8">
                <div className="h-16 flex items-center justify-between">
                  <div className="text-[10px] text-neutralText1 w-44">
                    PHOTO
                  </div>
                  <Avatar
                    imageClassName={"w-12 h-12"}
                    image={orgDetails?.profileImage}
                  />

                  <BaseButton
                    onClick={() => {
                      setProfileField("image");
                      setOpenModal(true);
                    }}
                    text="Change"
                    className="border border-neutralText2 text-neutralText2 py-1 px-6 bg-neutralBg1 text-xs hover:bg-primary hover:border-primary"
                    textColor="neutralText2"
                  />
                </div>
                <div className="h-16 flex items-center text-neutralText1 justify-between border-t-2 border-t-neutralBg3">
                  <div className="text-[10px] w-44">BUSINESS NAME</div>
                  <div className="text-sm font-black">
                    {orgDetails?.businessName}
                  </div>
                  <BaseButton
                    onClick={() => {
                      setProfileField("businessName");
                      setOpenModal(true);
                    }}
                    text="Change"
                    className="border border-neutralText2 text-neutralText2 py-1 px-6 bg-neutralBg1 text-xs hover:bg-primary hover:border-primary"
                    textColor="neutralText2"
                  />
                </div>
                <div className="h-16 flex items-center text-neutralText1 justify-between border-t-2 border-t-neutralBg3">
                  <div className="text-[10px] w-44">NAME</div>
                  <div className="text-sm font-black">
                    {orgDetails?.firstName} {orgDetails?.lastName}
                  </div>
                  <BaseButton
                    onClick={() => {
                      setProfileField("name");
                      setOpenModal(true);
                    }}
                    text="Change"
                    className="border border-neutralText2 text-neutralText2 py-1 px-6 bg-neutralBg1 text-xs hover:bg-primary hover:border-primary"
                    textColor="neutralText2"
                  />
                </div>
                <div className="h-16 flex items-center text-neutralText1 justify-between border-t-2  border-t-neutralBg3">
                  <div className="text-[10px] w-44">PHONE</div>
                  <div className="text-sm font-black">
                    {orgDetails?.contact || "-"}
                  </div>
                  <BaseButton
                    onClick={() => {
                      setProfileField("phone");
                      setOpenModal(true);
                    }}
                    text="Change"
                    className="border border-neutralText2 text-neutralText2 py-1 px-6 bg-neutralBg1 text-xs hover:bg-primary hover:border-primary"
                    textColor="neutralText2"
                  />
                </div>
                <div className="h-16 flex items-center text-neutralText1 justify-between border-t-2 border-t-neutralBg3">
                  <div className="text-[10px] w-44">EMAIL</div>
                  <div className="text-sm font-black">{orgDetails?.email}</div>
                  <BaseButton
                    onClick={() => {
                      setProfileField("email");
                      setOpenModal(true);
                    }}
                    text="Change"
                    className="border border-neutralText2 text-neutralText2 py-1 px-6 bg-neutralBg1 text-xs hover:bg-primary hover:border-primary"
                    textColor="neutralText2"
                  />
                </div>
                <div className="h-16 flex items-center text-neutralText1 justify-between border-t-2 border-t-neutralBg3">
                  <div className="text-[10px] w-44">ADDRESS</div>
                  <div className="text-sm font-black text-center line-clamp-2 px-8">
                    {orgDetails?.locationName || "-"}
                  </div>
                  <BaseButton
                    onClick={() => {
                      setProfileField("address");
                      setOpenModal(true);
                    }}
                    text="Change"
                    className="border border-neutralText2 text-neutralText2 py-1 px-6 bg-neutralBg1 text-xs hover:bg-primary hover:border-primary"
                    textColor="neutralText2"
                  />
                </div>
                <div className="h-16 flex items-center text-neutralText1 justify-between border-t-2 border-t-neutralBg3">
                  <div className="text-[10px] w-44">CURRENCY</div>
                  <div className="text-sm font-black">
                    {`${
                      `${orgDetails?.currency?.country}(${orgDetails?.currency?.currency_code})` ||
                      "-"
                    }`}
                  </div>
                  <BaseButton
                    onClick={() => {
                      setProfileField("currency");
                      setOpenModal(true);
                    }}
                    text="Change"
                    className="border border-neutralText2 text-neutralText2 py-1 px-6 bg-neutralBg1 text-xs hover:bg-primary hover:border-primary"
                    textColor="neutralText2"
                  />
                </div>
              </div>
            </OuterDiv>

            <OuterDiv>
              <div className="px-6 pt-6 pb-2">
                <CustomTitle
                  title="Your Plans"
                  description="Know where are your retailers and their balances"
                />
              </div>
              <div className="flex items-center justify-between bg-neutralBg2 mx-6 mb-8">
                {/* <div className="bg-primary h-16 w-4"></div> */}
                <div className="py-10 px-14 border-l-[6px] border-primary">
                  <div className="text-neutralText3 font-bold text-lg">
                    Monthly Plan
                  </div>
                  <div className="text-xs text-neutralText4">
                    Unlimited Salespersons
                  </div>
                </div>
                <div className="text-2xl text-neutralText1 font-black">
                  {getSymbolFromCurrency(currentPlan?.currency)}
                  {currentPlan?.pricePerSp || 0}/
                  <span className="text-sm text-neutralText3">per seat</span>
                  {/* {orgDetails.subscriptionPlan.plan.annualRate}/m */}
                </div>
                <Link to={"/plans-and-billing"}>
                  <BaseButton
                    text="View Details"
                    className="border border-neutralText2 text-neutralText2 h-7 py-1 px-3 bg-neutralBg1 text-xs hover:bg-primary hover:border-primary mx-6"
                    // textColor="neutralText2"
                  />
                </Link>
              </div>
            </OuterDiv>
            {/* <div className="py-8 text-primary-text-light font-bold">
              Add New Salesperson
            </div> */}
            {/* <SettingsForm
              edit
              initialValues={props.location.state?.editItem}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Overview;
