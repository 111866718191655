import React from "react";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
}));

const CustomTooltip = ({text, children}) => {
  return (
    <BootstrapTooltip 
    title={text}
    arrow
    placement="top">
        <IconButton size="small">
            {children}
        </IconButton> 
    </BootstrapTooltip>
  )
}

export default CustomTooltip