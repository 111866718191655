import * as Yup from "yup";

// TODO - Put in proper folder

export const RegisterSchema = Yup.object().shape({
  businessName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  contact: Yup.string()
    .min(9, "Number must be atleast 10 digits!")
    .max(15, "Number can't be more than 10 digits!")
    .required("Required"),
  jobTitle: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  employees: Yup.string().max(50, "Too Long!").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export const LoginSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export const SetPasswordSchema = Yup.object().shape({
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const CouponSchema = Yup.object().shape({
  couponCode: Yup.string().required("Required"),
});

export const addSalespersonSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too Long")
    .required("Name is required"),
  number: Yup.string()
    .min(9, "Phone Number must be atleast 10 digits!")
    .max(15, "Phone Number can't be more than 15 digits!")
    .required("Phone number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export const addSalespersonIdSchema = Yup.object().shape({
  spId: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too Long")
    .required("ID is required"),
});

export const addVendorSchema = Yup.object().shape({
  businessName: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too Long")
    .required("Business Name is required"),
  // location: Yup.array().required("Location is required"),
  // locationName: Yup.string().required("Address is required"),
  contact: Yup.string()
    .min(9, "Contact number must be at least 10 digits")
    .max(15, "Contact number can't be more than 15 digits")
    .required("Contact number required"),
  contactPerson: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too Long")
    .required("Contact person is required"),
  openingBalance: Yup.number("Balance must be numeric").max(9999999, "Too Long").nullable(),
  // assignSalesperson: Yup.object().required("Salesperson is required"),
});

export const editBalanceSchema = Yup.object().shape({
  amount: Yup.number().max(99999999, "Too Long").required("Balance is required"),
});

export const targetSchema=Yup.object().shape({
  target: Yup.number().max(99999999, "Too Long").required("Balance is required"),
});


export const updateVendorSchema = Yup.object().shape({
  businessName: Yup.string()
    .min(2, "Too Short")
    .max(50, "Too Long")
    .required("Business Name is required"),
  // location: Yup.array().required("Location is required"),
  // locationName: Yup.string().required("Address is required"),
  contact: Yup.string()
    .min(9, "Contact number must be at least 10 digits")
    .max(15, "Contact number can't be more than 15 digits")
    .required("Contact number required"),
  // contactPerson: Yup.string()
  //   .min(2, "Too Short")
  //   .max(50, "Too Long")
  //   .required("Contact person is required"),
  // openingBalance: Yup.number("Balance must be numeric").nullable(),
  // assignSalesperson: Yup.object().required("Salesperson is required"),
});

export const addProductSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  unit: Yup.string().required("Required"),
});

export const addVisitSchema = Yup.object().shape({
  vendorIds: Yup.array()
    .of(Yup.string())
    .min(1, "Add minimum 1 retailer")
    .required(),
  visitType: Yup.string().required("Visit Type is required"),
  // territoryId: Yup.object()
  // .shape({
  //   name: Yup.string().required("RRRPlease select a territory first"),
  // })
  // .required("Please select a territory first"),
  date: Yup.date().required("Visit date is required"),
});

export const updateVisitSchema = Yup.object().shape({
  // visitId: Yup.string().required("Visit Id is missing"),
  visitType: Yup.string().required("Visit Type is required"),
  status: Yup.string().required("Visit Status is required"),
  date: Yup.date().required("Visit date is required"),
});

export const createTransactionSchema = Yup.object().shape({
  vendorId: Yup.string().required("Select a retailer first"),
  date: Yup.date().required("Date is required"),
  transactionType: Yup.string().required("Transaction type is required"),
  amount: Yup.number()
    .min(1, "Amount should be greater than 1").max(999999999,"Amount excceds the limit")
    .required("Amount is required"),
});
