import { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FiSliders, FiX } from "react-icons/fi";
import startCase from "lodash/startCase";
import millify from "millify";

import EmptyFallBack from "../../components/common/emptyFallback";
import noTransactions from "../../assets/noTransactions.svg";
import CustomTable from "../../components/common/table";
import ConvertTimestamp from "../../components/convertTimestamp";
import { getSalespersonsTransactions } from "../../services/transactionsApi";
import { CustomDateRangePicker } from "../../components/common/customDatePicker";
import getCurrency from "../../utils/getCurrency";

const columns = [
  { key: "date", name: "DATE" },
  { key: "transactionType", name: "TYPE" },
  { key: "vendor", name: "RETAILER" },
  { key: "amount", name: "AMOUNT" },
];

export const TransactionsBySalesPerson = ({ spId }) => {
  const [transactions, setTransactions] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dateStart = new Date();
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateStart.setHours(0, 0, 0, 0);

  const dateEnd = new Date();
  dateEnd.setHours(23, 59, 59, 99);

  const [date, setDate] = useState([
    {
      startDate: dateStart,
      endDate: dateEnd,
      key: "selection",
    },
  ]);

  const getData = async ({ page }) => {
    setTableLoading(true);
    setOffset(page - 1);
    const data = await getSalespersonsTransactions({
      offset: page,
      limit: rowsPerPage,
      spId,
      startDate: parseInt(date[0].startDate / 1000, 10),
      endDate: parseInt(date[0].endDate / 1000, 10),
    });
    setCount(data?.count);
    setTransactions(data?.result || []);
    setTableLoading(false);
  };

  const clearFilters = () => {
    setDate([
      {
        startDate: dateStart,
        endDate: dateEnd,
        key: "selection",
      },
    ]);
  };

  useEffect(() => {
    getData({ page: 1 });
  }, [rowsPerPage, date]);

  useEffect(() => {
    const temp = transactions?.map((el) => {
      return {
        ...el,
        date: (
          <ConvertTimestamp
            timestamp={el.createdAt}
            className="font-normal text-neutralText1 truncate"
          />
        ),
        transactionType: (
          <div
            className={`px-2 py-0.5 border-l-4 ${
              el?.transactionType === "COLLECTION"
                ? "bg-successBg2 text-successText5 border-successText5"
                : el?.transactionType === "SALES"
                ? "bg-infoBg2 text-infoText4 border-infoText5"
                : el?.transactionType === "RETURN"
                ? "bg-errorBg2 text-errorText5 border-errorText5"
                : el?.transactionType === "OPENING_BALANCE"
                ? "bg-warningBg2 text-warningText5 border-warningText5"
                : ""
            } `}
          >
            {startCase(el?.transactionType?.toLowerCase())}
          </div>
        ),
        vendor: (
          <div className="text-infoText4 font-normal truncate">
            <Link to={`/view-vendor/${el.vendor?.id}`}>
              {el.vendor?.businessName}
            </Link>
          </div>
        ),
        amount: (
          <div className="text-successText5 font-extrabold truncate">
            {getCurrency()}{" "}
            {millify(el?.amount || 0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </div>
        ),
      };
    });
    setModifiedData(temp);
  }, [transactions]);

  return (
    <div className="bg-neutralBg1 border-x-[0.5px] border-b-[0.5px] border-neutralBg4 px-6 py-6">
      <div className="flex flex-row justify-between items-center pb-4">
        <div className="text-base text-neutralText1 font-medium">
          List of Transactions
        </div>
        <div className="flex items-center">
          <div className=" mr-2">
            {date[0].startDate / 1000 !== dateStart / 1000 ||
            date[0].endDate / 1000 !== dateEnd / 1000 ? (
              <button onClick={() => clearFilters()}>
                <div className="flex items-center justify-center text-infoText4 text-xs">
                  <FiX size={14} />
                  Clear
                </div>
              </button>
            ) : (
              <FiSliders className=" text-neutralText1" size={18} />
            )}
          </div>
          <CustomDateRangePicker
            date={date}
            setDate={setDate}
            changed={
              date[0].startDate / 1000 !== dateStart / 1000 ||
              date[0].endDate / 1000 !== dateEnd / 1000
            }
          />
        </div>
      </div>
      <CustomTable
        columns={columns}
        rows={modifiedData}
        count={count}
        page={offset}
        onPageChange={(_, page) => getData({ page: page + 1 })}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
        loading={tableLoading}
        emptyFallBack={
          <EmptyFallBack
            image={noTransactions}
            text="No Transactions Found"
            containerClass={"mt-14 mb-4"}
            imageClass={"h-44"}
          />
        }
      />
    </div>
  );
};
export default TransactionsBySalesPerson;
