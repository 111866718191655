import { APIClient } from "./apiClient";

export const addSalesperson = async (details) => {
  try {
    const response = await APIClient.post("/org/sp/add", details);

    console.log("Response: ", response.data);
    return response.data;
  } catch (err) {
    console.log("Error addSalesperson: ", err);
  }
};

export const checkBulkSalespersons = async (details) => {
  try {
    const response = await APIClient.post("/org/sp/bulk-check", details);
    return response.data;
  } catch (err) {
    console.log("Error checkBulkSalespersons: ", err);
  }
};

export const addBulkSalespersons = async (details) => {
  try {
    const response = await APIClient.post("/org/sp/bulk", details);

    return response.data;
  } catch (err) {
    console.log("Error addBulkSalespersons: ", err);
  }
};

export const updateSalesperson = async (details) => {
  try {
    const response = await APIClient.put("/org/sp/profile", details);

    return response.data;
  } catch (err) {
    console.log("Error updateSalesperson: ", err);
  }
};

export const getSalesPersons = async ({
  offset = 0,
  limit = 10,
  status = "",
  assignmentStatus = "",
  query = "",
  date = parseInt(Date.now() / 1000, 10),
}) => {
  try {
    const response = await APIClient.get("/org/sp/list", {
      params: {
        offset: offset,
        limit,
        date,
        query,
        status,
        assignmentStatus,
      },
    });

    return response.data;
  } catch (err) {
    console.log("Error getSalesPersons", err);
  }
};

export const getSalespersonById = async (spId, date) => {
  try {
    const res = await APIClient.get("/org/sp/stats", {
      params: { spId, date },
    });
    return res;
  } catch (err) {
    console.log("Error getting profile", err);
  }
};

export const getSalesteamOverview = async (startDate, endDate) => {
  try {
    const res = await APIClient.get("/org/top-sp", {
      params: {
        startDate: startDate,
        endDate: endDate,
        date: endDate,
        limit: 3,
      },
    });
    return res.data;
  } catch (err) {
    console.log("Error getting getSalesteamOverview", err);
  }
};

export const getSalespersonsStats = async (date) => {
  try {
    const res = await APIClient.get("/org/sp/list/stats", {
      params: { date: date },
    });
    return res.data;
  } catch (err) {
    console.log("Error getting getSalespersonsStats", err);
  }
};

export const setSalespersonPassword = async (token, password) => {
  try {
    const res = await APIClient.post("/salesperson/set-password", {
      token: token,
      password: password,
    });
    return res.data;
  } catch (err) {
    console.log("Error in sendSpResetLink", err);
  }
};

export const replaceSalesperson = async (oldId, newId) => {
  try {
    const res = await APIClient.post("/org/sp/replace", {
      newSpId: newId,
      oldSpId: oldId,
    });
    return res.data;
  } catch (err) {
    console.log("Error in sendSpResetLink", err);
  }
};
