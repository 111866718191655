import React, { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import SearchVendor from "../common/searchVendor";
import VendorForm from "../forms/vendorForm";
import { searchVendors } from "../../services/vendorsApi";

const steps = ["Search Retailer", "Details"];

function AddVendor({ handleCloseModal }) {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();
  const [query, setQuery] = useState("");

  const getData = async ({ page }) => {
    setLoading(true);
    const resp = await searchVendors({
      offset: page,
      limit: 10,
      query,
    });
    console.log("GetData", resp.result);
    setVendors(resp.result);
    setLoading(false);
  };

  useEffect(() => {
    setSelectedVendor("");
    getData({ page: 0 });
  }, [query]);

  return (
    <div className="pt-6">
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          return (
            <Step key={index}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    active: "!fill-infoText3",
                    completed: "!fill-successBg5",
                    text: "!font-mulish !font-light",
                    root: "!p-[4px]",
                  },
                }}
                classes={{
                  label:
                    "!text-neutralText3 !font-mulish !font-light !text-xs !-ml-1",
                  active: "!text-neutralText3 !font-mulish !font-light",
                  completed: "!text-neutralText3 !font-mulish !font-light",
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === 0 ? (
        <SearchVendor
          next={() => setActiveStep(1)}
          onNewClick={() => setActiveStep(1)}
          vendorList={vendors}
          selectedVendor={selectedVendor}
          setVendor={setSelectedVendor}
          onSearch={setQuery}
          loading={loading}
        />
      ) : activeStep === 1 ? (
        <VendorForm
          next={() => setActiveStep(2)}
          back={() => setActiveStep(0)}
          initialValues={selectedVendor || query}
          existing={!!selectedVendor?.id}
          handleCloseModal={handleCloseModal}
        />
      ) : null}
    </div>
  );
}

export default AddVendor;
