import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import useSupercluster from "use-supercluster";
import * as Unicons from "@iconscout/react-unicons";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CustomInput from "../../components/common/customInput";
import Filters from "../../components/common/filters";
import Marker from "../../components/common/marker";
import Map from "../../components/googleMap";
import { getSalesPersons } from "../../services/salespersonApi";
import { getVendorMapList } from "../../services/vendorsApi";
import { FilterType, TerritoriesPhase, Theme } from "../../constants/common";
import DarkMapStyle, { MapStyle } from "../../theme/darkMapStyle";
import {
  Tab,
  TabPanel,
  Tabs,
  TabsList,
} from "../../components/common/customTabs";
import Territories from "./territories";
import { getAllTerritories } from "../../services/territoriesApi";

function VendorsMapTab({}) {
  const [vendorMapList, setVendorMapList] = useState([]);
  const [points, setPoints] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [query, setQuery] = useState();
  const [activeFilters, setActiveFilters] = useState([]);
  const [phase, setPhase] = useState(TerritoriesPhase.ListTerritory);
  const [activeTerritory, setActiveTerritory] = useState();
  const [activePolygonArea, setActivePolygonArea] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const [selectedSalesperson, setSelectedSalesperson] = useState();
  const [searchedSalespersons, setSearchedSalespersons] = useState([]);
  const [salespersonsLoading, setSalespersonsLoading] = useState(false);

  const [selectedTerritory, setSelectedTerritory] = useState();
  const [searchedTerritories, setSearchedTerritories] = useState([]);
  const [territoryLoading, setTerritoryLoading] = useState(false);

  const [tabValue, setTabValue] = useState(0);

  const theme = useSelector((state) => state.theme);

  const searchSalespersons = async (query) => {
    setSalespersonsLoading(true);
    const resp = await getSalesPersons({
      offset: 1,
      limit: 5,
      query,
    });
    setSearchedSalespersons(resp.result || []);
    setSalespersonsLoading(false);
  };

  const searchTerritory = async (query) => {
    setTerritoryLoading(true);
    const resp = await getAllTerritories(query);
    setSearchedTerritories(resp || []);
    setTerritoryLoading(false);
  };

  const filters = [
    {
      filterKey: "territory",
      filterLabel: "Territory",
      filterType: FilterType.Autocomplete,
      selectedFilter: selectedTerritory,
      onFilterChange: setSelectedTerritory,
      onFilterInputChange: searchTerritory,
      filterLoading: territoryLoading,
      optionValue: "name",
      filterPlaceholder: "Territory",
      filterIcon: <Unicons.UilMap size={24} />,
      filterValues: searchedTerritories,
    },
    {
      filterKey: "salesperson",
      filterLabel: "Salesperson",
      filterType: FilterType.Autocomplete,
      selectedFilter: selectedSalesperson,
      onFilterChange: setSelectedSalesperson,
      onFilterInputChange: searchSalespersons,
      filterLoading: salespersonsLoading,
      optionValue: "name",
      filterPlaceholder: "Salesperson",
      filterIcon: <Unicons.UilUser size={24} />,
      filterValues: searchedSalespersons,
    },
  ];

  const getMapData = async () => {
    let mapData = await getVendorMapList({
      query,
      spId: selectedSalesperson?.id,
      territoryId: selectedTerritory?.territoryId,
    });
    mapData = mapData || [];
    console.log("🚀 ~ getMapData ~ mapData:", mapData);
    setVendorMapList(mapData);

    setPoints(
      mapData.map((el) => ({
        type: "Feature",
        properties: {
          cluster: false,
          id: el.id,
          category: "Some",
        },
        geometry: el?.location,
        vendor: el,
      }))
    );
  };

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const gMap = useRef();
  const gMaps = useRef();

  const handleMapLoad = (map, maps) => {
    gMap.current = map;
    gMaps.current = maps;
  };
  console.log("🚀 ~ clusters:", clusters);

  const fetchClusterVendors = (cluster) => {
    console.log("🚀 ~ fetchClusterVendors ~ cluster:", cluster);

    // Get all points within the cluster
    const leaves = supercluster.getLeaves(cluster.id, Infinity);
    console.log("🚀 ~ fetchClusterVendors ~ leaves:", leaves);

    // Calculate the total balance
    const totalBalance = leaves.reduce((acc, leaf) => {
      return acc + (leaf?.vendor?.balance || 0);
    }, 0);

    console.log("🚀 ~ fetchClusterVendors ~ totalBalance:", totalBalance);

    return totalBalance;
  };

  useEffect(() => {
    getMapData();
  }, [query, selectedSalesperson, selectedTerritory]);

  return (
    <div className="flex flex-row-reverse border border-neutralBg4 border-t-0 rounded h-screen relative">
      <Tabs
        defaultValue={0}
        orientation="vertical"
        onChange={(e, newValue) => setTabValue(newValue)}
        className="rounded-lg"
      >
        <TabsList style={{ marginBottom: -1 }}>
          <Tab className={"!w-max"}>
            <div className="flex items-center m-auto justify-center">
              <Unicons.UilListUl size="20" />
            </div>
          </Tab>
          <Tab className={"!w-max"}>
            <div className="flex items-center m-auto justify-center">
              <Unicons.UilMap size="20" />
            </div>
          </Tab>
        </TabsList>
        <TabPanel className="rounded-t-xl	rounded-b-xl" value={0}>
          <div className="bg-neutralBg1 absolute right-11 top-0 z-10 w-72 h-screen overflow-auto">
            <CustomInput
              icon={<FiSearch className="text-neutralText1 h-5 w-5" />}
              placeholder="Search"
              name="search"
              inputContainerClasses="border-0 py-3 !border-y-[0.5px] rounded-none border-neutralBg4"
              onChange={(e) => setQuery(e.target.value)}
              value={query}
            />
            <div className="text-neutralText3 text-[0.6rem] py-2 px-6 bg-neutralBg1 border-b-[0.5px] border-neutralBg4">
              Showing {vendorMapList?.length || 0} Retailers
            </div>

            {vendorMapList.map((el, i) => {
              return (
                <div
                  key={`ind-${i}`}
                  onClick={() => {
                    gMap.current.panTo({
                      lat: el.location?.coordinates[1],
                      lng: el.location?.coordinates[0],
                    });
                    gMap.current.setZoom(16);
                  }}
                  className={`flex justify-between py-3 px-6  bg-neutralBg1 border-b-[0.3px] hover:bg-neutralBg2 border-neutralText5 
                            ${
                              el.location &&
                              el.location?.coordinates?.length === 2
                                ? "cursor-pointer"
                                : "text-neutralText4"
                            }  `}
                >
                  <div className="grow mr-1">
                    <p
                      className={`${
                        el.location && el.location?.coordinates?.length === 2
                          ? "text-neutralText1"
                          : "text-neutralText4"
                      }  font-bold text-sm line-clamp-1`}
                    >
                      {el.businessName}
                    </p>
                    <p
                      className={`${
                        el.location && el.location?.coordinates?.length === 2
                          ? "text-neutralText3"
                          : "text-neutralText5"
                      }  text-xs line-clamp-1 line-clamp-2`}
                    >
                      {el.locationName}
                    </p>
                  </div>
                  <Link to={`/view-vendor/${el?.id}`} target="_blank">
                    <Unicons.UilExternalLinkAlt size={16} fill />
                  </Link>
                </div>
              );
            })}
          </div>
          <div className=" absolute left-4 top-3 z-10 ">
            <Filters
              filters={filters}
              setFilters={setActiveFilters}
              activeFilters={activeFilters}
              noLabel
            />
          </div>
        </TabPanel>
        <TabPanel className="rounded-t-xl	rounded-b-xl" value={1}>
          <div className="bg-neutralBg1 absolute right-11 top-0 z-10 w-72 h-screen overflow-auto">
            <Territories
              phase={phase}
              setPhase={setPhase}
              activeTerritory={activeTerritory}
              setActiveTerritory={setActiveTerritory}
              activePolygonArea={activePolygonArea}
              setActivePolygonArea={setActivePolygonArea}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              gMap={gMap}
              gMaps={gMaps}
              tabValue={tabValue}
            />
          </div>
        </TabPanel>
      </Tabs>
      {/* 
      <div className=" absolute right-16 bottom-3 z-10 ">
        <BaseButton
          text="View Territories"
          type="bg-neutralBg1 hover:bg-neutralBg2 rounded-full drop-shadow-lg"
          textColor="neutralText2"
          onClick={toggleTerritories}
        />
      </div> */}
      <Map
        yesIWantToUseGoogleMapApiInternals //this is important!
        onGoogleApiLoaded={({ map, maps }) => handleMapLoad(map, maps)}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
        options={{
          styles: theme === Theme.Dark ? DarkMapStyle : MapStyle,

          fullscreenControl: false,
        }}
      >
        {clusters.map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          const { vendor } = cluster;

          if (isCluster) {
            const clusterBalance = fetchClusterVendors(cluster);
            return (
              <div
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
                onClick={() => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    20
                  );
                  gMap.current.setZoom(expansionZoom);
                  gMap.current.panTo({ lat: latitude, lng: longitude });
                }}
              >
                <div
                  className="cluster-marker"
                  style={{
                    width: `120px`,
                    height: `45px`,
                  }}
                  onClick={() => {}}
                >
                  <p> {`Vendors ${pointCount}`}</p>
                  <p> {`Balance ${clusterBalance}`}</p>
                </div>
              </div>
            );
          }

          return (
            <Marker
              key={`vender-${cluster.properties.id}`}
              gMap={gMap}
              lat={latitude}
              lng={longitude}
              index={index}
              data={{
                id: vendor.id,
                name: vendor.businessName,
                address: vendor.locationName,
                balance: vendor.balance,
              }}
            />
          );
        })}
      </Map>
    </div>
  );
}

export default VendorsMapTab;
