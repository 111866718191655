import React, { useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useHistory } from "react-router-dom";
import startCase from "lodash/startCase";

import CustomBreadcrumbs from "../common/customBreadcrumbs";
import SelectFile from "./selectFile";
import MatchFields from "./matchFields";
import BulkAdd from "./bulkAdd";
import ReviewData from "./reviewData";
import { BulkImportType } from "../../constants/common";
// import CustomTitle from "../common/customTitle";
// import CustomTable from "../common/table";
// import { bulkImportData } from "../../constants/dummyData";

// const columns = [
//   { key: "date", name: "Date" },
//   { key: "recordsCreated", name: "Records Created" },
//   { key: "recordsUpdated", name: "Records Updated" },
//   { key: "warnings", name: "Warnings" },
//   { key: "errors", name: "Errors" },
//   { key: "status", name: "Status" },
// ];

const BulkImport = ({ steps, links, bulkImportType, busybeeFields }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [matchedFields, setMatchedFields] = useState();
  const [files, setFiles] = useState([]);
  const [parsed, setParsed] = useState();
  const [processedData, setProcessedData] = useState();

  const history = useHistory();
  console.log("Links: ",links[1].url);

  return (
    <>
      <div className="flex flex-row">
        <div className="w-full  pt-12 pb-8 px-12">
          <div className="col-span-7">
            <CustomBreadcrumbs links={links} />
            <div className="flex flex-row items-center ">
              <FiArrowLeft
                className="text-neutralText1 stroke-3 cursor-pointer mr-2"
                // size={12}
                onClick={() => history.goBack()}
              />
              <span className="text-neutralText1 font-black text-lg">
                Import {startCase(bulkImportType.toLowerCase())} from CSV Data
              </span>
            </div>
          </div>

          <div className="col-span-8 bg-neutralBg1 p-8 mt-4">
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel
                      StepIconProps={{
                        classes: {
                          active: "!fill-infoText3",
                          completed: "!fill-successBg5",
                          text: "!font-mulish !font-light",
                          // root: "!fill-white border border-neutralText3",
                        },
                      }}
                      classes={{
                        label: "!text-neutralText3 !font-mulish !font-light",
                        active: "!text-neutralText3 !font-mulish !font-light",
                        completed:
                          "!text-neutralText3 !font-mulish !font-light",
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === 0 ? (
              <SelectFile
                files={files}
                selectFiles={setFiles}
                next={() => setActiveStep(1)}
                bulkImportType={bulkImportType}
                setParsed={setParsed}
              />
            ) : activeStep === 1 ? (
              <MatchFields
                files={files}
                back={() => setActiveStep(0)}
                next={() => setActiveStep(2)}
                bulkImportType={bulkImportType}
                parsed={parsed}
                busybeeFields={busybeeFields}
                matchedFields={matchedFields}
                setMatchedFields={setMatchedFields}
                setProcessedData={setProcessedData}
              />
            ) : activeStep === 2 &&
              bulkImportType === BulkImportType.Product ? (
              <BulkAdd
                // done={() => null}
                // matchedFields={matchedFields}
                processedData={processedData}
                bulkImportType={bulkImportType}
                uploadAnother={() => {
                  setFiles([]);
                  setMatchedFields(null);
                  setParsed(null);
                  setActiveStep(0);
                }}
                link={links[1].url}
              />
            ) : activeStep === 2 ? (
              <ReviewData
                matchedFields={matchedFields}
                processedData={processedData}
                setProcessedData={setProcessedData}
                bulkImportType={bulkImportType}
                back={() => setActiveStep(1)}
                next={() => setActiveStep(3)}
              />
            ) : activeStep === 3 ? (
              <>
              <BulkAdd
                // done={() => null}
                // matchedFields={matchedFields}
                processedData={processedData}
                bulkImportType={bulkImportType}
                uploadAnother={() => {
                  setFiles([]);
                  setMatchedFields(null);
                  setParsed(null);
                  setActiveStep(0);
                }}
                link={links[1].url}
              />
            </>
            ) : null}
          </div>
          {/* <div className="col-span-8 bg-neutralBg1 p-8 mt-4">
            <CustomTitle
              title={`${startCase(
                bulkImportType.toLowerCase()
              )} Import history`}
              description="Check history and status of your imports here"
              descriptionClasses="text-neutralText1 font-light text-xs"
            />
            <CustomTable
              columns={columns}
              rows={bulkImportData}
              bodyCellClasses={{ root: "!text-[10px]" }}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default BulkImport;
