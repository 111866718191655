import { Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import GoogleAutocomplete from "react-google-autocomplete";
import AutocompleteInput from "@mui/material/Autocomplete";
import * as Unicons from "@iconscout/react-unicons";

import { updateVendorSchema } from "../validationSchema";
import ValidationErrors from "../common/validationErrors";
// import { addNewVendor, updateVendor } from "../../redux store/actions/vendor";
import CustomInput from "../common/customInput";
import BaseButton from "../common/baseButton";
import { MAPS_API_KEY } from "../../constants/config";
import {
  addVendor,
  addExistingVendor,
  updateVendor,
} from "../../services/vendorsApi";
import CustomAutocomplete from "../common/customAutocomplete";
import { getSalesPersons } from "../../services/salespersonApi";
import MarkLocation from "../MarkLocation";

const UpdateVendorForm = ({
  initialValues,
  locked,
  handleCloseModal,
  field,
}) => {
  const [loading, setLoading] = useState(false);
  const [salespersonsLoading, setSalespersonsLoading] = useState(false);
  const [salespersons, setSalespersons] = useState([]);
  const [query, setQuery] = useState("");

  const handleSubmitForm = async (values) => {
    console.log("🚀 ~ handleSubmitForm ~ values:", values)
    if (!values.contact) values.contact = "";
    if (typeof values.contact === "string") values.contact = values.contact;
    if (typeof values.contact === "number")
      values.contact = JSON.stringify(values.contact);
    setLoading(true);
    let vendorDetail = {
      id: initialValues?.id || "",
      businessName: values.businessName,
      contacts: [
        {
          name: values?.contactPerson || "",
          contact: values.contact,
          email: "",
        },
      ],
      location: values?.location || [],
      locationName: values?.locationName,
      contactPerson: values?.contactPerson || "",
      amount: values?.openingBalance || null,
      spId: values.assignSalesperson || "",
    };

    try {
      const vendorResponse = await updateVendor(vendorDetail);

      console.log("vendor response", vendorResponse);
      if (vendorResponse) {
        handleCloseModal();
        toast.success("Updated Successfully");
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const searchSalespersons = async (query) => {
    setSalespersonsLoading(true);
    const resp = await getSalesPersons({
      offset: 1,
      limit: 5,
      query,
    });
    setSalespersons(resp.result || []);
    setSalespersonsLoading(false);
  };

  useEffect(() => {
    searchSalespersons(query);
  }, [query]);

  console.log("contact person", initialValues);

  return (
    <Formik
      initialValues={{
        businessName: initialValues?.businessName || "",
        contact:
          initialValues?.contacts?.length > 0
            ? initialValues.contacts[0].contact
            : "",
        assignSalesperson: initialValues?.salesperson?.id || "",
        // contactPerson: initialValues?.contacts[0] || "",
        // openingBalance: initialValues?.balance || null,
        locationName: initialValues?.locationName || "",
        location:
          initialValues?.location?.coordinates &&
          initialValues?.location?.coordinates?.length === 2
            ? [
                initialValues?.location?.coordinates[1],
                initialValues?.location?.coordinates[0],
              ]
            : [],
      }}
      validationSchema={updateVendorSchema}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          {/* <div className="grid grid-cols-2 gap-4"> */}
          {field === "name" ? (
            <div>
              <CustomInput
                icon={
                  <Unicons.UilBuilding className="text-neutralText5 h-5 w-5" />
                }
                // containerClasses="py-2"
                label="Name"
                placeholder="John Proprietary"
                type="text"
                name="businessName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.businessName}
                disabled={locked}
              />
              {touched.businessName && errors.businessName && (
                <ValidationErrors
                  errors={`${errors.businessName}`}
                ></ValidationErrors>
              )}
            </div>
          ) : null}

          {field === "address" ? (
            <div>
              <MarkLocation lat={values.location[0]} lng={values.location[1]} />
              <CustomInput
                icon={<Unicons.UilUser className="text-neutralText5 h-5 w-5" />}
                // containerClasses="py-2"
                label="Business Address"
                placeholder="e.g., Srinagar, J&amp;K"
                type="text"
                name="locationName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.locationName}
                disabled={locked}
              />
              {/* <div className="text-neutralText2 text-xs py-1 block font-light">
                Business Address
              </div>

              <div
                className={`flex items-center border border-0.5 border-neutralBg4 bg-neutralBg1 rounded-sm px-2 py-1 focus-within:border-infoText5 transition ${
                  locked ? "bg-neutralBg3" : ""
                }`}
              >
                <Unicons.UilUserLocation className="text-neutralText5 h-5 w-5" />
                <GoogleAutocomplete
                  className="bg-transparent text-sm text-neutralText2 px-2 py-0.5 w-full outline-none "
                  id="locationName"
                  name="locationName"
                  placeholder="e.g., Srinagar, J&amp;K"
                  value={values.locationName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  apiKey={MAPS_API_KEY}
                  options={{
                    types: [],
                  }}
                  disabled={locked}
                  onPlaceSelected={(place) => {
                    // console.log("geometry", place.geometry.location);
                    setFieldValue("locationName", place.formatted_address);
                    setFieldValue("location", [
                      place.geometry.location.lat(),
                      place.geometry.location.lng(),
                    ]);
                  }}
                />
              </div> */}
              {touched.locationName && errors.locationName && (
                <ValidationErrors
                  errors={`${errors.locationName}`}
                ></ValidationErrors>
              )}
            </div>
          ) : null}

          {field === "contact" ? (
            <div>
              <CustomInput
                icon={
                  <Unicons.UilPhone className="text-neutralText5 h-5 w-5" />
                }
                // containerClasses="py-2"
                label="Contact Number"
                placeholder="+1 (298) 988967"
                type="number"
                name="contact"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contact}
              />
              {touched.contact && errors.contact && (
                <ValidationErrors
                  errors={`${errors.contact}`}
                ></ValidationErrors>
              )}
            </div>
          ) : null}

          {field === "person" ? (
            <div>
              <CustomInput
                icon={<Unicons.UilUser className="text-neutralText5 h-5 w-5" />}
                // containerClasses="py-2"
                label="Contact Person"
                placeholder="John Doe"
                type="text"
                name="contactPerson"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contactPerson}
              />
              {touched.contactPerson && errors.contactPerson && (
                <ValidationErrors
                  errors={`${errors.contactPerson}`}
                ></ValidationErrors>
              )}
            </div>
          ) : null}
          {field === "balance" ? (
            <div>
              <CustomInput
                icon={
                  <Unicons.UilRupeeSign className="text-neutralText5 h-5 w-5" />
                }
                // containerClasses="py-2"
                label="Opening Balance"
                placeholder="$60090"
               
               allowNumOnly
                name="openingBalance"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.openingBalance}
              />
              {touched.openingBalance && errors.openingBalance && (
                <ValidationErrors
                  errors={`${errors.openingBalance}`}
                ></ValidationErrors>
              )}
            </div>
          ) : null}

          {field === "salesperson" ? (
            <div>
              <CustomAutocomplete
                icon={
                  <Unicons.UilUserSquare className="text-neutralText5 h-5 w-5" />
                }
                value={values.assignSalesperson}
                onChange={(event, newValue) => {
                  setFieldValue("assignSalesperson", newValue);
                }}
                filterOptions={(x) => x}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                options={salespersons}
                type="text"
                autoComplete
                includeInputInList
                filterSelectedOptions
                onInputChange={(event, newInputValue) => {
                  setQuery(newInputValue);
                }}
                loading={salespersonsLoading}
              />

              {touched.assignSalesperson && errors.assignSalesperson && (
                <ValidationErrors
                  errors={`${errors.assignSalesperson}`}
                ></ValidationErrors>
              )}
            </div>
          ) : null}
          {/* </div> */}
          <div className=" flex justify-end mt-6">
            <BaseButton
              buttonProps={{ type: "submit" }}
              text={"Save"}
              width={"w-36"}
              type={"bg-secondary hover:bg-secondaryLight"}
              textColor="neutralBg1"
              loading={loading}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
export default UpdateVendorForm;
