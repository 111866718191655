import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import * as Unicons from "@iconscout/react-unicons";
// import { CircularProgress } from "@mui/material";

import { SetPasswordSchema } from "../validationSchema";
import { Formik, Form, Field } from "formik";
import { LOGIN } from "../../redux store/actions/actionTypes";
import {
  register,
  checkUserExistance,
  login,
} from "../../redux store/actions/loginAndRegistration";
import ValidationErrors from "../common/validationErrors";
import CustomInput from "../common/customInput";
import BaseButton from "../common/baseButton";

const SetPasswordForm = ({ registrationDetails, buyPlan }) => {
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const registerResponse = useSelector((state) => state.register);
  const dispatch = useDispatch();

  console.log("buyPlan", buyPlan);
  return (
    <div className="">
      <Formik
        initialValues={{
          confirmPassword: "",
          password: "",
          ...registrationDetails,
        }}
        validationSchema={SetPasswordSchema}
        // validate={(values)
        //   => {
        //   const errors = {};
        //   if (!values.email) {
        //     errors.email = "Required";
        //   } else if (
        //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        //   ) {
        //     errors.email = "Invalid email address";
        //   }
        //   if (!values.password) {
        //     errors.password = "Required";
        //   }
        //   return errors;
        // }}
        onSubmit={async (values) => {
          setLoader(true);
          console.log("formvalues", values);
          const data = await dispatch(register(values));
          // const userInfo = {
          //   email: values.email,
          //   password: values.password,
          // };
          // await dispatch(login(userInfo));
          console.log("data,,, ", data);
          if (data.status === 200) {
            if (buyPlan) {
              history.push({
                pathname: "/buy-plan",
                state: { buyPlan: buyPlan.el, orgDetails: data.data },
              });
            } else {
              const userInfo = {
                email: values.email,
                password: values.password,
              };
              await dispatch(login(userInfo));
              // setTimeout(() => {
              //   setLoader(false);
              // }, 2000);
              history.push("/dashboard");
              toast.success("Registered Successfully");
            }
          }
          // setLoader(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <CustomInput
              icon={<Unicons.UilLock className="text-neutralText5=1 h-5 w-5" />}
              containerClasses={"py-2 text-neutralText1"}
              label="Password"
              className="w-full outline-none border rounded-sm p-2"
              placeholder="********"
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            {touched.password && errors.password && (
              <ValidationErrors
                errors={`${errors.password}`}
              ></ValidationErrors>
            )}
            <CustomInput
              icon={<Unicons.UilLock className="text-neutralText5=1 h-5 w-5" />}
              containerClasses={"py-2 text-neutralText1"}
              label="Re-enter Password"
              className="w-full outline-none border rounded-sm p-2"
              placeholder="********"
              type="password"
              name="confirmPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
            />
            {touched.confirmPassword && errors.confirmPassword && (
              <ValidationErrors
                errors={`${errors.confirmPassword}`}
              ></ValidationErrors>
            )}
            <div className="py-8">
              <BaseButton
                text="Set Password"
                type="bg-primary w-full text-neutralText2 p-2 text-sm text-sm hover:bg-primaryLight"
                onClick={() => handleSubmit()}
                disabled={loader}
                loading={loader}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SetPasswordForm;
