import React from "react";
import AutocompleteInput, {
  autocompleteClasses,
} from "@mui/material/Autocomplete";
import * as Unicons from "@iconscout/react-unicons";
import { styled } from "@mui/material/styles";
import tailwindConfig from "../../theme/tailwindConfig";
import { Popper } from "@mui/material";

// import CustomInput from "./customInput";

const StyledAutocompletePopper = styled(Popper)(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    fontFamily: tailwindConfig.theme.fontFamily.mulish,
    fontSize: 14,
    color: tailwindConfig.theme.colors.neutralText1,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: tailwindConfig.theme.colors.neutralBg1,
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      padding: 8,
      borderBottom: `1px solid  ${tailwindConfig.theme.colors.neutralBg2}`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: tailwindConfig.theme.colors.neutralText2,
      },
    },
  },
}));

function PopperComponent(props) {
  return <StyledAutocompletePopper {...props} />;
}

function CustomAutocomplete({
  value,
  icon,
  label,
  placeholder,
  inputContainerClasses,
  inputClasses,
  ...props
}) {
  return (
    <AutocompleteInput
      value={value}
      {...props}
      PopperComponent={PopperComponent}
      renderInput={({ inputProps, InputProps }) => (
        <div className={`w-full ${inputContainerClasses}`}>
          {label && (
            <label className="text-neutralText2 text-xs py-1 block font-light">
              {label}
            </label>
          )}

          <div
            ref={InputProps.ref}
            className={`flex items-center border border-0.5 border-neutralBg4 bg-neutralBg1 rounded-sm px-2 py-1 focus-within:border-infoText5 transition ${inputClasses}`}
          >
            {icon}
            <input
              type="text"
              value={value}
              placeholder={placeholder || "e.g., John"}
              {...inputProps}
              className="bg-transparent text-sm text-neutralText1 px-2 w-full outline-none "
            />
          </div>
        </div>
      )}
    />
  );
}

export default CustomAutocomplete;
