const LandingHeader = (props) => {
  return (
    <div>
      <div className="text-neutralText2 font-roboto font-extrabold text-[11px] sm:text-[12px] 2xl:text-[13px] tracking-ultra-wide py-2 ">
        {props.header}
      </div>
      <div
        className={
          "lg:text-[3rem] sm:text-[3rem] text-[1.9rem]  pb-4 text-transparent bg-clip-text font-black bg-gradient-radial-text from-successBg5 to-neutralText1 leading-[38px] sm:leading-[48px] lg:leading-[55px] xl:leading-[56px] 2xl:leading-[1] transition-all duration-500 " +
          props.bodyClass
        }
      >
        {props.body}
      </div>
    </div>
  );
};
export default LandingHeader;
