import React, { useEffect, useState } from "react";
import millify from "millify";
import _ from "lodash";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Chart = ({ data, range, chartInfo }) => {
  const [chartData, setChartData] = useState();

  console.log("charts", chartInfo);

  const setData = () => {
    const chartValues = data.map((el) => {
      let d =
        new Date(el.timestamp * 1000).getDate().toString() +
        " " +
        new Date(el.timestamp * 1000).toString().slice(4, 7);

      return {
        timestamp: d,
        [chartInfo]: el.data,
      };
    });
    setChartData(chartValues);
  };

  useEffect(() => {
    if (data) {
      setData();
    }
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height="100%" className="">
      <LineChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="var(--theme-neutral-text-5)" strokeWidth={0.5} />
        <XAxis
          stroke="var(--theme-neutral-text-5)"
          strokeWidth={0.5}
          style={{ fontSize: "12px" }}
          dx={10}
          dy={5}
          tickLine={false}
          dataKey="timestamp"
        />
        <YAxis
          stroke="var(--theme-neutral-text-5)"
          strokeWidth={0.5}
          style={{ fontSize: "12px" }}
          padding={{}}
          tickLine={false}
        />
        <Tooltip />
        <Line
          type="monotone"
          dataKey={chartInfo}
          stroke="var(--theme-secondary)"
          strokeWidth={2}
          dot={false}
          activeDot={{ r: 5 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export default Chart;
