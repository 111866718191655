import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../Navbar/Breadcrumb";
import Articles from "./Articles";

const Dashboard = function (props) {
  const id = props.id;
  const [category, setCategory] = useState({});

  useEffect(() => {
    const filteredCategory = props.content.filter((obj) => {
      return obj.id === id;
    });
    setCategory(filteredCategory[0]);
  }, [id, props.content]);

  return (
    <div>
      <div className="flex">
        <div className="hidden w-1/4 flex-row h-fit p-10 bg-neutralBg1 px-10 mx-10 sm:block ">
          <ul className="text-base font-['Mulish']  flex flex-col 2xl:text-lg">
            <h3 className="text-grey text-base  text-[18px] font-bold pb-4">
              {props?.content[0]?.category}
            </h3>
            {props?.content.map((el, index) => {
              return (
                <Link className=" mb-4" to={el.id}>
                  <div
                    className={` text-[18px]  hover:cursor-pointer hover:text-[#60A5FA]
                        ${el.id === id ? "text-[#60A5FA]" : "text-[#999999]"}`}
                  >
                    {el.text}
                  </div>
                </Link>
              );
            })}
          </ul>
        </div>
        {category && (
          <div className="w-2/5 ">
            <h1 className="neutralText5"> RETAILER APP</h1>
            <h2 className="text-3xl mb-8 font-black text-transparent bg-clip-text bg-gradient-to-br from-successBg5 to-neutralText1">
              {category?.text}
            </h2>
            <p className="text-justify text-[16px]">{category.description}</p>
            {category?.image?.map((el) => {
              return (
                <div key={el.id} className="mt-8">
                  <img
                    src={require(`../../../../assets/learnAssets/images/${el.img}`)}
                    alt=""
                  />
                  <p>{el.caption}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className="w-2/6 mx-auto">
        <Articles />
      </div>
    </div>
  );
};

export default Dashboard;
