import React from "react";
import _ from "lodash";
import { Redirect, useHistory } from "react-router-dom";

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const GetPlan = async (orderId, userDetails, currentUser, history) => {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
  // if (!res) {
  //   alert("wertyu");
  //   return;
  // }
  console.log("get....", orderId, userDetails, currentUser);

  // console.log("plan order detail", this.props.orderDetails.orderId);
  const options = {
    key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
    amount: "50000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "USD",
    name: "BUSY BEE",
    description: "Test Transaction",
    // image: Logo,
    order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    handler: function (response) {
      if (!currentUser) {
        history.push({
          pathname: "/payment-complete",
          state: { orgDetails: userDetails || currentUser },
        });
      } else {
        history.push("/plans-and-billing");
      }
    },
    prefill: {
      name: _.isEmpty(currentUser)
        ? userDetails?.businessName
        : currentUser?.businessName,
      email: _.isEmpty(currentUser) ? userDetails?.email : currentUser?.email,
      contact: _.isEmpty(currentUser)
        ? userDetails?.contact
        : currentUser?.contact,
    },
    // notes: {
    //   address: "Razorpay Corporate Office",
    // },
    theme: {
      color: "#3399cc",
    },
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
};
export default GetPlan;
