import React, { useEffect, useRef, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { FiHelpCircle, FiSliders } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PropTypes from "prop-types";
import startCase from "lodash/startCase";
import { FiEdit2, FiTrash2, FiX } from "react-icons/fi";
import useSupercluster from "use-supercluster";
import { useSelector } from "react-redux";

import CustomTitle from "../components/common/customTitle";
import CustomModal from "../components/common/customModal";
import CustomModalTitle from "../components/common/customModalTitle";
import VisitsForm from "../components/forms/visitsForm";
import CustomTable from "../components/common/table";
import StatsCard from "../components/common/statsCard";
import Filters from "../components/common/filters";
import Map from "../components/googleMap";
import BaseButton from "../components/common/baseButton";
import { Tabs, TabsList, TabPanel, Tab } from "../components/common/customTabs";
import {
  deleteVisit,
  getAssignedVisits,
  getAssignedVisitsMap,
  getVisitStats,
} from "../services/visitsApi";
import ConvertTimestamp from "../components/convertTimestamp";
import ConfirmationModal from "../components/common/confirmationModal";
import { FilterType, Theme } from "../constants/common";
import { getSalesPersons } from "../services/salespersonApi";
import { getVendorList } from "../services/vendorsApi";
import EmptyFallBack from "../components/common/emptyFallback";
import noVisits from "../assets/noVisits.svg";
import DarkMapStyle, { MapStyle } from "../theme/darkMapStyle";
import Marker from "../components/common/marker";
import EditVisitsForm from "../components/forms/visitFormEdit";
import { CustomDateRangePicker } from "../components/common/customDatePicker";

const columns = [
  { key: "date", name: "Date", minWidth: 140, width: 140 },
  { key: "visitType", name: "Visit Type", minWidth: 120, width: 120 },
  { key: "vendor", name: "Retailer", minWidth: 200 },
  { key: "salesperson", name: "Salesperson", minWidth: 200 },
  { key: "status", name: "Status", minWidth: 120, width: 120 },
  { key: "actions", name: "" },
];

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Visits = () => {
  const dataGridRef = React.useRef(null);
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [visitStats, setVisitStats] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [assignedVisits, setAssignedVisits] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableLoading, setTableLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [visitToEdit, setVisitToEdit] = useState({});

  const [selectedSalesperson, setSelectedSalesperson] = useState();
  const [searchedSalespersons, setSearchedSalespersons] = useState([]);
  const [salespersonsLoading, setSalespersonsLoading] = useState(false);

  const [selectedVendor, setSelectedVendor] = useState();
  const [searchedVendors, setSearchedVendors] = useState([]);
  const [vendorsLoading, setVendorsLoading] = useState(false);

  const [visitMapList, setVisitMapList] = useState([]);
  const [points, setPoints] = useState([]);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [openCreateVisitModal, setOpenCreateVisitModal] = useState(false);
  const [openEditVisitModal, setOpenEditVisitModal] = useState(false);

  const dateStart = new Date();
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateStart.setHours(0, 0, 0, 0);

  const dateEnd = new Date();
  dateEnd.setHours(23, 59, 59, 99);

  const [date, setDate] = useState([
    {
      startDate: dateStart,
      endDate: dateEnd,
      key: "selection",
    },
  ]);

  const mapStart = new Date();
  mapStart.setHours(0, 0, 0);

  const mapEnd = new Date();
  mapEnd.setHours(23, 59, 59, 99);

  const [mapDate, setMapDate] = useState([
    {
      startDate: mapStart,
      endDate: mapEnd,
      key: "selection",
    },
  ]);

  const theme = useSelector((state) => state.theme);
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const gMap = useRef();
  const gMaps = useRef();

  const handleMapLoad = (map, maps) => {
    gMap.current = map;
    gMaps.current = maps;
  };

  const getOptionsList = async () => {
    const spResp = await getSalesPersons({
      offset: 1,
      limit: 5,
    });
    setSearchedSalespersons(spResp.result || []);
    const vendorResp = await getVendorList({
      offset: 1,
      limit: 5,
      status: "ACTIVE",
    });
    setSearchedVendors(vendorResp.result || []);
  };

  const searchSalespersons = async (query) => {
    const resp = await getSalesPersons({
      offset: 1,
      limit: 5,
      query,
    });
    setSearchedSalespersons(resp.result || []);
    setSalespersonsLoading(false);
  };

  const searchVendors = async (query) => {
    setVendorsLoading(true);
    const resp = await getVendorList({
      offset: 1,
      limit: 5,
      query,
    });
    setSearchedVendors(resp.result || []);
    setVendorsLoading(false);
  };

  const filters = [
    {
      filterKey: "status",
      filterLabel: "Status",
      selectedFilter: activeFilters.length > 0,
      selectedKey: activeFilters.filter((el) => {
        if (el.filterKey === "status") return el.filterValue;
      }),
      filterType: FilterType.Default,
      filterIcon: <Unicons.UilCheck />,
      filterValues: [
        {
          key: "ACTIVE",
          value: "Active",
        },
        {
          key: "COMPLETED",
          value: "Completed",
        },
        {
          key: "CANCELLED",
          value: "Cancelled",
        },
        {
          key: "TERMINATED",
          value: "Terminated",
        },
      ],
    },
    {
      filterKey: "visitType",
      filterLabel: "Type",
      selectedFilter: activeFilters.length > 0,
      selectedKey: activeFilters.filter((el) => {
        if (el.filterKey === "visitType") return el.filterValue;
      }),
      filterType: FilterType.Default,
      filterIcon: <Unicons.UilCheck />,
      filterValues: [
        {
          key: "COLLECTION",
          value: "Collection",
        },
        {
          key: "Orders",
          value: "Orders",
        },
        {
          key: "NEW_ORDER",
          value: "New Order",
        },
        {
          key: "RETURN",
          value: "Return",
        },
      ],
    },
    {
      filterKey: "vendor",
      filterLabel: "Retailer",
      filterType: FilterType.Autocomplete,
      selectedFilter: selectedVendor,
      onFilterChange: setSelectedVendor,
      onFilterInputChange: searchVendors,
      filterLoading: vendorsLoading,
      optionValue: "businessName",
      filterPlaceholder: "Retailer",
      filterIcon: <Unicons.UilUsersAlt size={24} />,
      filterValues: searchedVendors,
    },
    {
      filterKey: "salesperson",
      filterLabel: "Salesperson",
      filterType: FilterType.Autocomplete,
      selectedFilter: selectedSalesperson,
      onFilterChange: setSelectedSalesperson,
      onFilterInputChange: searchSalespersons,
      filterLoading: salespersonsLoading,
      optionValue: "name",
      filterPlaceholder: "Salesperson",
      filterIcon: <Unicons.UilUser size={24} />,
      filterValues: searchedSalespersons,
    },
  ];

  const getData = async ({ refresh, page, ...filters }) => {
    setTableLoading(refresh);
    const res = await getVisitStats();
    const data = history.location.state
      ? await getAssignedVisits({
          offset: page,
          limit: rowsPerPage,
          spId: history.location.state.salesperson,
          vendorId: history.location.state.vendor,
          startDate: parseInt(date[0].startDate / 1000, 10),
          endDate: parseInt(date[0].endDate / 1000, 10),
          ...filters,
        })
      : await getAssignedVisits({
          offset: page,
          limit: rowsPerPage,
          spId: selectedSalesperson?.id,
          vendorId: selectedVendor?.id,
          startDate: parseInt(date[0].startDate / 1000, 10),
          endDate: parseInt(date[0].endDate / 1000, 10),
          ...filters,
        });
    setOffset(page - 1);
    setVisitStats(res);
    setAssignedVisits(data?.result || []);
    setCount(data?.count);
    setTableLoading(false);
  };

  const applyFilters = ({ refresh = true, page }) => {
    const filterBuilder = {};

    if (activeFilters.length > 0) {
      for (const item of activeFilters) {
        filterBuilder[item.filterKey] = item.filterValue;
      }
    }
    getData({ refresh, page, ...filterBuilder });
  };

  const getMapData = async () => {
    const mapData = await getAssignedVisitsMap({
      startDate: parseInt(mapDate[0].startDate / 1000, 10),
      endDate: parseInt(mapDate[0].endDate / 1000, 10),
    });
    setVisitMapList(mapData || []);

    setPoints(
      mapData.map((el) => ({
        type: "Feature",
        properties: {
          cluster: false,
          id: el.vendor.id,
          category: "Some",
        },
        geometry: el?.vendor?.location,
        vendor: el?.vendor,
      }))
    );
  };

  useEffect(() => {
    console.log("history", history);
    if (history.location.state?.openModal) {
      setTimeout(() => {
        setOpenCreateVisitModal(true);
      }, 500);
    }
    if (!history.location.state) {
      history.replace("/visits");
    }
    getOptionsList();
  }, []);

  useEffect(() => {
    getMapData();
  }, [mapDate]);

  useEffect(() => {
    applyFilters({ page: 1 });
  }, [activeFilters, rowsPerPage, selectedSalesperson, selectedVendor, date]);

  const clearFilters = () => {
    setSelectedSalesperson(null);
    setSelectedVendor(null);
    setSearchedSalespersons([]);
    setSearchedVendors([]);
    setActiveFilters([]);
    setDate([
      {
        startDate: dateStart,
        endDate: dateEnd,
        key: "selection",
      },
    ]);
  };

  const tooltipText = {
    VistsToday: "Visits created today.",
    CompletedVisits: "Total visits marked complete.",
    ActiveSalespersons: "Active salespersons that can be assigned tasks.",
    InactiveSalespersons:
      "Inactive salespersons that can not be assigned tasks.",
  };

  useEffect(() => {
    const temp = assignedVisits?.map((el) => {
      return {
        ...el,
        date: (
          <ConvertTimestamp
            timestamp={el.date}
            className="font-normal text-neutralText3 truncate"
          />
        ),
        visitType: (
          <p
            className={`px-2 py-0.5 border-l-4 ${
              el?.visitType === "NEW_ORDER"
                ? "bg-infoBg2 text-infoText4 border-infoText5"
                : el?.visitType === "COLLECTION"
                ? "bg-successBg2 text-successText5 border-successText5"
                : el?.visitType === "RETURN"
                ? "bg-errorBg2 text-errorText5 border-errorText5"
                : el?.visitType === "Orders"
                ? "bg-warningBg2 text-warningText5 border-warningText5"
                : ""
            }`}
          >
            {startCase(el.visitType?.toLowerCase())}
          </p>
        ),
        status: (
          <p
            className={`px-2 py-0.5 rounded-full ${
              el?.status === "ACTIVE"
                ? "bg-infoBg2 text-infoText3"
                : el?.status === "COMPLETED"
                ? "bg-successBg2 text-successText3"
                : el?.status === "CANCELLED"
                ? "bg-errorBg2 text-errorText3"
                : el?.status === "TERMINATED"
                ? "bg-errorBg2 text-errorText3"
                : ""
            }`}
          >
            {startCase(el?.status?.toLowerCase())}
          </p>
        ),
        vendor: (
          <div className="font-normal text-infoText4 truncate">
            <Link to={`/view-vendor/${el.vendor.id}`}>
              {el.vendor.businessName}
            </Link>
          </div>
        ),
        salesperson: (
          <div className="font-normal text-infoText4 truncate">
            <Link to={`/view-salesperson/${el.salesPerson.id}`}>
              {el?.salesPerson?.name}
            </Link>
          </div>
        ),
        actions:
          el.status === "COMPLETED" || el.status === "CANCELLED" ? (
            <div className="flex items-center text-neutralText4 ">
              <div className="flex items-bottom pt-[5px] ">
                <FiEdit2 className="h-5 w-5" />
                <div className="px-1 pt-[2px]">Edit</div>
              </div>
              <div className="text-neutralText5 px-2">
                <FiTrash2 size={18} />
              </div>
            </div>
          ) : (
            <div
              className="flex items-center
          "
            >
              <div
                className="flex items-bottom text-neutralText1 pt-[5px] cursor-pointer"
                onClick={() => {
                  setVisitToEdit(el);
                  setOpenEditVisitModal(true);
                }}
              >
                <FiEdit2 className="h-5 w-5" />
                <div className="px-1 pt-[2px]">Edit</div>
              </div>
              {el.visitType === "Orders" ? (
                <div className="text-neutralText5 px-2">
                  <FiTrash2 size={18} />
                </div>
              ) : (
                <div className="flex items-center text-errorText5 px-2">
                  <ConfirmationModal
                    title="Are your sure?"
                    subtitle="Are you sure you want to delete this visit."
                    positiveLabel="Delete"
                    negativeLabel="Cancel"
                    onPositiveClick={async () => {
                      await deleteVisit(el.id);
                      applyFilters({ refresh: false, page: offset + 1 });
                    }}
                    button={(onClick) => (
                      <FiTrash2
                        size={18}
                        status={el.status}
                        className="cursor-pointer"
                        onClick={onClick}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          ),
      };
    });
    setModifiedData(temp);
  }, [assignedVisits]);

  console.log("history", history);

  return (
    <>
      {/* <TopBar value="Product" /> */}
      <CustomModal
        title={
          <CustomModalTitle
            title="Edit Visit"
            description="Edit product information and package unit"
          />
        }
        openModal={openEditVisitModal}
        setOpenModal={setOpenEditVisitModal}
      >
        <EditVisitsForm
          visit={visitToEdit}
          handleCloseModal={() => {
            applyFilters({ refresh: false, page: offset + 1 });
            setOpenEditVisitModal(false);
          }}
        />
      </CustomModal>
      <div className="flex flex-row">
        {/* <SideNav /> */}
        {loader ? (
          <div className="m-auto h-screen flex items-center">
            {/* <CircularProgress color="white" size={60} /> */}
          </div>
        ) : (
          <div className="w-full pb-12 grid grid-cols-4 px-6">
            <div className="col-span-4">
              <div className="flex justify-between items-center pt-12">
                <CustomTitle
                  title="Visits"
                  description="Assign and Manage Visits"
                />
                <CustomModal
                  title={
                    <CustomModalTitle
                      title="Create new Visit"
                      description="Enter product information and package unit "
                    />
                  }
                  button={<BaseButton text="Create Visit" />}
                  openModal={openCreateVisitModal}
                  setOpenModal={setOpenCreateVisitModal}
                >
                  <VisitsForm
                    handleCloseModal={() => {
                      setOpenCreateVisitModal(false);
                      applyFilters({ refresh: false, page: offset + 1 });
                    }}
                  />
                </CustomModal>
              </div>
              <div className="grid grid-cols-4 gap-x-4">
                <StatsCard
                  text="Visits Today"
                  icon={<FiHelpCircle />}
                  tooltip={tooltipText.VistsToday}
                >
                  {visitStats?.visitsToday.length || 0}
                </StatsCard>
                <StatsCard
                  text="Completed Visits"
                  icon={<FiHelpCircle />}
                  tooltip={tooltipText.CompletedVisits}
                >
                  {visitStats?.completedVisits.length || 0}
                </StatsCard>
                <StatsCard
                  text="Active Salespersons"
                  icon={<FiHelpCircle />}
                  tooltip={tooltipText.ActiveSalespersons}
                >
                  {visitStats?.activeSalesPersons || 0}
                </StatsCard>
                <StatsCard
                  text="Inactive Salespersons"
                  icon={<FiHelpCircle />}
                  tooltip={tooltipText.InactiveSalespersons}
                >
                  {visitStats?.inActiveSalesPersons || 0}
                </StatsCard>
              </div>
              <Tabs
                onChange={(e, newValue) => setTabValue(newValue)}
                className="rounded-lg mt-6"
                defaultValue={0}
              >
                <TabsList style={{ marginBottom: -1 }}>
                  <Tab>
                    <div className="flex items-center m-auto justify-center">
                      <Unicons.UilListUl size="20" />
                      <div className="px-2">List View </div>
                    </div>
                  </Tab>
                  <Tab>
                    <div className="flex items-center m-auto justify-center">
                      <Unicons.UilMap size="20" />
                      <div className="px-2">Map View </div>
                    </div>
                  </Tab>
                </TabsList>
                <TabPanel className="  rounded-t-xl	rounded-b-xl" value={0}>
                  <div className="flex flex-row justify-between items-center bg-neutralBg1 items-center pt-8 border-x border-x-neutralBg4 px-6">
                    <div className="text-base text-neutralText1 font-medium">
                      List of Visits
                      {/* <div className="text-neutralText3 text-sm">
                          for the month of{" "}
                          <span className="text-infoText4">february</span>
                        </div> */}
                    </div>
                    <div className="flex items-center">
                      <div className=" mr-2">
                        {filters
                          .map((el) => !!el.selectedFilter)
                          .includes(true) ||
                        date[0].startDate / 1000 !== dateStart / 1000 ||
                        date[0].endDate / 1000 !== dateEnd / 1000 ? (
                          <button onClick={() => clearFilters()}>
                            <div className="flex items-center justify-center text-infoText4 text-xs">
                              <FiX size={14} />
                              Clear
                            </div>
                          </button>
                        ) : (
                          <FiSliders className=" text-neutralText1" size={18} />
                        )}
                      </div>
                      <CustomDateRangePicker
                        date={date}
                        setDate={setDate}
                        changed={
                          date[0].startDate / 1000 !== dateStart / 1000 ||
                          date[0].endDate / 1000 !== dateEnd / 1000
                        }
                      />
                      <Filters
                        noLabel
                        setFilters={setActiveFilters}
                        activeFilters={activeFilters}
                        filters={filters}
                      />
                    </div>
                  </div>
                  <div
                    className="py-4 sm:px-6 bg-neutralBg1 border border-neutralBg4 border-t-0 rounded-b"
                    onClick={() => {
                      // Fix for `cellFocus (select) breaks input functionality`
                      dataGridRef.current.selectCell(
                        {
                          idx: 0,
                          rowIdx: -1,
                          mode: "SELECT",
                        },
                        true
                      );
                    }}
                  >
                    <CustomTable
                      dataGridRef={dataGridRef}
                      columns={columns}
                      rows={modifiedData}
                      emptyFallBack={
                        <EmptyFallBack
                          image={noVisits}
                          text="No Visits Found"
                          containerClass={"mt-14 mb-4"}
                          imageClass={"h-44"}
                        />
                      }
                      count={count}
                      page={offset}
                      onPageChange={(_, page) => getData({ page: page + 1 })}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={(e) =>
                        setRowsPerPage(e.target.value)
                      }
                      loading={tableLoading}
                    />
                  </div>
                </TabPanel>
                <TabPanel className=" rounded-t-xl	rounded-b-xl" value={1}>
                  <div className="flex bg-neutralBg1 border border-neutralBg4 border-t-0 rounded h-screen relative">
                    {/* <div className="flex flex-row justify-between items-center">
                      <div className="text-base text-neutralText1 font-medium">
                        List of Visits
                      </div>
                    </div> */}
                    <div className="absolute flex items-center top-6 right-6 z-10">
                      <div className=" mr-2">
                        {date[0].startDate / 1000 !== dateStart / 1000 ||
                        date[0].endDate / 1000 !== dateEnd / 1000 ? (
                          <button onClick={() => clearFilters()}>
                            <div className="flex items-center justify-center text-neutralText1 text-xs">
                              <FiX size={14} />
                              Clear
                            </div>
                          </button>
                        ) : (
                          <FiSliders className=" text-neutralText1" size={18} />
                        )}
                      </div>
                      <CustomDateRangePicker
                        date={date}
                        setDate={setDate}
                        changed={
                          date[0].startDate / 1000 !== dateStart / 1000 ||
                          date[0].endDate / 1000 !== dateEnd / 1000
                        }
                      />
                    </div>

                    <Map
                      yesIWantToUseGoogleMapApiInternals //this is important!
                      onGoogleApiLoaded={({ map, maps }) =>
                        handleMapLoad(map, maps)
                      }
                      onChange={({ zoom, bounds }) => {
                        setZoom(zoom);
                        setBounds([
                          bounds.nw.lng,
                          bounds.se.lat,
                          bounds.se.lng,
                          bounds.nw.lat,
                        ]);
                      }}
                      options={{
                        styles: theme === Theme.Dark ? DarkMapStyle : MapStyle,

                        fullscreenControl: false,
                      }}
                    >
                      {clusters.map((cluster) => {
                        const [longitude, latitude] =
                          cluster.geometry.coordinates;
                        const { cluster: isCluster, point_count: pointCount } =
                          cluster.properties;

                        const { vendor } = cluster;

                        if (isCluster) {
                          return (
                            <div
                              key={`cluster-${cluster.id}`}
                              lat={latitude}
                              lng={longitude}
                              onClick={() => {
                                const expansionZoom = Math.min(
                                  supercluster.getClusterExpansionZoom(
                                    cluster.id
                                  ),
                                  20
                                );
                                gMap.current.setZoom(expansionZoom);
                                gMap.current.panTo({
                                  lat: latitude,
                                  lng: longitude,
                                });
                              }}
                            >
                              <div
                                className="cluster-marker"
                                style={{
                                  width: `${
                                    10 + (pointCount / points.length) * 20
                                  }px`,
                                  height: `${
                                    10 + (pointCount / points.length) * 20
                                  }px`,
                                }}
                                onClick={() => {}}
                              >
                                {pointCount}
                              </div>
                            </div>
                          );
                        }

                        return (
                          <Marker
                            key={`vender-${cluster.properties.id}`}
                            gMap={gMap}
                            lat={latitude}
                            lng={longitude}
                            data={{
                              id: vendor.id,
                              name: vendor.businessName,
                              address: vendor.locationName,
                            }}
                          />
                        );
                      })}
                    </Map>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Visits;
