// import { makeStyles } from "@mui/styles";
import * as Unicons from "@iconscout/react-unicons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import CustomModalTitle from "./common/customModalTitle";
import OuterDiv from "./common/outerDiv";
import CustomTable from "./common/table";
import { useEffect, useState } from "react";
import { getOrders } from "../services/ordersApi";
import ConvertTimestamp from "./convertTimestamp";
import EmptyFallBack from "./common/emptyFallback";
import noOrders from "../assets/noOrders.svg";
import { useSelector } from "react-redux";

const columns = [
  { key: "name", name: "Retailer", minWidth: 100 },
  { key: "date", name: "Order date", minWidth: 100 },
  { key: "createdBy", name: "Created By", minWidth: 100 },
  // { key: "chevron", name: "", minWidth: 50, width: 50 },
];

const DashboardOrders = ({ date }) => {
  const currentUser = useSelector((s) => s.login);

  const [orders, setOrders] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modifiedData, setModifiedData] = useState([]);

  const getData = async () => {
    setTableLoading(true);
    const data = await getOrders({
      limit: 5,
      startDate: parseInt(date.startDate / 1000, 10),
      endDate: parseInt(date.endDate / 1000, 10),
    });
    setOrders(data.result || []);
    setTableLoading(false);
  };

  useEffect(() => {
    getData();
  }, [date]);

  useEffect(() => {
    const temp = orders?.map((el) => {
      return {
        ...el,
        name: (
          <div className="text-infoText4 font-normal truncate ">
            {el?.vendor?.businessName}
          </div>
        ),
        createdBy:
          currentUser.id === el?.addedById?.id ? (
            <div className="text-neutralText3 font-normal ">You</div>
          ) : (
            <Link
              to={
                el?.addedById?.businessName
                  ? `/view-vendor/${el?.addedById?.id}`
                  : `/view-salesperson/${el?.addedById?.id}`
              }
            >
              <div className="text-infoText4 font-normal truncate">
                {el?.addedById?.businessName || el?.addedById?.name}
              </div>
            </Link>
          ),
        date: (
          <ConvertTimestamp
            timestamp={el?.createdAt}
            className="text-neutralText1 font-medium"
          />
        ),
        // chevron: (
        //   <Unicons.UilAngleRightB className="text-neutralText1" size="20px" />
        // ),
      };
    });
    setModifiedData(temp);
  }, [orders]);

  return (
    <OuterDiv>
      <div className="p-6 h-[444px]">
        <div className="flex justify-between text-neutralText1">
          <CustomModalTitle
            title="Orders"
            description={
              <>
                {/* Showing Data for the month of{" "}
                <span className="text-infoText4">february</span> */}
              </>
            }
          />
          <Link to="/orders" target="_blank">
            <Unicons.UilExternalLinkAlt size={22} />
          </Link>
        </div>
        <div className="pt-4">
          <CustomTable
            columns={columns}
            rows={modifiedData}
            emptyFallBack={
              <EmptyFallBack
                image={noOrders}
                text="Sorry cound’t find any orders"
                containerClass={"mt-14"}
              />
            }
            bodyRowProps={{
              path: "/view-order",
            }}
            loading={tableLoading}
          />
        </div>
      </div>
    </OuterDiv>
  );
};
export default DashboardOrders;
