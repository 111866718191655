import { APIClient } from "./apiClient";

export const getActivity = async ({
  offset = 1,
  limit = "",
  startDate,
  endDate,
  spId,
  sortField = "createdAt",
  sortOrder = "DESC",
}) => {
  try {
    const response = await APIClient.get("/org/activities", {
      params: {
        offset: offset,
        limit: limit,
        startDate: startDate,
        endDate: endDate,
        spId: spId,
        sortField: sortField,
        sortOrder: sortOrder,
      },
    });

    return response.data;
  } catch (err) {
    console.log("error getting activities", err);
  }
};

export const getNotifications = async ({
  offset = 1,
  limit = 10,
  status = "",
  sortField = "createdAt",
  sortOrder = "DESC",
}) => {
  try {
    const response = await APIClient.get("/org/notifications", {
      params: {
        offset: offset,
        limit: limit,
        status: status,
        sortField,
        sortOrder,
      },
    });
    console.log("activity response ", response);
    return response.data;
  } catch (err) {
    console.log("error getting activities", err);
  }
};

export const markAsRead = async ({ id }) => {
  try {
    console.log("Activities", id);
    const response = await APIClient.put("/org/activities", {
      id: id,
    });
    console.log("activity response ", response);
    return response.data;
  } catch (err) {
    console.log("error getting activities", err);
  }
};
