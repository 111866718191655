import React, { useState } from "react";

import BulkImport from "../../components/bulkImport";
import { BulkImportType } from "../../constants/common";

const steps = ["Select File", "Match Fields", "Add Products"];
const links = [
  { title: "Home", url: "/" },
  { title: "Products", url: "/products" },
  { title: "Bulk Import", url: "/bulk-import" },
];

const productsFields = ["name", "unit"];

const BulkImportProducts = () => {
  return (
    <BulkImport
      steps={steps}
      bulkImportType={BulkImportType.Product}
      links={links}
      busybeeFields={productsFields}
    />
  );
};

export default BulkImportProducts;
