import { GET_SETTINGS } from "../actions/actionTypes";

//get setting
export const getSettings = (response = null, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return action.payload;

    default:
      return response;
  }
};
