import {
  REGISTER,
  LOGIN,
  LOGOUT,
  SUBSCRIBE,
  GET_PLANS,
  GET_SUBSCRIPTION_PLANS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "./actionTypes";
import { toast } from "react-toastify";
import axios from "axios";

import {
  RAZOR_PAY_SUBSCRIPTION_URL,
  RAZOR_PAY_KEY_ID,
  RAZOR_PAY_KEY_SECRET,
} from "../../constants/config";
import { APIClient } from "../../services/apiClient";

//for checking user exisitance
export const checkUserExistance = async (email) => {
  try {
    const response = await APIClient.post("/org/check", {
      email: email,
    });
    console.log("userExists", response);
    // dispatch({
    //   type: CHECK_USER_EXISTANCE,
    //   payload: response.data,
    // });
    return response.data;
  } catch (err) {
    console.log("error in  checking user exisitance...", err);

    return err.response.data;
  }
};

//for registering a user
export const register = (userInfo) => async (dispatch) => {
  console.log("sss", userInfo);
  try {
    const response = await APIClient.post("/org/register", userInfo);
    dispatch({
      type: REGISTER,
      payload: response.data,
    });
    return response;
  } catch (err) {
    console.log("error in registration...", err.response.data);
  }
};

//for login
export const login = (loginInfo) => async (dispatch) => {
  try {
    const response = await APIClient.post("/org/login", loginInfo);
    dispatch({
      type: LOGIN,
      payload: response.data,
    });
    return response;
  } catch (err) {
    console.log("error login", err);
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT,
    });
  } catch (err) {
    console.log("error logout", err);
  }
};
//get all razorpay plan
export const getPlans = () => async (dispatch) => {
  try {
    // const url = `${serverURL}/getPlans`;
    // axios({
    //   method: "get",
    //   url,
    //   auth: {
    //     username: razorPayKeyId,
    //     password: razorPayKeySecret,
    //   },
    // })
    //   .then(function (response) {
    //     console.log("data aav", response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

    // TODO - check with backend - [razorPayKeySecret is sensitive info]

    const res = await APIClient.get(
      "/getPlans",
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
      {
        auth: {
          username: RAZOR_PAY_KEY_ID,
          password: RAZOR_PAY_KEY_SECRET,
        },
      }
    );

    console.log("aav", res);

    dispatch({
      type: GET_PLANS,
      payload: res,
    });
  } catch (err) {
    console.log("get plan error", err);
  }
};
export const buyPlan = async (
  planId,
  orgId,
  currency,
  subscriptionType,
  coupon
) => {
  console.log("buyPlan", planId, orgId, currency, subscriptionType, coupon);
  try {
    const response = await APIClient.post("/org/order", {
      planId: planId,
      orgId: orgId,
      currency: currency,
      subscriptionType: subscriptionType,
      couponCode: coupon,
    });
    console.log("response", response);
    // dispatch({
    //   type: BUY_PLAN,
    //   payload: response,
    // });
    return response.data;
  } catch (err) {
    console.log("error here...", err);
  }
};

//for subscribing a plan
export const subscribe = (planId) => async (dispatch) => {
  try {
    let body = {
      plan_id: "plan_CmJsSrkJMGI8wy",
      customer_notify: 1,
      total_count: 6,
      start_at: 1561615372,
    };

    const res = await axios.post(
      RAZOR_PAY_SUBSCRIPTION_URL,
      body,
      {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
      {
        auth: {
          username: RAZOR_PAY_KEY_ID,
          password: RAZOR_PAY_KEY_SECRET,
        },
      }
    );
    console.log("subsss", res);
    dispatch({
      type: SUBSCRIBE,
      payload: res,
    });
  } catch (err) {
    console.log("error here...", err);
  }
};

export const getSubscriptionPlans = (limit, currency) => async (dispatch) => {
  // console.log("Calling subs", currency);
  console.log("limit", limit);
  try {
    const data = await APIClient.get("/org/subscription/plans", {
      params: { currency: currency, limit: limit },
    });
    console.log("aav", data.data);
    let payload = data.data;

    dispatch({
      type: GET_SUBSCRIPTION_PLANS,
      payload: payload,
    });
  } catch (err) {
    console.log("Calling subs", currency);
    console.log("subplans", err);
  }
};
export const forgotPassword = (emailId) => async (dispatch) => {
  console.log("forgot before", emailId);
  try {
    const response = await APIClient.post(
      "/org/sendresetpasswordlink",
      emailId
    );
    dispatch({
      type: FORGOT_PASSWORD,
      payload: response.data,
    });
    console.log("response token", response);
    return response;
  } catch (err) {
    console.log("error in forgot...", err);
  }
};
export const resetPassword = (token, newPassword) => async (dispatch) => {
  console.log("reset before", token, newPassword);
  try {
    const response = await APIClient.post("/org/recoveraccount", {
      password: newPassword,
    });
    dispatch({
      type: RESET_PASSWORD,
      payload: response.data,
    });
    console.log("response token", response);
    return response;
  } catch (err) {
    console.log("error in forgot...", err);
  }
};

export const checkCoupon = async (coupon, id) => {
  console.log("couponId", coupon, id);
  try {
    const response = await APIClient.post("/org/coupon-check", {
      couponCode: coupon,
      subscriptionId: id,
    });
    // dispatch({
    //   type: CHECK_COUPON,
    //   payload: response,
    // });
    console.log("response ", response);
    return response;
  } catch (err) {
    console.log("error ....", err);
    if (err.response) {
      // dispatch({
      //   type: CHECK_COUPON,
      //   payload: err.response,
      // });
      return err.response;
    }
  }
};
