import { GET_SALES_AND_REVENUE, GET_SALESPERSON_PERFORMANCE  } from "../actions/actionTypes";

//for getting sales and revenue
export const salesAndRevenue = (response = null, action) => {
  switch(action.type){
    case GET_SALES_AND_REVENUE:
      return action.payload;
    default:
      return response;
  }
}

// for getting salesperson performance
export const salesPersonPerformance = (response = null, action) => {
  switch(action.type){
    case GET_SALESPERSON_PERFORMANCE:
      return action.payload;
    default:
      return response;
  }
}