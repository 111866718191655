import React from "react";
import { FiX } from "react-icons/fi";

function CustomModalTitle({ title, description, onCrossPress }) {
  return (
    <div className="flex justify-between items-center">
      <div>
        <div className="text-neutralText1 font-semibold">{title}</div>
        <div className="text-xs text-neutralText3"> {description}</div>
      </div>
      {onCrossPress && (
        <FiX size={20} onClick={onCrossPress} className="cursor-pointer" />
      )}
    </div>
  );
}

export default CustomModalTitle;
