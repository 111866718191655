import React from "react";
import Breadcrumb from "./Breadcrumb";
import Search from "./Search";
const Navbar = function (props) {
  return (
    <div className="px-14 py-4 flex flex-row w-full justify-between sm:px-5 lg:pl-4 xl:pl-10 2xl:pl-14 mb-4">
      <Breadcrumb data={props.category} />
      <Search content={props.content} />
    </div>
  );
};
export default Navbar;
