import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import { FiArrowLeft, FiEdit2, FiHelpCircle } from "react-icons/fi";
import { toast } from "react-toastify";
import millify from "millify";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import AssignedVendorList from "./assignedVendorList";
import AssignedVisits from "./assignedVisits";
import Avatar from "../../components/common/avatar";
import { TransactionsBySalesPerson } from "./transactions";
import { getSalespersonById } from "../../services/salespersonApi";
import FullscreenLoader from "../../components/common/fullscreenLoader";
import CustomBreadcrumbs from "../../components/common/customBreadcrumbs";
import StatusPill from "../../components/common/statusPill";
import ConfirmationModal from "../../components/common/confirmationModal";
import BaseButton from "../../components/common/baseButton";
import CustomLinearProgress from "../../components/common/customLinearprogress";
import {
  Tabs,
  TabsList,
  TabPanel,
  Tab,
} from "../../components/common/customTabs";
import CustomInput from "../../components/common/customInput";
import CustomModal from "../../components/common/customModal";
import CustomModalTitle from "../../components/common/customModalTitle";
import SalesPersonForm from "../../components/forms/salesPersonForm";
import { SPStatus } from "../../constants/common";
import { updateSalesperson } from "../../services/salespersonApi";
import { CustomMonthYearPicker } from "../../components/common/customDatePicker";
import AddOrEditTarget from "../../components/forms/addOrEditTarget";

const monthDateRange = (date = new Date()) => {
  const start = new Date(date);
  start.setMonth(start.getMonth() - 1);
  start.setHours(0, 0, 0, 0);

  const end = new Date(date);
  end.setHours(23, 59, 59, 99);
  return { start, end };
};

const ViewSalesPerson = () => {
  const targetRef = React.useRef(null);
  const [targetdate, _] = useState(new Date());
  const { id } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState(new Date());
  const [salesperson, setSalesperson] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openTargetModal, setOpenTargetModal] = useState(false);
  const [target, setTarget] = useState("");

  const getData = async (date, refresh = true) => {
    setLoading(refresh);
    const response = await getSalespersonById(id, parseInt(date / 1000, 10));
    setLoading(false);
    // setPerformanceChart(data);
    setSalesperson({ ...response?.data.salesPerson, ...response?.data });
  };

  const updateSalespersonDetails = async (details) => {
    const resp = await updateSalesperson(details);
    getData(dateFilter, false);

    if (resp) {
      toast.success("Salesperson status updated successfully");
    } else {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getData(dateFilter, false);
  }, [dateFilter]);

  return (
    <>
      <div className="flex flex-row">
        {loading ? (
          <FullscreenLoader className="!text-neutralText3" />
        ) : (
          <div className="w-full pb-12 grid grid-cols-4 px-6">
            {/* <CustomModal
              title={
                <CustomModalTitle
                  title="Opening Balance"
                  description="Enter amount to set opening balance"
                />
              }
              boxStyle={{ p: 0, width: 650 }}
              topContainerStyle={"px-[32px] pt-[32px]"}
              bottomContainerStyle={
                "px-[32px] pb-[32px] pt-[25px] bg-neutralBg2 rounded-b mt-2"
              }
              openModal={openTargetModal}
              setOpenModal={setOpenTargetModal}
            >
              <AddOrEditTarget
                id={salesperson?.id}
                target={salesperson?.targetsAchieved?.target}
                handleClose={() => {
                  getData(dateFilter, false);
                  setOpenTargetModal(false);
                }}
              />
            </CustomModal> */}
            <div className="col-span-4">
              <CustomBreadcrumbs
                links={[
                  { title: "Home", url: "/" },
                  { title: "Salespersons", url: "/salespersons" },
                  { title: "View", url: `/view-salesperson/${id}` },
                ]}
              />
              <div className="flex flex-row justify-between ">
                <div className="flex flex-row items-center">
                  <FiArrowLeft
                    className="text-neutralText1 stroke-3 cursor-pointer mr-2"
                    // size={12}
                    onClick={() => history.goBack()}
                  />
                  <span className="text-neutralText1 font-black text-lg">
                    View Salesperson
                  </span>
                </div>
                <div className="flex">
                  <CustomMonthYearPicker
                    date={dateFilter}
                    onChange={setDateFilter}
                  />
                </div>
              </div>

              <div className="grid grid-cols-4 gap-2 mt-4">
                <div className="bg-neutralBg1 rounded border-[0.5px] border-neutralBg4 py-6 px-4">
                  <div className="flex flex-row justify-between">
                    <Avatar
                      image={salesperson?.profileImage}
                      imageClassName="w-14 h-14"
                    />
                    <div>
                      <CustomModal
                        title={
                          <CustomModalTitle
                            title="Edit Salesperson"
                            description="Enter salesperson details"
                          />
                        }
                        boxStyle={{ p: 0, width: 550 }}
                        topContainerStyle={"px-[32px] pt-[32px]"}
                        bottomContainerStyle={
                          "px-[32px] pb-[32px] bg-neutralBg3 rounded-b mt-2"
                        }
                        button={
                          <BaseButton
                            outline
                            text="Edit"
                            type="border-infoText5 hover:bg-infoText5 hover:text-white rounded-full !px-3 !py-0.5 text-[10px]"
                            textColor="infoText5"
                            height=" "
                          />
                        }
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                      >
                        <SalesPersonForm
                          edit
                          initialValues={salesperson}
                          handleCloseModal={() => {
                            setOpenModal(false);
                            getData(dateFilter, false);
                          }}
                        />
                      </CustomModal>
                    </div>
                  </div>
                  <p className="font-light text-[10px] text-infoText4 mt-1 ">
                    {salesperson?.userName}
                  </p>
                  <p className="text-md text-neutralText1">
                    {salesperson?.name}
                  </p>
                  <p className="text-xs text-neutralText5 mt-1 mb-2">
                    Phone: {salesperson?.contact || "-"}
                  </p>
                  <p className="text-xs text-neutralText5 mb-2">
                    Email: {salesperson?.email}
                  </p>
                  <p className="text-xs text-neutralText5 mb-3">
                    ID: {salesperson?.salespersonId}
                  </p>
                  <ConfirmationModal
                    title="Are your sure?"
                    subtitle={`Are you sure you want to ${
                      salesperson?.status === SPStatus.ACTIVE
                        ? "deactivate"
                        : "activate"
                    } the salesperson. ${
                      salesperson?.status === SPStatus.ACTIVE
                        ? "He/She will not be able to login to app"
                        : ""
                    }`}
                    positiveLabel={`Yes ${
                      salesperson?.status === SPStatus.ACTIVE
                        ? "Deactivate"
                        : "Activate"
                    }`}
                    positiveType={
                      salesperson?.status === SPStatus.INACTIVE
                        ? "bg-successText4 hover:bg-successText5"
                        : null
                    }
                    negativeLabel="Cancel"
                    onPositiveClick={() => {
                      updateSalespersonDetails({
                        id: salesperson?.id,
                        status:
                          salesperson?.status === SPStatus.INACTIVE
                            ? SPStatus.ACTIVE
                            : SPStatus.INACTIVE,
                      });
                    }}
                    button={(onClick) => (
                      <StatusPill
                        status={salesperson?.status}
                        className="text-xs"
                        onClick={onClick}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col justify-between bg-neutralBg1 rounded border-[0.5px] border-neutralBg4 pt-6 ">
                  <div className="px-4">
                    <p className="text-md text-neutralText1 mt-1">Target</p>
                    {/* <p className="text-[10px] text-neutralText5 mt-0.5">
                    For the month of{" "}
                    <span className="text-infoText5">February</span>
                  </p> */}
                  </div>

                  <div className="px-4">
                    <p className="flex flex-row items-center text-neutralText4 font-light text-xs">
                      Sale <FiHelpCircle className="stroke-3 ml-1" />
                    </p>
                    <p className="text-successText5 font-black text-xl">
                      {millify(salesperson?.targetsAchieved?.achieved || 0, {
                        precision: 2,
                        decimalSeparator: ".",
                      })}{" "}
                      /{" "}
                      <span className="text-errorText5 font-normal text-sm">
                        {millify(salesperson?.targetsAchieved?.target || 0, {
                          precision: 2,
                          decimalSeparator: ".",
                        })}
                      </span>
                    </p>
                    {salesperson?.targets?.length === 0 ? (
                      <>
                        {/* <button
                              className="bg-transparent border border-infoText5 rounded px-2 py-1 text-sm text-infoText4 hover:bg-infoText5 hover:text-neutralBg1"
                              onClick={(e) => {
                               setOpenTargetModal(true)
                              }}
                            >
                              <div className="flex items-center">
                                <Unicons.UilPlus size={16} />
                                <div>Add Target</div>
                              </div>
                            </button> */}
                        <ConfirmationModal
                          title="Add Target for Salesperson"
                          subtitle={`Enter target for the month of ${dayjs(
                            targetdate
                          ).format("MMMM")}`}
                          positiveLabel="Set Target "
                          negativeLabel="Cancel"
                          onPositiveClick={async () => {
                            if (+targetRef.current.value === 0) {
                              toast.error("Target cannot be 0");
                              return;
                            }
                            await updateSalespersonDetails({
                              id: salesperson?.id,
                              target: {
                                date: parseInt(targetdate / 1000, 10),
                                target: Number(targetRef.current.value),
                              },
                            });
                            setOpenTargetModal(false);
                            setTimeout(() => {
                              // applyFilters({ refresh: false, page: offset + 1 });
                              getData(dateFilter, false);
                            }, 1000);
                            targetRef.current.value = "";
                          }}
                          positiveType="bg-primary text-black hover:bg-primaryLight"
                          boxStyle={{ p: 0 }}
                          topContainerStyle={"px-[32px] pt-[32px]"}
                          bottomContainerStyle={
                            "px-[32px] pb-[32px] bg-neutralBg3 rounded-b mt-2"
                          }
                          button={(onClick) => (
                            <button
                              className="bg-transparent border border-infoText5 rounded px-2 py-1 text-sm text-infoText4 hover:bg-infoText5 hover:text-neutralBg1"
                              onClick={(e) => {
                                e.preventDefault();
                                onClick();
                              }}
                            >
                              <div className="flex items-center">
                                <Unicons.UilPlus size={16} />
                                <div>Add Target </div>
                              </div>
                            </button>
                          )}
                          midContent={
                            <CustomInput
                              inputRef={targetRef}
                              containerClasses="py-2"
                              label="Enter target"
                              name="target"
                              placeholder="e.g., 10000"
                              type="number"
                              allowNumOnly
                            />
                          }
                        />
                      </>
                    ) : (
                      <ConfirmationModal
                        title="Edit Target for Salesperson"
                        subtitle={`Enter target for the month of ${dayjs(
                          targetdate
                        ).format("MMMM")}`}
                        positiveLabel="Change Target"
                        negativeLabel="Cancel"
                        onPositiveClick={async () => {
                          await updateSalespersonDetails({
                            id: salesperson?.id,
                            target: {
                              date: parseInt(targetdate / 1000, 10),
                              target: Number(targetRef.current.value),
                            },
                          });
                          setTimeout(() => {
                            getData(dateFilter, false);
                          }, 1000);

                          targetRef.current.value = "";
                        }}
                        positiveType="bg-primary text-black hover:bg-primaryLight"
                        boxStyle={{ p: 0 }}
                        topContainerStyle={"px-[32px] pt-[32px]"}
                        bottomContainerStyle={
                          "px-[32px] pb-[32px] bg-neutralBg3 rounded-b mt-2"
                        }
                        button={(onClick) => (
                          <span
                            className="flex flex-row items-center text-infoText5 hover:text-infoText4 text-xs cursor-pointer "
                            onClick={() => onClick()}
                          >
                            <FiEdit2 className="stroke-3 mr-1" /> Change
                          </span>
                        )}
                        midContent={
                          <CustomInput
                            inputRef={targetRef}
                            containerClasses="py-2"
                            label="Enter target"
                            name="target"
                            placeholder="e.g., 1000"
                            type="number"
                            allowNumOnly
                          />
                        }
                      />
                    )}
                  </div>
                  <div>
                    <CustomLinearProgress
                      value={
                        salesperson?.targetsAchieved?.achieved >
                        salesperson?.targetsAchieved?.target
                          ? 100
                          : (salesperson?.targetsAchieved?.achieved /
                              salesperson?.targetsAchieved?.target) *
                            100
                      }
                      hideValue={true}
                      classes={{ root: "!rounded-none" }}
                    />
                  </div>
                </div>
                <div className="bg-neutralBg1 rounded border-[0.5px] border-neutralBg4 py-6 px-4 flex flex-col justify-between">
                  <div className="">
                    <p className="text-md text-neutralText1 mt-1">Visits</p>
                    {/* <p className="text-[10px] text-neutralText5 mt-0.5">
                    For the month of{" "}
                    <span className="text-infoText5">February</span>
                  </p> */}
                  </div>
                  <div className="flex flex-row mt-2">
                    <div className="py-4">
                      <p className="flex flex-row items-center text-neutralText4 font-light text-xs">
                        Completed Visits{" "}
                        <FiHelpCircle className="stroke-3 ml-1" />
                      </p>
                      <p className="text-neutralText1 font-black text-xl">
                        {salesperson?.visitsData?.completedVisits || 0}
                      </p>
                    </div>
                    <div className="ml-4 px-4 border-l border-r py-4">
                      <p className="flex flex-row items-center text-neutralText4 font-light text-xs">
                        Missed Visits <FiHelpCircle className="stroke-3 ml-1" />
                      </p>
                      <p className="text-errorText5 font-black text-xl">
                        {salesperson?.visitsData?.notCompleteVisits || 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-between bg-neutralBg1 rounded border-[0.5px] border-neutralBg4 py-6 ">
                  <div className="px-4">
                    <p className="text-md text-neutralText1 mt-1">Collection</p>
                    {/* <p className="text-[10px] text-neutralText5 mt-0.5">
                    For the month of{" "}
                    <span className="text-infoText5">February</span>
                  </p> */}
                  </div>

                  <div className="px-4">
                    <p className="flex flex-row items-center text-neutralText4 font-light text-xs">
                      Collection <FiHelpCircle className="stroke-3 ml-1" />
                    </p>
                    <p className="text-successText5 font-black text-xl">
                      {millify(salesperson?.revenue?.collection || 0, {
                        precision: 2,
                        decimalSeparator: ".",
                      })}{" "}
                      /{" "}
                      <span className="text-errorText5 font-normal text-sm">
                        {millify(salesperson?.revenue?.sales || 0, {
                          precision: 2,
                          decimalSeparator: ".",
                        })}
                      </span>
                    </p>
                  </div>
                  <div />
                </div>
              </div>

              <div className="mt-4">
                <Tabs
                  defaultValue={0}
                  onChange={(e, newValue) => setActiveTab(newValue)}
                >
                  <TabsList>
                    <Tab value={0}>Visits</Tab>
                    <Tab value={1}>Transactions</Tab>
                    <Tab value={2}>Retailers</Tab>
                  </TabsList>
                  <TabPanel value={0}>
                    <AssignedVisits spId={id} />
                  </TabPanel>
                  <TabPanel value={1}>
                    <TransactionsBySalesPerson spId={id} />
                  </TabPanel>
                  <TabPanel value={2}>
                    <AssignedVendorList spId={id} />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ViewSalesPerson;
