import * as React from "react";
import { useState } from "react";
import millify from "millify";
import { useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import {
  FiArrowLeft,
  FiMapPin,
  FiX,
  FiHelpCircle,
  FiSliders,
} from "react-icons/fi";
import { CustomDateRangePicker } from "../../components/common/customDatePicker";
import * as Unicons from "@iconscout/react-unicons";
import { ShakeVertical } from "reshake";

import Avatar from "../../components/common/avatar";
import AssignedVisits from "./assignedVisits";
import Transactions from "./transactions";
import TransactionsForm from "../../components/forms/transactionsForm";
import Orders from "./orders";
import { getVendorDetails } from "../../services/api";
import { updateVendor, getVendorStats } from "../../services/vendorsApi";
import {
  Tabs,
  TabPanel,
  TabsList,
  Tab,
  DashboardTab,
} from "../../components/common/customTabs";
import FullscreenLoader from "../../components/common/fullscreenLoader";
import CustomBreadcrumbs from "../../components/common/customBreadcrumbs";
import BaseButton from "../../components/common/baseButton";
import InfoCard from "../../components/common/cards/infoCard";
import InfoCardItem from "../../components/common/cards/infoCardItem";
import { Chip } from "@mui/material";
import StatusPill from "../../components/common/statusPill";
import ConfirmationModal from "../../components/common/confirmationModal";
import ActionBanner from "../../components/common/cards/actionBanner";
import { BannerType, VendorStatus } from "../../constants/common";
import AssignSalespersonModal from "../../components/assignSalespersonModal";
import Chart from "../../components/chart";
import CustomModal from "../../components/common/customModal";
import CustomModalTitle from "../../components/common/customModalTitle";
import CreateOrder from "../../components/createOrder";
import AssignVisitModal from "../../components/assignVisitModal";
import getCurrency from "../../utils/getCurrency";
import EditBalanceForm from "../../components/forms/editBalanceForm";
import { sumBy } from "lodash";

const ViewVendor = (props) => {
  const { id } = useParams();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [forceRefresh, setforceRefresh] = useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [active, setActive] = React.useState(false);
  const [vendorDetails, setVendorDetails] = useState();

  const [chartData, setChartData] = useState();
  const [transactionToEdit, setTransactionToEdit] = useState({});
  const [openCreateTransactionModal, setOpenCreateTransactionModal] =
    useState(false);
  const [openEditBalanceModal, setOpenEditBalanceModal] = useState(false);
  const [openEditTransactionModal, setOpenEditTransactionModal] =
    useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openVisitModal, setOpenVisitModal] = useState(false);
  const [openAssignmentModal, setOpenAssignmentModal] = useState(false);

  const dateStart = new Date();
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateStart.setHours(0, 0, 0, 0);

  const dateEnd = new Date();
  dateEnd.setHours(23, 59, 59, 99);

  const [date, setDate] = useState([
    {
      startDate: dateStart,
      endDate: dateEnd,
      key: "selection",
    },
  ]);

  const getData = async (refresh = true) => {
    setLoading(refresh);
    setforceRefresh(true);
    const data = await getVendorStats(
      parseInt(date[0].startDate / 1000, 10),
      parseInt(date[0].endDate / 1000, 10),
      id
    );
    let res = await getVendorDetails(id);

    setChartData(data);

    setVendorDetails(res);
    setLoading(false);
    setforceRefresh(false);
  };

  const clearFilters = () => {
    setDate([
      {
        startDate: dateStart,
        endDate: dateEnd,
        key: "selection",
      },
    ]);
    getData(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    console.log("refreshed", activeTab, "activeTab");
  }, [chartData, activeTab, forceRefresh]);
  useEffect(() => {
    getData(false);
  }, [date]);

  const handelShake = () => {
    setActive(true);
    setTimeout(() => {
      setActive(false);
    }, 600);
  };

  return (
    <>
      <div className="flex flex-row">
        {loading ? (
          <FullscreenLoader className="!text-neutralText3" />
        ) : (
          <div className="w-full pb-12 grid grid-cols-4 px-6">
            <CustomModal
              title={
                <CustomModalTitle
                  title="Add New Transaction"
                  description="Enter product information and package unit"
                />
              }
              openModal={openCreateTransactionModal}
              setOpenModal={setOpenCreateTransactionModal}
            >
              <TransactionsForm
                vendor={vendorDetails}
                handleClose={() => {
                  setOpenCreateTransactionModal(false);
                  getData(false);
                  setActiveTab(1);

                  // applyFilters({ refresh: false, page: offset + 1 });
                }}
              />
            </CustomModal>
            <CustomModal
              title={
                <CustomModalTitle
                  title="Edit Opening Balance"
                  description="Enter amount to edit opening balance"
                />
              }
              boxStyle={{ p: 0, width: 650 }}
              topContainerStyle={"px-[32px] pt-[32px]"}
              bottomContainerStyle={
                "px-[32px] pb-[32px] pt-[25px] bg-neutralBg2 rounded-b mt-2"
              }
              openModal={openEditBalanceModal}
              setOpenModal={setOpenEditBalanceModal}
            >
              <EditBalanceForm
                id={vendorDetails?.openingBalanceId}
                vendorId={id}
                openingBalance={vendorDetails?.openingBalance}
                handleClose={() => {
                  setOpenEditBalanceModal(false);
                  getData(false);
                  setActiveTab(1);

                  // applyFilters({ refresh: false, page: offset + 1 });
                }}
              />
            </CustomModal>
            <div className="col-span-4">
              <CustomBreadcrumbs
                links={[
                  { title: "Home", url: "/" },
                  { title: "Retailers", url: "/vendors" },
                  { title: "View", url: `/view-vendor/${id}` },
                ]}
              />
              <div className="flex flex-row items-center">
                <FiArrowLeft
                  className="text-neutralText1 stroke-3 cursor-pointer mr-2"
                  // size={12}
                  onClick={() => history.goBack()}
                />
                <span className="text-neutralText1 font-black text-lg">
                  View Retailer
                </span>
              </div>

              <AssignSalespersonModal
                openModal={openAssignmentModal}
                setOpenModal={setOpenAssignmentModal}
                vendors={[id]}
                onClose={() => {
                  getData(false);
                }}
              />

              {!vendorDetails?.spId && (
                <ShakeVertical
                  max={70}
                  freeze={true}
                  active={active}
                  fixed={true}
                  fixedStop={false}
                >
                  <ActionBanner
                    type={BannerType.Warning}
                    onAction={() => {
                      setOpenAssignmentModal(true);
                    }}
                    text="You haven’t assigned a salesperson to this retailer. Please assign a salesperson to activate retailer"
                    actionText="Select Salesperson"
                    containerClassName="mt-2"
                  />
                </ShakeVertical>
              )}

              <div className="grid grid-cols-2 gap-2 mt-4">
                <InfoCard>
                  <InfoCardItem
                    field="name"
                    lock={vendorDetails?.locked}
                    vendorDetails={vendorDetails}
                    onUpdate={() => getData(false)}
                    TextComponent={
                      <div className="flex flex-row items-center ">
                        <Avatar
                          image={vendorDetails?.businessLogo}
                          imageClassName="w-10 h-10"
                        />
                        <p className="text-sm text-neutralText1 font-bold ml-3">
                          {vendorDetails?.businessName || "--"}
                        </p>
                      </div>
                    }
                  />
                  <InfoCardItem
                    field="salesperson"
                    // lock={vendorDetails?.status === "INACTIVE"}
                    vendorDetails={vendorDetails}
                    onUpdate={() => getData(false)}
                    text={
                      vendorDetails?.salesperson?.name ? (
                        <Link
                          to={`/view-salesperson/${vendorDetails?.salesperson?.id}`}
                          target="_blank"
                        >
                          {vendorDetails?.salesperson?.name}
                        </Link>
                      ) : (
                        "Assign Salesperson"
                      )
                    }
                    textClassName={
                      vendorDetails?.salesperson?.name
                        ? "text-sm text-infoText4"
                        : "text-sm text-neutralText3"
                    }
                    icon={
                      <Unicons.UilUser
                        size={20}
                        className="text-neutralText4"
                      />
                    }
                    buttonProps={{
                      text: vendorDetails?.salesperson?.name ? "Change" : "Add",
                      onClick: () => {
                        setOpenAssignmentModal(true);
                      },
                    }}
                  />
                  <InfoCardItem
                    field="address"
                    lock={vendorDetails?.locked}
                    vendorDetails={vendorDetails}
                    onUpdate={() => getData(false)}
                    text={
                      vendorDetails?.mapped
                        ? vendorDetails?.locationName
                        : (
                            <div>
                              {vendorDetails?.locationName} (Not Mapped)
                            </div>
                          ) || "--"
                    }
                    textClassName={"w-3/4"}
                    icon={
                      vendorDetails?.mapped ? (
                        <FiMapPin size={"20px"} className="text-neutralText4" />
                      ) : (
                        <Unicons.UilExclamationTriangle className="mr-1 text-warningText4 w-[18px] min-w-[18px] min-h-[18px]" />
                      )
                    }
                  />
                  <InfoCardItem
                    field="contact"
                    vendorDetails={vendorDetails}
                    onUpdate={() => getData(false)}
                    text={
                      vendorDetails?.contacts &&
                      vendorDetails?.contacts?.length > 0
                        ? vendorDetails?.contacts[0]?.contact || "--"
                        : "--"
                    }
                    icon={
                      <Unicons.UilPhoneAlt
                        size={20}
                        className="text-neutralText4"
                      />
                    }
                    buttonProps={{
                      text:
                        vendorDetails?.contacts &&
                        vendorDetails?.contacts?.length > 0
                          ? "Change"
                          : "Add",
                    }}
                  />
                  <div
                    className={`flex flex-row justify-between items-center border-b border-neutralBg3 py-3 `}
                  >
                    <div className="flex flex-row items-center  ">
                      <Unicons.UilQuestionCircle
                        size={20}
                        className="text-neutralText4"
                      />
                      <div
                        className={`text-xs text-neutralText3 ml-3 ${
                          vendorDetails?.status === "ACTIVE"
                            ? "text-successText5"
                            : "text-errorText5"
                        }`}
                      >
                        {vendorDetails?.status}
                      </div>
                    </div>{" "}
                    <ConfirmationModal
                      title="Are your sure?"
                      subtitle={`Are you sure you want to ${
                        vendorDetails?.status === VendorStatus.ACTIVE
                          ? "deactivate"
                          : "activate"
                      } the retailer.`}
                      positiveLabel={`Yes ${
                        vendorDetails?.status === VendorStatus.ACTIVE
                          ? "Deactivate"
                          : "Activate"
                      }`}
                      positiveType={
                        vendorDetails?.status === VendorStatus.INACTIVE
                          ? "bg-successText4 hover:bg-successText5"
                          : null
                      }
                      negativeLabel="Cancel"
                      onPositiveClick={async () => {
                        await updateVendor({
                          id: vendorDetails.id,
                          status:
                            vendorDetails.status === "ACTIVE"
                              ? "INACTIVE"
                              : "ACTIVE",
                        });
                        getData(false);
                        
                      }}
                      button={(onClick) => (
                        <button
                          onClick={() =>
                            !vendorDetails?.spId ? handelShake() : onClick()
                          }
                          className={`rounded-2xl text-xs py-1.5 flex px-3 w-24 flex-row justify-center items-center cursor-pointer text-neutralBg1 ${
                            vendorDetails?.status === "ACTIVE"
                              ? "bg-errorText5 hover:bg-errorText4"
                              : "bg-successBg5 hover:bg-successBg4"
                          } `}
                        >
                          <div>
                            {vendorDetails?.status === "ACTIVE"
                              ? "Deactivate"
                              : "Activate"}
                          </div>
                        </button>
                      )}
                    />
                  </div>
                  <div className="flex py-3 items-center  border-b border-neutralBg3">
                    <Unicons.UilMapPinAlt
                      size={20}
                      className="text-neutralText4 "
                    />
                    {vendorDetails?.territories?.length > 0 ? (
                      vendorDetails?.territories?.map((el, index) => (
                        <Chip
                          key={`index-${index}`}
                          label={el.name}
                          onDelete={() => null}
                          size="small"
                          deleteIcon={<FiX />}
                          classes={{
                            root: "!text-infoText2 !bg-infoBg2 !font-light !ml-3 ",
                            deleteIcon: "!text-infoText2 hover:!bg-infoBg2",
                          }}
                        />
                      ))
                    ) : (
                      <span className="text-xs text-neutralText3 ml-3">
                        No territories assigned yet
                      </span>
                    )}
                  </div>
                  <div className="flex justify-between items-center border-b border-neutralBg3">
                    <div className="flex py-3 items-center text-xs text-neutralText3">
                      <Unicons.UilMoneyBill
                        size={20}
                        className="text-neutralText4"
                      />
                      <div className="ml-3">
                        Opening Balance : {getCurrency()}{" "}
                        {vendorDetails?.openingBalance}
                      </div>
                    </div>
                    <BaseButton
                      onClick={() => setOpenEditBalanceModal(true)}
                      text="Change"
                      type={`
                         border border-infoText5 hover:bg-infoText5 hover:text-white
                    rounded-full !px-3 !py-0.5 text-[10px]`}
                      textColor={"infoText5"}
                      height=" "
                    />
                  </div>
                  <AssignVisitModal
                    openModal={openVisitModal}
                    setOpenModal={setOpenVisitModal}
                    vendors={[vendorDetails?.id]}
                    onClose={() => {
                      getData(false);
                      setActiveTab(2);
                    }}
                  />
                  <div className="pt-3 flex flex-row items-center justify-between">
                    <div className="flex">
                      {vendorDetails?.mapped ? (
                        <BaseButton
                          disabled={
                            vendorDetails?.status === "INACTIVE" ||
                            vendorDetails?.salesperson?.status === "INACTIVE"
                          }
                          text="Assign Visit"
                          type={`${
                            vendorDetails?.status === "INACTIVE"
                              ? "border-neutralBg5 hover:border-neutralBg5 hover:bg-transparent hover:text-neutralBg5 "
                              : "border-infoText5 hover:bg-infoText5 hover:text-white "
                          } !px-3 !py-0.5 text-[10px] border `}
                          textColor={
                            vendorDetails?.status === "INACTIVE"
                              ? "neutralBg5"
                              : "infoText5"
                          }
                          onClick={() => setOpenVisitModal(true)}
                        />
                      ) : (
                        <ConfirmationModal
                          hideButtons={true}
                          title={
                            <div className="flex items-start text-warningText4 w-full">
                              <Unicons.UilExclamationTriangle className="mr-1 text-warningText4 w-[18px] min-w-[18px] min-h-[18px]" />
                              Can't assign visit to this vendor
                            </div>
                          }
                          subtitle="Please ask the associated salesperson to map this retailer."
                          positiveLabel={"Close"}
                          // negativeLabel="Close"
                          // onPositiveClick={() => handleRemoveProduct(el.id)}
                          button={(onClick) => (
                            <BaseButton
                              onClick={onClick}
                              text="Assign Visit"
                              className={`${
                                vendorDetails?.status === "INACTIVE"
                                  ? "border-neutralBg5 hover:border-neutralBg5 hover:bg-transparent hover:text-neutralBg5 text-neutralBg5 "
                                  : "border-infoText5 hover:bg-infoText5 hover:text-white text-infoText5 "
                              } !px-3 !py-[.75em] text-[10px] border `}
                              textColor={
                                vendorDetails?.status === "INACTIVE"
                                  ? "neutralBg5"
                                  : "infoText5"
                              }
                            />
                          )}
                        />
                      )}
                      <CustomModal
                        title={
                          <CustomModalTitle
                            title="Add new Order"
                            description="Enter product information and package unit"
                          />
                        }
                        boxStyle={{ p: 0, width: 550 }}
                        topContainerStyle={"px-[32px] pt-[32px]"}
                        bottomContainerStyle={
                          "px-[32px] pb-[32px] bg-neutralBg3 rounded-b mt-2"
                        }
                        openModal={openOrderModal}
                        setOpenModal={setOpenOrderModal}
                        onClose={() => getData(false)}
                      >
                        <CreateOrder
                          vendorDetails={vendorDetails}
                          handleClose={() => {
                            setOpenOrderModal(false);

                            getData(false);
                            setActiveTab(0);
                          }}
                        />
                      </CustomModal>
                      <BaseButton
                        onClick={() => setOpenOrderModal(true)}
                        disabled={
                          vendorDetails?.status === "INACTIVE" ||
                          vendorDetails?.salesperson?.status === "INACTIVE"
                        }
                        text="Add Order"
                        type={`${
                          vendorDetails?.status === "INACTIVE"
                            ? "border-neutralBg5 hover:border-neutralBg5 hover:bg-transparent hover:text-neutralBg5 "
                            : "border-infoText5 hover:bg-infoText5 hover:text-white "
                        } !px-3 !py-0.5 text-[10px] border border-l-0`}
                        textColor={
                          vendorDetails?.status === "INACTIVE"
                            ? "neutralBg5"
                            : "infoText5"
                        }
                      />

                      <BaseButton
                        disabled={
                          vendorDetails?.status === "INACTIVE" ||
                          vendorDetails?.salesperson?.status === "INACTIVE"
                        }
                        text="Add Transaction"
                        type={`${
                          vendorDetails?.status === "INACTIVE"
                            ? "border-neutralBg5 border-l-0 hover:border-neutralBg5 hover:bg-transparent hover:text-neutralBg5 "
                            : "border-infoText5 border-l-0 hover:bg-infoText5 hover:text-white "
                        } !px-3 !py-0.5 text-[10px] border `}
                        textColor={
                          vendorDetails?.status === "INACTIVE"
                            ? "neutralBg5"
                            : "infoText5"
                        }
                        openModal={openCreateTransactionModal}
                        setOpenModal={setOpenCreateTransactionModal}
                        onClick={() => {
                          setOpenCreateTransactionModal(true);
                        }}
                      />
                      {/* <BaseButton
                      outline
                      text="Add Order"
                      type="border-infoText5 hover:bg-infoText5 hover:text-white !px-3 !py-0.5 text-[10px] border-l-0"
                      textColor="infoText5"
                    /> */}
                    </div>
                    <div className="flex items-center">
                      <div className="text-successText4 bg-successBg2 text-sm px-4 h-8 flex items-center">
                        Total Balance
                      </div>
                      <div className="text-successText4 font-bold bg-successBg3 text-xl px-4 h-8 flex items-center">
                        {getCurrency()}
                        {millify(vendorDetails?.balance || 0, {
                          precision: 2,
                          decimalSeparator: ".",
                        })}
                      </div>
                    </div>

                    {/* <div>
                      <ConfirmationModal
                        title="Are your sure?"
                        subtitle={`Are you sure you want to ${
                          vendorDetails?.status === VendorStatus.ACTIVE
                            ? "deactivate"
                            : "activate"
                        } the vendor.`}
                        positiveLabel={`Yes ${
                          vendorDetails?.status === VendorStatus.ACTIVE
                            ? "Deactivate"
                            : "Activate"
                        }`}
                        positiveType={
                          vendorDetails?.status === VendorStatus.INACTIVE
                            ? "bg-successText4 hover:bg-successText5"
                            : null
                        }
                        negativeLabel="Cancel"
                        onPositiveClick={async () => {
                          await updateVendor({
                            id: vendorDetails.id,
                            status:
                              vendorDetails.status === "ACTIVE"
                                ? "INACTIVE"
                                : "ACTIVE",
                          });
                          getData(false);
                        }}
                        button={(onClick) => (
                          <StatusPill
                            status={vendorDetails?.status}
                            className="text-xs"
                            onClick={() => {
                              if (!vendorDetails?.spId) {
                                toast.error(
                                  "Please assign a salesperson first"
                                );
                                return;
                              }
                              onClick();
                            }}
                          />
                        )}
                      />
                    </div> */}
                  </div>
                </InfoCard>

                <InfoCard className={"p-0 h-full"}>
                  <Tabs
                    defaultValue={0}
                    onChange={(e, newValue) => setActiveTab(newValue)}
                  >
                    <div className="bg-neutralBg1">
                      <TabsList>
                        <div className="flex justify-between">
                          <div>
                            <DashboardTab value={0}>
                              <div className="flex items-center text-neutralText4">
                                <div className="font-normal pr-1text-[13px]">
                                  Sale
                                </div>
                                <FiHelpCircle className="stroke-3 text-neutralText5" />
                              </div>
                              <div className="text-[26px] min-w-[3rem] font-black text-neutralText1 text-left py-2">
                                {getCurrency()}{" "}
                                {millify(
                                  sumBy(chartData?.sales, (o) => o?.data) || 0,
                                  {
                                    precision: 2,
                                    decimalSeparator: ".",
                                  } || 0,
                                  {
                                    precision: 2,
                                    decimalSeparator: ".",
                                  }
                                )}
                              </div>
                            </DashboardTab>

                            <DashboardTab value={1}>
                              {" "}
                              <div className="flex items-center text-neutralText4">
                                <div className="font-normal pr-1 text-[13px]">
                                  Collection
                                </div>
                                <FiHelpCircle className="stroke-3 text-neutralText5" />
                              </div>
                              <div className="text-[26px] font-black text-neutralText1 text-left py-2">
                                {getCurrency()}{" "}
                                {millify(
                                  sumBy(
                                    chartData?.collection,
                                    (o) => o?.data
                                  ) || 0,
                                  {
                                    precision: 2,
                                    decimalSeparator: ".",
                                  }
                                )}
                              </div>
                            </DashboardTab>
                          </div>
                          <div className="flex items-center">
                            <div className=" mr-2">
                              {date[0].startDate / 1000 !== dateStart / 1000 ||
                              date[0].endDate / 1000 !== dateEnd / 1000 ? (
                                <button onClick={() => clearFilters()}>
                                  <div className="flex items-center justify-center text-infoText4 text-xs">
                                    <FiX size={14} />
                                    Clear
                                  </div>
                                </button>
                              ) : (
                                <FiSliders
                                  className=" text-neutralText1"
                                  size={18}
                                />
                              )}
                            </div>
                            <CustomDateRangePicker
                              date={date}
                              setDate={setDate}
                              changed={
                                date[0].startDate / 1000 !== dateStart / 1000 ||
                                date[0].endDate / 1000 !== dateEnd / 1000
                              }
                            />
                          </div>
                        </div>
                      </TabsList>
                    </div>
                    <TabPanel value={0}>
                      <div
                        className="bg-neutralBg3 pt-10"
                        style={{
                          height: "360px",
                        }}
                      >
                        <Chart
                          data={chartData?.sales || []}
                          range={date}
                          chartInfo="Sale"
                        />
                      </div>
                    </TabPanel>
                    <TabPanel value={1}>
                      <div
                        className="bg-neutralBg3 pt-10"
                        style={{
                          height: "360px",
                        }}
                      >
                        <Chart
                          data={chartData?.collection || []}
                          range={date}
                          chartInfo="Sale"
                        />
                      </div>
                    </TabPanel>
                  </Tabs>
                </InfoCard>
              </div>
              <div className="mt-4">
                <div className="">
                  <Tabs
                    defaultValue={0}
                    value={activeTab}
                    onChange={(e, newValue) => setActiveTab(newValue)}
                  >
                    <TabsList>
                      <Tab value={0}>Orders</Tab>
                      <Tab value={1}>Transactions</Tab>
                      <Tab value={2}>Visits</Tab>
                    </TabsList>

                    <TabPanel value={0}>
                      <Orders
                        vendorId={id}
                        date={date}
                        refresh={forceRefresh}
                      />
                    </TabPanel>
                    <TabPanel value={1}>
                      <Transactions
                        vendorId={id}
                        date={date}
                        refresh={forceRefresh}
                      />
                    </TabPanel>
                    <TabPanel value={2}>
                      <AssignedVisits
                        vendorId={id}
                        date={date}
                        refresh={forceRefresh}
                      />
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ViewVendor;
