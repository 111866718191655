import { CircularProgress } from "@mui/material";
import React from "react";

const FullscreenLoader = ({ className }) => {
  return (
    <div className="m-auto h-screen flex items-center">
      <CircularProgress color="primary" className={className} />
    </div>
  );
};

export default FullscreenLoader;
