import React, { useState } from "react";

import CustomTitle from "../../components/common/customTitle";
import OuterDiv from "../../components/common/outerDiv";
import BaseButton from "../../components/common/baseButton";
import Avatar from "../../components/common/avatar";
import CustomModal from "../../components/common/customModal";
import CustomModalTitle from "../../components/common/customModalTitle";
import ChangePassword from "../../components/forms/changePassword";

function SignInAndSecurity() {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  console.log("modal", openFormModal, openModal);
  return (
    <>
      <div className="flex flex-row">
        <div className="w-full pb-12 grid grid-cols-4 px-12">
          <div className="col-span-3 w-10/12 pt-12">
            <CustomTitle
              title="Sign in & Security"
              description="Account Information"
            />
            <OuterDiv>
              <div className="p-6">
                <CustomTitle title="Change Password" />
                <div className="flex items-center justify-between text-neutralText3 pt-6 font-semibold">
                  <div>Current Password</div>
                  <div>*****************</div>
                  <CustomModal
                    buttonText="Change"
                    title={
                      <CustomModalTitle
                        title="Change Password"
                        description="Enter following details to change your password"
                      />
                    }
                    boxStyle={{ p: 0, width: 650 }}
                    topContainerStyle={"px-[32px] pt-[32px]"}
                    bottomContainerStyle={
                      "px-[32px] pb-[32px] pt-[25px] bg-neutralBg2 rounded-b mt-2"
                    }
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    button={
                      <BaseButton
                        text="Change"
                        className="border border-neutralText2 py-1 px-6 bg-neutralBg1 text-xs hover:bg-primary hover:border-primary"
                        textColor="neutralText2"
                      />
                    }
                  >
                    <ChangePassword
                      handleCloseModal={() => {
                        setOpenModal(false);
                        setOpenFormModal(false);
                      }}
                    />
                  </CustomModal>
                </div>
              </div>
            </OuterDiv>
            {/* <OuterDiv>
              <div className="p-6">
                <CustomTitle
                  title="Active Devices"
                  description="See where you are logged in"
                />
                <div className="flex items-center px-4 pt-8 text-neutralText3">
                  <Avatar imageClassName={"w-20 h-20"} />
                  <div className="px-6">Windows 10 | Chrome 65.000</div>
                </div>
              </div>
            </OuterDiv> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default SignInAndSecurity;
