import React, { useEffect, useState } from "react";
import {
  getSalesPersons,
  replaceSalesperson,
} from "../../services/salespersonApi";
import { toast } from "react-toastify";
import BaseButton from "./baseButton";
import BaseModal from "./baseModal";
import CustomAutocomplete from "./customAutocomplete";

const ConfirmationModal = ({
  title,
  subtitle,
  positiveLabel,
  negativeLabel,
  onPositiveClick,
  onNegativeClick,
  positiveType,
  negativeType,
  button,
  children,
  boxStyle,
  topContainerStyle,
  bottomContainerStyle,
  midContent,
  salesperson,
  hideButtons,
  handleCloseModal,
  negativeTextColor,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const [searchedSalespersons, setSearchedSalespersons] = useState([]);
  const [selectedSalesperson, setSelectedSalesperson] = useState();
  const [changeSalesperson, setChangeSalesperson] = useState("yes");
  const handleClose = () => setOpen(false);

  const searchSalespersons = async (query) => {
    const resp = await getSalesPersons({
      offset: 1,
      limit: 10,
      status: "ACTIVE",
    });
    setSearchedSalespersons(resp.result || []);
  };

  const spList = async () => {
    const resp = await getSalesPersons({
      offset: 1,
      status: "ACTIVE",
      limit: 10,
    });
    setSearchedSalespersons(resp.result || []);
  };

  useEffect(() => {
    spList();
  }, []);

  return (
    <div>
      {button(handleOpen)}
      <BaseModal
        open={open}
        handleClose={handleClose}
        boxStyle={{ ...boxStyle, width: 450, outline: "none" }}
      >
        {children || (
          <>
            <div className={topContainerStyle}>
              <div className="pb-2 text-xl font-bold text-neutralText1">
                {title}
              </div>
              <div className="pb-2 text-sm text-neutralText4">{subtitle}</div>
            </div>
            {salesperson?.status === "ACTIVE" && (
              <>
                <div className="text-neutralText1 py-4">
                  Do you want to replace another salesperson with this
                  salesperson?
                </div>
                <div className="flex items-center text-sm text-neutralText1">
                  {" "}
                  <div className="flex items-center mr-6">
                    <input
                      type="radio"
                      name="yes"
                      onChange={() => {
                        setChangeSalesperson("yes");
                        // setChangeSalespersonCondition("yes");
                      }}
                      checked={changeSalesperson === "yes"}
                    />
                    <div className="px-2">Yes</div>
                  </div>{" "}
                  <div className="flex items-center">
                    <input
                      type="radio"
                      name="no"
                      onChange={() => {
                        setChangeSalesperson("no");
                        // setChangeSalespersonCondition("no");
                      }}
                      checked={changeSalesperson === "no"}
                    />
                    <div className="px-2">No</div>
                  </div>{" "}
                </div>
                {changeSalesperson === "yes" ? (
                  <div className="py-4">
                    <CustomAutocomplete
                      // icon={el.filterIcon}
                      // disabled={!!transaction}
                      placeholder={"Search Salesperson"}
                      onChange={(event, newValue) => {
                        setSelectedSalesperson(newValue);
                      }}
                      filterOptions={(x) =>
                        x.filter((el) => el.name != salesperson.name)
                      }
                      getOptionLabel={(option) => {
                        return typeof option === "string"
                          ? option
                          : option.name;
                      }}
                      options={searchedSalespersons}
                      autoComplete
                      filterSelectedOptions
                      onInputChange={(event, newInputValue) => {
                        if (newInputValue) {
                          searchSalespersons(newInputValue);
                        }
                      }}
                    />
                  </div>
                ) : null}
              </>
            )}
            <div className={bottomContainerStyle}>
              {midContent}
              <div className={"py-4 flex justify-between"}>
                <div className="mr-3">
                  <BaseButton
                    text={
                      salesperson?.status === "ACTIVE" &&
                      changeSalesperson === "yes"
                        ? "Replace"
                        : positiveLabel || "Proceed"
                    }
                    onClick={async () => {
                      if (onPositiveClick) {
                        const data =
                          salesperson?.status === "ACTIVE" &&
                          changeSalesperson === "yes"
                            ? await replaceSalesperson(
                                salesperson.id,
                                selectedSalesperson.id
                              )
                            : await onPositiveClick();
                        if (data === undefined) handleClose();
                        if (data.status === "OK") {
                          handleClose();
                          handleCloseModal();
                          toast.success("Salesperson Replaced");
                        }
                      }

                      handleClose();
                    }}
                    type={
                      positiveType ||
                      "w-auto border border-errorText4 bg-errorText4 hover:bg-errorText5"
                    }
                    textColor="neutralBg1"
                    width={"w-40"}
                  />
                </div>
                {!hideButtons && (
                  <div>
                    <BaseButton
                      text={negativeLabel || "Cancel"}
                      onClick={() => {
                        if (onNegativeClick) {
                          onNegativeClick();
                          handleClose();
                        } else {
                          handleClose();
                        }
                      }}
                      outline
                      type={
                        negativeType ||
                        "border-successText5 hover:bg-successText5 hover:text-white"
                      }
                      textColor={negativeTextColor || "successText5"}
                      width={"w-40"}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </BaseModal>
    </div>
  );
};

export default ConfirmationModal;
