import {
  GET_VENDOR_LIST,
  RESET_STATE,
  ADD_NEW_VENDOR,
  ADD_EXISTING_VENDOR,
  VENDOR_TO_EDIT,
  UPDATE_VENDOR
} from "../actions/actionTypes";

//for getting vendor list
// export const getVendorList = (
//   vendorList = [{ list: [], isLast: false, status: null }],
//   action
// ) => {
//   switch (action.type) {
//     case GET_VENDOR_LIST:
//       return (vendorList = [
//         {
//           list: [...vendorList[0].list, ...action.payload.list],
//           isLast: action.payload.isLast,
//           status: action.payload.responseStatus
//         }
//       ]);

//     case RESET_STATE:
//       return (vendorList = [{ list: [], isLast: false }]);

//     default:
//       return vendorList;
//   }
// };

export const vendorToEdit = (vendor = null, action) => {
  switch (action.type) {
    case VENDOR_TO_EDIT:
      return action.payload;
    
    default:
      return vendor;
  }
}

//for adding new vendor
export const addNewVendor = (response = null, action) => {
  switch (action.type) {
    case ADD_NEW_VENDOR:
      return action.payload;
    case RESET_STATE:
      return (response = null);

    default:
      return response;
  }
};

//for adding new vendor
export const addExistingVendor = (response = null, action) => {
  switch (action.type) {
    case ADD_EXISTING_VENDOR:
      return action.payload;
    case RESET_STATE:
      return (response = null);

    default:
      return response;
  }
};

//for adding new vendor
export const updateVendor = (response = null, action) => {
  switch (action.type) {
    case UPDATE_VENDOR:
      return action.payload;
    case RESET_STATE:
      return (response = null);

    default:
      return response;
  }
};
