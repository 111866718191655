import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import { CircularProgress } from "@mui/material";
import _ from "lodash";

import SideNav from "../components/sideNav";
import TopBar from "../components/topBar";
import { Transactions } from "../services/api";
import CustomTable from "../components/common/table";
import ConvertTimestamp from "../components/convertTimestamp";
import { FiCheck } from "react-icons/fi";
import { AiOutlineClockCircle } from "react-icons/ai";
import getCurrency from "../utils/getCurrency";

const columns = [
  { key: "planName", name: "Name" },
  { key: "endDate", name: "Expiring On" },
  { key: "status", name: "Status" },
];

const Subscriptions = () => {
  const [modifiedData, setModifiedData] = useState([]);
  const [transactions, setTransactions] = useState();
  const [loader, setLoader] = useState(true);

  const currentUser = useSelector((state) => state.getProfileDetails);
  const getData = async () => {
    const data = await Transactions(currentUser.id);
    setTransactions(data);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [currentUser]);

  useEffect(() => {
    const temp = transactions?.list.map((el) => {
      const transactionStatus = _.lowerCase(el.status);
      const planCategory = _.lowerCase(el.planName);
      return {
        ...el,
        planName: (
          <Link
            className="hover:text-green-600 text-md font-bold     "
            to={{ pathname: "/view-subscription", state: { el } }}
          >
            {" "}
            {_.startCase(planCategory)}
          </Link>
        ),
        endDate: <ConvertTimestamp timestamp={el.endDate} />,
        status:
          transactionStatus === "completed" ? (
            <div className="bg-green-100 m-auto w-28 text-green-700 py-1 rounded-full px-1 font-mulish">
              {_.startCase(transactionStatus)}
            </div>
          ) : (
            <div className="bg-gray-300 w-28 m-auto text-gray-700 py-1 rounded-full px-1 font-mulish">
              {_.startCase(transactionStatus)}
            </div>
          ),
        // action: (
        //   <Action
        //     actions={[
        //       { actionName: "View", link: "/viewVendor", editItem: { el } },
        //       { actionName: "Edit", link: "/editVendor", editItem: { el } },
        //     ]}
        //   />
        // ),
        // select: (
        //   <Checkbox
        //     onChange={() => {
        //       console.log("checking", vendorId, el.vendorId);
        //       if (vendorId.includes(el.vendorId)) {
        //         console.log("includes", el.vendorId);
        //         for (let i = 0; i < vendorId.length; i++) {
        //           if (vendorId[i] == el.vendorId) {
        //             vendorId.splice(i, 1);
        //             break;
        //           }
        //         }
        //       } else {
        //         console.log("Logging", false, el.vendorId);
        //         vendorId.push(el.vendorId);
        //       }
        //     }}
        //   />
        // ),
      };
    });
    setModifiedData(temp);
    console.log("tempdata", temp);
  }, [transactions]);

  console.log("modified data..", currentUser);
  return (
    <>
      <TopBar value="Subscriptions" />
      <div className="flex flex-row">
        <SideNav />
        {loader ? (
          <div className="m-auto h-screen flex items-center">
            {/* <CircularProgress color="white" size={60} /> */}
          </div>
        ) : (
          <div className="w-full lg:w-10/12 pb-12">
            <div className="grid px-4 sm:px-12 grid-cols-4 py-8">
              {/* {transactions?.list.map((item) => { */}
              <div class="text-left border col-span-4 border-secondary-text-light w-full sm:w-9/12 xl:w-8/12 border-opacity-40 mx-auto rounded py-4 flex-col flex sm:flex-row justify-between">
                {/* <div class=""> */}
                <div class="px-6">
                  <button class=" border cursor-default border-primary rounded-full px-2 bg-gradient-to-r to-gradient-from from-gradient-via">
                    <p className="text-xs text-gray-800  font-mulish">
                      {_.upperFirst(
                        transactions?.list[0]?.planName || "no plan"
                      )}
                    </p>
                  </button>
                  <div class=" text-md font-semibold py-2 text-primary-text font-mulish">
                    Current Plan
                  </div>
                  <div class="grid grid-col-1 sm:grid-cols-2 text-sm font-semibold py-2 text-secondary-text sm:space-x-4">
                    <div className="sm:py-2 flex items-center justify-start space-x-2 mb-2">
                      <FiCheck className="text-green-400 text-md" />
                      <span className="text-sm text-gray-700 font-medium font-mulish">
                        {`Upto ${
                          transactions?.list[0]?.spLimit || 0
                        } Salespersons`}
                      </span>
                    </div>
                    <div className="sm:py-2 flex items-center justify-start space-x-2 mb-2">
                      <FiCheck className="text-green-400 text-md" />
                      <span className="text-sm text-gray-700 font-medium font-mulish">
                        {`Upto ${
                          transactions?.list[0]?.vendorLimit || 0
                        } Retailers`}
                      </span>
                    </div>
                  </div>

                  <div className="sm:py-2 flex items-center justify-start space-x-2 mb-2 w-full">
                    <AiOutlineClockCircle className="text-red-600 text-md" />
                    <>
                      <span className="text-sm text-gray-700 font-medium font-mulish">
                        Plan expires on :{" "}
                      </span>
                      <span className="text-sm text-gray-700 font-semibold font-mulish">
                        {transactions?.list[0]?.endDate ? (
                          <ConvertTimestamp
                            timestamp={transactions?.list[0]?.endDate}
                          />
                        ) : (
                          "--"
                        )}
                      </span>
                    </>
                  </div>
                </div>
                <div class="text-center px-6">
                  <div class="text-2xl font-bold py-6 text-primary-text">
                    {getCurrency()}
                    {transactions?.list[0]?.amount || 0}/mo
                  </div>
                  <Link to="/plans">
                    <button
                      onClick={() => {
                        //   getPlan(monthlyPlan);
                      }}
                      className="bg-primary-light rounded text-xs text-white px-3 w-full py-2"
                    >
                      Upgrade Plan
                    </button>
                  </Link>
                </div>
                {/* </div> */}
                {/* </div> */}
              </div>
              {/* })} */}
              <div className="text-xs col-span-4 mt-20 border-2 rounded-xl">
                <div className="py-8 px-4">
                  <div className="flex flex-col">
                    <div className="text-lg text-secondary-text font-medium font-mulish">
                      Subscription History
                    </div>
                    <CustomTable
                      columns={columns}
                      rows={modifiedData}
                    ></CustomTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Subscriptions;
