import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Unicons from "@iconscout/react-unicons";
import { Formik } from "formik";

import { editBalanceSchema, LoginSchema, targetSchema } from "../validationSchema";
import { login } from "../../redux store/actions/loginAndRegistration";
import ValidationErrors from "../common/validationErrors";
import CustomInput from "../common/customInput";
import BaseButton from "../common/baseButton";
import { editBalance } from "../../services/vendorsApi";
import getCurrency from "../../utils/getCurrency";
import { updateSalesperson } from "../../services/salespersonApi";
import { toast } from "react-toastify";

const AddOrEditTarget = ({id,target,handleClose}) => {
  const history = useHistory();

  const [date, setDate] = useState(new Date());
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  console.log("res",id);


  const updateSalespersonDetails = async (details) => {
    const resp = await updateSalesperson(details);

    if (resp) {
    //   setSalespersons((o) =>
    //     o.map((el) => (el.id === details.id ? { ...el, ...details } : el))
    //   );
      toast.success("Salesperson status updated successfully");
      setLoader(false)
      handleClose()
    } else {
    }
  };


  return (
    <div className="">
      <Formik
        initialValues={{ target:target|| 0 }}
        validationSchema={targetSchema}
        // validate={(values)
        //   => {
        //   const errors = {};
        //   if (!values.email) {
        //     errors.email = "Required";
        //   } else if (
        //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        //   ) {
        //     errors.email = "Invalid emai••••••l address";
        //   }
        //   if (!values.password) {
        //     errors.password = "Required";
        //   }
        //   return errors;
        // }}
        onSubmit={async(values) => {
          setLoader(true)
        const res=await  updateSalespersonDetails({
            id: id,
            target: {
              date: parseInt(date / 1000, 10),
              target: values.target,
            }})
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
             <div>
              <CustomInput
                icon={
                  <div className="text-neutralText5 h-5 w-5 flex justify-center items-center">
                    {getCurrency()}
                  </div>
                }
                allowNumOnly
                // containerClasses="py-2"
                label="Opening Balance"
                placeholder="60090"
                type="number"
                name="target"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.target}
              />
              {touched.target && errors.target && (
                <ValidationErrors
                  errors={`${errors.target}`}
                ></ValidationErrors>
              )}
            </div>
          <div className=" flex justify-between mt-6">
          <BaseButton
            //   buttonProps={{ type: "submit" }}
            onClick={(e)=>{e.preventDefault()
            handleClose()
            }}
              text={"Cancel"}
              width={"w-36"}
              type={"bg-warningBg5 hover:bg-warningBg4"}
              textColor="neutralBg1"
            //   loading={loader}
            />
            <BaseButton
              buttonProps={{ type: "submit" }}
              text={"Save"}
              width={"w-36"}
              type={"bg-secondary hover:bg-secondaryLight"}
              textColor="neutralBg1"
              loading={loader}
            /></div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddOrEditTarget;
