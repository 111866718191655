import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";

import BaseButton from "./baseButton";
import CustomInput from "./customInput";
import SelectVendorCard from "../selectVendorCard";
import Loader from "./loader";

function SearchVendor({
  vendorList,
  next,
  onNewClick,
  setVendor,
  selectedVendor,
  onSearch,
  loading,
}) {
  // TODO add empty state
  const [query, setQuery] = useState("");
  console.log("loading321", query);

  useState(() => {
    setVendor("");
    setQuery();
  }, []);

  return (
    <div className="px-2 py-4 text-neutralText2">
      <div className=" text-lg ">
        Search from existing retailers in the Busybee database.{" "}
      </div>
      <div className="text-xs pb-4">
        BusyBee retailers are already mapped and verified.
      </div>
      <CustomInput
        icon={<FiSearch className="text-neutralText1 h-5 w-5" />}
        placeholder="Enter Retailer Name to Search"
        containerClasses="mr-2"
        onChange={(e) => {
          onSearch(e.target.value);
          setQuery(e.target.value);
        }}
      />
      <div className="overflow-auto mt-4 max-h-48 scrollbar scrollbar-thumb-neutralText4 scrollbar-track-neutralBg3 scrollbar-thin hover:scrollbar-thumb-neutralText3">
        <div>
          {loading ? (
            <div className="h-48 flex justify-center items-center">
              <Loader color={"secondary"} />
            </div>
          ) : (
            vendorList?.map((el, index) => (
              <div className="py-1 w-full" key={`Index-${index}`}>
                <button className="w-full" onClick={next}>
                  <SelectVendorCard
                    search
                    vendor={el}
                    setVendor={setVendor}
                    checked={selectedVendor?.id === el.id}
                  />
                </button>
              </div>
            ))
          )}
        </div>
      </div>
      {vendorList?.length > 0 && (
        <div>
          <div className="p-2 text-center text-neutralText2 text-[0.6rem] font-bold">
            Not in the list?
          </div>
          <div className="flex justify-center">
            <BaseButton onClick={onNewClick} text={"Create New Retailer"} />
          </div>
        </div>
      )}
      {query && !vendorList && !loading && (
        <div>
          <div className="p-2 text-center text-neutralText2 text-[0.6rem] font-bold">
            No matches found.
          </div>
          <div className="flex justify-center">
            <BaseButton onClick={onNewClick} text={"Create New Retailer"} />
          </div>
        </div>
      )}
      {/* <div className="text-right">
        <BaseButton
          disabled={!!!selectedVendor}
          text={"Next"}
          onClick={next}
          type={`${
            selectedVendor
              ? "bg-infoText4  hover:bg-infoText3"
              : "bg-neutralText4"
          } `}
          textColor="neutralBg1"
          width={"w-40"}
        />
      </div> */}
    </div>
  );
}

export default SearchVendor;
