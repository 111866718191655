import { useEffect, useRef, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "react-toastify";
// import { makeStyles } from "@mui/styles";
import millify from "millify";
import * as Unicons from "@iconscout/react-unicons";
import dayjs from "dayjs";
import Avatar from "./common/avatar";
import CustomModalTitle from "./common/customModalTitle";
import OuterDiv from "./common/outerDiv";
import CustomTable from "./common/table";
import {
  getSalesteamOverview,
  updateSalesperson,
} from "../services/salespersonApi";
import CustomLinearProgress from "./common/customLinearprogress";
import noSalesperson from "../assets/noSalesperson.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import getCurrency from "../utils/getCurrency";
import EmptyFallBack from "./common/emptyFallback";
import ConfirmationModal from "./common/confirmationModal";
import CustomInput from "./common/customInput";

const columns = [
  // { key: "rank", name: "Rank", minWidth: 50, width: 50 },
  { key: "name", name: "Name", minWidth: 180 },
  { key: "totalSale", name: "Total Sale", minWidth: 80, width: 80 },
  { key: "target", name: "Progress", minWidth: 100, width: 150 },
  { key: "chevron", name: "", minWidth: 50, width: 50 },
];

const TopSalespersons = ({ date }) => {
  const targetRef = useRef(null);

  const [targetdate, setTargetDate] = useState(new Date());
  const [topSalespersonsList, setTopSalespersonsList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modifiedData, setModifiedData] = useState([]);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const getData = async () => {
    setTableLoading(true);

    const data = await getSalesteamOverview(
      parseInt(date.startDate / 1000, 10),
      parseInt(date.endDate / 1000, 10)
    );

    setTopSalespersonsList(data?.data || []);
    setTableLoading(false);
  };

  useEffect(() => {
    getData();
  }, [date]);

  const updateSalespersonDetails = async (details) => {
    const resp = await updateSalesperson(details);

    if (resp) {
      setTopSalespersonsList((o) =>
        o.map((el) => (el.id === details.id ? { ...el, ...details } : el))
      );
      toast.success("Salesperson status updated successfully");
    } else {
    }
  };

  useEffect(() => {
    const temp = topSalespersonsList?.map((el) => {
      console.log(el.status);
      return {
        ...el,
        name: (
          <div className="font-bold text-neutralText1 flex flex-row items-center ">
            <Avatar
              image={el?.profileImage}
              className="mr-2"
              imageClassName={"w-12 h-12"}
            />
            <span className="truncate">{el?.name}</span>
          </div>
        ),
        chevron: (
          <Unicons.UilAngleRightB className="text-neutralText1" size="20px" />
        ),
        totalSale: (
          <div className="text-neutralText1 font-normal truncate">
            {getCurrency()}
            {millify(el.sale || 0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </div>
        ),
        target:
          el?.targets?.length === 0 ? (
            <>
              <ConfirmationModal
                title="Add Target for Salesperson"
                subtitle={`Enter target for the month of ${dayjs(
                  targetdate
                ).format("MMMM")}`}
                positiveLabel="Set Target  "
                negativeLabel="Cancel"
                onPositiveClick={async () => {
                  await updateSalespersonDetails({
                    id: el.id,
                    target: {
                      date: parseInt(targetdate / 1000, 10),
                      target: Number(targetRef.current.value),
                    },
                  });
                  setOpenFormModal(false);
                  getData();
                  targetRef.current.value = "";
                }}
                positiveType="bg-primary text-black hover:bg-primaryLight"
                boxStyle={{ p: 0 }}
                topContainerStyle={"px-[32px] pt-[32px]"}
                bottomContainerStyle={
                  "px-[32px] pb-[32px] bg-neutralBg3 rounded-b mt-2"
                }
                button={(onClick) => (
                  <button
                    className="bg-transparent border border-infoText5 rounded px-2 py-1 text-sm text-infoText4 hover:bg-infoText5 hover:text-neutralBg1"
                    onClick={(e) => {
                      e.preventDefault();
                      onClick();
                    }}
                  >
                    <div className="flex items-center">
                      <Unicons.UilPlus size={16} />
                      <div>Add Target </div>
                    </div>
                  </button>
                )}
                midContent={
                  <CustomInput
                    allowNumOnly={true}
                    inputRef={targetRef}
                    containerClasses="py-2"
                    label="Enter target"
                    name="target"
                    placeholder="e.g., $67898"
                    type="number"
                  />
                }
              />
            </>
          ) : (
            <div className="flex flex-row w-full">
              <CustomLinearProgress
                value={
                  el?.targetsAchieved?.achieved > el?.targetsAchieved?.target
                    ? 100
                    : (el?.targetsAchieved?.achieved /
                        el?.targetsAchieved?.target) *
                      100
                }
                hideValue={true}
              />
              <div className="text-xs absolute flex w-10/12 justify-center -mt-[2px]">
                {`${millify(el?.targetsAchieved?.achieved || 0)} / ${millify(
                  el?.targetsAchieved?.target || 0,
                  {
                    precision: 2,
                    decimalSeparator: ".",
                  }
                )}`}
              </div>
              <ConfirmationModal
                title={`Enter target for the month of ${dayjs(
                  targetdate
                ).format("MMMM")}`}
                positiveLabel="Change Target"
                negativeLabel="Cancel"
                onPositiveClick={async () => {
                  await updateSalespersonDetails({
                    id: el.id,
                    target: {
                      date: parseInt(targetdate / 1000, 10),
                      target: Number(targetRef.current.value),
                    },
                  });
                  getData();
                  targetRef.current.value = "";
                }}
                positiveType="bg-primary text-black hover:bg-primaryLight"
                boxStyle={{ p: 0 }}
                topContainerStyle={"px-[32px] pt-[32px]"}
                bottomContainerStyle={
                  "px-[32px] pb-[32px] bg-neutralBg3 rounded-b mt-2"
                }
                button={(onClick) => (
                  <FiEdit2
                    className="text-infoText4 stroke-3 cursor-pointer ml-1"
                    size={12}
                    onClick={(e) => {
                      e.preventDefault();
                      onClick();
                    }}
                  />
                )}
                midContent={
                  <CustomInput
                    allowNumOnly={true}
                    inputRef={targetRef}
                    containerClasses="py-2"
                    label="Enter target"
                    name="target"
                    placeholder="e.g., $67898"
                    type="number"
                  />
                }
              />
            </div>
          ),
        // rank: (
        //   <div className="flex flex-row items-center font-bold text-neutralText1">
        //     {Math.abs(el.score || 0)}{" "}
        //     {el?.score < 0 ? (
        //       <Unicons.UilAngleDown size="20px" className="text-errorText5" />
        //     ) : (
        //       <Unicons.UilAngleUp size="20px" className="text-successText5" />
        //     )}
        //   </div>
        // ),
      };
    });
    setModifiedData(temp);
  }, [topSalespersonsList]);

  return (
    <OuterDiv>
      <div className="p-6 h-[341px]">
        <div className="flex justify-between text-neutralText1">
          <CustomModalTitle
            title="Your Salesteam Overview"
            description={
              <>
                {/* Showing Data for the month of{" "}
                <span className="text-infoText4">february</span> */}
              </>
            }
          />
          <Link to="/salespersons" target="_blank">
            <Unicons.UilExternalLinkAlt size={22} />
          </Link>
        </div>
        <div className="pt-4">
          <CustomTable
            columns={columns}
            rows={modifiedData}
            emptyFallBack={
              <EmptyFallBack
                image={noSalesperson}
                text="No Salespersons Found"
                containerClass={"mt-6 h-1/2 w-2/12 m-auto"}
              />
            }
            bodyRowProps={{
              path: "/view-salesperson",
            }}
            loading={tableLoading}
          />
        </div>
      </div>
    </OuterDiv>
  );
};
export default TopSalespersons;
