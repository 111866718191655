import * as React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { startCase } from "lodash";

import {
  cancelOrCompleteOrder,
  getOrderDetails,
} from "../../services/ordersApi";
import OuterDiv from "../../components/common/outerDiv";
import OrderDetailsHeader from "../../components/common/orderDetailsHeader";
import ProcessState from "../../components/common/processState";
import BaseButton from "../../components/common/baseButton";
import CustomModal from "../../components/common/customModal";
import CustomModalTitle from "../../components/common/customModalTitle";
import ConfirmationModal from "../../components/common/confirmationModal";
import CustomBreadcrumbs from "../../components/common/customBreadcrumbs";
import ProductList from "../../components/common/productList";
import AssignVisitModal from "../../components/assignVisitModal";
import TransactionsForm from "../../components/forms/transactionsForm";
import CompleteOrderForm from "../../components/forms/completeOrderForm";
import FullscreenLoader from "../../components/common/fullscreenLoader";
import getCurrency from "../../utils/getCurrency";
import millify from "millify";

const OrderDetails = ({ onClose }) => {
  const history = useHistory();

  const { id } = useParams();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);
  const [openVisitModal, setOpenVisitModal] = useState(false);
  const [openTransactionFormModal, setOpenTransactionFormModal] =
    useState(false);

  console.log("id", id, useParams());

  const getData = async () => {
    setLoading(true);
    const data = id ? await getOrderDetails(id) : {};
    setOrder(data);
    setLoading(false);
  };

  const cancelOrder = async (orderId, vendorId) => {
    const details = {
      orderId: orderId,
      vendorId: vendorId,
      date: parseInt(new Date() / 1000, 10),
      type: "CANCELLED",
    };

    return await cancelOrCompleteOrder(details);
  };

  useEffect(() => {
    console.log("currentUser 123", id);
    getData();
  }, [id]);

  useEffect(() => {
    console.log("currentUser 123", id);
  }, []);

  console.log("currentUser,", order);

  return (
    <>
      <div className="flex flex-row">
        {loading ? (
          <div className="m-auto h-screen flex items-center">
            <FullscreenLoader className="!text-neutralText3" />
          </div>
        ) : (
          <div className="w-full grid grid-cols-2 gap-x-2 lg:w-6/12 py-12 px-6">
            <div className="col-span-2">
              <CustomBreadcrumbs
                links={[
                  { title: "Home", url: "/dashboard" },
                  { title: "Orders", url: "/orders" },
                  { title: "View Order", url: `/view-order/${id}` },
                ]}
              />
              <div className="flex flex-row items-center -mb-4">
                <span
                  onClick={() => history.goBack()}
                  className=" cursor-pointer py-1 z-10"
                >
                  <FiArrowLeft
                    className="text-neutralText1 stroke-3  mr-2"
                    // size={12}
                  />
                </span>
                <div className="text-neutralText1 text-lg font-black">
                  View Order
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <OuterDiv>
                <OrderDetailsHeader
                  order={order || {}}
                  disableOuterLink
                  handleClose={() => {
                    getData();
                    setOpenTransactionFormModal(false);
                  }}
                />
                <div className="mx-4 border-y border-y-neutralBg4">
                  <ProcessState
                    processState={order?.status}
                    processDate={order}
                  />
                </div>{" "}
                {order?.status === "COMPLETED" ? (
                  <div className="flex justify-between items-center py-[9px] px-4">
                    <div>
                      <div className="text-neutralText4">Sale Value</div>
                      <div className="text-2xl font-extrabold text-successText5">
                        {getCurrency()}{" "}
                        {millify(order?.transaction?.amount || 0, {
                          precision: 2,
                          decimalSeparator: ".",
                        })}
                      </div>
                    </div>
                    <CustomModal
                      title={
                        <CustomModalTitle
                          title="Edit transaction"
                          description="Edit transaction below"
                        />
                      }
                      boxStyle={{ p: 0, width: 650 }}
                      topContainerStyle={"px-[32px] pt-[32px]"}
                      bottomContainerStyle={
                        "px-[32px] pb-[32px] pt-[25px] bg-neutralBg2 rounded-b mt-2"
                      }
                      button={<BaseButton text="Edit" />}
                    >
                      <TransactionsForm
                        transaction={{
                          ...order?.transaction,
                          vendor: order?.vendor,
                        }}
                        handleClose={() => {
                          getData();
                          onClose();
                          setOpenTransactionFormModal(false);
                        }}
                      />
                    </CustomModal>
                  </div>
                ) : order?.status === "CANCELLED" ? (
                  <div className="bg-errorText5 text-neutralBg1 py-3 px-2 text-center">
                    Cancelled
                  </div>
                ) : (
                  <div className="flex px-4 py-6">
                    <CustomModal
                      title={
                        <CustomModalTitle
                          title="Complete Order"
                          description="Complete Order below "
                        />
                      }
                      boxStyle={{ p: 0, width: 650 }}
                      topContainerStyle={"px-[32px] pt-[32px]"}
                      bottomContainerStyle={
                        "px-[32px] pb-[32px] pt-[25px] bg-neutralBg2 rounded-b mt-2"
                      }
                      button={
                        <BaseButton
                          text="Complete Order"
                          className="bg-successText5 text-neutralBg1 text-xs px-4 py-1 font-light "
                          textColor={"neutralBg1"}
                        />
                      }
                    >
                      <CompleteOrderForm
                        orderDetails={order}
                        handleClose={() => {
                          getData();
                        }}
                      />
                    </CustomModal>
                    <ConfirmationModal
                      title="Are your sure?"
                      subtitle="Are you sure you want to cancel this order."
                      positiveLabel="Yes, Cancel order"
                      negativeLabel="No"
                      onPositiveClick={async () => {
                        await cancelOrder(order.id, order.vendor.id);
                        getData();
                      }}
                      button={(onClick) => (
                        <button
                          onClick={onClick}
                          className="text-errorText5 mx-4 text-sm"
                        >
                          Cancel Order
                        </button>
                      )}
                    />
                  </div>
                )}
              </OuterDiv>
            </div>
            <div className="col-span-1 h-full ">
              <OuterDiv>
                <div className="bg-neutralBg1 p-4">
                  <div className="flex justify-between items-center mb-2">
                    <div className="text-neutralText1 font-bold text-sm">
                      Visits
                    </div>
                    {["COMPLETED", "CANCELLED"].includes(order?.status) ||
                    order?.visits?.find(
                      (element) => element.status === "ACTIVE"
                    ) ? null : (
                      <AssignVisitModal
                        order={order}
                        openModal={openVisitModal}
                        setOpenModal={setOpenVisitModal}
                        onClose={() => getData()}
                      />
                    )}
                  </div>
                  {order?.visits?.length > 0 ? (
                    order?.visits?.map((el, index) => {
                      return (
                        <div
                          key={index}
                          className="flex justify-between font-light text-xs text-neutralText2 py-1"
                        >
                          <p>
                            {/* el.date */}
                            {dayjs(el?.updatedAt * 1000).format("DD-MM-YYYY")}
                          </p>
                          <p
                            className={`px-2 py-0.5 rounded-full ${
                              el?.status === "ACTIVE"
                                ? "bg-infoBg2 text-infoText3"
                                : el?.status === "COMPLETED"
                                ? "bg-successBg2 text-successText3"
                                : el?.status === "CANCELLED"
                                ? "bg-errorBg2 text-errorText3"
                                : el?.status === "TERMINATED"
                                ? "bg-errorBg2 text-errorText3"
                                : ""
                            }`}
                          >
                            {startCase(el?.status?.toLowerCase())}
                          </p>
                          <p
                            className={`cursor-pointer ${
                              el?.status === "ACTIVE"
                                ? "text-errorText3"
                                : "text-infoText3"
                            }`}
                          >
                            {el?.status === "ACTIVE" ? "Cancel" : ""}
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <p className="font-light text-neutralText1 text-xs text-center">
                      No visits available
                    </p>
                  )}
                </div>
              </OuterDiv>
            </div>{" "}
            <ProductList
              listClassName="border border-neutralBg3"
              order={order}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default OrderDetails;
