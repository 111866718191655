import * as Unicons from "@iconscout/react-unicons";

const CompanyCards = (props) => {
  return (
    <div className="items-center bg-neutralBg1 pb-10 px-6 hover:shadow-xl mb-6 text-center">
      <div className="-mt-10 rounded-full w-fit m-auto shadow-xl p-6 text-secondary bg-neutralBg1">
        <Unicons.UilBringBottom size={28} />
      </div>
      <div className="">
        <div className="text-2xl pt-8 text-transparent bg-clip-text font-black bg-gradient-radial-sm-text from-successBg5 to-neutralText1">
          {props.header}
        </div>
        <div className="text-neutralText5 py-4 text-lg font-semibold">
          {props.body}
        </div>
      </div>
    </div>
  );
};
export default CompanyCards;
