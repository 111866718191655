import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import { FiEdit2, FiHelpCircle, FiSliders, FiX } from "react-icons/fi";
import dayjs from "dayjs";
import CustomTable from "../../components/common/table";
import CustomTitle from "../../components/common/customTitle";
import Filters from "../../components/common/filters";
import Avatar from "../../components/common/avatar";
import FullscreenLoader from "../../components/common/fullscreenLoader";
import StatusPill from "../../components/common/statusPill";
import CustomLinearProgress from "../../components/common/customLinearprogress";
import OuterDiv from "../../components/common/outerDiv";
import ConfirmationModal from "../../components/common/confirmationModal";
import CustomModal from "../../components/common/customModal";
import CustomModalTitle from "../../components/common/customModalTitle";
import CustomInput from "../../components/common/customInput";
import BaseButton from "../../components/common/baseButton";
import SalesPersonForm from "../../components/forms/salesPersonForm";
import {
  getSalesPersons,
  getSalespersonsStats,
  updateSalesperson,
} from "../../services/salespersonApi";
import { BannerType, SPStatus } from "../../constants/common";
import StatsCard from "../../components/common/statsCard";
import noSalesperson from "../../assets/noSalesperson.svg";
import EmptyFallBack from "../../components/common/emptyFallback";
import millify from "millify";
import getCurrency from "../../utils/getCurrency";
import { useSelector } from "react-redux";
import ActionBanner from "../../components/common/cards/actionBanner";
import { Formik } from "formik";
import ValidationErrors from "../../components/common/validationErrors";
import { addSalespersonIdSchema } from "../../components/validationSchema";
import EditBalanceForm from "../../components/forms/editBalanceForm";
import AddOrEditTarget from "../../components/forms/addOrEditTarget";
import { sumBy } from "lodash";

const columns = [
  // { key: "rank", name: "Rank", minWidth: 50, width: 50 },
  { key: "salespersonId", name: "ID", minWidth: 80, width: 120 },
  { key: "name", name: "Name", minWidth: 200 },
  { key: "vendorCount", name: "Retailers", minWidth: 80, width: 80 },
  { key: "contact", name: "Number", minWidth: 80, width: 120 },
  { key: "totalSale", name: "Total Sale", minWidth: 80, width: 80 },
  { key: "status", name: "Status", minWidth: 120, width: 120 },
  { key: "target", name: "Progress", minWidth: 100, width: 150 },
  { key: "chevron", name: "", minWidth: 50, width: 50 },
];

const Salespersons = () => {
  const dataGridRef = React.useRef(null);
  const [targetdate, _] = useState(new Date());
  const history = useHistory();
  const targetRef = useRef(null);
  const currentUser = useSelector((state) => state.getProfileDetails);

  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  // const [bulkActionDisabled, setBulkActionDisabled] = useState(true);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [changeSalesperson, setChangeSalesperson] = useState("yes");
  const [salespersons, setSalespersons] = useState([]);
  const [salespersonsStats, setSalespersonsStats] = useState();
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeFilters, setActiveFilters] = useState([]);
  const [query, setQuery] = useState("");
  const [modifiedData, setModifiedData] = useState([]);
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [openTargetModal, setOpenTargetModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSetSpIdModal, setOpenSetSpIdModal] = useState(false);
  const [selectedSP, setSelectedSP] = useState("");

  const filters = [
    {
      filterKey: "status",
      filterLabel: "Status",
      selectedKey: activeFilters?.filter((el) => {
        if (el?.filterKey === "status") return el?.filterValue;
      }),
      filterIcon: <Unicons.UilQuestionCircle />,
      filterValues: [
        {
          key: "ACTIVE",
          value: "Active",
        },
        {
          key: "INACTIVE",
          value: "Inactive",
        },
      ],
    },
    {
      filterKey: "assignmentStatus",
      filterLabel: "Assignment",
      selectedKey: activeFilters?.filter((el) => {
        if (el?.filterKey === "assignmentStatus") return el?.filterValue;
      }),
      filterIcon: <Unicons.UilUsersAlt size={24} />,
      filterValues: [
        {
          key: "ASSIGNED",
          value: "Assigned",
        },
        {
          key: "UNASSIGNED",
          value: "Unassigned",
        },
      ],
    },
  ];

  const tooltipText = {
    ActiveSalespersons: "Total active Salespersons that can be assigned tasks.",
    TargetsAchieved: "Monthly sales targets completed this month.",
    Visits: "Total completed visits.",
  };

  const getData = async ({ refresh = true, page, rowsPerPage, ...filters }) => {
    setTableLoading(refresh);
    setOffset(page - 1);

    const data = await getSalespersonsStats(parseInt(date / 1000, 10));
    const resp = await getSalesPersons({
      offset: page,
      limit: rowsPerPage,
      query,
      ...filters,
    });
    setSalespersonsStats(data);
    setSalespersons(resp?.result || []);
    setCount(resp?.count);
    setTableLoading(false);
  };

  const applyFilters = ({ refresh = true, page }) => {
    const filterBuilder = {};

    if (activeFilters?.length > 0) {
      for (const item of activeFilters) {
        filterBuilder[item?.filterKey] = item?.filterValue;
      }
      console.log("here are filter", filterBuilder);
    }
    getData({ refresh, page, rowsPerPage, ...filterBuilder });
  };

  const updateSalespersonDetails = async (details) => {
    const resp = await updateSalesperson(details);

    if (resp) {
      setSalespersons((o) =>
        o.map((el) => (el.id === details.id ? { ...el, ...details } : el))
      );
      toast.success("Salesperson status updated successfully");
    } else {
    }
  };

  const clearFilters = () => {
    setQuery("");
    setActiveFilters([]);
  };

  useEffect(() => {
    if (history.location.state?.openModal) {
      setTimeout(() => {
        setOpenModal(true);
      }, 500);
    }
    history.replace("/salespersons");
  }, []);

  useEffect(() => {
    applyFilters({ page: 1 });
  }, [activeFilters, rowsPerPage, query]);

  useEffect(() => {
    const temp = salespersons.map((el) => {
      return {
        ...el,

        status: el?.salespersonId ? (
          <ConfirmationModal
            salesperson={el}
            title={
              currentUser.spLimit <= salespersonsStats?.activeSalesPerson &&
              el?.status === "INACTIVE" ? (
                <div className="flex items-center text-warningText4">
                  <Unicons.UilExclamationTriangle className="mr-1 text-warningText4 w-[18px] min-w-[18px] min-h-[18px]" />
                  Salesperson limit reached!
                </div>
              ) : (
                "Are your sure?"
              )
            }
            setChangeSalespersonCondition={setChangeSalesperson}
            changeSalespersonCondition={changeSalesperson}
            subtitle={
              currentUser.spLimit <= salespersonsStats?.activeSalesPerson &&
              el?.status === "INACTIVE" ? (
                "Please upgrade your plan to activate this salesperson."
              ) : (
                <>
                  <div>
                    Are you sure you want to{" "}
                    {el.status === SPStatus.ACTIVE ? "deactivate" : "activate"}{" "}
                    the salesperson.{" "}
                    {el.status === SPStatus.ACTIVE
                      ? "They will not be able to login to app"
                      : ""}
                  </div>
                </>
              )
            }
            handleCloseModal={() => {
              setOpenModal(false);
              setOpenFormModal(false);
              setTimeout(() => {
                applyFilters({ refresh: false, page: offset + 1 });
              }, 1000);
            }}
            positiveLabel={
              currentUser.spLimit <= salespersonsStats?.activeSalesPerson &&
              el?.status === "INACTIVE"
                ? "Upgrade Plan"
                : `Yes ${
                    el.status === SPStatus.ACTIVE ? "Deactivate" : "Activate"
                  }`
            }
            positiveType={
              el.status === SPStatus.INACTIVE
                ? "bg-successText4 hover:bg-successText5"
                : null
            }
            negativeLabel="Cancel"
            onPositiveClick={async () => {
              currentUser.spLimit <= salespersonsStats?.activeSalesPerson &&
              el?.status === "INACTIVE"
                ? history.push("/buy-plan")
                : await updateSalespersonDetails({
                    id: el.id,
                    status:
                      el.status === SPStatus.INACTIVE
                        ? SPStatus.ACTIVE
                        : SPStatus.INACTIVE,
                  });
              setTableLoading(true);
              setTimeout(() => {
                applyFilters({ refresh: false, page: offset + 1 });
              }, 1000);
            }}
            button={(onClick) => (
              <StatusPill
                status={el.status}
                // className="w-[88]"
                onClick={onClick}
              />
            )}
          />
        ) : (
          <>
            <CustomModal
              title={
                <CustomModalTitle
                  title="Activate Salesperson"
                  description="Salesperson uses unique id to login with. Activate this salesperson now to send login id through email."
                />
              }
              openModal={openActivateModal}
              // setOpenModal={setOpenActivateModal}
              button={<StatusPill status={el.status} />}
            >
              <div className="">
                <Formik
                  initialValues={{
                    spId: "",
                  }}
                  validationSchema={addSalespersonIdSchema}
                  onSubmit={async (values) => {
                    setLoading(true);

                    const resp = await updateSalesperson({
                      id: el.id,
                      salespersonId: values.spId,
                      status: "ACTIVE",
                    });

                    if (resp === "Updated Successfully") {
                      toast.success("Salesperson Activated");
                      setTimeout(() => {
                        applyFilters({ refresh: false, page: offset + 1 });
                      }, 1000);
                      // handleCloseModal();
                    }
                    setLoading(false);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="pb-4 text-xs text-neutralText4">
                        <CustomInput
                          containerClasses="py-2"
                          label="Create Salesperson ID"
                          placeholder="JOHNDOE123"
                          type="text"
                          name="spId"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.spId}
                        />
                        {touched.spId && errors.spId && (
                          <ValidationErrors errors={`${errors.spId}`} />
                        )}
                        <ActionBanner
                          type={BannerType.Info}
                          text={
                            <i>
                              When you activate a salesperson, this ID and a
                              password will be sent to the salesperson's email
                              id.
                            </i>
                          }
                          containerClassName="mt-2"
                        />
                      </div>
                      <div className="flex justify-end pt-2">
                        <BaseButton
                          text={"Activate"}
                          width={"w-36"}
                          onClick={() => handleSubmit}
                          loading={loading}
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </CustomModal>
          </>
        ),
        userName: (
          <span className="font-bold text-neutralText3 truncate">
            {el.userName}
          </span>
        ),
        name: (
          <div className="font-bold text-neutralText1 flex flex-row items-center">
            <Avatar
              image={el?.profileImage}
              className="mr-2"
              imageClassName={"w-12 h-12"}
            />
            <span className="truncate">{el.name}</span>
          </div>
        ),
        chevron: (
          <Unicons.UilAngleRightB className="text-neutralText1" size="20px" />
        ),
        totalSale: (
          <div className="truncate">
            {getCurrency()}{" "}
            {millify(el.sale || 0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </div>
        ),
        target:
          el?.targets?.length === 0 ? (
            <>
              <ConfirmationModal
                title="Add Target for Salesperson"
                subtitle={`Enter target for the month of ${dayjs(
                  targetdate
                ).format("MMMM")}`}
                positiveLabel="Set Target "
                negativeLabel="Cancel"
                onPositiveClick={async () => {
                  if (+targetRef.current.value === 0) {
                    toast.error("Target cannot be 0");
                    return;
                  }

                  await updateSalespersonDetails({
                    id: el.id,
                    target: {
                      date: parseInt(targetdate / 1000, 10),
                      target: Number(targetRef.current.value),
                    },
                  });
                  setOpenFormModal(false);
                  setTimeout(() => {
                    applyFilters({ refresh: false, page: offset + 1 });
                    // getData({});
                  }, 1000);
                  targetRef.current.value = "";
                }}
                positiveType="bg-primary text-black hover:bg-primaryLight"
                boxStyle={{ p: 0 }}
                topContainerStyle={"px-[32px] pt-[32px]"}
                bottomContainerStyle={
                  "px-[32px] pb-[32px] bg-neutralBg3 rounded-b mt-2"
                }
                button={(onClick) => (
                  <button
                    className="bg-transparent border border-infoText5 rounded px-2 py-1 text-sm text-infoText4 hover:bg-infoText5 hover:text-neutralBg1"
                    onClick={(e) => {
                      e.preventDefault();
                      onClick();
                    }}
                  >
                    <div className="flex items-center">
                      <Unicons.UilPlus size={16} />
                      <div>Add Target </div>
                    </div>
                  </button>
                )}
                midContent={
                  <CustomInput
                    inputRef={targetRef}
                    containerClasses="py-2"
                    label="Enter target"
                    name="target"
                    placeholder="e.g., 10000"
                    type="number"
                    allowNumOnly
                  />
                }
              />
            </>
          ) : (
            <div className="flex flex-row w-full">
              <CustomLinearProgress
                value={
                  el?.targetsAchieved?.achieved > el?.targetsAchieved?.target
                    ? 100
                    : (el?.targetsAchieved?.achieved /
                        el?.targetsAchieved?.target) *
                      100
                }
                hideValue={true}
              />
              <div className="text-xs absolute flex w-10/12 justify-center -mt-[2px]">
                {`${millify(el?.targetsAchieved?.achieved || 0)} / ${millify(
                  el?.targetsAchieved?.target || 0,
                  {
                    precision: 2,
                    decimalSeparator: ".",
                  }
                )}`}
              </div>
              <ConfirmationModal
                title="Edit Target for Salesperson"
                subtitle={`Enter target for the month of ${dayjs(
                  targetdate
                ).format("MMMM")}`}
                positiveLabel="Change Target "
                negativeLabel="Cancel"
                onPositiveClick={async () => {
                  if (+targetRef.current.value === 0) {
                    toast.error("Target cannot be 0");
                    return;
                  }
                  await updateSalespersonDetails({
                    id: el.id,
                    target: {
                      date: parseInt(targetdate / 1000, 10),
                      target: Number(targetRef.current.value),
                    },
                  });
                  setTimeout(() => {
                    getData({});
                  }, 1000);

                  targetRef.current.value = "";
                }}
                positiveType="bg-primary text-black hover:bg-primaryLight"
                boxStyle={{ p: 0 }}
                topContainerStyle={"px-[32px] pt-[32px]"}
                bottomContainerStyle={
                  "px-[32px] pb-[32px] bg-neutralBg3 rounded-b mt-2"
                }
                button={(onClick) => (
                  <FiEdit2
                    className="text-infoText4 stroke-3 cursor-pointer ml-1"
                    size={12}
                    onClick={(e) => {
                      e.preventDefault();
                      onClick();
                    }}
                  />
                )}
                midContent={
                  <CustomInput
                    inputRef={targetRef}
                    containerClasses="py-2"
                    label="Enter target"
                    name="target"
                    placeholder="e.g., 1000"
                    type="number"
                    allowNumOnly
                  />
                }
              />
            </div>
          ),
      };
    });
    setModifiedData(temp);
  }, [salespersons]);

  console.log("openActivateModal", openActivateModal);

  return (
    <>
      {/* <TopBar value="Salesperson" /> */}
      <div className="flex flex-row">
        {/* <SideNav /> */}
        {loading ? (
          <FullscreenLoader className="!text-neutralText3" />
        ) : (
          <div className="w-full pb-12 grid grid-cols-4 pr-12 pl-6">
            <CustomModal
              title={
                <CustomModalTitle
                  title="Opening Balance"
                  description="Enter amount to set opening balance"
                />
              }
              boxStyle={{ p: 0, width: 650 }}
              topContainerStyle={"px-[32px] pt-[32px]"}
              bottomContainerStyle={
                "px-[32px] pb-[32px] pt-[25px] bg-neutralBg2 rounded-b mt-2"
              }
              openModal={openTargetModal}
              setOpenModal={setOpenTargetModal}
            >
              <AddOrEditTarget
                // id={vendorDetails?.openingBalanceId}
                id={selectedSP?.id}
                target={selectedSP?.targetsAchieved?.target}
                handleClose={() => {
                  setOpenTargetModal(false);
                  setTimeout(() => {
                    applyFilters({ refresh: false, page: offset + 1 });
                  }, 1000);
                }}
              />
            </CustomModal>
            {/* <div className="grid grid-cols-4 px-6"> */}
            <div className="col-span-4">
              <div className="flex justify-between items-center pt-12">
                <CustomTitle
                  title="Salespersons"
                  description="Organise and Manage Sales Team"
                />
                <div className="flex">
                  <div className="mr-2">
                    <Link to={"/salesperson/bulk-import"}>
                      <BaseButton
                        text={"Import CSV"}
                        outline
                        type={
                          "border-successText5 hover:bg-successText5 hover:text-white"
                        }
                        textColor="successText5"
                        width={"w-36"}
                      />
                    </Link>
                  </div>

                  <CustomModal
                    title={
                      <CustomModalTitle
                        title="Add Salesperson"
                        description="Enter salesperson details"
                      />
                    }
                    boxStyle={{ p: 0, width: 550 }}
                    topContainerStyle={"px-[32px] pt-[32px]"}
                    bottomContainerStyle={
                      "px-[32px] pb-[32px] bg-neutralBg3 rounded-b mt-2"
                    }
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    button={
                      <BaseButton
                        text={"Add New Salespersons"}

                        // onClick={onPositiveClick}
                      />
                    }
                  >
                    <SalesPersonForm
                      handleCloseModal={() => {
                        setOpenModal(false);
                        setOpenFormModal(false);
                        setTimeout(() => {
                          applyFilters({ refresh: false, page: offset + 1 });
                        }, 1000);
                      }}
                    />
                  </CustomModal>
                </div>
              </div>
              <div className="grid grid-cols-5 gap-x-4 w-11/12">
                <StatsCard
                  text="Active Salespersons"
                  icon={<FiHelpCircle />}
                  tooltip={tooltipText.ActiveSalespersons}
                >
                  {salespersonsStats?.activeSalesPerson}
                </StatsCard>
                <StatsCard
                  text="Targets Achieved"
                  icon={<FiHelpCircle />}
                  tooltip={tooltipText.TargetsAchieved}
                >
                  <div>
                    {salespersonsStats?.targets?.completed || 0}
                    <span className="text-base font-light">
                      /
                      {salespersonsStats?.targets?.completed +
                        salespersonsStats?.targets?.notCompleted || 0}
                    </span>
                  </div>
                </StatsCard>
                <StatsCard
                  className="col-span-2"
                  text="Top Salesperson this Month"
                >
                  {salespersonsStats?.topSalesPerson && (
                    <div className="flex py-4">
                      <Avatar
                        image={salespersonsStats?.topSalesPerson?.profileImage}
                        imageClassName={"w-16 h-16"}
                      />
                      <div className="px-4 font-semibold text-lg">
                        <div>{salespersonsStats?.topSalesPerson?.name}</div>
                        <div className="text-sm font-normal text-neutralText2">
                          Sale: {getCurrency()}{" "}
                          {millify(
                            salespersonsStats?.topSalesPerson?.sales || 0,
                            {
                              precision: 2,
                              decimalSeparator: ".",
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </StatsCard>
                <StatsCard
                  text="Visits"
                  icon={<FiHelpCircle />}
                  stat={11}
                  tooltip={tooltipText.Visits}
                >
                  {/* {salespersonsStats?.completedVisits.length || 0}{" "} */}
                  {sumBy(salespersonsStats?.completedVisits, "count")}
                </StatsCard>
              </div>
              {/* <div className="w-full lg:w-10/12 pb-12"> */}
              {/* <div className="grid  px-4 sm:px-12 grid-cols-4 "> */}
              {/* <div className="col-span-4 lg:col-span-3">
                <div className="flex flex-col sm:flex-row  w-full sm:w-11/12 md:w-9/12 lg:w-11/12 xl:w-10/12  text-sm py-6 justify-between">
                  <Cards
                    value={{
                      stats: stats?.assignedVisits,
                      label: "Assigned Visits",
                    }}
                  />
                  <Cards
                    value={{
                      stats: stats?.pendingVisits,
                      label: "Pending Visits",
                      style: "sm:mx-6",
                    }}
                  />
                  <Cards
                    value={{
                      stats: stats?.totalSalespersons,
                      label: "Total Salespersons",
                    }}
                  />
                </div>
              </div>
              <div className="text-xs col-span-4  px-2 sm:px-6 sm:w-11/12 flex flex-col md:flex-row  justify-between">
                <div className="flex flex-row  py-3 md:py-6">
                  <select
                    id="status"
                    name="status"
                    form="status"
                    onChange={(e) => setStatus(e.target.value)}
                    className="outline-none border w-28 bg-white text-secondary-text-light border-secondary-text-light py-1 border-opacity-40 rounded-md"
                  >
                    <option value="">Select</option>
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </select>
                  <div className="px-4">
                    <button
                      onClick={() => applyBulkAction()}
                      disabled={spIds.length == 0 || !status}
                      className={`${
                        spIds.length == 0 || !status
                          ? "bg-gray-300"
                          : " bg-blue-500"
                      } text-white w-28 h-full rounded-md`}
                    >
                      Apply
                    </button>
                  </div>
                </div>
                <div className="flex flex-row py-3 md:py-6">
                  <div className="pr-4">
                    <input
                      className="text-secondary-text-light text-xs outline-none border w-28 border-secondary-text-light py-1 text-center border-opacity-40 rounded-md"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        handleChange(e.target.value, searchStatus);
                      }}
                    ></input>
                  </div>
                  <div className="">
                    <select
                      id="status"
                      name="status"
                      form="status"
                      onChange={(e) => {
                        setSearchStatus(e.target.value);
                        handleChange(searchText, e.target.value);
                      }}
                      className="outline-none border w-28 bg-white border-secondary-text-light text-secondary-text-light py-1 border-opacity-40 rounded-md"
                    >
                      <option value="">Status</option>
                      <option value="ACTIVE">Active</option>
                      <option value="INACTIVE">Inactive</option>
                    </select>
                  </div>
                </div>
              </div> */}
              <OuterDiv>
                <div className="flex flex-row justify-between bg-neutralBg1 items-center pt-8 px-6">
                  <div className="text-base w-1/4 text-neutralText1 font-medium">
                    List of Salespersons
                  </div>
                  <div className="flex items-center">
                    <div className="mr-2">
                      {activeFilters.length > 0 || query.length > 0 ? (
                        <button onClick={() => clearFilters()}>
                          <div className="flex items-center justify-center text-infoText4 text-xs">
                            <FiX size={14} />
                            Clear
                          </div>
                        </button>
                      ) : (
                        <FiSliders className=" text-neutralText1" size={18} />
                      )}
                    </div>
                  </div>

                  <Filters
                    filters={filters}
                    setFilters={setActiveFilters}
                    activeFilters={activeFilters}
                    enableSearch
                    searchValue={query}
                    onSearch={(e) => setQuery(e.target.value)}
                  />
                </div>
                <div
                  className="py-4 sm:px-6"
                  onClick={() => {
                    // Fix for `cellFocus (select) breaks input functionality`
                    dataGridRef.current.selectCell(
                      {
                        idx: 0,
                        rowIdx: -1,
                        mode: "SELECT",
                      },
                      true
                    );
                  }}
                >
                  <CustomTable
                    dataGridRef={dataGridRef}
                    columns={columns}
                    rows={modifiedData}
                    emptyFallBack={
                      <EmptyFallBack
                        image={noSalesperson}
                        text="No Salespersons found"
                        containerClass={"mt-14 mb-4"}
                        imageClass={"w-56"}
                      />
                    }
                    bodyRowProps={{
                      path: "/view-salesperson",
                    }}
                    count={count}
                    page={offset}
                    onPageChange={(_, page) => applyFilters({ page: page + 1 })}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
                    loading={tableLoading}
                  />
                  {/* {salespersons.length > 0 ? (
                    <div className="flex justify-center p-4">
                      <button
                        disabled={isLast}
                        className={`${
                          isLast ? "bg-gray-400" : "bg-blue-500"
                        } text-white text-xs rounded-md py-2 px-4`}
                        onClick={() => loadMore()}
                      >
                        view more
                      </button>
                    </div>
                  ) : null} */}
                </div>
              </OuterDiv>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Salespersons;
