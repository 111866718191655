import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiMapPin } from "react-icons/fi";
import AOS from "aos";
import * as Unicons from "@iconscout/react-unicons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "aos/dist/aos.css";

import LandingNav from "../../components/landingComponents/landingNav";
import Features from "../../components/landingComponents/features";
import LandingFooter from "../../components/landingComponents/landingFooter";
import LandingHeader from "../../components/landingComponents/landingHeader";
import Main from "../../assets/Main.webp";
import BaseButton from "../../components/common/baseButton";
import HowBusyBeeHelps from "../../components/landingComponents/howBusyBeeHelps";
import Help from "../../components/landingComponents/help";

const Landing = () => {
  const [swiperRef, setSwiperRef] = useState(null);

  let appendNumber = 4;
  let prependNumber = 1;

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div className="contain bg-backgroundLogoV bg-right-top bg-no-repeat">
      <LandingNav />
      <div className="m-auto mb-12 lg:pt-[4rem] w-11/12 xl:px-4 2xl:w-11/12 xl:px-[2rem] 2xl:px-[4rem] text-center lg:text-left justify-center flex-col-reverse lg:flex-row flex">
        <div className="lg:w-10/12 xl:w-8/12 2xl:w-5/12 lg:pt-6 xl:pt-12 m-auto lg:m-0 lg:mr-auto">
          <LandingHeader
            header="WELCOME TO BUSYBEE"
            body="Mobilising Sales Teams Organising Retailers"
          />
          <div className="lg:w-8/12 lg:w-10/12 xl:w-8/12 2xl:w-8/12 text-neutralText2 pt-4 pb-10 m-auto lg:m-0 lg:mr-auto">
            Your sales team knows your retailers best. Let them create and
            maintain their details for you.
          </div>
          <Link to={"/register"}>
            <BaseButton
              text={
                <div className="flex justify-between items-center text-xl text-semibold">
                  <div>Try for free</div>
                  <Unicons.UilArrowRight size={24} className="ml-2" />
                </div>
              }
              type="h-[52px] bg-primary  hover:bg-primaryLight"
              width=""
            />
          </Link>
          {/* <div className="py-4 text-lg text-infoText4">
            Request Product tour
          </div> */}
        </div>
        <div
          className="sm:w-10/12 py-8 lg:py-0 lg:w-9/12 xl:w-8/12 2xl:w-7/12 m-auto lg:m-0 lg:mr-auto
        "
        >
          <img
            src={Main}
            alt="vid"
            className="object-cover xl:max-w-[750px]"
          ></img>
        </div>
      </div>{" "}
      <div className="pt-[4rem] lg:pt-0 xl:pt-6 2xl:pt-12 pb-[4rem] lg:pb-[10rem] xl:pb-[10rem] 2xl:pb-[16rem] text-center lg:bg-backgroundBuildings bg-[center_top_8rem] bg-cover lg:bg-contain bg-no-repeat">
        <div className="lg:absolute w-full -mt-20 lg:mt-28 flex justify-between bg-backgroundBuildings lg:bg-none bg-[center_top_8rem] bg-right-bottom sm:bg-left-bottom md:bg-bottom bg-cover bg-no-repeat">
          <div className="flex md:w-1/3 sm:ml-6 lg:ml-0 justify-around">
            <div
              data-aos={"fade-up"}
              data-aos-delay="1000"
              className="xl:mr-6 text-center w-fit text-secondary"
            >
              <div className="text-neutralText2 pb-2 font-bold w-10/12 lg:w-8/12 m-auto">
                New Retailer Added
              </div>
              <FiMapPin className="m-auto" size={45} />
            </div>
            <div
              data-aos={"fade-up"}
              data-aos-delay="2000"
              className="xl:mr-5 mt-8 hidden md:block text-center w-fit text-secondary"
            >
              <div className="text-neutralText2 pb-2 font-bold w-10/12 lg:w-8/12 m-auto">
                Order Fullfilled to Retailer
              </div>
              <FiMapPin className="m-auto" size={45} />
            </div>
          </div>
          <div
            data-aos={"fade-up"}
            className="text-center w-max sm:w-auto xl:-mr-0 w-fit text-secondary"
          >
            <div className="text-neutralText2 pb-2 font-bold w-10/12 sm:w-8/12 m-auto">
              Salesperson added new retailer
            </div>
            <FiMapPin className="m-auto" size={45} />
          </div>
        </div>
        <div className="px-4 lg:pt-0 sm:px-0 sm:w-11/12 md:w-9/12 2xl:w-1/2 m-auto">
          <LandingHeader
            header="WELCOME TO BUSYBEE"
            body="Manage Sales Teams and Retailers Remotely"
          />
        </div>

        <div className="w-10/12 md:w-7/12 lg:w-5/12 2xl:w-3/12 m-auto text-neutralText2 pt-4 pb-10">
          BusyBee helps you organise all retailer details in one place and
          streamline your daily sales activities easily
        </div>

        <Link to={"/register"} className="z-90">
          <BaseButton
            textColor={"text-neutralText2"}
            text={
              <div className="flex justify-between items-center text-xl text-semibold">
                <div>Get Started</div>
                <Unicons.UilArrowRight size={24} className="mr-1" />
              </div>
            }
            type="h-[52px] bg-primary hover:bg-primaryLight"
            width=""
          />
        </Link>
      </div>{" "}
      <Features />
      <HowBusyBeeHelps />
      <Help />
      <div id="howItWorks">
        <LandingFooter />
      </div>
    </div>
  );
};

export default Landing;
