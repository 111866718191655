import React, { useEffect, useRef, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";

function SelectInput({ icon, label, options, onChange, disabled }) {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const toggle_ref = useRef(null);
  const contentRef = useRef(null);

  const handleShowfilters = (option) => {
    console.log("disabled1", showOptions);
    setSelectedOption(option);
    console.log("disabled", showOptions);
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    const listener = document.addEventListener("mousedown", (e) => {
      console.log("disabled3", showOptions);
      // user click toggle
      if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      } else {
        console.log("disabled4", showOptions);
        // user click outside toggle and content
        if (contentRef.current && !contentRef.current.contains(e.target)) {
          // setShowOptions(false);
        }
      }
    });
    return document.removeEventListener("mousedown", listener);
  }, []);

  console.log("disabled", showOptions);

  return (
    <div>
      <div
        onClick={() => (disabled ? null : handleShowfilters())}
        className={`flex justify-between bg-neutralBg1 items-center ${
          disabled ? "text-neutralText5" : "text-neutralText4"
        } border border-neutralBg4 p-2 py-1 ${!disabled && "cursor-pointer"}`}
      >
        <>
          {icon}
          <div className="px-2 text-xs">{selectedOption || label}</div>
        </>

        {showOptions ? <Unicons.UilAngleUp /> : <Unicons.UilAngleDown />}
      </div>
      {showOptions ? (
        <div
          ref={contentRef}
          className="absolute bg-neutralBg1 text-neutralText4 p-4 mt-2 shadow-md z-10"
        >
          {options.map((item, index) => {
            console.log("visits", item);
            return (
              <div
                key={`index-${index}`}
                onClick={() => {
                  if (onChange) onChange(item);
                  //   handleAppliedFilters(item.key, el.filterKey, item.value);
                  handleShowfilters(item === "New Order" ? "Take Order" : item);
                }}
                className="p-2 hover:bg-neutralBg2 cursor-pointer"
              >
                {item === "New Order" ? "Take Order" : item}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

export default SelectInput;
