import * as React from "react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { FiHelpCircle } from "react-icons/fi";
import { FiSliders, FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import millify from "millify";

import OuterDiv from "../components/common/outerDiv";
import { startSocket } from "../services/socketIO";
import CalendarComponent from "../components/calendar";
import Chart from "../components/chart";
import DashboardOrders from "../components/dashboardOrders";
import TopSalespersons from "../components/topSalespersons";
import ActivityCard from "../components/activityCard";
import TopVendors from "../components/topVendors";
import CustomTitle from "../components/common/customTitle";
import CustomModalTitle from "../components/common/customModalTitle";
import CustomTooltip from "../components/common/customTooltip";

import {
  DashboardTab,
  Tabs,
  TabsList,
  TabPanel,
} from "../components/common/customTabs";
import { getDashboardChatData } from "../services/dashboardApi";
import getCurrency from "../utils/getCurrency";
import { CustomDateRangePicker } from "../components/common/customDatePicker";
import DashboardSteps from "../components/dashboardSteps";
import FullscreenLoader from "../components/common/fullscreenLoader";
import { CircularProgress } from "@mui/material";
import VendorInteraction from "../components/vendorInteraction";

const Dashboard = () => {
  const dateStart = new Date();
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateStart.setHours(0, 0, 0, 0);

  const dateEnd = new Date();
  dateEnd.setHours(23, 59, 59, 99);

  const [date, setDate] = useState([
    {
      startDate: dateStart,
      endDate: dateEnd,
      key: "selection",
    },
  ]);

  const [dashboardStats, setDashboardStats] = useState();
  const [loader, setLoader] = useState(true);

  const currentUser = useSelector((state) => state.getProfileDetails);
  const [activeTab, setActiveTab] = React.useState(0);

  const getChartData = async ({ chartType }) => {
    setLoader(true);
    const resp = await getDashboardChatData({
      chartType,
      startDate: parseInt(date[0].startDate / 1000, 10),
      endDate: parseInt(date[0].endDate / 1000, 10),
    });
    if (resp) {
      setDashboardStats(resp[0]);
    }
    setLoader(false);
  };

  const clearFilters = () => {
    setDate([
      {
        startDate: dateStart,
        endDate: dateEnd,
        key: "selection",
      },
    ]);
  };

  useEffect(() => {
    let chartType = "";
    switch (activeTab) {
      case 0:
        chartType = "SALES";
        break;
      case 1:
        chartType = "COLLECTION";
        break;
      case 2:
        chartType = "VENDORS";
        break;
      case 3:
        chartType = "SALESPERSON";
        break;
      default:
        chartType = "SALESPERSON";
    }
    getChartData({ chartType });
  }, [activeTab, date]);

  // useEffect(() => {
  //   const filter = monthDateRange(dateFilter);
  //   setDate({
  //     startDate: parseInt(filter.start.getTime() / 1000, 10),
  //     endDate: parseInt(filter.end.getTime() / 1000, 10),
  //   });

  //   getData(dateFilter);
  // }, [dateFilter]);

  useEffect(() => {
    console.log("DATEDATEDATE", date);
  }, [date]);

  const tooltipText = {
    Sale:"Total sale amount on all sales transactions made through BusyBee Salesperson and Organisation.",
    Collection:"Total collection amount made through BusyBee Salesperson and Organisation.",
    Retailers:"Total number of Retailers added to BusyBee.",
    Completed_Visits:"Total number of visits marked complete by BusyBee Salesperson and Organisation."
  }

  return (
    <>
      {/* {loader ? (
        <div className="m-auto h-screen flex items-center">
          <FullscreenLoader className="!text-neutralText3" />
        </div>
      ) : ( */}
        <div className="flex flex-row">
          {/* <SideNav /> */}
          <div className="w-full px-6">
            <div className="flex flex-col lg:grid lg:grid-cols-6 ">
              {!(
                currentUser?.spExists &&
                currentUser?.vendorExists &&
                currentUser?.productExists
              ) && (
                <div className="col-span-6 pt-12">
                  <DashboardSteps
                    spExists={currentUser?.spExists}
                    vendorExists={currentUser?.vendorExists}
                    productExists={currentUser?.productExists}
                  />
                </div>
              )}
              <div className="col-span-6 flex justify-between items-center pt-12">
                <CustomTitle
                  title={`Welcome to your Account, ${currentUser?.businessName} `}
                  description={
                    <div className="text-xs">
                      {currentUser?.productExists &&
                      currentUser?.vendorExists &&
                      currentUser?.spExists
                        ? "Here is some information we gathered about your business"
                        : "Follow these simple steps to set up your account"}
                    </div>
                  }
                />
                {/* <Filters filters={filters} setFilters={setActiveFilters} /> */}{" "}
                <div className="flex items-center">
                  <div className=" mr-2">
                    {date[0].startDate / 1000 !== dateStart / 1000 ||
                    date[0].endDate / 1000 !== dateEnd / 1000 ? (
                      <button onClick={() => clearFilters()}>
                        <div className="flex items-center justify-center text-infoText4 text-xs">
                          <FiX size={14} />
                          Clear
                        </div>
                      </button>
                    ) : (
                      <FiSliders className=" text-neutralText1" size={18} />
                    )}
                  </div>
                  <CustomDateRangePicker
                    date={date}
                    setDate={setDate}
                    changed={
                      date[0].startDate / 1000 !== dateStart / 1000 ||
                      date[0].endDate / 1000 !== dateEnd / 1000
                    }
                  />
                </div>
              </div>
              <>
                <div className="col-span-4 bg-neutralBg">
                  <OuterDiv>
                    <Tabs
                      defaultValue={activeTab}
                      onChange={(e, newValue) => setActiveTab(newValue)}
                    >
                      <div className="bg-neutralBg1 px-6">
                        <div className="flex justify-between py-4">
                          <div className="py-">
                            <CustomModalTitle
                              title="Your Business Overview"
                              description={
                                <>
                                  {/* Showing Data for the month of{" "}
                            <span className="text-infoText4">february</span> */}
                                </>
                              }
                            />
                          </div>
                          <div className="flex items-center">
                            <div className="text-successText4 bg-successBg2 text-sm px-4 h-8 flex items-center">
                              Balance
                            </div>
                            <div className="text-successText4 font-bold bg-successBg3 text-xl px-4 h-8 flex items-center">
                              {getCurrency()}
                              {millify(dashboardStats?.totalBalance || 0, {
                                precision: 2,
                                decimalSeparator: ".",
                              })}
                            </div>
                          </div>
                        </div>
                        <TabsList>
                          <DashboardTab value={0}>
                            <div className="flex items-center text-neutralText4">
                              <div className="font-normal pr-1 text-[13px]">
                                Sale
                              </div>
                              <CustomTooltip text={tooltipText.Sale}>
                                <FiHelpCircle className="stroke-3 text-neutralText5" />
                              </CustomTooltip>
                            </div>
                            <div className="text-[26px] font-black text-neutralText1 text-left py-2">
                              {getCurrency()}{" "}
                              {millify(dashboardStats?.totalSales || 0, {
                                precision: 2,
                                decimalSeparator: ".",
                              })}
                            </div>
                          </DashboardTab>
                          <DashboardTab value={1}>
                            {" "}
                            <div className="flex items-center text-neutralText4">
                              <div className="font-normal pr-1 text-[13px]">
                                Collection
                              </div>
                              <CustomTooltip text={tooltipText.Collection}>
                                <FiHelpCircle className="stroke-3 text-neutralText5" />
                              </CustomTooltip>
                            </div>
                            <div className="text-[26px] font-black text-neutralText1 text-left py-2">
                              {getCurrency()}{" "}
                              {millify(dashboardStats?.totalCollections || 0, {
                                precision: 2,
                                decimalSeparator: ".",
                              })}
                            </div>
                          </DashboardTab>
                          <DashboardTab value={2}>
                            {" "}
                            <div className="flex items-center text-neutralText4">
                              <div className="font-normal pr-1 text-[13px]">
                                Retailers
                              </div>
                              <CustomTooltip text={tooltipText.Retailers}>
                                <FiHelpCircle className="stroke-3 text-neutralText5" />
                              </CustomTooltip>
                            </div>
                            <div className="text-[26px] font-black text-neutralText1 text-left py-2">
                              {millify(dashboardStats?.totalVendors || 0, {
                                precision: 2,
                                decimalSeparator: ".",
                              })}
                            </div>
                          </DashboardTab>
                          <DashboardTab value={3}>
                            {" "}
                            <div className="flex items-center text-neutralText4">
                              <div className="font-normal pr-1 text-[13px]">
                                Completed Visits
                              </div>
                              <CustomTooltip text={tooltipText.Completed_Visits}>
                                <FiHelpCircle className="stroke-3 text-neutralText5" />
                              </CustomTooltip>
                            </div>
                            <div className="text-[26px] font-black text-neutralText1 text-left py-2">
                              {dashboardStats?.completedVisits || 0}
                            </div>
                          </DashboardTab>
                        </TabsList>
                      </div>
                      {loader ? (
                        // <div className="bg-neutralBg3 pt-10">
                        <div className="mx-auto flex items-center justify-center"   
                        style={{
                         height: "360px",
                         }}>
                         <CircularProgress color="primary"  className="!text-neutralText3"/>
                        </div>
                        /* </div> */
                      ) : (
                        <div>
                        <TabPanel value={0}>
                          <div
                            className="bg-neutralBg3 pt-10"
                            style={{
                              height: "360px",
                            }}
                          >
                            <Chart
                              data={dashboardStats?.chartData || []}
                              range={date}
                              chartInfo="Sale"
                            />
                          </div>
                        </TabPanel>
                        <TabPanel value={1}>
                          <div
                            className="bg-neutralBg3 pt-10"
                            style={{
                              height: "360px",
                            }}
                          >
                            <Chart
                              data={dashboardStats?.chartData || []}
                              range={date}
                              chartInfo="Collection"
                            />
                          </div>
                        </TabPanel>
                        <TabPanel value={2}>
                          <div
                            className="bg-neutralBg3 pt-10"
                            style={{
                              height: "360px",
                            }}
                          >
                            <Chart
                              data={dashboardStats?.chartData || []}
                              range={date}
                              chartInfo="Retailers"
                            />
                          </div>
                        </TabPanel>
                        <TabPanel value={3}>
                          <div
                            className="bg-neutralBg3 pt-10"
                            style={{
                              height: "360px",
                            }}
                          >
                            <Chart
                              data={dashboardStats?.chartData || []}
                              range={date}
                              chartInfo="Completed Visits"
                            />
                          </div>
                        </TabPanel>
                      </div>
                      )}
                    </Tabs>
                  </OuterDiv>
                  <div className="">
                    <TopSalespersons date={date[0]} />
                    <TopVendors date={date[0]} />
                    <VendorInteraction date={date[0]} />
                  </div>
                </div>
                <div className=" col-span-4 lg:col-span-2 w-full pl-4 rounded-lg">
                  <CalendarComponent />
                  <DashboardOrders date={date[0]} />
                  <ActivityCard />
                </div>
              </>
            </div>
          </div>
        </div>
      {/* )} */}
    </>
  );
};
export default Dashboard;
