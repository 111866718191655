import React from "react";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import BreadcrumbLink from "@mui/material/Link";
import { Link } from "react-router-dom";

const CustomBreadcrumbs = ({ links }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {links.map((el, index) => (
        <BreadcrumbLink
          key={`index-${index}`}
          component={Link}
          underline="hover"
          //   color="inherit"
          classes={{
            root: `text-xs ${
              index >= links.length - 1 ? "!text-neutralBg4" : "!text-infoText4"
            }`,
          }}
          to={`${el.url}`}
        >
          {el.title}
        </BreadcrumbLink>
      ))}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
