import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

import LandingFooter from "../../components/landingComponents/landingFooter";
import LoginForm from "../../components/forms/loginForm";
import Register from "../../components/forms/registerationForm";
import LandingHeader from "../../components/landingComponents/landingHeader";
import OuterDiv from "../../components/common/outerDiv";
import BusyBeeLogo from "../../assets/BusyBeeLogo.svg";
import SetPasswordForm from "../../components/forms/setPasswordForm";
import GradientBackground from "../../components/common/gradientBackground";

const SetPassword = (props) => {
  console.log("setForm", props.location.state.values);
  const registrationDetails = props.location.state.values;
  const buyPlan = props.location.state.buyPlan;
  return (
    <div className="flex justify-center items-center h-screen bg-loginBg bg-center bg-cover bg-no-repeat">
      <OuterDiv className="my-0 mx- w-11/12 sm:w-3/4 md:w-1/2 lg:w-5/12 2xl:w-1/3 z-10 shadow-xl">
        <div className="px-3 sm:px-12 py-8 sm:py-20">
          <div className="flex justify-center pb-6">
            <img src={BusyBeeLogo} />
            {/* BUSYBEE */}
          </div>
          <div className="py-6">
            <div className="text-xl text-center font-semibold text-neutralText1">
              Set Password
            </div>
            <div className="text-xs text-neutralText4 text-center py-1">
              Set a password for your account
            </div>
          </div>
          <SetPasswordForm
            registrationDetails={registrationDetails}
            buyPlan={buyPlan}
          />
        </div>
      </OuterDiv>
    </div>
  );
};

export default SetPassword;
