import React, { useEffect, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import startCase from "lodash/startCase";
import { FiX, FiEdit2, FiSliders, FiTrash2 } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import CustomTitle from "../components/common/customTitle";
import OuterDiv from "../components/common/outerDiv";
import Filters from "../components/common/filters";
import CustomTable from "../components/common/table";
import {
  deleteTransaction,
  getTransactionsList,
} from "../services/transactionsApi";
import { getSalesPersons } from "../services/salespersonApi";
import ConvertTimestamp from "../components/convertTimestamp";
import CustomModal from "../components/common/customModal";
import CustomModalTitle from "../components/common/customModalTitle";
import BaseButton from "../components/common/baseButton";
import TransactionsForm from "../components/forms/transactionsForm";
import ConfirmationModal from "../components/common/confirmationModal";
import { FilterType } from "../constants/common";
import { getVendorList } from "../services/vendorsApi";
import noTransactions from "../assets/noTransactions.svg";
import EmptyFallBack from "../components/common/emptyFallback";
import { CustomDateRangePicker } from "../components/common/customDatePicker";
import millify from "millify";
import { includes } from "lodash";
import getCurrency from "../utils/getCurrency";

const columns = [
  { key: "date", name: "DATE", minWidth: 140, width: 140 },
  { key: "transactionType", name: "TYPE", minWidth: 140, width: 140 },
  { key: "vendor", name: "RETAILER", minWidth: 200 },
  { key: "salesperson", name: "SALESPERSON", minWidth: 200 },
  { key: "amount", name: "AMOUNT", minWidth: 120, width: 120 },
  { key: "actions", name: "" },
];

function Transactions() {
  const dataGridRef = React.useRef(null);
  const history = useHistory();

  const [modifiedData, setModifiedData] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeFilters, setActiveFilters] = useState([]);
  const [openCreateTransactionModal, setOpenCreateTransactionModal] =
    useState(false);
  const [openEditTransactionModal, setOpenEditTransactionModal] =
    useState(false);
  const [transactionToEdit, setTransactionToEdit] = useState({});

  const [selectedSalesperson, setSelectedSalesperson] = useState();
  const [searchedSalespersons, setSearchedSalespersons] = useState([]);
  const [salespersonsLoading, setSalespersonsLoading] = useState(false);

  const [selectedVendor, setSelectedVendor] = useState();
  const [searchedVendors, setSearchedVendors] = useState([]);
  const [vendorsLoading, setVendorsLoading] = useState(false);

  const searchSalespersons = async (query) => {
    setSalespersonsLoading(true);
    const resp = await getSalesPersons({
      offset: 1,
      limit: 5,
      query,
    });
    setSearchedSalespersons(resp.result || []);
    setSalespersonsLoading(false);
  };

  const searchVendors = async (query) => {
    setVendorsLoading(true);
    const resp = await getVendorList({
      offset: 1,
      limit: 5,
      query,
    });
    setSearchedVendors(resp.result || []);
    setVendorsLoading(false);
  };

  const dateStart = new Date();
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateStart.setHours(0, 0, 0, 0);

  const dateEnd = new Date();
  dateEnd.setHours(23, 59, 59, 99);

  const [date, setDate] = useState([
    {
      startDate: dateStart,
      endDate: dateEnd,
      key: "selection",
    },
  ]);

  const getOptionsList = async () => {
    const spResp = await getSalesPersons({
      offset: 1,
      limit: 5,
    });
    setSearchedSalespersons(spResp.result || []);
    const vendorResp = await getVendorList({
      offset: 1,
      limit: 5,
      status: "ACTIVE",
    });
    setSearchedVendors(vendorResp.result || []);
  };

  const filters = [
    {
      filterKey: "salesperson",
      filterLabel: "Salesperson",
      filterType: FilterType.Autocomplete,
      selectedFilter: selectedSalesperson,
      onFilterChange: setSelectedSalesperson,
      onFilterInputChange: searchSalespersons,
      filterLoading: salespersonsLoading,
      optionValue: "name",
      filterPlaceholder: "Salesperson",
      filterIcon: <Unicons.UilUser size={24} />,
      filterValues: searchedSalespersons,
    },
    {
      filterKey: "vendor",
      filterLabel: "Retailer",
      filterType: FilterType.Autocomplete,
      selectedFilter: selectedVendor,
      onFilterChange: setSelectedVendor,
      onFilterInputChange: searchVendors,
      filterLoading: vendorsLoading,
      optionValue: "businessName",
      filterPlaceholder: "Retailer",
      filterIcon: <Unicons.UilUsersAlt size={24} />,
      filterValues: searchedVendors,
    },
    {
      filterKey: "txnType",
      filterLabel: "Transaction Type",
      selectedFilter: activeFilters.length > 0,
      selectedKey: activeFilters,
      filterType: FilterType.Default,
      filterIcon: <Unicons.UilTransaction size={24} />,
      filterValues: [
        {
          key: "SALES",
          value: "Sale",
        },
        {
          key: "COLLECTION",
          value: "Collection",
        },
        {
          key: "RETURN",
          value: "Return",
        },
        {
          key: "OPENING_BALANCE",
          value: "Opening Balance",
        },
      ],
    },
  ];

  const getTransactions = async ({ refresh = true, page, ...filters }) => {
    setTableLoading(refresh);
    setOffset(page - 1);
    const res = history.location.state
      ? await getTransactionsList({
          offset: page,
          limit: rowsPerPage,
          spId: history.location.state.salesperson,
          vendorId: history.location.state.vendor,
          startDate: parseInt(date[0].startDate / 1000, 10),
          endDate: parseInt(date[0].endDate / 1000, 10),
          ...filters,
        })
      : await getTransactionsList({
          offset: page,
          limit: rowsPerPage,
          spId: selectedSalesperson?.id,
          vendorId: selectedVendor?.id,
          startDate: parseInt(date[0].startDate / 1000, 10),
          endDate: parseInt(date[0].endDate / 1000, 10),
          ...filters,
        });
    setCount(res?.count);
    setTransactions(res?.result || []);
    setTableLoading(false);
  };

  const handleDeleteTransaction = async (id) => {
    const resp = await deleteTransaction(id);
    if (resp) {
      applyFilters({ refresh: false, page: offset + 1 });
      toast.success("Successfully deleted transaction");
    }
  };

  const applyFilters = ({ refresh = true, page }) => {
    const filterBuilder = {};

    if (activeFilters.length > 0) {
      for (const item of activeFilters) {
        filterBuilder[item.filterKey] = item.filterValue;
      }
    }
    getTransactions({ refresh, page, ...filterBuilder });
  };

  useEffect(() => {
    applyFilters({ page: 1 });
    getOptionsList();
  }, [activeFilters, rowsPerPage, selectedSalesperson, selectedVendor, date]);

  const clearFilters = () => {
    setSelectedSalesperson(null);
    setSelectedVendor(null);
    setSearchedSalespersons([]);
    setSearchedVendors([]);
    setActiveFilters([]);
    setDate([
      {
        startDate: dateStart,
        endDate: dateEnd,
        key: "selection",
      },
    ]);
  };

  useEffect(() => {
    const temp = transactions?.map((el) => {
      return {
        ...el,
        date: (
          <ConvertTimestamp
            timestamp={el.date}
            className="font-normal text-neutralText1 truncate"
          />
        ),
        transactionType: (
          <div
            className={`px-2 py-0.5 border-l-4 ${
              el?.transactionType === "COLLECTION"
                ? "bg-successBg2 text-successText5 border-successText5"
                : el?.transactionType === "SALES"
                ? "bg-infoBg2 text-infoText4 border-infoText5"
                : el?.transactionType === "RETURN"
                ? "bg-errorBg2 text-errorText5 border-errorText5"
                : el?.transactionType === "OPENING_BALANCE"
                ? "bg-warningBg2 text-warningText5 border-warningText5"
                : ""
            } `}
          >
            {startCase(el?.transactionType?.toLowerCase())}
          </div>
        ),
        vendor: (
          <div className="text-infoText4 font-normal truncate">
            <Link to={`/view-vendor/${el?.vendor?.id}`}>
              {el?.vendor?.businessName}
            </Link>
          </div>
        ),
        salesperson: (
          <div className="text-infoText4 font-normal truncate">
            <Link to={`/view-salesperson/${el?.salesperson?.id}`}>
              {el?.salesperson?.name}
            </Link>
          </div>
        ),
        amount: (
          <div className="text-successText5 font-extrabold truncate">
            {getCurrency()}{" "}
            {millify(el.amount || 0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </div>
        ),
        actions: (
          <div
            className="flex items-center
          "
          >
            <div
              className="flex items-bottom text-neutralText1 pt-[5px] cursor-pointer"
              onClick={() => {
                setTransactionToEdit(el);
                setOpenEditTransactionModal(true);
              }}
            >
              <FiEdit2 className="h-5 w-5" />
              <div className="px-1 pt-[2px]">Edit</div>
            </div>

            {el.transactionTypeId ? (
              <div className="text-neutralText5 px-2">
                <FiTrash2 size={18} />
              </div>
            ) : (
              <div className="flex items-center text-errorText5 px-2">
                <ConfirmationModal
                  title="Are your sure?"
                  subtitle="Are you sure you want to delete this transaction."
                  positiveLabel="Delete"
                  negativeLabel="Cancel"
                  onPositiveClick={() => handleDeleteTransaction(el?.id)}
                  button={(onClick) => (
                    <FiTrash2
                      size={18}
                      status={el.status}
                      className="cursor-pointer"
                      onClick={onClick}
                    />
                  )}
                />
              </div>
            )}
          </div>
        ),
      };
    });
    setModifiedData(temp);
  }, [transactions]);

  console.log("history", useHistory());

  return (
    <>
      {/* <TopBar value="Product" /> */}
      <CustomModal
        title={
          <CustomModalTitle
            title="Edit Transaction"
            description="Edit product information and package unit"
          />
        }
        openModal={openEditTransactionModal}
        setOpenModal={setOpenEditTransactionModal}
      >
        <TransactionsForm
          transaction={transactionToEdit}
          handleClose={() => {
            applyFilters({ refresh: false, page: offset + 1 });
            setOpenEditTransactionModal(false);
          }}
        />
      </CustomModal>
      <div className="flex flex-row">
        {/* <SideNav /> */}
        {0 ? (
          <div className="m-auto h-screen flex items-center">
            {/* <CircularProgress color="white" size={60} /> */}
          </div>
        ) : (
          <div className="w-full pb-12 grid grid-cols-4 pl-6 pr-12">
            <div className="col-span-4 ">
              <div className="flex justify-between items-center pt-12">
                <CustomTitle
                  title="Transactions"
                  description="Edit and Manage Transactions"
                />
                <CustomModal
                  title={
                    <CustomModalTitle
                      title="Add New Transaction"
                      description="Enter product information and package unit"
                    />
                  }
                  button={<BaseButton text="Add Transaction" />}
                  openModal={openCreateTransactionModal}
                  setOpenModal={setOpenCreateTransactionModal}
                >
                  <TransactionsForm
                    handleClose={() => {
                      setOpenCreateTransactionModal(false);
                      applyFilters({ refresh: false, page: offset + 1 });
                    }}
                  />
                </CustomModal>
              </div>
              <OuterDiv>
                <div className="flex flex-row justify-between bg-neutralBg1 items-center pt-8 px-6">
                  <div className="text-base text-neutralText1 font-medium">
                    List of Transactions
                    {/* <div className="text-neutralText3 text-sm">
                        for the month of{" "}
                        <span className="text-infoText4">february</span>
                      </div> */}
                  </div>
                  <div className="flex items-center">
                    <div className=" mr-2">
                      {filters
                        .map((el) => !!el.selectedFilter)
                        .includes(true) ||
                      date[0].startDate / 1000 !== dateStart / 1000 ||
                      date[0].endDate / 1000 !== dateEnd / 1000 ? (
                        <button onClick={() => clearFilters()}>
                          <div className="flex items-center justify-center text-infoText4 text-xs">
                            <FiX size={14} />
                            Clear
                          </div>
                        </button>
                      ) : (
                        <FiSliders className=" text-neutralText1" size={18} />
                      )}
                    </div>
                    <CustomDateRangePicker
                      date={date}
                      setDate={setDate}
                      changed={
                        date[0].startDate / 1000 !== dateStart / 1000 ||
                        date[0].endDate / 1000 !== dateEnd / 1000
                      }
                    />
                    <Filters
                      noLabel
                      setFilters={setActiveFilters}
                      activeFilters={activeFilters}
                      filters={filters}
                    />
                  </div>
                </div>
                <div
                  className="py-4 sm:px-6"
                  onClick={() => {
                    // Fix for `cellFocus (select) breaks input functionality`
                    dataGridRef.current.selectCell(
                      {
                        idx: 0,
                        rowIdx: -1,
                        mode: "SELECT",
                      },
                      true
                    );
                  }}
                >
                  <CustomTable
                    dataGridRef={dataGridRef}
                    columns={columns}
                    rows={modifiedData}
                    emptyFallBack={
                      <EmptyFallBack
                        image={noTransactions}
                        text="No Transactions Found"
                        containerClass={"mt-14 mb-4"}
                        imageClass={"h-44"}
                      />
                    }
                    count={count}
                    page={offset}
                    onPageChange={(_, page) => applyFilters({ page: page + 1 })}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
                    loading={tableLoading}
                  />
                </div>
              </OuterDiv>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Transactions;
