import React, { useEffect, useRef, useState } from "react";
import AddTerritories from "../../components/territories/addTerritories";
import ListTerritories from "../../components/territories/listTerritories";
import ListTerritoryVendors from "../../components/territories/listTerritoryVendors";
import { TerritoriesPhase } from "../../constants/common";
import { getAllTerritories } from "../../services/territoriesApi";

function Territories({
  phase,
  setPhase,
  activeTerritory,
  setActiveTerritory,
  activePolygonArea,
  setActivePolygonArea,
  isEdit,
  setIsEdit,
  gMap,
  gMaps,
}) {
  const [territories, setTerritories] = useState([]);
  const [polygons, setPolygons] = useState([]);
  const [polygonAreas, setPolygonAreas] = useState([]);

  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const resp = await getAllTerritories();

    const polys = resp.map((el) => {
      return el.coordinates.map((el) => {
        return { lat: el[1], lng: el[0] };
      });
    });
    setTerritories(resp);
    setPolygons(polys);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const areas = [];
    if (gMap.current && gMaps.current) {
      polygons.forEach((el, i) => {
        var area = new gMaps.current.Polygon({
          paths: el,
          // editable: true,
          strokeColor: `${territories[i].color}`,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: `${territories[i].color}`,
          fillOpacity: 0.35,
        });
        area.setMap(gMap.current);
        areas.push(area);
      });
      setPolygonAreas(areas);
    }
    return () => {
      setPolygonAreas([]);
      areas.map((el) => el.setMap(null));
    };
  }, [polygons]);

  return phase === TerritoriesPhase.ListTerritory ? (
    <ListTerritories
      gMap={gMap}
      gMaps={gMaps}
      territories={territories}
      polygons={polygons}
      onCreateClick={() => {
        setIsEdit(false);
        setPhase(TerritoriesPhase.AddTerritory);
      }}
      onTerritoryClick={(territory, index) => {
        setPhase(TerritoriesPhase.ListVendorTerritory);
        setActivePolygonArea(polygonAreas[index]);
        setActiveTerritory(territory);
      }}
    />
  ) : phase === TerritoriesPhase.AddTerritory ? (
    <AddTerritories
      gMap={gMap}
      gMaps={gMaps}
      isEdit={isEdit}
      activeTerritory={activeTerritory}
      activePolygonArea={activePolygonArea}
      onBackClick={() => {
        getData();
        setPhase(TerritoriesPhase.ListTerritory);
      }}
    />
  ) : phase === TerritoriesPhase.ListVendorTerritory ? (
    <ListTerritoryVendors
      gMap={gMap}
      gMaps={gMaps}
      onEdit={() => {
        setIsEdit(true);
        setPhase(TerritoriesPhase.AddTerritory);
      }}
      onBackClick={() => {
        getData();
        setPhase(TerritoriesPhase.ListTerritory);
      }}
      activeTerritory={activeTerritory}
    />
  ) : null;
}

export default Territories;
