import React from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import * as Unicons from "@iconscout/react-unicons";

import LandingFooter from "../../components/landingComponents/landingFooter";
import LoginForm from "../../components/forms/loginForm";
import Register from "../../components/forms/registerationForm";
import LandingHeader from "../../components/landingComponents/landingHeader";
import OuterDiv from "../../components/common/outerDiv";
import BusyBeeLogo from "../../assets/BusyBeeLogo.svg";
import CustomInput from "../../components/common/customInput";
import BaseButton from "../../components/common/baseButton";
import GradientBackground from "../../components/common/gradientBackground";
import { forgotPassword } from "../../services/loginAndRegistrationApi";

const ForgotPassword = () => {
  const [email, setEmail] = useState();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const handleSubmit = async () => {
    setLoader(true);
    const data = await forgotPassword(email);
    console.log("send reset response", data);
    if (data === "email sent successfully") {
      toast.success("Link sent successfully");
    }
    history.push({
      pathname: "/get-started",
    });

    setLoader(false);
  };

  return (
    <div className="flex justify-center items-center h-screen bg-loginBg bg-center bg-cover bg-no-repeat">
      <OuterDiv className="my-0 mx- w-11/12 sm:w-3/4 md:w-1/2 lg:w-5/12 2xl:w-1/3 z-10 shadow-xl">
        <div className="px-3 sm:px-12 py-8 sm:py-20">
          <div className="flex justify-center pb-6">
            <img src={BusyBeeLogo} />
            {/* BUSYBEE */}
          </div>
          <div className="py-6">
            <div className="text-xl text-center font-semibold text-neutralText1">
              Forgot Password?
            </div>
            <div className="text-xs text-neutralText4 text-center py-1">
              See what is going on with your business
            </div>
          </div>
          <CustomInput
            icon={<Unicons.UilUser className="text-neutralText5=1 h-5 w-5" />}
            containerClasses={"py-2 text-neutralText1"}
            label="Email"
            className="w-full outline-none border rounded-sm p-2"
            placeholder="mail@abc.com"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* {!email ? <div className="text-errorText5">required</div> : null} */}
          <div className="py-8">
            <BaseButton
              disabled={!email || loader}
              loading={loader}
              text="Send Reset Link"
              type="bg-primary w-full text-neutralText2 p-2 text-sm hover:bg-primaryLight"
              onClick={() => handleSubmit()}
            />
          </div>
          <div className="">
            <BaseButton
              disabled={false}
              loading={loader}
              text="Back to Login"
              type="bg-primary w-full text-neutralText2 p-2 text-sm hover:bg-primaryLight"
              onClick={() => history.goBack()}
            />
          </div>
        </div>
      </OuterDiv>
    </div>
  );
};

export default ForgotPassword;
