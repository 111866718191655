import { APIClient } from "./apiClient";

export const getSalespersonsTransactions = async ({
  offset = 0,
  limit = 10,
  spId,
  startDate,
  endDate,
}) => {
  try {
    const response = await APIClient.get("/org/salesperson/transactions", {
      params: {
        spId,
        offset,
        limit,
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (err) {
    console.log("Error getSalespersonsTransactions", err);
  }
};
export const getVendorsTransactions = async ({
  offset = 0,
  limit = 10,
  vendorId,
  startDate,
  endDate,
}) => {
  try {
    const response = await APIClient.get("/org/vendor/transactions", {
      params: {
        vendorId,
        offset,
        limit,
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (err) {
    console.log("Error getVendorsTransactions", err);
  }
};

export const getTransactionsList = async ({
  offset = 0,
  limit = 10,
  startDate = "",
  endDate = "",
  spId,
  vendorId,
  txnType,
}) => {
  try {
    const response = await APIClient.get("/org/transactions", {
      params: {
        offset,
        limit,
        startDate,
        endDate,
        spId,
        vendorId,
        txnType,
      },
    });
    return response.data;
  } catch (err) {
    console.log("getTransactionsList list error", err);
  }
};

export const createTransaction = async (details) => {
  console.log("createTransaction", details);
  try {
    const res = await APIClient.post("/org/transaction", details);
    return res.data;
  } catch (err) {
    console.log("createTransaction error ", err.response);
  }
};

export const updateTransaction = async (details) => {
  try {
    const res = await APIClient.put("/org/transaction", details);
    //no need to dispatch any action as we do not store this in store coz there is no need
    return res.data;
  } catch (err) {
    console.log("updateTransaction error ", err.response);
  }
};

export const deleteTransaction = async (id) => {
  const details = { txnId: id };
  try {
    const res = await APIClient.delete("/org/transaction", { params: details });
    //no need to dispatch any action as we do not store this in store coz there is no need
    return res.data;
  } catch (err) {
    console.log("deleteTransaction error ", err.response);
  }
};
