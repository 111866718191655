import React, { useEffect, useState } from "react";
import { FiEdit2, FiTrash2, FiSearch } from "react-icons/fi";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import CustomTable from "../../components/common/table";
import ConvertTimestamp from "../../components/convertTimestamp";
import { getProductList, removeProduct } from "../../services/productsApi";
import OuterDiv from "../../components/common/outerDiv";
import CustomTitle from "../../components/common/customTitle";
import CustomModal from "../../components/common/customModal";
import CustomModalTitle from "../../components/common/customModalTitle";
import ProductForm from "../../components/forms/productForm";
import BaseButton from "../../components/common/baseButton";
import CustomInput from "../../components/common/customInput";
import ConfirmationModal from "../../components/common/confirmationModal";
import EmptyFallBack from "../../components/common/emptyFallback";
import noProducts from "../../assets/noProducts.svg";
import FullscreenLoader from "../../components/common/fullscreenLoader";

const columns = [
  { key: "name", name: "PRODUCT NAME" },
  { key: "unit", name: "UNIT" },
  { key: "addedBy", name: "ADDED BY" },
  { key: "actions", name: "" },
];
const Products = () => {
  const dataGridRef = React.useRef(null);
  const history = useHistory();

  const [modifiedData, setModifiedData] = useState([]);
  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [activeFilters, setActiveFilters] = useState([]);
  const [productToEdit, setProductToEdit] = useState({});
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [openEditProductModal, setOpenEditProductModal] = useState(false);

  const currentUser = useSelector((s) => s.login);

  const getProducts = async ({ refresh = true, page, ...filters }) => {
    setTableLoading(refresh);
    // await dispatch(resetState());
    setOffset(page - 1);
    const res = await getProductList({
      offset: page,
      limit: rowsPerPage,
      query,
      ...filters,
    });

    setCount(res?.count);
    setProducts(res?.result || []);
    setTableLoading(false);
    setLoading(false);
  };

  const applyFilters = ({ refresh = true, page }) => {
    const filterBuilder = {};

    if (activeFilters.length > 0) {
      for (const item of activeFilters) {
        filterBuilder[item.filterKey] = item.filterValue;
      }
    }
    getProducts({ refresh, page, ...filterBuilder });
  };

  const handleRemoveProduct = async (id) => {
    const resp = await removeProduct(id);
    if (resp) {
      applyFilters({ refresh: false, page: offset + 1 });
      toast.success("Product deleted successfully");
    }
  };

  useEffect(() => {
    if (history.location.state?.openModal) {
      setTimeout(() => {
        setOpenAddProductModal(true);
      }, 500);
    }
    applyFilters({ page: 1 });
  }, [activeFilters, rowsPerPage, query]);

  useEffect(() => {
    const temp = products?.map((el) => {
      return {
        ...el,
        name: (
          <div className="text-neutralText1 font-extrabold truncate">
            {el.name}
          </div>
        ),
        addedBy:
          currentUser.id === el?.addedBy?.id ? (
            <div className="text-neutralText3 font-normal ">You</div>
          ) : (
            <div className="text-infoText4 font-normal truncate">
              <Link to={`/view-salesperson/${el?.addedBy?.id}`}>
                {el?.addedBy?.businessName || el?.addedBy?.name}
              </Link>
            </div>
          ),
        mrpPerUnit: (
          <div className="text-infoText4 font-normal truncate">
            {el.mrpPerUnit}
          </div>
        ),
        createdOn: <ConvertTimestamp timestamp={el.createdOn} />,
        actions: (
          <div
            className="flex items-center
          "
          >
            <div
              className="flex items-bottom text-neutralText1 pt-[5px] cursor-pointer"
              onClick={() => {
                setProductToEdit(el);
                setOpenEditProductModal(true);
              }}
            >
              <FiEdit2 className="h-5 w-5" />
              <div className="px-1 pt-[2px]">Edit</div>
            </div>

            <div className="flex items-center text-errorText5 px-2">
              <ConfirmationModal
                title="Are your sure?"
                subtitle="Are you sure you want to delete this product."
                positiveLabel="Delete"
                negativeLabel="Cancel"
                onPositiveClick={() => handleRemoveProduct(el.id)}
                button={(onClick) => (
                  <FiTrash2
                    size={18}
                    status={el.status}
                    className="cursor-pointer"
                    onClick={onClick}
                  />
                )}
              />
            </div>
          </div>
        ),
      };
    });
    setModifiedData(temp);
  }, [products]);

  return (
    <>
      {/* <TopBar value="Product" /> */}
      <CustomModal
        title={
          <CustomModalTitle
            title="Edit Product"
            description="Edit product information and package unit"
          />
        }
        openModal={openEditProductModal}
        setOpenModal={setOpenEditProductModal}
      >
        <ProductForm
          edit
          productDetails={productToEdit}
          handleClose={() => {
            setOpenEditProductModal(false);
            setTimeout(() => {
              applyFilters({ refresh: false, page: offset + 1 });
            }, 1000);
          }}
        />
      </CustomModal>
      <div className="flex flex-row">
        {/* <SideNav /> */}
        {loading ? (
          <div className="m-auto h-screen flex items-center">
            <FullscreenLoader className="!text-neutralText3" />
          </div>
        ) : (
          <div className="w-full pb-12 grid grid-cols-4 pl-6 pr-12">
            <div className="col-span-4 w-8/12">
              <div className="flex justify-between items-center pt-12">
                <CustomTitle
                  title="Products"
                  description="Keep your Product Inventory up to date"
                />
                <div className="flex">
                  <div className="mr-2">
                    <Link to={"/product/bulk-import"}>
                      <BaseButton
                        text={"Import CSV"}
                        outline
                        type={
                          "border-successText5 hover:bg-successText5 hover:text-white"
                        }
                        textColor="successText5"
                        width={"w-36"}
                      />
                    </Link>
                  </div>
                  <CustomModal
                    buttonText="Add New Product"
                    title={
                      <CustomModalTitle
                        title="Add new Product"
                        description="Enter product information and package unit"
                      />
                    }
                    button={<BaseButton text="Add New Product" />}
                    openModal={openAddProductModal}
                    setOpenModal={setOpenAddProductModal}
                  >
                    <ProductForm
                      handleClose={() => {
                        applyFilters({ refresh: false, page: offset + 1 });
                        setOpenAddProductModal(false);
                      }}
                    />
                  </CustomModal>
                </div>
              </div>
              <OuterDiv className="my-16">
                <div className="p-3 pb-4">
                  <CustomInput
                    icon={<FiSearch className="text-neutralText1 h-5 w-5" />}
                    placeholder="Search"
                    containerClasses="mr-2"
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </div>
                <div
                  className="px-2"
                  onClick={() => {
                    // Fix for `cellFocus (select) breaks input functionality`
                    dataGridRef.current.selectCell(
                      {
                        idx: 0,
                        rowIdx: -1,
                        mode: "SELECT",
                      },
                      true
                    );
                  }}
                >
                  <CustomTable
                    dataGridRef={dataGridRef}
                    columns={columns}
                    rows={modifiedData}
                    emptyFallBack={
                      <EmptyFallBack
                        image={noProducts}
                        text="No Products found"
                        containerClass={"mt-14 mb-4"}
                        imageClass={"h-44"}
                      />
                    }
                    count={count}
                    page={offset}
                    onPageChange={(_, page) => applyFilters({ page: page + 1 })}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
                    loading={tableLoading}
                  />
                </div>
              </OuterDiv>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Products;
