import { useEffect, useState } from "react";
import { Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";

import {
  addSalespersonIdSchema,
  addSalespersonSchema,
} from "../validationSchema";
import ValidationErrors from "../common/validationErrors";
import { BannerType } from "../../constants/common";
import CustomInput from "../common/customInput";
import BaseButton from "../common/baseButton";
import {
  addSalesperson,
  updateSalesperson,
} from "../../services/salespersonApi";
import ActionBanner from "../common/cards/actionBanner";

const SalesPersonForm = ({
  initialValues,
  edit,
  handleCloseModal,
  salespersonSet,
}) => {
  const [loading, setLoading] = useState(false);
  const [addedSpId, setAddedSpId] = useState("");
  const [addSp, setAddSp] = useState(false);

  useEffect(() => {
    if (salespersonSet) {
      setAddSp(true);
    }
  }, []);

  const Form1 = () => {
    return (
      <div className="pt-3">
        <div>Do you want to activate this salesperson?</div>
        <div className="pb-2 text-xs text-neutralText4">
          To assign tasks to this salesperson you need to activate them.
        </div>
        <Formik
          initialValues={{
            spId: "",
          }}
          validationSchema={addSalespersonIdSchema}
          onSubmit={async (values) => {
            setLoading(true);

            const resp = await updateSalesperson({
              id: addedSpId,
              salespersonId: values.spId,
              status: "ACTIVE",
            });
            if (resp === "Updated Successfully") {
              toast.success("Salesperson Activated");
              handleCloseModal();
            }
            setLoading(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="py-4 text-xs text-neutralText4">
                <CustomInput
                  containerClasses="py-2"
                  label="Create Salesperson ID"
                  placeholder="JOHNDOE123"
                  type="text"
                  name="spId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.spId}
                />
                {touched.spId && errors.spId && (
                  <ValidationErrors errors={`${errors.spId}`} />
                )}
                <ActionBanner
                  type={BannerType.Info}
                  text={
                    <i>
                      When you activate a salesperson, this ID and a password
                      will be sent to the salesperson's email id. 
                    </i>
                  }
                  containerClassName="mt-2"
                />
              </div>
              <div className="flex justify-between pt-2">
                <BaseButton
                  text={"Not Now"}
                  width={"w-36"}
                  onClick={(e) => {
                    // e.preventDefault();
                    handleCloseModal();
                  }}
                  className="py-1 px-8 bg-errorText5 text-xs text-neutralBg1"
                />
                <BaseButton
                  text={"Activate"}
                  width={"w-36"}
                  onClick={() => handleSubmit}
                  loading={loading}
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  };

  console.log("setSpId", salespersonSet, addSp);
  if (addSp) {
    return <Form1 />;
  } else
    return (
      <Formik
        initialValues={{
          name: initialValues?.name || "",
          email: initialValues?.email || "",
          number: initialValues?.contact || "",
        }}
        validationSchema={addSalespersonSchema}
        onSubmit={async (values) => {
          setLoading(true);

          let details = {
            id: initialValues?.id,
            email: values.email,
            name: values.name,
            contact: values.number,
          };

          const salespersonResponse = edit
            ? await updateSalesperson(details)
            : await addSalesperson(details);

          console.log("salespersonResponse", salespersonResponse);

          if (salespersonResponse === "Updated Successfully") {
            toast.success("Salesperson Updated");
            handleCloseModal();
          } else if (salespersonResponse?.id) {
            toast.success("Salesperson Added");
            setAddedSpId(salespersonResponse?.id);
            setAddSp(true);
            // handleCloseModal();
          }

          setLoading(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <CustomInput
              containerClasses="py-2"
              label="Name"
              placeholder="John Doe"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            {touched.name && errors.name && (
              <ValidationErrors errors={`${errors.name}`} />
            )}
            <CustomInput
              containerClasses="py-2"
              label="Phone Number"
              placeholder="+1 (298) 988967"
              type="text"
              name="number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.number}
            />
            {errors.number && touched.number && (
              <ValidationErrors errors={`${errors.number}`} />
            )}
            <CustomInput
              containerClasses="py-2"
              label="Email"
              placeholder="user@example.com"
              type="text"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {touched.email && errors.email && (
              <ValidationErrors errors={`${errors.email}`} />
            )}
            <div className="flex justify-end">
              <BaseButton
                text={edit ? "Save" : "Add"}
                width={"w-36"}
                onClick={() => {
                  handleSubmit();
                }}
                loading={loading}
              />
            </div>
          </form>
        )}
      </Formik>
    );
};
export default SalesPersonForm;
