import { APIClient } from "./apiClient";

export const getDashboardChatData = async ({
  chartType,
  startDate,
  endDate,
}) => {
  try {
    const response = await APIClient.get("/org/dashboard", {
      params: {
        chartType,
        startDate,
        endDate,
      },
    });
    return response.data;
  } catch (err) {
    console.log("Error getDashboardChatData", err);
  }
};
