import { FiCheck, FiX, FiClock } from "react-icons/fi";

import ConvertTimestamp from "../convertTimestamp";

const CustomStatus = ({ status, time }) => {
  switch (status) {
    case "COMPLETED":
      return (
        <div
          className={
            "flex items-center bg-successBg2 text-xs text-neutralText1 rounded-full py-[1px]"
          }
        >
          <div
            className={
              "bg-successText5 text-neutralBg1 p-[4px] rounded-full text-neutralBg1"
            }
          >
            <FiCheck />
          </div>
          <ConvertTimestamp
            timestamp={time}
            className="text-xs text-successText4 px-2"
          />
        </div>
      );

    case "PROCESSING":
      return (
        <div
          className={
            "flex items-center bg-warningBg2 text-xs text-neutralText1 rounded-full py-[1px]"
          }
        >
          <div
            className={`bg-warningText5 neutralText1 p-[4px] rounded-full text-neutralBg1`}
          >
            <FiClock />
          </div>
          <div className="px-2 text-warningText4 text-xs">{status}</div>
        </div>
      );
    case "CANCELLED":
      return (
        <div
          className={
            "flex items-center bg-errorBg2 text-xs text-neutralText1 rounded-full py-[1px]"
          }
        >
          <div
            className={`bg-errorText5 neutralText1 p-[4px] rounded-full text-neutralBg1`}
          >
            <FiX />
          </div>
          <ConvertTimestamp
            timestamp={time}
            className="text-xs text-errorText4 px-2"
          />
        </div>
      );

    default:
      return (
        <div className="bg-neutralBg3 text-xs text-neutralText1 rounded-full py-[3px] px-9">
          {status}
        </div>
      );
  }
};
export default CustomStatus;
