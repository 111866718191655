// import React, { useState, useEffect, useRef } from "react";
// import { FiMenu, FiX } from "react-icons/fi";
// import { useHistory } from "react-router-dom";
// import * as Unicons from "@iconscout/react-unicons";

// import SideNavItem from "./common/sideNavItem";

// const SideNav = () => {
//   const [sideMenu, setSideMenu] = useState(false);
//   const toggle_ref = useRef(null);
//   const contentRef = useRef(null);
//   const history = useHistory();

//   // const toggleDropdown = () => {
//   //   setSideMenu(!setSideMenu);
//   //   console.log("dropdown in toggle finc", sideMenu);
//   // };

//   document.addEventListener("mousedown", (e) => {
//     // user click toggle
//     if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
//     } else {
//       // user click outside toggle and content
//       if (contentRef.current && !contentRef.current.contains(e.target)) {
//         setSideMenu(false);
//       }
//     }
//   });

//   useEffect(() => {
//     window.addEventListener(
//       "resize",
//       () => {
//         if (window.innerWidth > 1024) {
//           setSideMenu(false);
//         } else {
//           console.log("lesser screen");
//         }
//       },
//       true
//     );

//     // return () =>
//   }, []);

//   console.log("dropdown", history);
//   return (
//     <>
//       <div
//         ref={contentRef}
//         className={`lg:pt-10 z-40 h-full transform transition fixed ease-linear bg-neutralBg2 duration-500 border-r border-r-neutralBg3 ${
//           sideMenu
//             ? "-translatex-0  lg:w-52  "
//             : "-translate-x-full  lg:-translate-x-0 lg:w-52 lg:block"
//         }`}
//       >
//         <div className="w-full">
//           <button
//             className="px-6 pt-6 lg:hidden"
//             onClick={() => setSideMenu(!sideMenu)}
//           >
//             <FiX />
//           </button>
//           <div className="flex flex-col justify-between font-semibold items-center h-full overflow-y-auto max-h-screen">
//             <div className="pl-4 lg:pl-8 text-left w-full h-screen">
//               <SideNavItem
//                 icon={<Unicons.UilDashboard size={20} />}
//                 name={"Dashboard"}
//                 link={"/dashboard"}
//               />

//               <SideNavItem
//                 icon={<Unicons.UilFileCheckAlt size={20} />}
//                 name={"Orders"}
//                 link={"/orders"}
//               />
//               <SideNavItem
//                 icon={<Unicons.UilLocationPoint size={20} />}
//                 name={"Visits"}
//                 link={"/visits"}
//               />

//               <SideNavItem
//                 icon={<Unicons.UilUser size={20} />}
//                 name={"Salesperson"}
//                 link={"/salespersons"}
//               />

//               <SideNavItem
//                 icon={<Unicons.UilTransaction size={20} />}
//                 name={"Transactions"}
//                 link={"/transactions"}
//               />

//               <SideNavItem
//                 icon={<Unicons.UilUsersAlt size={20} />}
//                 name={"Vendors"}
//                 link={"/vendors"}
//               />

//               <SideNavItem
//                 icon={<Unicons.UilLayerGroup size={20} />}
//                 name={"Products"}
//                 link={"/products"}
//               />
//             </div>
//             {/* <div>
//               <img className="p-4 pt-8 m-auto" src={bottomLogo} alt="" />
//               <div className="flex justify-center">
//                 <Link to="/plans">
//                   <button className="text-xs bg-primary p-2 px-4 rounded-full text-secondary-text">
//                     <div className="flex items-center">
//                       <span className="px-2"> Upgrade to premium</span>{" "}
//                       <FiArrowUp />
//                     </div>
//                   </button>
//                 </Link>
//               </div>
//             </div> */}
//           </div>
//         </div>
//       </div>
//       <div className="transform transition ease-linear w-0 lg:w-52  duration-500 -translate-x-full lg:-translate-x-0"></div>
//       <div
//         className={` z-20 h-16 flex items-center px-5 fixed transform transition  ${
//           sideMenu ? "hidden" : "block w-1/12 lg:w-0 lg:hidden"
//         } `}
//       >
//         <button ref={toggle_ref} onClick={() => setSideMenu(!sideMenu)}>
//           {sideMenu ? null : <FiMenu />}
//         </button>
//       </div>
//     </>
//   );
// };
// export default SideNav;

import React, { useState, useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { makeStyles } from "@mui/styles";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { FiMenu, FiX } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";

import SideNavItem from "./common/sideNavItem";

import tailwindConfig from "../theme/tailwindConfig";

const drawerWidth = 208;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const sideNavElements = [
  {
    icon: <Unicons.UilDashboard size={20} />,
    name: "Dashboard",
    link: "/dashboard",
  },
  {
    icon: <Unicons.UilFileCheckAlt size={20} />,
    name: "Orders",
    link: "/orders",
  },
  {
    icon: <Unicons.UilLocationPoint size={20} />,
    name: "Visits",
    link: "/visits",
  },
  {
    icon: <Unicons.UilUser size={20} />,
    name: "Salespersons",
    link: "/salespersons",
  },
  {
    icon: <Unicons.UilTransaction size={20} />,
    name: "Transactions",
    link: "/transactions",
  },
  {
    icon: <Unicons.UilUsersAlt size={20} />,
    name: "Retailers",
    link: "/vendors",
  },
  {
    icon: <Unicons.UilLayerGroup size={20} />,
    name: "Products",
    link: "/products",
  },
];
const SideNav = () => {
  const [sideMenu, setSideMenu] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const toggle_ref = useRef(null);
  const contentRef = useRef(null);
  const history = useHistory();
  const [open, setOpen] = React.useState(true);

  // const toggleDropdown = () => {
  //   setSideMenu(!setSideMenu);
  //   console.log("dropdown in toggle finc", sideMenu);
  // };
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiPaper-root": {
        // marginTop: "64px",
        top: "auto",
        backgroundColor: tailwindConfig.theme.colors.neutralBg2,
        borderRight: `1px solid ${tailwindConfig.theme.colors.neutralBg3}`,
      },
      "& .MuiButtonBase-root": {
        "&:hover": {
          background: tailwindConfig.theme.colors.neutralBg3,
        },
      },
      "& .MuiSvgIcon-root ": {
        color: tailwindConfig.theme.colors.neutralText4,
      },
      "& .MuiDrawer-root ": { display: "block" },
      "& .MuiTypography-root": {
        fontSize: "inherit",
        fontWeight: "inherit",
        color: "inherit",
        transitionDuration: 200,
      },
      "& .MuiList-root": {
        paddingLeft: open ? "2rem" : 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  }));

  const theme = useTheme();
  const classes = useStyles();

  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
    } else {
      // user click outside toggle and content
      if (contentRef.current && !contentRef.current.contains(e.target)) {
        setSideMenu(false);
      }
    }
  });

  useEffect(() => {
    setActiveLink(history.location.pathname);
    window.addEventListener(
      "resize",
      () => {
        if (window.innerWidth > 1024) {
          setSideMenu(false);
        } else {
          console.log("lesser screen");
        }
      },
      true
    );

    // return () =>
  }, [history.location.pathname]);

  return (
    <>
      <div
        ref={contentRef}
        className={`lg:pt-10 z-40 sm:w-1/3 md:w-3/12 h-full transform transition fixed lg:hidden ease-linear bg-neutralBg2 duration-300 border-r border-r-neutralBg3 ${
          sideMenu ? "-translatex-0  " : "-translate-x-full  lg:-translate-x-0 "
        }`}
      >
        <div className="w-full">
          <button
            className="px-6 pt-6 lg:hidden"
            onClick={() => setSideMenu(!sideMenu)}
          >
            <FiX />
          </button>
          <div className="flex flex-col justify-between font-semibold items-center h-full overflow-y-auto max-h-screen">
            <div className="pl-4 lg:pl-8 text-left w-full h-screen">
              <SideNavItem
                icon={<Unicons.UilDashboard size={20} />}
                name={"Dashboard"}
                link={"/dashboard"}
              />

              <SideNavItem
                icon={<Unicons.UilFileCheckAlt size={20} />}
                name={"Orders"}
                link={"/orders"}
              />
              <SideNavItem
                icon={<Unicons.UilLocationPoint size={20} />}
                name={"Visits"}
                link={"/visits"}
              />

              <SideNavItem
                icon={<Unicons.UilUser size={20} />}
                name={"Salespersons"}
                link={"/salespersons"}
              />
              <SideNavItem
                icon={<Unicons.UilTransaction size={20} />}
                name={"Transactions"}
                link={"/transactions"}
              />

              <SideNavItem
                icon={<Unicons.UilUsersAlt size={20} />}
                name={"Retailers"}
                link={"/vendors"}
              />

              <SideNavItem
                icon={<Unicons.UilLayerGroup size={20} />}
                name={"Products"}
                link={"/products"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block">
        <div className={classes.root}>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader sx={{ minHeight: 48 }}>
              {open ? (
                <IconButton onClick={() => setOpen(false)}>
                  <ChevronLeftIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setOpen(true)}>
                  <ChevronRightIcon />
                </IconButton>
              )}
            </DrawerHeader>
            <List>
              {sideNavElements.map(({ name, icon, link }) => (
                // <Link to={link}>
                <ListItemButton
                  component={Link}
                  to={link}
                  disableRipple
                  // selected={history.location.pathname === link}
                  onClick={() => setActiveLink(link)}
                  key={name}
                  sx={{
                    alignItems: "center",
                    justifyContent: open ? "!initial" : "!center",
                    px: open ? "8px" : 2.5,
                    my: "8px",
                    fontSize: ".7rem",
                    fontWeight: activeLink === link ? 600 : 500,
                    backgroundColor:
                      activeLink === link
                        ? tailwindConfig.theme.colors.neutralBg3
                        : "",
                    color:
                      activeLink === link
                        ? tailwindConfig.theme.colors.neutralText1
                        : tailwindConfig.theme.colors.neutralText4,
                    ":hover": {
                      backgroundColor: tailwindConfig.theme.colors.neutralBg3,
                      fontSize: ".8rem",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? "8px" : "auto",
                      justifyContent: "center",
                      color:
                        activeLink === link
                          ? tailwindConfig.theme.colors.neutralText1
                          : tailwindConfig.theme.colors.neutralText4,
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={name}
                    sx={{ opacity: open ? 1 : 0, width: open ? "full" : 0 }}
                  />
                </ListItemButton>
                // </Link>
              ))}
            </List>
          </Drawer>
        </div>
      </div>
      <div
        className={`fixed transition duration-500 w-screen h-screen  bg-neutralText1 z-10 ${
          sideMenu ? "opacity-80" : "opacity-0 pointer-events-none"
        }`}
      ></div>

      <div
        className={` z-20 h-16 flex items-center px-5 fixed transform transition  ${
          sideMenu ? "hidden" : "block w-1/12 lg:w-0 lg:hidden"
        } `}
      >
        <button ref={toggle_ref} onClick={() => setSideMenu(!sideMenu)}>
          {sideMenu ? null : <FiMenu />}
        </button>
      </div>
    </>
  );
};
export default SideNav;
