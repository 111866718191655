import startCase from "lodash/startCase";
import React, { useState } from "react";
import { toast } from "react-toastify";

import { createVisit } from "../services/visitsApi";
import BaseButton from "./common/baseButton";
import { CustomDateInputPicker } from "./common/customDatePicker";
import CustomModal from "./common/customModal";
import CustomModalTitle from "./common/customModalTitle";
import SelectInput from "./common/selectInput";
import ValidationErrors from "./common/validationErrors";

function AssignVisitModal({
  order,
  vendors,
  openModal,
  setOpenModal,
  onClose,
}) {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [visitType, setVisitType] = useState();

  const [visitTypeError, setVisitTypeError] = useState("");

  const handleVisit = async () => {
    setLoading(true);

    if (!order && !visitType) {
      setVisitTypeError("Visit type is required");
      setLoading(false);
      return;
    }

    const details = {
      date: parseInt(date.getTime() / 1000, 10),
      visitType: order
        ? "Orders"
        : visitType.split(" ").join("_").toUpperCase(),
      ...(order?.id && { visitTypeId: order?.id }),
      vendorIds: order ? [order?.vendor.id] : vendors,
    };

    const resp = await createVisit(details);
    setLoading(false);
    if (resp) {
      toast.success("Successfully assigned visits");
      setOpenModal(false);
      onClose();
    } else {
      // toast.error("Something went wrong");
    }
  };

  return (
    <CustomModal
      title={
        <CustomModalTitle
          title="Assign Visit"
          description="Add new retailer from busybee database or create new"
        />
      }
      boxStyle={{ p: 0, width: 650 }}
      topContainerStyle={"px-[32px] pt-[32px]"}
      bottomContainerStyle={
        "px-[32px] pb-[32px] pt-[25px] bg-neutralBg2 rounded-b mt-2"
      }
      button={
        order && (
          <BaseButton
            text="Assign Visit"
            className="border border-neutralText2 py-1 px-4 bg-neutralBg1 text-xs text-neutralText1 "
            textColor=""
          />
        )
      }
      openModal={openModal}
      setOpenModal={setOpenModal}
    >
      <CustomDateInputPicker
        date={date}
        minDate={new Date()}
        onChange={(selectedDate) => {
          setDate(selectedDate);
        }}
      />
      {!order && (
        <>
          <label className="text-xs py-1 block font-light mt-2">
            Visit Type
          </label>
          <SelectInput
            label="Select a visit type"
            options={["COLLECTION", "NEW_ORDER", "RETURN"].map((el) =>
              startCase(el.toLowerCase())
            )}
            onChange={(type) => {
              setVisitTypeError("");
              setVisitType(type);
            }}
          />
          {visitTypeError && (
            <ValidationErrors errors={`${visitTypeError}`} className="mt-1" />
          )}
        </>
      )}
      <div className=" pt-12 flex justify-end  m-auto">
        <BaseButton
          onClick={handleVisit}
          loading={loading}
          text={"Assign Visit"}
        />
      </div>
    </CustomModal>
  );
}

export default AssignVisitModal;
