import * as Unicons from "@iconscout/react-unicons";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import BaseButton from "../../components/common/baseButton";

import { LOGIN } from "../../redux store/actions/actionTypes";
import OuterDiv from "../../components/common/outerDiv";

const PaymentComplete = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const orgDetails = history.location.state.orgDetails;

  const handleClick = async () => {
    await dispatch({
      type: LOGIN,
      payload: orgDetails,
    });
    history.push("/dashboard");
  };

  console.log("history", orgDetails);
  return (
    <div className="py-12 h-screen bg-loginBg bg-center bg-cover bg-no-repeat">
      <div className="py-6 col-span-2 text-center sm:col-span-3 text-transparent text-xl sm:text-4xl font-black bg-clip-text bg-gradient-radial-sm-text from-successBg5 to-neutralText1">
        Review and plan your subscription
      </div>
      <OuterDiv className="w-3/12 mx-auto text-center">
        <div className="flex justify-center pt-8 pb-4">
          <Unicons.UilCheckCircle className="text-successBg5" size={50} />
        </div>
        <div className="text-neutralText3 text-xl font-extrabold pb-2">
          Payment Complete
        </div>
        <div className="text-sm text-neutralText4 w-9/12 m-auto">
          Thany you, your payment has been successfull. A confimation email has
          been sent to email@example.com
        </div>
        <div className="py-8">
          <BaseButton onClick={() => handleClick()} text={"Go to Dashboard"} />
        </div>
      </OuterDiv>
    </div>
  );
};
export default PaymentComplete;
