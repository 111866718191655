import Axios from "axios";
import getSymbolFromCurrency from "currency-symbol-map";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as Unicons from "@iconscout/react-unicons";
import { FiCheck, FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

import OuterDiv from "../../components/common/outerDiv";
import {
  addSeats,
  getCurrentPlan,
  getOrderId,
  getPlans,
} from "../../services/plansAndBilling";
import Loader from "../../components/common/loader";
import BaseButton from "../../components/common/baseButton";
import { LOGIN } from "../../redux store/actions/actionTypes";
import GetPlan from "../../services/razorpayFunction";
import getCurrency from "../../utils/getCurrency";
import CheckoutForm from "../../services/stripeFunction";
import FullscreenLoader from "../../components/common/fullscreenLoader";

const stripePromise = loadStripe(
  "pk_test_51KnJAJSA0OxQaV5wL8NS3r0adDqlxY8vXqdUxYJvlLrFhmpmUfrYxvKpufWMLjrWchIuuNj9n13Ko64YQgcqvaQ200v3QShI89"
);

const BuyPlan = () => {
  const currentUser = useSelector((state) => state?.getProfileDetails);
  const dispatch = useDispatch();
  const history = useHistory();
  const buyPlan = history?.location?.state?.buyPlan;
  const orgDetails = history.location.state?.orgDetails;

  const [users, setUsers] = useState(1);
  const [plans, setPlans] = useState([]);
  const [stripeKey, setStripeKey] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  const [orderIdResp, setOrderIdResp] = useState("");
  const [loader, setLoader] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(buyPlan);
  const [paymentType, setPaymentType] = useState();

  const getPlansList = async () => {
    try {
      setLoader(true);
      const currPlan = await getCurrentPlan();
      setCurrentPlan(currPlan);
      console.log("currPlan", currPlan.plan);
      if (currPlan?.plan?.id) {
        setSelectedPlan(currPlan.plan);
      } else {
        setSelectedPlan(buyPlan);
      }
      const res = await Axios.get("https://geolocation-db.com/json/");
      console.log(res.data);
      console.log("currencyCode", res.data.country_name);
      let currency = res.data.country_name === "India" ? "INR" : "USD";
      console.log("currencyCode,,,", currency);
      const data = await getPlans(currency);
      if (currency) setLoader(false);
      console.log("plans", data);
      setPlans(data.data);
      setLoader(false);
    } catch (e) {
      console.error("error in plans", e);
      const currPlan = await getCurrentPlan();
      setCurrentPlan(currPlan);
      console.log("currPlan", currPlan.plan);
      if (currPlan?.plan?.id) {
        setSelectedPlan(currPlan.plan);
      } else {
        setSelectedPlan(buyPlan);
      }
      const data = await getPlans("INR");
      if (data) setLoader(false);
      console.log("plans", data);
      setPlans(data.data);
      setLoader(false);
    }
  };

  useEffect(() => {
    getPlansList();
  }, []);

  const handleClick = async () => {
    let res;
    try {
      res = await Axios.get("https://geolocation-db.com/json/");
    } catch (e) {
      console.log("error", e);
    }

    let currency;
    if (res) {
      currency = res.data.country_name === "India" ? "INR" : "USD";
    } else {
      currency = "INR";
    }

    const details = {
      orgId: orgDetails?.id || currentUser?.id,
      planId: currentUser?.plan?.id || selectedPlan.id,
      currency: currency,
      couponCode: "",
      spCount: Number(users),
    };
    const data =
      !currentPlan || currentPlan?.planType === "TRIAL"
        ? await getOrderId(details)
        : await addSeats({ currency, users });
    console.log("buyPlan,,,", data);
    setOrderIdResp(data);
    if (currency === "INR") {
      GetPlan(data.orderId, orgDetails, currentUser, history);
    }
  };

  const handleSkip = async () => {
    dispatch({
      type: LOGIN,
      payload: orgDetails,
    });
    history.push("dashboard");
  };
  const options = {
    merchantDisplayName: "Busy Bee",
    clientSecret: orderIdResp?.clientSecret,
  };

  console.log(
    "buyPlan,,,,",
    buyPlan,
    selectedPlan,
    !currentPlan || currentPlan?.planType === "TRIAL"
  );
  return (
    <>
      {loader ? (
        <div className="m-auto h-screen flex items-center">
          <FullscreenLoader className="!text-neutralText3" />
        </div>
      ) : (
        <div className="py-16 bg-loginBg bg-center bg-cover bg-no-repeat">
          {orgDetails && (
            <button
              onClick={() => handleSkip()}
              className="text-sm 2xl:text-lg flex flex-row items-center px-16 ml-auto"
            >
              <span>Skip</span>
              <FiChevronRight />
            </button>
          )}
          <div className="">
            {currentUser?.id && (
              <button
                onClick={() => history.goBack()}
                className="text-sm 2xl:text-lg text-neutralText1 flex flex-row items-center px-16"
              >
                <FiChevronLeft />
                <span>Back</span>
              </button>
            )}
            <div className="py-6 col-span-2 text-center sm:col-span-3 text-transparent text-xl sm:text-4xl font-black bg-clip-text bg-gradient-radial-sm-text from-successBg5 to-neutralText1">
              Review and plan your subscription
            </div>
          </div>
          <div className="flex justify-center">
            <OuterDiv className="w-5/12 p-10">
              <div className="text-neutralText3 text-xl font-extrabold">
                {currentUser?.subscriptionId &&
                !currentPlan?.planType === "TRIAL"
                  ? "Add more Salespersons to your Team"
                  : "Choose your sales team size"}
              </div>
              <div className="text-neutralText3 text-sm pb-6 pt-2 w-9/12">
                Team members have access to all projects and folders. Typically
                used for employees such as designers, PM's, developers and
                researchers.
              </div>
              <div className="flex text-neutralText2 jusify-between rounded-full">
                <button
                  className="bg-neutralBg2 px-4 py-2 text-black rounded-l-full border-2 border-r-0 border-neutralBg3"
                  onClick={() => setUsers(Number(users) - 1)}
                  disabled={users < 2}
                >
                  -
                </button>
                <div
                  onChange={(e) => ""}
                  className="px-2 outline-none text-neutralText3 bg-neutralBg1 text-center border-y-2 border-y-neutralBg3 flex items-center"
                >
                  <input
                    type={"number"}
                    value={Number(users)}
                    className="w-9 outline-none bg-neutralBg1 text-right"
                    onChange={(e) => setUsers(e.target.value)}
                  />{" "}
                  <span className="px-2">Team members</span>
                </div>

                <button
                  className="bg-neutralBg2 px-4 py-2 text-black rounded-r-full border-2 border-l-0 border-neutralBg3"
                  onClick={() => setUsers(Number(users) + 1)}
                >
                  +
                </button>
              </div>
              <div className="text-neutralText3 text-xl font-extrabold py-6">
                Choose your renewal cycle
              </div>
              <div>
                <div className="grid grid-cols-2 gap-x-6">
                  {plans.map((el, index) => (
                    <button key={index} onClick={() => setSelectedPlan(el)}>
                      <OuterDiv
                        className={`col-span-1 text-left my-1 ${
                          el.id === selectedPlan?.id ? "border-infoText5" : ""
                        } `}
                      >
                        {" "}
                        <div className="flex items-center py-6 text-neutralText3 text-sm">
                          <input
                            className="mx-4"
                            type="radio"
                            name="payment"
                            onChange={() => setPaymentType("UPI")}
                            checked={el.id === selectedPlan?.id}
                          />
                          <div>
                            <div className="font-extrabold">
                              {el.planName} renewal
                            </div>
                            <div>
                              {getSymbolFromCurrency(el.currency)}
                              {el.price} / month, per user
                            </div>
                          </div>
                        </div>
                      </OuterDiv>
                    </button>
                  ))}
                </div>
              </div>
              <div className="text-neutralText3 text-xl font-extrabold pt-6">
                Choose Payment Method
              </div>
              <div className="flex items-center py-2 pt-6">
                <input
                  type="radio"
                  name="payment_type"
                  onChange={() => setPaymentType("card")}
                  checked={paymentType === "card"}
                />
                <div className="px-4">Credit or Debit Card</div>
              </div>{" "}
              <div className="flex items-center py-2">
                <input
                  type="radio"
                  name="payment_type"
                  onChange={() => setPaymentType("net")}
                  checked={paymentType === "net"}
                />
                <div className="px-4">Net Banking</div>
              </div>{" "}
              <div className="flex items-center py-2">
                <input
                  type="radio"
                  name="payment_type"
                  onChange={() => setPaymentType("UPI")}
                  checked={paymentType === "UPI"}
                />
                <div className="px-4">Other UPI Apps</div>
              </div>
            </OuterDiv>
            <OuterDiv className="ml-4 w-1/4 border-successBg3 bg-successBg1 p-10">
              <div className="text-neutralText3 text-xl font-extrabold pb-6">
                Annual Plan
              </div>
              <div className="flex items-center py-2">
                <FiCheck className="text-successText5" />
                <div className="text-neutralText3 text-sm px-2">
                  {users} Team Members
                </div>
              </div>
              <div className="flex items-center py-2">
                <FiCheck className="text-successText5" />
                <div className="text-neutralText3 text-sm px-2">
                  Unlimited Retailers
                </div>
              </div>
              <div className="flex items-center py-2">
                <FiCheck className="text-successText5" />
                <div className="text-neutralText3 text-sm px-2">
                  Discount {getSymbolFromCurrency(selectedPlan?.currency)}
                  {selectedPlan?.price - selectedPlan?.pricePerSp}
                </div>
              </div>
              <div className="py-12 border-b border-b-successText5">
                <div className="text-neutralText4 text-xl">Total amount</div>
                <div className="flex text-neutralText1 font-black pt-3">
                  <div className="text-2xl">
                    {getSymbolFromCurrency(
                      selectedPlan?.currency ||
                        currentUser?.currency?.currency_code
                    )}
                  </div>
                  <div className="text-[3rem] leading-[1]">
                    {selectedPlan?.pricePerSp * users || 0}
                  </div>
                </div>
              </div>
              <div className="py-4">
                {(orderIdResp?.gateway === "STRIPE" && (
                  <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm
                      currency={selectedPlan?.currency}
                      price={selectedPlan?.pricePerSp * users || 0}
                    />
                  </Elements>
                )) || (
                  <BaseButton
                    onClick={() => handleClick()}
                    className={
                      "w-full text-neutralBg1 font-extrabold bg-successText5 py-2 hover:bg-successBg4"
                    }
                    text={`Pay ${getSymbolFromCurrency(
                      selectedPlan?.currency ||
                        currentUser?.currency?.currency_code
                    )}${selectedPlan?.pricePerSp * users || 0} `}
                  />
                )}
              </div>
              <div className="px-2 flex items-initial">
                <Unicons.UilLock className="text-neutralText1" size={20} />
                <div className="text-neutralText3 text-sm w-10/12 pl-2">
                  This is a secure 128-SSL encrypted connection. Read our terms
                  of service and other policies here.
                </div>
              </div>
            </OuterDiv>
          </div>
        </div>
      )}
    </>
  );
};

export default BuyPlan;
