import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import * as Unicons from "@iconscout/react-unicons";

import OuterDiv from "../../components/common/outerDiv";
import RegisterationForm from "../../components/forms/registerationForm";
import BaseButton from "../../components/common/baseButton";
import BusyBeeLogo from "../../assets/BusyBeeLogo.svg";
import GradientBackground from "../../components/common/gradientBackground";
import LandingNav from "../../components/landingComponents/landingNav";
import LandingHeader from "../../components/landingComponents/landingHeader";
import conatactUsImg from "../../assets/contactUsImage.svg";
import LandingFooter from "../../components/landingComponents/landingFooter";
import ContactSalesForm from "../../components/forms/contactSalesForm";

const ContactUs = () => {
  return (
    <div className="contain bg-backgroundLogoV bg-right-top bg-no-repeat">
      <LandingNav />
      <div className="lg:flex justify-center px-3 pt-6 sm:p-12">
        <div className="w-full z-10 self-center">
          <div className="xl:px-12 2xl:px-24 text-center lg:text-left">
            <div className="w-11/12 mx-auto lg:mx-0">
              <LandingHeader
                header="WELCOME TO BUSYBEE"
                body="Learn more about how BusyBee can help you"
              />
            </div>
            <div className="text-neutralText4 text-2xl">
            Write to us and we will get back to you shortly after.
            </div>
          </div>
          <div className="2xl:pl-6 flex justify-center lg:justify-start">
            <img src={conatactUsImg} alt="" />
            {/* <div>
              <div className="text-neutralText3 text-[24px] font-black">
                With your 30 days free trial, you get:
              </div>
              <div className="text-neutralText1 pt-4 text-[16px]">
                <div className="flex items-center py-2">
                  {" "}
                  <Unicons.UilCheckCircle
                    size={24}
                    className="mr-5 bg-primary min-w-6 rounded-full"
                  />
                  Upload your own
                </div>
                <div className="flex items-center py-2">
                  {" "}
                  <Unicons.UilCheckCircle
                    size={24}
                    className="mr-5 bg-primary min-w-6 rounded-full"
                  />
                  Unlimited Salespersons, Vendors
                </div>
                <div className="flex items-center py-2">
                  {" "}
                  <Unicons.UilCheckCircle
                    size={24}
                    min-w-6
                    className="mr-5 bg-primary min-w-6 rounded-full"
                  />
                  Guided experiences for sales reps, leaders, and administrators
                </div>
                <div className="flex items-center py-2">
                  {" "}
                  <Unicons.UilCheckCircle
                    size={24}
                    className="mr-5 bg-primary min-w-6 rounded-full"
                  />
                  Online training.
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <OuterDiv className="sm:w-9/12 xl:w-7/12 m-auto shadow-xl z-10 my-0 h-fit">
          <div className="p-4 sm:p-12">
            {/* <div className="flex justify-center">
            <img src={BusyBeeLogo} />
            BUSYBEE
          </div>
          <div className="py-6">
            <div className="text-xl text-center font-semibold text-neutralText1">
              Sign up now to start your free trial
            </div>
            <div className="text-xs text-neutralText4 text-center py-1">
              No credit required, no software to install.
            </div>
          </div> */}
            <ContactSalesForm />
          </div>
        </OuterDiv>
      </div>
      {/* <div className="sm:w-8/12 lg:w-7/12 xl:w-5/12 m-auto text-center pt-6 sm:pt-0 pb-12">
        <LandingHeader body="Looking for help, support and tutorial?" />
        <BaseButton
          text={"Go to Learn"}
          className="py-2 sm:py-4 px-7 sm:px-14 text-neutralText1 text-base sm:text-xl font-semibold bg-primary"
        />
      </div> */}
      <LandingFooter />
    </div>
  );
};
export default ContactUs;
