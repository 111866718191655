import React from "react";

const SalespersonFeatureCards = (props) => {
  // console.log(props);
  return (
    <div
      onClick={props.onClick}
      className={` ${
        props?.SalespersonFeature === props?.SalespersonHeader
          ? "shadow-xl bg-neutralBg1"
          : ""
      } p-4 rounded-lg mb-2 cursor-pointer transition-shadow max-w-[36rem]`}
    >
      <p
        className={`text-xl font-black ${
          props?.SalespersonFeature === props?.SalespersonHeader
            ? "text-secondary"
            : "text-neutralText1"
        } `}
      >
        {props.header}
      </p>
      <p className="text-neutralText3 text-md font-medium max-w-[32rem] ">
        {props.body}
      </p>
    </div>
  );
};

export default SalespersonFeatureCards;
