import React from "react";
import startCase from "lodash/startCase";
import * as Unicons from "@iconscout/react-unicons";

import { SPStatus } from "../../constants/common";

const StatusPill = ({ status, label, onClick, className }) => {
  const style = () =>
    status === SPStatus.ACTIVE
      ? `bg-successBg5 hover:bg-successBg4`
      : status === SPStatus.INACTIVE
      ? `bg-errorText5 hover:bg-errorText4`
      : "bg-neutralBg5 hover:bg-neutralBg4";

  const icon = () =>
    status === SPStatus.ACTIVE ? (
      <Unicons.UilCheck size={18} />
    ) : status === SPStatus.INACTIVE ? (
      <Unicons.UilTimes size={18} />
    ) : (
      <Unicons.UilExclamation size={18} />
    );

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        if (onClick) onClick();
      }}
      className={`rounded-2xl py-1.5 flex px-3 w-24 flex-row justify-center items-center cursor-pointer text-neutralBg1 ${style()} ${className}`}
    >
      {icon()}
      <span className="ml-1">{startCase(label || status?.toLowerCase())}</span>
    </div>
  );
};

export default StatusPill;
