import React, { useEffect, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { FiX } from "react-icons/fi";
import dayjs from "dayjs";
import startCase from "lodash/startCase";

import ProcessState from "./processState";
import OrderDetailsHeader from "./orderDetailsHeader";
import BaseButton from "./baseButton";
import CustomModal from "./customModal";
import CustomModalTitle from "./customModalTitle";
import ConfirmationModal from "./confirmationModal";
import {
  cancelOrCompleteOrder,
  getOrderDetails,
} from "../../services/ordersApi";
// import VisitsForm from "../forms/visitsForm";
import ProductList from "./productList";
import CompleteOrderForm from "../forms/completeOrderForm";
import TransactionsForm from "../forms/transactionsForm";
import AssignVisitModal from "../assignVisitModal";
import { updateVisit } from "../../services/visitsApi";
import getCurrency from "../../utils/getCurrency";
import Loader from "./loader";
import millify from "millify";

function OrderSidebar({ orderId, closeOrder, onClose }) {
  const [order, setOrder] = useState();
  const [loader, setLoader] = useState(true);
  const [openCompleteOrderModal, setOpenCompleteOrderModal] = useState(false);
  const [openVisitModal, setOpenVisitModal] = useState(false);
  const [openTransactionFormModal, setOpenTransactionFormModal] =
    useState(false);

  const getData = async () => {
    setLoader(true);
    const data = orderId ? await getOrderDetails(orderId) : null;
    setOrder(data);
    setLoader(false);
  };

  useEffect(() => {
    if (orderId) {
      getData();
    }
  }, [orderId]);

  const cancelOrder = (orderId, vendorId) => {
    const details = {
      orderId: orderId,
      // vendorId: vendorId,
      date: parseInt(new Date() / 1000, 10),
      type: "CANCELLED",
    };
    cancelOrCompleteOrder(details);
    getData();
    onClose();
  };
  console.log("orderSide", order);

  return (
    <>
      {loader ? (
        <div className="flex justify-center items-center h-full">
          <Loader color="secondary" />
        </div>
      ) : (
        <>
          <div className="flex justify-between">
            <div>
              <div className="text-base text-neutralText1 font-bold">
                Order Details
              </div>
              <div className="text-neutralText3 text-xs">
                Order details and Visits
              </div>
            </div>
            <button className="text-neutralText1" onClick={() => closeOrder()}>
              <FiX />
            </button>
          </div>
          <div className="bg-neutralBg1 my-4 rounded">
            <OrderDetailsHeader
              order={order}
              handleClose={() => {
                getData();
                onClose();
                setOpenTransactionFormModal(false);
              }}
            />
            <div className="mx-4 border-y border-y-neutralBg4">
              <ProcessState processState={order?.status} processDate={order} />
            </div>
            {order?.status === "COMPLETED" ? (
              <div className="flex justify-between items-center p-4">
                <div>
                  <div className="text-neutralText4 text-xs">Sale Value</div>
                  <div className="text-xl font-extrabold text-successText5">
                    {getCurrency()}{" "}
                    {millify(order?.transaction?.amount || 0, {
                      precision: 2,
                      decimalSeparator: ".",
                    })}
                  </div>
                </div>
                <CustomModal
                  title={
                    <CustomModalTitle
                      title="Edit transaction"
                      description="Edit transaction below"
                    />
                  }
                  boxStyle={{ p: 0, width: 650 }}
                  topContainerStyle={"px-[32px] pt-[32px]"}
                  bottomContainerStyle={
                    "px-[32px] pb-[32px] pt-[25px] bg-neutralBg2 rounded-b mt-2"
                  }
                  button={<BaseButton text="Edit" />}
                  openModal={openTransactionFormModal}
                  setOpenModal={setOpenTransactionFormModal}
                >
                  <TransactionsForm
                    transaction={{
                      ...order?.transaction,
                      vendor: order?.vendor,
                    }}
                    handleClose={() => {
                      getData();
                      onClose();
                      setOpenTransactionFormModal(false);
                    }}
                  />
                </CustomModal>
              </div>
            ) : order?.status === "CANCELLED" ? (
              <div className="bg-errorText5 text-neutralBg1 py-3 px-2 text-center">
                Cancelled
              </div>
            ) : (
              <div className="flex px-4 py-6">
                <CustomModal
                  title={
                    <CustomModalTitle
                      title="Complete this order"
                      description="Description"
                    />
                  }
                  boxStyle={{ p: 0, width: 650 }}
                  topContainerStyle={"px-[32px] pt-[32px]"}
                  bottomContainerStyle={
                    "px-[32px] pb-[32px] pt-[25px] bg-neutralBg2 rounded-b mt-2"
                  }
                  button={
                    <BaseButton
                      text="Complete Order"
                      className="bg-successText5 text-neutralBg1 text-xs px-4 py-1 font-light "
                      textColor={"neutralBg1"}
                    />
                  }
                  openModal={openCompleteOrderModal}
                  setOpenModal={setOpenCompleteOrderModal}
                >
                  <CompleteOrderForm
                    orderDetails={order}
                    handleClose={() => {
                      getData();
                      onClose();
                      setOpenCompleteOrderModal(false);
                    }}
                  />
                </CustomModal>
                <ConfirmationModal
                  title="Are your sure?"
                  subtitle="Are you sure you want to cancel this order."
                  positiveLabel="Yes, Cancel order"
                  negativeLabel="No"
                  onPositiveClick={async () => {
                    setLoader(true);
                    await cancelOrder(order.id, order.vendor.id);
                    setTimeout(() => {
                      getData();
                    }, 1000);
                    onClose();
                  }}
                  button={(onClick) => (
                    <button
                      onClick={onClick}
                      className="text-errorText5 mx-4 text-sm"
                    >
                      Cancel Order
                    </button>
                  )}
                />
              </div>
            )}

            <div className="bg-neutralBg2 p-4">
              <div className="flex justify-between items-center mb-1">
                <div className="text-neutralText1 font-bold text-sm">
                  Visits
                </div>
                {["COMPLETED", "CANCELLED"].includes(order?.status) ||
                order?.visits?.find(
                  (element) => element.status === "ACTIVE"
                ) ? null : order.vendor.mapped ? (
                  <AssignVisitModal
                    order={order}
                    openModal={openVisitModal}
                    setOpenModal={setOpenVisitModal}
                    onClose={() => {
                      getData();
                      onClose();
                    }}
                  />
                ) : (
                  <div className="flex items-center text-errorText5 px-2">
                    <ConfirmationModal
                      hideButtons={true}
                      title={
                        <div className="flex items-start text-warningText4 w-full">
                          <Unicons.UilExclamationTriangle className="mr-1 text-warningText4 w-[18px] min-w-[18px] min-h-[18px]" />
                          Can't assign visit to this retailer
                        </div>
                      }
                      subtitle="Please ask the associated salesperson to map this retailer."
                      positiveLabel={"Close"}
                      // negativeLabel="Close"
                      // onPositiveClick={() => handleRemoveProduct(el.id)}
                      button={(onClick) => (
                        <BaseButton
                          onClick={onClick}
                          text="Assign Visit"
                          className="border border-neutralText2 py-1 px-4 bg-neutralBg1 text-xs text-neutralText1 "
                          textColor=""
                        />
                      )}
                    />
                  </div>
                )}
              </div>
              {order?.visits?.length > 0 ? (
                order?.visits?.map((el, index) => {
                  return (
                    <div
                      key={index}
                      className="flex justify-between font-light text-xs text-neutralText2 py-1"
                    >
                      <p>{dayjs(el?.updatedAt * 1000).format("DD-MM-YYYY")}</p>
                      <p
                        className={`px-2 py-0.5 rounded-full ${
                          el?.status === "ACTIVE"
                            ? "bg-infoBg2 text-infoText3"
                            : el?.status === "COMPLETED"
                            ? "bg-successBg2 text-successText3"
                            : el?.status === "CANCELLED"
                            ? "bg-errorBg2 text-errorText3"
                            : el?.status === "TERMINATED"
                            ? "bg-errorBg2 text-errorText3"
                            : ""
                        }`}
                      >
                        {startCase(el?.status?.toLowerCase())}
                      </p>
                      <p
                        className={`cursor-pointer ${
                          el?.status === "ACTIVE"
                            ? "text-errorText3"
                            : "text-infoText3"
                        }`}
                      >
                        {el?.status === "ACTIVE" ? (
                          <ConfirmationModal
                            title="Are your sure?"
                            subtitle="Are you sure you want to cancel visit."
                            positiveLabel="Yes"
                            negativeLabel="Cancel"
                            onPositiveClick={() => {
                              updateVisit({
                                date: parseInt(new Date() / 1000, 10),
                                visitId: el?.id,
                                status: "CANCELLED",
                              });
                              setLoader(true);
                              setTimeout(() => {
                                getData();
                              }, 1000);
                            }}
                            button={(onClick) => (
                              <div onClick={onClick}>Cancel</div>
                            )}
                          />
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  );
                })
              ) : (
                <p className="font-light text-neutralText1 text-xs text-center">
                  No visits available
                </p>
              )}
            </div>
          </div>
          <ProductList listClassName="mb-4" order={order} />
        </>
      )}
    </>
  );
}

export default OrderSidebar;
