import React, { useEffect, useRef, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { FiX } from "react-icons/fi";
import { polygon, unkinkPolygon } from "@turf/turf";
import { toast } from "react-toastify";

import CustomInput from "../common/customInput";
import BaseButton from "../common/baseButton";
import {
  createTerritory,
  updateTerritory,
} from "../../services/territoriesApi";
import ValidationErrors from "../common/validationErrors";

function AddTerritories({
  activeTerritory,
  onBackClick,
  gMap,
  gMaps,
  isEdit,
  activePolygonArea,
}) {
  let poly = useRef(null);
  let listener;

  useEffect(() => {
    if (activePolygonArea) {
      activePolygonArea?.setOptions({ editable: true });
      poly.current = activePolygonArea;
      setPolyInitialized(true);
    }
  }, []);

  const [polyInitialized, setPolyInitialized] = useState(false);

  const [territoryName, setTerritoryName] = useState(
    isEdit ? activeTerritory.name : ""
  );
  const [territoryColor, setTerritoryColor] = useState(
    isEdit ? activeTerritory.color : "#75B09C"
  );
  const [errors, setErrors] = useState({
    territoryName: "",
    territoryColor: "",
    territoryPath: "",
  });

  useEffect(() => {
    handleMapLoad();
    const map = gMaps.current;
    const polyRef = poly.current;
    return () => {
      polyRef.setMap(null);
      map.event.removeListener(listener);
    };
  }, []);

  useEffect(() => {
    poly.current.setOptions({ fillColor: territoryColor });
  }, [territoryColor]);

  const handleMapLoad = () => {
    if (isEdit) {
      return;
    }
    poly.current = new gMaps.current.Polygon({
      strokeOpacity: 1.0,
      strokeWeight: 2,
      editable: true,
      geodesic: true,
    });
    poly.current.setMap(gMap.current);

    listener = gMap.current.addListener("click", addLatLng);
  };

  const addLatLng = (event) => {
    const path = poly.current.getPath();

    setPolyInitialized(true);

    // Because path is an MVCArray, we can simply append a new coordinate
    // and it will automatically appear.
    path.push(event.latLng);
  };

  const onCreate = async () => {
    setErrors({});
    if (!territoryName) {
      // Raise error
      setErrors((o) => {
        return { ...o, territoryName: "Please give your territory a name" };
      });
      return;
    }
    const arr = poly.current
      .getPath()
      .getArray()
      .map((e) => [e.lat(), e.lng()]);

    if (!arr || arr.length <= 2) {
      // Raise error
      setErrors((o) => {
        return {
          ...o,
          territoryPath: "Please add at least 3 points to territory",
        };
      });
      return;
    }
    if (!territoryColor) {
      // Raise error
      setErrors((o) => {
        return {
          ...o,
          territoryPath: "Please add territory color",
        };
      });
      return;
    }
    const modifiedArr = arr.map((el) => [el[1], el[0]]);

    if (
      !isEdit ||
      (modifiedArr[0][0] !== modifiedArr[modifiedArr.length - 1][0] &&
        modifiedArr[0][1] !== modifiedArr[modifiedArr.length - 1][1])
    ) {
      modifiedArr.push(modifiedArr[0]);
    }

    const coordinates = [];
    const unkinkedPoly = unkinkPolygon(polygon([modifiedArr]));
    if (unkinkedPoly.features.length > 1) {
      unkinkedPoly.features.forEach(function (feature) {
        coordinates.push(feature.geometry.coordinates);
      });
    }

    let data = [];

    if (coordinates.length > 0) {
      data = coordinates.map((el, index) => {
        return {
          name: `${territoryName}-${index + 1}`,
          coordinates: el[0],
          color: territoryColor,
        };
      });
    } else {
      data.push({
        name: territoryName,
        coordinates: modifiedArr,
        color: territoryColor,
      });
    }

    if (isEdit && coordinates.length === 0) {
      const resp = await updateTerritory({
        territoryId: activeTerritory.territoryId,
        name: territoryName,
        coordinates: modifiedArr,
        color: territoryColor,
      });
      if (resp) {
        toast.success("Territory updated successfully");
        onBackClick();
      }
    } else {
      const details = { territories: data };
      if (isEdit && coordinates.length > 0) {
        details.removalId = activeTerritory?.territoryId;
      }
      const resp = await createTerritory(details);
      if (resp) {
        toast.success("Territory created successfully");
        onBackClick();
      }
    }
  };

  return (
    <div className="px-4 py-4 ">
      <div className="flex justify-between items-center text-neutralText1 mb-4 ">
        <div className="flex items-center">
          <Unicons.UilArrowLeft
            onClick={onBackClick}
            className="mr-1 cursor-pointer"
          />
          <p className=" font-bold text-lg">
            {isEdit ? "Edit Territory" : "Create Territory"}
          </p>
        </div>
        {/* <FiX onClick={toggleTerritories} className="cursor-pointer" size={18} /> */}
      </div>
      <CustomInput
        label={
          <p>
            Territory Name <span className="text-errorText5">*</span>
          </p>
        }
        value={territoryName}
        onChange={(e) => setTerritoryName(e.target.value)}
        placeholder="South Kashmir"
      />
      {errors.territoryName && (
        <ValidationErrors errors={`${errors.territoryName}`} />
      )}
      <p className="text-neutralText2 text-sm mt-1">
        Area <span className="text-errorText4">*</span>
      </p>
      <p className="text-[0.6rem] block  font-light italic text-neutralText5 mb-2">
        Start drawing on map to create a territory
      </p>
      <div className="mb-3">
        {polyInitialized && (
          <div className="flex justify-between font-bold text-sm ">
            <p>Polygon</p>
            <FiX
              className="cursor-pointer"
              onClick={() => {
                setPolyInitialized(false);
                const path = poly.current.getPath();
                const len = path.length;

                for (let i = 0; i < len; i += 1) {
                  if (path.getAt(0)) path.removeAt(0);
                }
              }}
            />
          </div>
        )}
        {errors.territoryPath && (
          <ValidationErrors errors={`${errors.territoryPath}`} />
        )}
      </div>
      <p className="text-neutralText2 text-sm mt-1">
        Choose a color <span className="text-errorText4">*</span>
      </p>
      <p className="text-[0.6rem] block  font-light italic text-neutralText5 mb-2">
        Select a color from color box
      </p>

      <div className="mb-2">
        <input
          type="color"
          value={territoryColor}
          onChange={(e) => setTerritoryColor(e.target.value)}
        />
        {errors.territoryColor && (
          <ValidationErrors errors={`${errors.territoryColor}`} />
        )}
      </div>

      <BaseButton
        text={isEdit ? "Update Area" : "Add Area"}
        // type="bg-successText5 hover:bg-successText4"
        textType="neutralBg1"
        onClick={onCreate}
      />
    </div>
  );
}

export default AddTerritories;
