import React from "react";

function CustomInput({
  inputContainerRef,
  inputRef,
  label,
  icon,
  containerClasses,
  inputContainerClasses,
  allowNumOnly = false,
  ...inputProps
}) {
  return (
    <div className={"w-full text-neutralText2 " + containerClasses}>
      {label && (
        <label className="text-xs py-1 block font-light">{label}</label>
      )}
      <div
        ref={inputContainerRef}
        className={`flex items-center border border-0.5 border-neutralBg4 bg-neutralBg1 rounded-sm px-2 py-1 focus-within:border-infoText5 transition ${
          inputProps.disabled ? "bg-neutralBg3" : ""
        } ${inputContainerClasses}`}
      >
        {icon}
        <input
          ref={inputRef}
          {...inputProps}
          onKeyDown={(event) => {
            if (allowNumOnly) {
              if (
                event.code.includes("Digit") ||
                event.code.includes("Numpad") ||
                event.code.includes("Period") ||
                event.code.includes("Backspace") ||
                event.code.includes("Enter")
              ) {
                // return true;
              } else {
                event.preventDefault();
                return false;
              }
            }
          }}
          className={
            "bg-transparent text-sm text-neutralText2 px-2 w-full outline-none"
          }
        />
      </div>
    </div>
  );
}
export default CustomInput;

export const CustomSelect = ({
  label,
  options,
  containerClasses,
  ...inputProps
}) => {
  return (
    <div className={"w-full " + containerClasses}>
      <div className="text-neutralText2 text-xs py-1 block font-light">
        {label}
      </div>
      <select
        {...inputProps}
        type="text"
        name="transactionType"
        placeholder="Select Transaction Type"
        className="bg-transparent text-sm text-neutralText2 px-2 py-1.5 w-full outline-none border border-0.5 border-neutralBg4 bg-neutralBg1"
      >
        {options.map((el) => (
          <option className="text-neutralText4" key={el.key} value={el.value}>
            {el.key}
          </option>
        ))}
      </select>
    </div>
  );
};
