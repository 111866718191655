import React, { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { StepConnector } from "@mui/material";

import AddSalesperson from "../../assets/learnAssets/images/Add-Salesperson.mp4";
import AddVendor from "../../assets/learnAssets/images/Add-retailer.mp4";
import AddVisit from "../../assets/learnAssets/images/Assign-visit.mp4";
import BaseButton from "../common/baseButton";

const steps = [
  "Add a Salesperson",
  "Add a Retailer",
  "Assign visits to Salesperson",

  // "Add a Product",
  // "Add transactions",
];

const LearnSteps = () => {
  const [activeStep, setActiveStep] = useState(0);
  return (
    <div className="sm:pt-24 m-auto xl:w-10/12 2xl:w-9/12">
      <div className="hidden sm:block">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={
            <StepConnector
              classes={{
                root: "lg:!px-1",
              }}
            />
          }
        >
          {steps.map((label, index) => {
            return (
              <Step key={label} classes={{ root: "!px-0" }}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      active: "!fill-infoText5",
                      completed: "!fill-successBg5",
                      text: "!font-mulish !font-light",
                      root: "sm:!h-8 sm:!w-8 lg:!h-12 lg:!w-12 sm:!-mt-1 lg:!-mt-3 !z-20",
                    },
                  }}
                  classes={{
                    label:
                      "!text-neutralText3 !font-mulish !font-light !text-sm md:!text-base lg:!text-xl !font-normal",
                    active: "!text-neutralText3 !font-mulish !font-light",
                    completed: "!text-neutralText3 !font-mulish !font-light",
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>

      {activeStep === 0 ? (
        <div className="text-center px-6 sm:px-0 pt-8 sm:pt-16">
          <div className="text-2xl sm:text-3xl font-bold">
            Add your sales team
          </div>
          <div className="py-4 text-neutralText5 text-base sm:text-xl font-semibold w-3/4 md:w-1/2 m-auto">
            BusyBee helps manage your sales-teams and retailers from one place
            without bugging you with complex programs.
          </div>
          <BaseButton
            text={"Next"}
            onClick={() => setActiveStep(1)}
            className={
              "bg-infoText4 py-1 px-12 sm:py-3 sm:px-24 sm:text-xl text-neutralBg1"
            }
          />
          <video
            src={AddSalesperson}
            loop
            autoPlay={true}
            muted
            playsInline
            className="m-auto my-5"
            alt="add new salesperson"
          />
        </div>
      ) : activeStep === 1 ? (
        <div className="text-center px-6 sm:px-0 pt-8 sm:pt-16">
          <div className="text-2xl sm:text-3xl font-bold">
            Add your Retailers
          </div>
          <div className="py-4 text-neutralText5 text-base sm:text-xl font-semibold w-3/4 md:w-1/2 m-auto">
            Add your retailers along with their balance details and have your
            salespersons map them
          </div>
          <BaseButton
            text={"Next"}
            onClick={() => setActiveStep(2)}
            className={
              "bg-infoText4 py-1 px-12 sm:py-3 sm:px-24 sm:text-xl text-neutralBg1"
            }
          />
          <video
            src={AddVendor}
            loop
            autoPlay={true}
            muted
            playsInline
            className=" m-auto my-5"
            alt="add new retailer"
          />
        </div>
      ) : activeStep === 2 ? (
        <div className="text-center px-6 sm:px-0 pt-8 sm:pt-16">
          <div className="text-2xl sm:text-3xl font-bold">Assign Visits</div>
          <div className="py-4 text-neutralText5 text-base sm:text-xl font-semibold w-3/4 md:w-1/2 m-auto">
            Once your retailers are added you can assign visits to their
            respective salespersons for tasks like making collections, or
            delivering and taking orders
          </div>
          {/* <BaseButton
            text={"Next"}
            onClick={() => setActiveStep(2)}
            className={
              "bg-infoText4 py-1 px-12 sm:py-3 sm:px-24 sm:text-xl text-neutralBg1"
            }
          /> */}
          <video
            src={AddVisit}
            loop
            autoPlay={true}
            muted
            playsInline
            className="m-auto my-5"
            alt="add new visit"
          />
        </div>
      ) : /* <img src={sliderImage1} alt="vid" className="m-auto" /> */

      null}
    </div>
  );
};
export default LearnSteps;
