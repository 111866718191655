import React from "react";
import { useEffect, useState } from "react";

import CustomTable from "../../components/common/table";
import { getVendorList } from "../../services/vendorsApi";
import EmptyFallBack from "../../components/common/emptyFallback";
import noVendors from "../../assets/noVendors.svg";
import millify from "millify";
import getCurrency from "../../utils/getCurrency";
const columns = [
  { key: "name", name: "RETAILER" },
  { key: "sales", name: "SALE" },
  { key: "address", name: "ADDRESS" },
  { key: "collection", name: "PAID" },
  { key: "balance", name: "BALANCE" },
];
export const AssignedVendorList = ({ spId }) => {
  const dataGridRef = React.useRef(null);
  const [assignedVendors, setAssignedVendors] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableLoading, setTableLoading] = useState(false);

  const getData = async ({ page }) => {
    setTableLoading(true);
    const data = await getVendorList({
      offset: page,
      limit: rowsPerPage,
      spId,
      // startDate: date.startDate,
      // endDate: date.endDate,
    });
    setOffset(page - 1);
    setAssignedVendors(data.result || []);
    setCount(data.count);
    setTableLoading(false);
  };

  useEffect(() => {
    getData({ page: 1 });
  }, [rowsPerPage]);

  useEffect(() => {
    const temp = assignedVendors?.map((el) => {
      return {
        ...el,
        address: <div className="truncate">{el?.locationName}</div>,
        collection: (
          <div className="px-3 py-0.5 rounded-full text-neutralText4 text-xs bg-successBg2 truncate">
            {getCurrency()} {millify(el?.collection||0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </div>
        ),
        balance: (
          <div className="px-3 py-0.5 rounded-full text-neutralText4 text-xs bg-warningBg2 truncate">
            {getCurrency()} {millify(el?.balance||0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </div>
        ),
        name: (
          <span className="font-medium  text-infoText4 flex flex-row items-center truncate">
            {el?.businessName}
          </span>
        ),
        status:
          el?.status === "ACTIVE" ? (
            <div className="bg-green-100 text-green-700 py-1 rounded-full px-1">
              {el?.status}
            </div>
          ) : (
            <div className="bg-gray-300 text-gray-500 py-1 rounded-full px-1">
              {el?.status}
            </div>
          ),
      };
    });
    setModifiedData(temp);
  }, [assignedVendors]);

  return (
    <div className="bg-neutralBg1 border-x-[0.5px] border-b-[0.5px] border-neutralBg4 px-6 py-6">
      <div
        className="flex flex-row justify-between items-center pb-4"
        onClick={() => {
          // Fix for `cellFocus (select) breaks input functionality`
          dataGridRef.current.selectCell(
            {
              idx: 0,
              rowIdx: -1,
              mode: "SELECT",
            },
            true
          );
        }}
      >
        <div className="text-base text-neutralText1 font-medium">
          List of Assigned Retailers
        </div>
      </div>
      <CustomTable
        dataGridRef={dataGridRef}
        columns={columns}
        rows={modifiedData}
        count={count}
        page={offset}
        bodyRowProps={{
          path: "/view-vendor",
          id: "vendorId",
        }}
        onPageChange={(_, page) => getData({ page: page + 1 })}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
        loading={tableLoading}
        emptyFallBack={
          <EmptyFallBack
            image={noVendors}
            text="No Retailers found"
            containerClass={"mt-14 mb-4"}
            imageClass={"h-44"}
          />
        }
      />
    </div>
  );
};
export default AssignedVendorList;
