import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import tailwindConfig from "../../theme/tailwindConfig";

function Loader({ color, ...props }) {
  return <ClipLoader {...props} color={tailwindConfig.theme.colors[color]} />;
}

export default Loader;
