import React, { useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Unicons from "@iconscout/react-unicons";

import BaseButton from "../common/baseButton";
import CustomInput from "../common/customInput";
import { changePassword } from "../../services/accountSettingsApi";

function ChangePassword({ handleCloseModal }) {
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [loader, setLoader] = useState(false);

  const handleChangePassword = async () => {
    setLoader(true);
    console.log(
      "old password",
      oldPassword,
      "new password",
      newPassword,
      "confirm password",
      confirmPassword
    );
    const res = await changePassword(oldPassword, newPassword);
    console.log("res", res);
    if (res.status === "OK") {
      toast.success("Password Changed");
      handleCloseModal();
    }
    setLoader(false);
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={""}
      onSubmit={(values) => {
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <CustomInput
            onChange={(e) => setOldPassword(e.target.value)}
            label={"Old Password"}
            type="password"
          />
          <CustomInput
            onChange={(e) => setNewPassword(e.target.value)}
            label={"New Password"}
            type="password"
          />
          <CustomInput
            onChange={(e) => setConfirmPassword(e.target.value)}
            label={"Confirm New Password"}
            type="password"
          />
          <div className="pt-8 text-right">
            <BaseButton
              onClick={() => handleChangePassword()}
              text={"Change Password"}
              loading={loader}
            />
          </div>
        </form>
      )}
    </Formik>
  );
}

export default ChangePassword;
