import { createTheme } from "./utils";

export const lightPallette = createTheme({
  infoText1: "#1e40af",
  infoText2: "#1d4ed8",
  infoText3: "#0789d1",
  infoText4: "#00a3ff",
  infoText5: "#4ebfff",
  infoBg1: "#eff6ff",
  infoBg2: "#c8ebff",
  infoBg3: "#bfdbfe",
  infoBg4: "#93c5fd",
  infoBg5: "#60a5fa",
  primaryDark: "#e5aa33",
  primary: "#ffe91f",
  primaryLight: "#ffcc67",
  successText1: "#064e3b",
  successText2: "#065f46",
  successText3: "#047857",
  successText4: "#1f856e",
  successText5: "#2bb596",
  successBg1: "#ecfdf5",
  successBg2: "#d1ffd3",
  successBg3: "#a7f3d0",
  successBg4: "#6ee7b7",
  successBg5: "#34d399",
  warningText1: "#78350f",
  warningText2: "#92400e",
  warningText3: "#b45309",
  warningText4: "#d97706",
  warningText5: "#f59e0b",
  warningBg1: "#fffbeb",
  warningBg2: "#fef3c7",
  warningBg3: "#fde68a",
  warningBg4: "#fcd34d",
  warningBg5: "#fbbf24",
  errorBg1: "#fef2f2",
  errorBg2: "#fee2e2",
  errorBg3: "#ffc0c0",
  errorBg4: "#fca5a5",
  errorBg5: "#f87171",
  errorText1: "#7f1d1d",
  errorText2: "#991b1b",
  errorText3: "#b91c1c",
  errorText4: "#dc2626",
  errorText5: "#ef4444",
  neutralText1: "#19191b",
  neutralText2: "#4d4d4d",
  neutralText3: "#696969",
  neutralText4: "#818181",
  neutralText5: "#999999",
  neutralBg1: "#ffffff",
  neutralBg2: "#f6f6f6",
  neutralBg3: "#ededed",
  neutralBg4: "#d2d2d2",
  neutralBg5: "#afafaf",
  black: "#4d4d4d",
  white: "#fff",
  secondaryDark: "#619181",
  secondary: "#75B09C",
  secondaryLight: "#89CFB7",
});

export const darkPallette = createTheme({
  infoText1: "#EFF6FF",
  infoText2: "#C8EBFF",
  infoText3: "#BFDBFE",
  infoText4: "#93C5FD",
  infoText5: "#60A5FA",
  infoBg1: "#4C5A87",
  infoBg2: "#6776A1",
  infoBg3: "#5B99BD",
  infoBg4: "#48B3F0",
  infoBg5: "#6BCAFF",
  successText1: "#ECFDF5",
  successText2: "#D1FFD3",
  successText3: "#A7F3D0",
  successText4: "#6EE7B7",
  successText5: "#39E5A6",
  successBg1: "#3F7667",
  successBg2: "#558275",
  successBg3: "#609686",
  successBg4: "#5A9B8D",
  successBg5: "#32D1AD",
  warningText1: "#FFFBEB",
  warningText2: "#FEF3C7",
  warningText3: "#FDE68A",
  warningText4: "#FCD34D",
  warningText5: "#FBBF24",
  warningBg1: "#5B463B",
  warningBg2: "#807052",
  warningBg3: "#B48E09",
  warningBg4: "#D9B706",
  warningBg5: "#F59E0B",
  errorBg1: "#925D5D",
  errorBg2: "#A86C6C",
  errorBg3: "#D17575",
  errorBg4: "#DB5151",
  errorBg5: "#EF4444",
  errorText1: "#FEF2F2",
  errorText2: "#FEE2E2",
  errorText3: "#FFC0C0",
  errorText4: "#FCA5A5",
  errorText5: "#F87171",
  neutralText1: "#FFFFFF",
  neutralText2: "#F6F6F6",
  neutralText3: "#EDEDED",
  neutralText4: "#D2D2D2",
  neutralText5: "#AFAFAF",
  neutralBg1: "#4D4D4D",
  neutralBg2: "#383838",
  neutralBg3: "#696969",
  neutralBg4: "#818181",
  neutralBg5: "#999999",
  black: "#4d4d4d",
  white: "#fff",
  primaryDark: "#e5aa33",
  primary: "#ffe91f",
  primaryLight: "#ffcc67",
  secondaryDark: "#619181",
  secondary: "#75B09C",
  secondaryLight: "#89CFB7",
});
