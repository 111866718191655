import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import { createOrder, editOrder } from "../../services/ordersApi";
import BaseButton from "../common/baseButton";
import SelectVendorCard from "../selectVendorCard";

const ConfirmOrder = ({
  selectedVendor,
  products,
  setProducts,
  next,
  prev,
  handleClose,
  edit,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([...products]);

  const handleSelectedProducts = (id, action, val) => {
    const arr = products;
    arr.map((el) => {
      if (el.id === id) {
        val
          ? (el.value = val)
          : action === "add"
          ? (el.value = el.value + 1)
          : (el.value = el.value - 1);
      }
      return el;
    });
    setSelectedProducts([...arr]);
    return setProducts(arr);
  };

  const deleteProduct = (id) => {
    const arr = products;
    arr.map((el) => {
      if (el.id === id) {
        products.pop(el);
      }
    });
    setSelectedProducts([...arr]);
    return setProducts(arr);
  };

  useEffect(() => {}, [products]);

  const handleCreateOrder = async (vendor, products) => {
    setLoading(true);
    const orderedProducts = selectedProducts.map((el) => {
      return { productId: el.id, quantity: el.value };
    });

    const orderPayload = orderedProducts.filter((el) => el.quantity > 0);

    const resp = edit
      ? await editOrder(selectedVendor.id, orderPayload, edit.id)
      : await createOrder(selectedVendor.id, orderPayload);
    console.log("orderedProducts.value ", resp);
    if (resp) {
      edit
        ? toast.success("Order updated successfully")
        : toast.success("Order created successfully");
    }
    console.log("resp..", !!resp);
    if (!!resp) handleClose();
    setLoading(false);
  };

  console.log("edit", edit);

  return (
    <>
      <div className="p-2">
        <div className="text-xs text-neutralText4 py-1">Order by</div>
        <SelectVendorCard vendor={selectedVendor} />
      </div>
      <div>
        <div className="px-2">
          <div className="text-neutralText4 text-xs py-1">
            {products.length} Product(s) added
          </div>
          {products.map(
            (el, index) =>
              el.value > 0 && (
                <div key={index} className="py-1">
                  <div className="flex items-center justify-between text-sm bg-neutralBg1 px-3 p-2">
                    <div className="text-xs">
                      <div className="text-neutralText2">{el.name}</div>
                      <div className="text-neutralText4">{el.unit}</div>
                    </div>
                    <div className="flex items-center jusify-between">
                      <button
                        disabled={el.value < 1}
                        className={`text-black ${
                          el.value === 0 ? "bg-neutralBg5" : "bg-primary "
                        } px-2`}
                        onClick={() =>
                          el.value === 0
                            ? deleteProduct(el.id)
                            : handleSelectedProducts(el.id, "sub")
                        }
                      >
                        -
                      </button>
                      <input
                        onChange={(e) =>
                          handleSelectedProducts(
                            el.id,
                            "",
                            Number(e.target.value)
                          )
                        }
                        className="px-2 outline-none w-10 text-neutralText-1 bg-neutralBg1 text-center"
                        value={el.value}
                      />
                      <button
                        className="bg-primary px-2 mr-2 text-black"
                        onClick={() => handleSelectedProducts(el.id, "add")}
                      >
                        +
                      </button>
                      <button
                        onClick={
                          () => deleteProduct(el.id)

                          // selectedProducts.map((item) => {
                          //   if (item.id === el.id) {
                          //     products.pop(el);
                          //   }
                          //   return selectedProducts;
                          // })
                        }
                        className="text-neutralText5"
                      >
                        <FiX key={index} />
                      </button>
                    </div>
                  </div>
                </div>
              )
          )}
          <div className="flex justify-between px-1 pt-8">
            <BaseButton
              text={"Back"}
              onClick={prev}
              type={"bg-transparent font-black border border-successText5"}
              textColor="text-successText1"
              width={"w-40"}
            />
            <BaseButton
              disabled={!products.filter((item) => item.value > 0).length > 0}
              text={edit ? "Edit Order" : "Confirm Order"}
              onClick={() => handleCreateOrder(products.id, products)}
              type={`${
                !products.filter((item) => item.value > 0).length > 0
                  ? "bg-neutralText4"
                  : "bg-successText5"
              }`}
              loading={loading}
              textColor="neutralBg1"
              width={"w-40"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmOrder;
