import React from "react";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
const Breadcrumb = function (props) {
  return (
    <nav className="flex flex-row text-base items-center 2xl:text-lg">
      <ul className="flex items-center">
        <li className="underline underline-offset-4 text-[20px] text-[#60A5FA] ">
          <Link to="/learn">Learn</Link>
        </li>
        <div className="mx-5">
          <FiChevronRight className="w-5 h-5 stroke-black" />
        </div>
        <li className="underline underline-offset-4">
          <Link className="text-[#60A5FA] text-[20px]" to={props.data}>
            {props.data}
          </Link>
        </li>
      </ul>
    </nav>
  );
};
export default Breadcrumb;
