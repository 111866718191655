import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import * as Unicons from "@iconscout/react-unicons";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import tailwindConfig from "../../theme/tailwindConfig";

import BaseButton from "../../components/common/baseButton";
import LandingHeader from "../../components/landingComponents/landingHeader";
import LandingFooter from "../../components/landingComponents/landingFooter";
import LandingNav from "../../components/landingComponents/landingNav";
import mascot from "../../assets/aboutPageImg.svg";
import drivingBee from "../../assets/drivingBee.svg";
import CompanyCards from "../../components/landingComponents/companyCards";
import {
  TimelineConnector,
  TimelineContent,
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  TimelineOppositeContent,
} from "@mui/lab";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: 12,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: tailwindConfig.theme.colors.neutralText1,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: tailwindConfig.theme.colors.successText5,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    border: 0,
    width: 4,
    display: "flex",
    justifyContent: "center",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 15,
  height: 15,
  display: "flex",
  borderRadius: "50%",
  padding: 0,
  border: 10,
  // justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: tailwindConfig.theme.colors.successText5,
  }),
  ...(ownerState.completed && {
    backgroundColor: tailwindConfig.theme.colors.success,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: (
      <div className="h-1 w-1 bg-neutralBg1 rounded-full justify-self-center self-center border-3 border-secondary"></div>
    ),
    2: <div></div>,
    3: <div></div>,
    4: <div></div>,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={"items-center justify-center"}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  {
    label: "2019",
    description:
      "BusyBee helps manage your sales-teams and retailers from one place",
  },
  {
    label: "2020",
    description:
      "BusyBee helps manage your sales-teams and retailers from one place",
  },
  {
    label: "2021",
    description:
      "BusyBee helps manage your sales-teams and retailers from one place",
  },
  {
    label: "2022",
    description:
      "BusyBee helps manage your sales-teams and retailers from one place",
  },
];

const Company = () => {
  const [activeStep, setActiveStep] = React.useState(3);
  return (
    <div className="contain">
      <LandingNav />
      <div className="lg:pl-20 xl:pl-32 lg:pt-12 2xl:pl-36 lg:flex items-center justify-between bg-backgroundLogoV bg-right-top bg-no-repeat">
        <div className="w-11/12 md:w-10/12 mx-auto text-center lg:mx-0 lg:text-left py-8 lg:py-0 lg:6/12 2xl:w-5/12">
          <LandingHeader
            header="Mission"
            body="Making Field Sales Management easy and accessible"
            bodyClass={"pb-8 sm:pb-0 max-w-[30rem]"}
          />
          <p className="text-neutralText3 text-lg font-roboto">
            Monitor sales teams remotely.
          </p>
          <p className="text-neutralText3 text-lg font-roboto">
            Make retailer dealings transparent.
          </p>
        </div>
        <div className="w-10/12 m-auto">
          <img src={mascot} className="-mt-14" />
        </div>
      </div>
      <div className="bg-[#5F9988] lg:bg-drivingBeeBg bg-contain h-max flex-col-reverse lg:flex-row flex items-center justify-between">
        <div className="lg:w-7/12">
          <img src={drivingBee} />
        </div>
        <div className="w-11/12 sm:w-8/12 lg:w-5/12  mx-auto text-center lg:mx-0 lg:text-left pt-8 sm:pt-24 lg:pt-0">
          <div className="text-neutralBg1 font-roboto font-extrabold text-[11px] sm:text-[13px] tracking-ultra-wide py-2 ">
            VISION
          </div>
          <div className="lg:w-9/12 2xl:text-[4rem] lg:text-[3rem] sm:text-[3rem] text-[1.9rem] font-bold text-neutralBg1 leading-[38px] sm:leading-[48px] lg:leading-[55px] xl:leading-[56px] 2xl:leading-[70px]">
            Your go to sales management tool
          </div>
          <p className="text-neutralBg1 text-xl font-roboto max-w-[24rem] py-4">
            BusyBee envisions a market where sales teams work efficiently and
            retailers and organisation stay connected to eliminate over dependence
            on salespersons
          </p>
        </div>
      </div>
      <div className="w-11/12 m-auto pt-8 pb-12 grid grid-cols-1 xl:grid-cols-2 items-center justify-between">
        <div className="w-full">
          <LandingHeader
            header="OUR STORY"
            body="How it all started"
            bodyClass={"pb-0"}
          />
          <p className="text-neutralText3 text-lg font-roboto">
            A big problem with many of the local businesses here was that they
            had no idea who or where their retailers actually were. These
            businesses were only tethered to their retailers through the sales
            agents. And in 2019 we decided to address the issue with a system
            that solves the problem but does not require the businesses to
            completely revamp their existing workflow. Busybee integrates
            seamlessly into business workflows.
          </p>
        </div>
        <div className=" w-full">
          <Timeline position="alternate">
            <TimelineItem>
              <TimelineOppositeContent>
                <p className="bg-clip-text font-bold bg-gradient-radial-text from-successBg5 to-neutralText1 text-xl">
                  2019
                </p>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <Unicons.UilCircle className="text-secondary" size={24} />
                <TimelineConnector
                  sx={{ bgcolor: tailwindConfig.theme.colors.secondary }}
                />
              </TimelineSeparator>
              <TimelineContent>
                <p className="text-neutralText3 text-lg font-roboto">
                  Initial conception, <br />
                  market survey
                </p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <p className="bg-clip-text font-bold bg-gradient-radial-text from-successBg5 to-neutralText1 text-xl">
                  2020
                </p>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <Unicons.UilCircle className="text-secondary" size={24} />
                <TimelineConnector
                  sx={{ bgcolor: tailwindConfig.theme.colors.secondary }}
                />
              </TimelineSeparator>
              <TimelineContent>
                <p className="text-neutralText3 text-lg font-roboto">
                  Product Development commencement
                </p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <p className="bg-clip-text font-bold bg-gradient-radial-text from-successBg5 to-neutralText1 text-xl">
                  2021
                </p>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <Unicons.UilCircle className="text-secondary" size={24} />
                <TimelineConnector
                  sx={{ bgcolor: tailwindConfig.theme.colors.secondary }}
                />
              </TimelineSeparator>
              <TimelineContent>
                <p className="text-neutralText3 text-lg font-roboto">
                  Initial user trials <br />
                  and feedback
                </p>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <p className="bg-clip-text font-bold bg-gradient-radial-text from-successBg5 to-neutralText1 text-xl">
                  2022
                </p>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <Unicons.UilCircle className="text-secondary" size={24} />
                <TimelineConnector
                  sx={{ bgcolor: tailwindConfig.theme.colors.secondary }}
                />
              </TimelineSeparator>
              <TimelineContent>
                <p className="text-neutralText3 text-lg font-roboto">
                  Product finalisation, <br />
                  official launch
                </p>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
      </div>
      {/* <div className="text-center"> */}
      {/* </div> */}
      <div className="pb-32">
        <div className="2xl:text-[4rem] lg:text-[3rem] sm:text-[3rem] text-[1.9rem] w-fit m-auto pb-24 text-transparent bg-clip-text font-black bg-gradient-radial-sm-text from-successBg5 to-neutralText1 leading-[38px] sm:leading-[48px] lg:leading-[55px] xl:leading-[60px] 2xl:leading-[70px] ">
          Our Values
        </div>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-y-20 grid-flow-row auto-rows-max gap-x-6 w-10/12 xl:w-11/12 2xl:w-10/12 m-auto">
          <CompanyCards
            header="Creative"
            body="To carve out creative solutions to novel problems"
          />
          <CompanyCards
            header="Helpful"
            body="To make empathic connections for truly helpful endeavours"
          />
          <CompanyCards
            header="Experimental"
            body="To be willing to undertake brave experimentation"
          />
          <CompanyCards
            header="Innovative"
            body="To not miss any opportunity to innovate even in simple tasks"
          />
          <CompanyCards
            header="Tech Savy"
            body="To try out and incorporate latest technologies in methods"
          />
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};
export default Company;
