module.exports = {
  content: ["./src/**/*.{js,jsx}"],
  // darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      backgroundImage: (theme) => ({
        "gradient-radial-to-br":
          "radial-gradient(100% 100% at 0% 0%,var(--tw-gradient-from, var(--tw-gradient-stops)),var(--tw-gradient-to, var(--tw-gradient-stops)))",
        "gradient-radial-to-tl":
          "radial-gradient(100% 100% at 100% 100%,var(--tw-gradient-from, var(--tw-gradient-stops)),var(--tw-gradient-to, var(--tw-gradient-stops)))",
        "gradient-radial-to-tr":
          "radial-gradient(100% 100% at 0% 100%,var(--tw-gradient-from, var(--tw-gradient-stops)),var(--tw-gradient-to, var(--tw-gradient-stops)))",
        "gradient-radial-to-bl":
          "radial-gradient(100% 100% at 100% 0%,var(--tw-gradient-from, var(--tw-gradient-stops)),var(--tw-gradient-to, var(--tw-gradient-stops)))",
        "gradient-radial-text":
          "radial-gradient(50% 150% at 0% 0%,var(--tw-gradient-from, var(--tw-gradient-stops)),var(--tw-gradient-to, var(--tw-gradient-stops)))",
        "gradient-radial-sm-text":
          "radial-gradient(65% 120% at 0% 0%,var(--tw-gradient-from, var(--tw-gradient-stops)),var(--tw-gradient-to, var(--tw-gradient-stops)))",
        backgroundLogoV: "url('../assets/landingBackgroundV.svg')",
        backgroundLogoA: "url('../assets/landingBackgroundA.svg')",
        backgroundBuildings: "url('../assets/buildingsBg.svg')",
        loginBg: "url('../assets/loginBg.svg')",
        drivingBeeBg: "url('../assets/drivingBeeBg.svg')",
      }),
      // backgroundImage: (theme) => ({
      //   backgroundLogo: "url('../assets/landingBackground.png')",
      // }),
      fontSize: {
        xxs: ".5rem",
        lxxs: ".7rem",
      },
      height: {
        fixed: "710px",
      },
      boxShadow: {
        primary: " 0px 5px 34px rgba(255, 189, 57, 0.42)",
      },
      colors: {
        infoText1: "var(--theme-info-text-1)",
        infoText2: "var(--theme-info-text-2)",
        infoText3: "var(--theme-info-text-3)",
        infoText4: "var(--theme-info-text-4)",
        infoText5: "var(--theme-info-text-5)",
        infoBg1: "var(--theme-info-bg-1)",
        infoBg2: "var(--theme-info-bg-2)",
        infoBg3: "var(--theme-info-bg-3)",
        infoBg4: "var(--theme-info-bg-4)",
        infoBg5: "var(--theme-info-bg-5)",
        primaryDark: "var(--theme-primary-dark)",
        primary: "var(--theme-primary)",
        primaryLight: "var(--theme-primary-light)",
        successText1: "var(--theme-success-text-1)",
        successText2: "var(--theme-success-text-2)",
        successText3: "var(--theme-success-text-3)",
        successText4: "var(--theme-success-text-4)",
        successText5: "var(--theme-success-text-5)",
        successBg1: "var(--theme-success-bg-1)",
        successBg2: "var(--theme-success-bg-2)",
        successBg3: "var(--theme-success-bg-3)",
        successBg4: "var(--theme-success-bg-4)",
        successBg5: "var(--theme-success-bg-5)",
        warningText1: "var(--theme-warning-text-1)",
        warningText2: "var(--theme-warning-text-2)",
        warningText3: "var(--theme-warning-text-3)",
        warningText4: "var(--theme-warning-text-4)",
        warningText5: "var(--theme-warning-text-5)",
        warningBg1: "var(--theme-warning-bg-1)",
        warningBg2: "var(--theme-warning-bg-2)",
        warningBg3: "var(--theme-warning-bg-3)",
        warningBg4: "var(--theme-warning-bg-4)",
        warningBg5: "var(--theme-warning-bg-5)",
        errorBg1: "var(--theme-error-bg-1)",
        errorBg2: "var(--theme-error-bg-2)",
        errorBg3: "var(--theme-error-bg-3)",
        errorBg4: "var(--theme-error-bg-4)",
        errorBg5: "var(--theme-error-bg-5)",
        errorText1: "var(--theme-error-text-1)",
        errorText2: "var(--theme-error-text-2)",
        errorText3: "var(--theme-error-text-3)",
        errorText4: "var(--theme-error-text-4)",
        errorText5: "var(--theme-error-text-5)",
        neutralText1: "var(--theme-neutral-text-1)",
        neutralText2: "var(--theme-neutral-text-2)",
        neutralText3: "var(--theme-neutral-text-3)",
        neutralText4: "var(--theme-neutral-text-4)",
        neutralText5: "var(--theme-neutral-text-5)",
        neutralBg1: "var(--theme-neutral-bg-1)",
        neutralBg2: "var(--theme-neutral-bg-2)",
        neutralBg3: "var(--theme-neutral-bg-3)",
        neutralBg4: "var(--theme-neutral-bg-4)",
        neutralBg5: "var(--theme-neutral-bg-5)",
        black: "var(--theme-black)",
        white: "var(--theme-white)",
        secondaryDark: "var(--theme-secondary-dark)",
        secondary: "var(--theme-secondary)",
        secondaryLight: "var(--theme-secondary-light)",
      },
      letterSpacing: {
        "ultra-wide": ".55rem",
      },
      fontFamily: {
        mulish: "Mulish",
        roboto: "Roboto Condensed",
      },

      strokeWidth: {
        3: "3px",
      },
    },
  },
  // variants: {
  //   // backgroundColor: ["active"],
  //   borderStyle: ["responsive", "hover"],
  //   borderWidth: ["responsive", "hover"],
  // },
  plugins: [
    // ...
    require("@tailwindcss/line-clamp"),
    require("tailwind-scrollbar"),
  ],
};
