const links = [
        {
            id: "1",
            text: "How do I register to use BusyBee for my Business?",
            link: "/",
            category: "Registration",
            image: [
                {
                    img: "RA-1.png",
                    caption: ""
                },
                {
                    img: "RA-2.png",
                    caption: ""
                },
                {
                    img: "RA-3.png",
                    caption: ""
                },
                {
                    img: "RA-4.png",
                    caption: ""
                },
                    ], 
            description: "Registering on BusyBee is easy, just open BusyBee from your browser and click ‘try for free’. Fill in your business details and hit submit to start your free trial right away."
        },
        {
            id: "2",
            text: "Setting up my BusyBee Account.",
            link: "/SettingUp",
            category: "Registration",
            image: [
                {
                    img: "Setting1.png",
                    caption: ""
                }
                    ],
            description: "Once you arrive at your dashboard for the first time you will be greeted by a set up guide to walk you through the steps to add your sales team and retailers."
        },
        {
            id: "1",
            text: "How do I view / edit my profile and Account details and settings?",
            link: "/EditAccount",
            category: "Account",
            image: [
                {
                    img: "Profile-1.png",
                    caption: ""
                },
                {
                    img: "Profile-2.png",
                    caption: ""
                },
                {
                    img: "Profile-3.png",
                    caption: ""
                },
                {
                    img: "Profile-4.png",
                    caption: ""
                }
                ],
            description: "You can view your BusyBee profile by going to the BusyBee dashboard from your browser then click on your profile icon in the top left corner and click ‘View Account’. This will take you to your profile overview page where you can edit all the details you want."
        },
        {
            id: "2",
            text: "How do I set/change my currency unit?",
            link: "/ChangeCurrency",
            category: "Account",
            image: [
                {
                    img: "Currency-1.png",
                    caption: ""
                },
                {
                    img: "Currency-2.png",
                    caption: ""
                },
                {
                    img: "Currency-3.png",
                    caption: ""
                }, 
                {
                    img: "Currency-4.png",
                    caption: ""
                }
                    ],
            description: "You assign currency units for transactions from your profile settings. Simply click on the profile icon in the top right corner, then click on ‘View Account’. This will take you to your profile overview page where you can scroll down to ‘Currency’, displayed next to it will be your currency unit."
        },
        {
            id: "3",
            text: "Can I log activities?",
            link: "/LogActivities",
            category: "Account",
            image: [
                {
                    img: "Log-1.png",
                    caption: ""
                },
                {
                    img: "Log-2.png",
                    caption: ""
                }
                    ],
            description: "BusyBee automatically records activities like new transactions, assignments to salespersons, visit completions, orders, app logins, etc so that you always stay on top of your business activities. You can see the complete log of activities from your BusyBee dashboard by scrolling down to the activities section in the bottom right of you dashboard."
        },
        {
            id: "4",
            text: "Will I get notified on important activities?",
            link: "/GetNotified",
            category: "Account",
            image: [
                {
                    img: "Notify-1.png",
                    caption: ""
                },
                {
                    img: "Notify-2.png",
                    caption: ""
                }
            ],
            description: "You will find notificategoryions on specific activities like new orders, new retailer added,  visit completion, etc. right from your dashboard. To view notificategoryions click on the bell icon in the top right corner."
        },
        {
            id: "5",
            text: "How do I make the best use of information graphs on my dashboard?",
            link: "/InfoGraphs",
            category: "Account",
            image: [
                {
                    img: "Graph-1.png",
                    caption: ""
                }
            ],
            description: "BusyBee puts together handy graphs representing your sales, collections, retailers and visits information. Graphs help visualise information for an intuitive understanding of your growth trend. Graphs by default display data for one month up to the current date. You can however, narrow in on any time period using the calendar date filter."
        },
        {
            id: "1",
            text: "How do I add new salespersons?",
            link: "/AddSalesPerson",
            category: "Salesperson",
            image: [
                {
                    img: "AddPerson-1.png",
                    caption: ""
                },
                {
                    img: "AddPerson-2.png",
                    caption: ""
                },
                {
                    img: "AddPerson-3.png",
                    caption: ""
                },
                {
                    img: "AddPerson-4.png",
                    caption: ""
                },
                    ],
            description: "To add a new salesperson simply go to the ‘Salespersons’ tab of the BusyBee app from your browser. Click on the the ‘Add New Salesperson’ button and fill in the salesperson details. Hit ‘Add’ when finished. The new salesperson will be displayed in the table as ‘Inactive’ which means you won’t be able to assign them visits. To activate the salesperson, click on the red ‘Inactive’ label next to the salesperson; you will be asked to create an id for the salesperson which the salesperson would use to login from their mobile device. After you type in the id, hit activate and an email along with the credentials will be sent to the salesperson. The salesperson will find the BusyBee Salesperson app download link as well as the id in the email"
        },
        {
            id: "2",
            text: "How do I add salespersons from my existing database?",
            link: "/AddFromDB",
            category: "Salesperson",
            image: [
                {
                    img: "DB-1.png",
                    caption: "2. Upload your .CSV file by clicking on ’SELECT FILE’. Wait for the file to upload and then click ‘Proceed’. BusyBee will run the algorithm to see if your file contains required field headers. Click ‘Proceed’.",
                },
                {
                    img: "DB-2.png",
                    caption: "3. Once the file is checked for headers you will be presented with an overview of your file. Any empty fields will be marked and that particular record will not be added."
                },
                {
                    img: "DB-3.png",
                    caption: "The added salespersons will be displayed in the salespersons table."
                },
                {
                    img: "DB-4.png",
                    caption: ""
                }
            ],
            description:"You can add all salespersons from your current database by uploading the salesperson list in a .CSV file with the following headers - email, name, locategoryionName, contact. To make things even easier, you can download the .CSV template and use it. To download the template go to the ‘Salespersons’ tab, click on ‘Import CSV’ and then click on ‘Download our CSV template’. You can know more about .CSV files here. Once you have prepared you .CSV containing salespersons, upload it to your BusyBee by going to the ‘Salespersons’ tab from your browser, then follow these 4 simple steps-"        
        },
        {
            id: "3",
            text: "What if I want to remove a salesperson?",
            link: "/RemoveSalesPerson",
            category: "Salesperson",
            image: [
                {
                    img: "RemovePerson-1.png",
                    caption: ""
                }
                    ],
            description: "Salespersons cannot be completely removed from records, but if a salesperson does not work for you any more you can deactivate that salesperson anytime by going to the ‘Salespersons’ tab, then click on the ’Active’ label next to the salesperson name. You will be asked to replace this salesperson by another from your active salespersons. The retailers assigned to the previous salesperson will then be assigned to the new salesperson. If you don’t want to replace the salesperson you can skip the step by clicking ‘No’ and then click ‘Deactivate’ right away."
        },
        {
            id: "4",
            text: "How do I assign salesperson to a retailer?",
            link: "/AssignSalesPerson",
            category: "Salesperson",
            image: [
                {
                    img: "Assign-1.png",
                    caption: ""
                }
                    ],
            description: "To assign a salesperson to a retailer go to the ‘retailers’ tab click on the retailer title to open retailer details page, then click on the ‘Add’ / ‘Change’ button against the salesperson to assign a new salesperson. To assign a single salesperson to multiple retailers, simply select the check boxes in front of the retailer titles and then click on the the blue ‘Assign Salesperson’ button. Select the salesperson from the dropdown list and click ‘Add’."
        },
        {
            id: "5",
            text: "How do I view/edit a salesperson’s information?",
            link: "/ViewInfo",
            category: "Salesperson",
            image: [
                {
                    img: "ViewInfo-1.png",
                    caption: ""
                }
                    ],
            description: "To view a salesperson’s profile and work details and transactions, go to the ‘Salespersons’ tab, then click on the salesperson title you want to view. This will take you to the salesperson details page where you can view and edit the salesperson profile and contact information."
        },
        {
            id: "6",
            text: "How do I view sales and other transactions made by a salesperson?",
            link: "/ViewSales",
            category: "Salesperson",
            image: [
                {
                    img: "ViewSales-1.png",
                    caption: ""
                },
                {
                    img: "ViewSales-2.png",
                    caption: ""
                },
                {
                    img: "ViewSales-3.png",
                    caption: ""
                },
                {
                    img: "ViewSales-1.png",
                    caption: ""
                }
                    ],
            description: "To view a salesperson’s profile and work details and transactions, go to the ‘Salespersons’ tab, then click on the salesperson title you want to view. This will take you to the salesperson details page where you can view and edit the salesperson profile and contact information."
        },
        {
            id: "7",
            text: "How do I set sales targets for salespersons?",
            link: "/Target",
            category: "Salesperson",
            image: [
                {
                    img: "Target-1.png",
                    caption: ""
                },
                {
                    img: "Target-2.png",
                    caption: ""
                },
                {
                    img: "Target-3.png",
                    caption: ""
                }  
                    ],
            description: "You can set sales targets every month to your salespersons by going to the ‘Salespersons’ tab, then click on the ’Add Target’ button next to the salesperson title, put in the amount that you want and then hit ’Set Target’. You can view the progress made on the target from the same place."
        },

        {
            id: "1",
            text: "How do I add new retailers?",
            link: "/AddRetailer",
            category: "Retailer",
            image: [
                {
                    img: "vendor-1.png",
                    caption: ""
                },
                {
                    img: "vendor-2.png",
                    caption: ""
                },
                {
                    img: "vendor-3.png",
                    caption: ""
                }
            ],
            description: "To add a new retailer simply go to the ‘Retailers’ tab of the BusyBee app from your browser. Click on the ‘Add New Retailer’ button in the top right corner. This will open the search retailer window where you can search for your retailers from the BusyBee database by typing in the retailer title or address. If the desired retailer pops up select the retailer and then hit ‘Next’. Fill in retailer details and then click ‘Save’. This will automatically provide you with the retailer's mapped locategoryion. In case your search query does not find a match in the BusyBee database, click ‘Add New Retailer’ and then fill in the details and then hit ’Add’. This will create a new retailer, however, the retailer will be unmapped by default and can be mapped only from the BusyBee Salesperson app. All new retailers are inactive by default and need to be activated manually before you can assign visits or add orders to them. To see how you can activate a retailer click here."
        },
        {
            id: "2",
            text: "How do I add retailers from my existing database?",
            link: "/AddRetailerFromDB",
            category: "Retailer",
            image: [
                {
                    img: "vendor-1.png",
                    caption:"Upload your .CSV file by clicking on ’SELECT FILE’. Wait for the file to upload and then click ‘Proceed’. BusyBee will run the algorithm to see if your file contains required field headers. Click ‘Proceed’."
                },
                {
                    img: "vendor-2.png",
                    caption:"Once the file is checked for headers you will be presented with an overview of your file. Any empty fields will be marked and that particular record will not be added."
                },
                {
                    img: "vendor-3.png",
                    caption:"Review your file and when you are satisfied click ‘Proceed’ to finish. In case you want to make changes or corrections to your file you can click ‘Go Back’ any time during the process to stop and begin again. The added vendors will be displayed in the vendors table."
                },
                {
                    img: "vendor-4.png",
                    caption:""
                },
                    ],
            description: "You can add all retailers from your current database by uploading the retailer list in a .CSV file with the following headers - businessName, locategoryionName, amount *. To make things even easier, you can download the .CSV template and use it. To download the template go to the ‘Retailers’ tab, click on ‘Import CSV’ and then click on ‘Download our CSV template’. You can know more about .CSV files here. Once you have prepared you .CSV containing retailers, upload it to your BusyBee by going to the ‘Retailers’ tab from your browser, then follow these 4 simple steps-"
        },
        {
            id: "3",
            text: "How do I activate a retailer?",
            link: "/ActivateRetailer",
            category: "Retailer",
            image: [
                {
                    img: "vendor-1.png",
                    caption: ""
                },
                {
                    img: "vendor-2.png",
                    caption: ""
                },
                {
                    img: "vendor-3.png",
                    caption: ""
                },
                    ],
            description: "To activate a retailer go to the ‘Retailers’ tab from your browser, click on the retailer title you want to activate. This will take you to the retailer details page. Click on the ‘Activate’ button to activate the retailer. In case you don’t have a salesperson assigned to the retailer, you will be prompted to assign a salesperson first and then the retailer will be activated."
        },
        {
            id: "4",
            text: "What if I want to remove a retailer?",
            link: "/RemoveRetailer",
            category: "Retailer",
            image: [
                {
                    img: "vendor-1.png",
                    caption: ""
                },
                {
                    img: "vendor-2.png",
                    caption: ""
                },
                {
                    img: "vendor-3.png",
                    caption: ""
                },
                    ],
            description: "Retailers can not be completely removed from the records, but if a retailer does not work for you any more you can deactivate that retailer anytime by going to the ‘Retailers’ tab, then click on on the retailer you want to deactivate. This will take you to the retailer details page. Click on the ‘Deactivate’ button to deactivate the retailer. You will not be able to assign visits or add orders to the deactivated retailer. You can, however, activate the retailer back again any time later."
        },
        {
            id: "5",
            text: "How do I view my retailers on the map?",
            link: "/ViewOnMap",
            category: "Retailer",
            image: [
                {
                    img: "vendor-1.png",
                    caption: ""
                },
                {
                    img: "vendor-2.png",
                    caption: ""
                },
                {
                    img: "vendor-3.png",
                    caption: ""
                },
                    ],
            description: "To get a visual reference of where your retailers are physically locategoryed, you can view your retailers on the map. Simply go to the ‘Retailers’ tab from your browser and click on the ‘Map View’ option. Your retailers will be displayed as pins on the map. You can click on the pin or use pinch and zoom to get a closer view. You can view retailers within particular territories by clicking on the territory icon on the right of the map. To know more about territories click here."
        },
        {
            id: "6",
            text: "How do I change the salesperson assigned to a retailer?",
            link: "/ChangeSalesPerson",
            category: "Retailer",
            image: [
                {
                    img: "vendor-1.png",
                    caption: ""
                },
                {
                    img: "vendor-2.png",
                    caption: ""
                },
                {
                    img: "vendor-3.png",
                    caption: ""
                },
                    ],
            description: "To change the salesperson currently assigned to a retailer go to the ‘Retailers’ tab and click on the retailer you want to assign a new salesperson to. This will take you to the retailer details page. Click on the ‘Add’ / ‘Change’ button next to salesperson and then select a new salesperson from the dropdown list. Click ‘Add’ to finish."
        },
        {
            id: "7",
            text: "What are territories and how do they help?",
            link: "/Territory",
            category: "Retailer",
            image: [
                {
                    img: "vendor-1.png",
                    caption: ""
                },
                {
                    img: "vendor-2.png",
                    caption: ""
                },
                {
                    img: "vendor-3.png",
                    caption: ""
                },
                    ],
            description: "Territory is a group of closely locategoryed retailers marked by user defined boundaries. Territories help organise retailers in groups to make retailer management easier, for example you can assign visits to multiple retailers at once using territories. To assign a visit to a territory go to the ‘Visits’ tab from your browser. Click on the ‘Create Visit’ button in the top right corner, select visit type, then select the ‘Territory’ option. Type in the territory name, then click ‘Create Visit’ to finish."
        },
        {
            id: "8",
            text: "How do I create / edit a territory?",
            link: "/CreateTerritory",
            category: "Retailer",
            image: [
                {
                    img: "vendor-1.png",
                    caption: ""
                },
                {
                    img: "vendor-2.png",
                    caption: ""
                },
                {
                    img: "vendor-3.png",
                    caption: ""
                },
                    ],
            description: "To create a new territory simply go to the ‘Retailers’ tab from your browser and click on the ‘Map View’ option. Then follow these short steps to create a new territory-"
        },
        {
            id: "1",
            text: "What are visits?",
            link: "/Visits",
            category: "Visits",
            image: [
                {
                    img: "vendor-1.png",
                    caption: ""
                },
                {
                    img: "vendor-2.png",
                    caption: ""
                }
                    ],
            description: "A visit is when a salesperson stops by a retailer to perform an assigned task. The tasks may include taking a product restocking order, a new sale, payment collections, delivering orders, etc."
        },
        {
            id: "2",
            text: "How do I create and assign visits?",
            link: "/CreateVisits",
            category: "Visits",
            image: [
                    {
                        img: "visits-1.png",
                        caption: "2. Select Visit Date, Visit Type and Vendor- if you want to assign for a single vendor, or select Territory if you want to assign for multiple vendors."
                    },
                    {
                        img: "visits-2.png",
                        caption: "3. Hit ‘Create Visit’ to finish."
                    },
                    {
                        img: "visits-3.png",
                        caption: "To assign a visit from the ‘Vendors’ tab go to the ‘Vendors’ tab from your browser, then- 1. Select the checkboxes against the vendors you want to assign visits to."
                    },
                    {
                        img: "visits-1.png",
                        caption: "2. Click the ‘Assign Visit’ button"
                    },
                    {
                        img: "visits-2.png",
                        caption: "3. Select Visit Date and Visit Type. Then hit ‘Assign Visit’ to finish."
                    },
                    {
                        img: "visits-3.png",
                        caption: ""
                    },
                ],
            description: "New visits can be assigned to salespersons from the ‘Visits’ tab as well as from the ‘Retailers’ tab. To assign a visit from the ‘Visits’ tab go to the ‘Visits’ tab from your browser, then-"
        },
        {
            id: "3",
            text: "Can I reschedule visits?",
            link: "/RescheduleVisits",
            category: "Visits",
            image: [
                {
                    img: "visits-1.png",
                    caption: ""
                },
                {
                    img: "visits-2.png",
                    caption: ""
                },
                {
                    img: "visits-3.png",
                    caption: ""
                },
                    ],
            description: "Visits can be rescheduled easily from the ‘Visits’ tab. Click on the edit icon next to the visit you want to reschedule. Select a date and hit ‘Update Visit’ to finish."
        },
        {
            id: "4",
            text: "How do I view a visit’s status?",
            link: "/ViewVisitStatus",
            category: "Visits",
            image: [
                {
                    img: "visits-1.png",
                    caption: ""
                },
                {
                    img: "visits-2.png",
                    caption: ""
                },
                    ],
            description: "To View a visit’s status go to the ‘Visits’ tab from your browser. Visit’s status will be displayed next to the assigned salesperson title."
        },
        {
            id: "5",
            text: "How do I cancel a visit?",
            link: "/CancelVisit",
            category: "Visits",
            image: [
                {
                    img: "visits-1.png",
                    caption: ""
                },
                {
                    img: "visits-2.png",
                    caption: ""
                },
                    ],
            description: "To cancel an active visit go to the ‘Visits’ tab from your browser and click on the trash icon next to the visit you want to cancel."
        },
        {
            id: "6",
            text: "How do I know if the salesperson actually completed the visit?",
            link: "/CompletedVisit",
            category: "Visits",
            image: [
                {
                    img: "visits-1.png",
                    caption: ""
                },
                {
                    img: "visits-2.png",
                    caption: ""
                },
                    ],
            description: "The BusyBee Salesperson app does not allow a visit to be marked complete unless the salesperson is actually present at the retailer locategoryion. So you can rest assured that completed visits really were completed by the salesperson."
        },
        {
            id: "1",
            text: "What are the different types of transactions?",
            link: "/TypesOfTransactions",
            category: "Transactions",
            image: [
                {
                    img: "transaction-1.png",
                    caption: ""
                },
                {
                    img: "transaction-2.png",
                    caption: ""
                },
                {
                    img: "transaction-3.png",
                    caption: ""
                },
                    ],
            description: "BusyBee groups transactions into 4 types- Opening Balance, Sale, Collection and return. Opening balances for retailers are added at the time of creating a retailer and represent whatever amount was outstanding against a retailer prior to the transition to BusyBee. Sales transactions represent the sale amount of the products delivered to the retailer, similarly collection represents the amount paid by the retailer on a sale. Return shows the value of products returned to the organisation by the retailer. However, returns will not be deducted from the total sale figures of the salesperson."
        },
        {
            id: "2",
            text: "Can I edit or modify transactions?",
            link: "/EditTransactions",
            category: "Transactions",
            image: [
                {
                    img: "transaction-1.png",
                    caption: ""
                },
                {
                    img: "transaction-2.png",
                    caption: ""
                },
                {
                    img: "transaction-3.png",
                    caption: ""
                },
                    ],
            description: "All transactions can be edited post creation by the organisation. Both the amount value and the date of transaction can be edited by the organisation. All transactions except for a sale transaction associated with an order can be deleted."
        },
        {
            id: "1",
            text: "What are orders? How do I create orders for a retailer?",
            link: "/Orders",
            category: "Orders",
            image: [
                {
                    img: "order-1.png",
                    caption: ""
                },
                {
                    img: "order-2.png",
                    caption: ""
                },
                {
                    img: "order-3.png",
                    caption: ""
                },
                    ],
            description: "Orders are product restocking requests for retailers. Orders can be created by salespersons when they visit a retailer or alternatively orders can also be created from the BusyBee Organisation. To create an order from the BusyBee organisation got to ‘Orders’ tab from your browser, click ‘Add New Order’ and select the retailer from the list. The add the products from the product list along with the quantity. If the product is not listed add the product right there by clicking ‘Add new product’. Click ‘Add Products’ to proceed to the confirmation page. You can review the added products here. Click ‘Confirm Order’ to finish creating the new order. The new order will appear in the orders table."
        },
        {
            id: "2",
            text: "How do I assign visit on an order?",
            link: "/AssignVisitOnOrder",
            category: "Orders",
            image: [
                {
                    img: "order-1.png",
                    caption: ""
                },
                {
                    img: "order-2.png",
                    caption: ""
                },
                {
                    img: "order-3.png",
                    caption: ""
                },
                    ],
            description: "All orders are automatically displayed in the BusyBee Salesperson app for the assigned salesperson to see. The salesperson can then deliver the order whenever they wish. If however, you want to specify a particular time when the salesperson should go and deliver the order to the retailer, you can assign a visit against that order. To assign a visit on the order simply go to the ‘Orders’ tab and click on the order id of the desired order. This will open a side card displaying all details of the order. Click ‘Assign Visit’ and select the date when the order should be delivered. Hit ‘Assign Visit’ to finish."
        },
        {
            id: "3",
            text: "What if an order cannot be completed for some reason?",
            link: "/OrderNotCompleted",
            category: "Orders",
            image: [
                {
                    img: "order-1.png",
                    caption: ""
                },
                {
                    img: "order-2.png",
                    caption: ""
                },
                {
                    img: "order-3.png",
                    caption: ""
                },
                    ],
            description: "If an order cannot be fulfilled on the previously assigned date you can always reassign a new delivery date. To see how a visit can be reassigned a new date, click here. If however, the order cannot be fulfilled at all then you can cancel the order anytime by going to the ’Orders’ tab and click on the order to be cancelled. This will open a side bar containing all details of the order. Click ‘Cancel Order’ and this will cancel the order along with the visits associated with that order."
        },
        {
            id: "1",
            text: "What are products? How do I add new products?",
            link: "/Products",
            category: "Products",
            image: [
                {
                    img: "product-1.png",
                    caption: ""
                },
                {
                    img: "product-2.png",
                    caption: ""
                },
                {
                    img: "product-3.png",
                    caption: ""
                },
                    ],
            description: "You can create a basic inventory of your products and add it to your BusyBee. These products can be used to create orders for retailers from the BusyBee Organisation as well as from the BusyBee Salesperson app. To add products individually to your BusyBee go to the ‘Products’ tab from your browser, click ‘Add New Product’ and fill in the product details. Hit ‘Add’ to finish."
        },
        {
            id: "2",
            text: "How do I add products from my inventory database?",
            link: "/ProductsFromDB",
            category: "Products",
            image: [
                    {
                        img: "product-1.png",
                        caption: "2. Upload your .CSV file by clicking on ’SELECT FILE’. Wait for the file to upload and then click ‘Proceed’. BusyBee will run the algorithm to see if your file contains required field headers. Click ‘Proceed’" 
                    },
                    {
                        img: "product-2.png",
                        caption: "3. Once the file is checked for headers you will be presented with an overview of your file. Any empty fields will be marked and that particular record will not be added"},
                    {
                        img: "product-3.png",
                        caption: "4. Review your file and when you are satisfied click ‘Proceed’ to finish. In case you want to make changes or corrections to your file you can click ‘Go Back’ any time during the process to stop and begin again. The added products will be displayed the the products table"
                    }
                ],
            description: "You can add all products from your current database by uploading the product list in a .CSV file with the following headers - name, unit. To make things even easier, you can download the .CSV template and use it. To download the template go to the ‘Products’ tab, click on ‘Import CSV’ and then click on ‘Download our CSV template’. You can know more about .CSV files here. Once you have prepared you .CSV containing products, upload it to your BusyBee by going to the ‘Products’ tab from your browser, then follow these 4 simple steps-"
        },
        {
            id: "3",
            text: "How do I delete products?",
            link: "/DeleteProducts",
            category: "Products",
            image: [
                {
                    img: "product-1.png",
                    caption: ""
                },
                {
                    img: "product-2.png",
                    caption: ""
                },
                {
                    img: "product-3.png",
                    caption: ""
                },
                    ],
            description: "To delete a product from your BusyBee go to the ‘Products’ tab from your browser and click the trash can icon against the product you want to delete. Confirm action by clicking the ‘Delete’ button."
        },
        {
            id: "1",
            text: "What is a .CSV file and how do I use it?",
            link: "/CSVFile",
            category: "Imports",
            image: [
                    {
                        img: "import-1.png",
                        caption: "A CSV file has a fairly simple structure. It’s a list of data separated by commas. For example, let’s say you have a few salespersons in a contact manager, and you export them as a CSV file. You’d get a file containing text like this: Name,Email,Phone Number,Address Bob Smith,bob@example.com,123-456-7890,123 Gold Street Mike Jones,mike@example.com,098-765-4321,321 Silver Avenue"
                    },
                    {
                        img: "import-2.png",
                        caption: "You can open and edit .CSV files on Windows in Notepad or Notepad++. If you are using Mac OS you can use TextEdit to open and edit .CSV files. If you want a more comprehensive understanding of .CSV files you can refer to https://www.howtogeek.com/348960/what-is-a-csv-file-and-how-do-i-open-it/ Subscription packages allow you to extend the abilities of your BusyBee over what you get from the trial period."
                    },
                    {
                        img: "import-3.png",
                        caption: ""
                    }
            ],
            description: "A CSV file is a plain text file that contains a list of data separated by commas. These files are frequently used to transfer data between apps. CSV files are frequently supported by databases and contact manager."
        },
        {
            id: "2",
            text: "How do I ensure my .CSV file uploads without conflicts or errors?",
            link: "/UploadWithoutError",
            category: "Imports",
            image: [
                {
                    img: "import-1.png", 
                    caption: ""
                },
                {
                    img: "import-1.png", 
                    caption: ""
                },
                {
                    img: "import-1.png", 
                    caption: ""
                },
                    ],
            description: "To enable error free uploads of .CSV files make sure that the data fields match the relevant header fields and that none of the required fields are left empty."
        },
    ]
export default links;