import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FiEdit2 } from "react-icons/fi";
import Autocomplete from "react-google-autocomplete";
// import { CircularProgress } from "@mui/material";

import Loader from "../../components/common/loader";
import { MAPS_API_KEY } from "../../constants/config";
import dummy from "../../assets/dummy.png";
import { countries } from "../../constants/countryList";
import { UploadProfileLogo } from "../../services/api";
import { submitProfileDetails } from "../../redux store/actions/organizerProfile";
import defaultLogo from "../../assets/user.png";
import { getProfileDetails } from "../../redux store/actions/organizerProfile";
import ValidationErrors from "../common/validationErrors";
import CustomInput from "../common/customInput";
import BaseButton from "../common/baseButton";
import Avatar from "../common/avatar";

import "../../theme/App.css";

const SettingsForm = (props) => {
  const [logo, setLogo] = useState();
  const [imageLoader, setImageLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [profileDetails, setProfileDetails] = useState();
  const [image, setImage] = useState();
  // const [modifiedData, setModifiedData] = useState([]);

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login);
  const orgDetails = useSelector((state) => state.getProfileDetails);

  const getData = async () => {
    const data = await dispatch(getProfileDetails(currentUser.id));
    setProfileDetails(data?.data);
    // {data.data.currency=="USD"?setSelectedCountry()}
    console.log("set profile", data.data.currency);
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, []);

  // const resizeImage = (base64Str, maxWidth = 500, maxHeight = 500) => {
  //   return new Promise((resolve) => {
  //     let img = new Image();
  //     img.src = base64Str;
  //     img.onload = () => {
  //       let canvas = document.createElement("canvas");
  //       const MAX_WIDTH = maxWidth;
  //       const MAX_HEIGHT = maxHeight;
  //       let width = img.width;
  //       let height = img.height;
  //       console.log("img details,,", width, height);
  //       if (width > height) {
  //         width = height;
  //       } else {
  //         if (height > MAX_HEIGHT) {
  //           width *= MAX_HEIGHT / height;
  //           height = MAX_HEIGHT;
  //         }
  //       }
  //       canvas.width = maxWidth;
  //       canvas.height = maxHeight;
  //       let ctx = canvas.getContext("2d");
  //       ctx.drawImage(img, 0, 0, maxWidth, maxHeight);
  //       resolve(canvas.toDataURL());
  //     };
  //   });
  // };

  const setProfileLogo = async (event) => {
    setImageLoader(true);
    if (event.target.files.length !== 0) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = async () => {
        try {
          const data = await UploadProfileLogo("key", reader.result);
          setLogo(data);
          setImageLoader(false);
        } catch (error) {
          console.log(error.response);
        }
      };
    }
  };
  console.log("profileDetails in sett", dummy);
  return (
    <>
      {0 ? (
        <div className="m-auto h-60 col-span-4 flex items-center">
          {/* <CircularProgress color="white" size={60} /> */}
        </div>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            email: profileDetails?.email,
            password: "",
            confirmPassword: "",
            currency: profileDetails?.currency,
            contact: profileDetails?.contact,
            businessName: profileDetails?.businessName,
            profileImage: profileDetails?.profileImage,
            location: profileDetails?.location?.coordinates || [],
            locationName: profileDetails?.locationName,
            firstName: profileDetails?.firstName,
            lastName: profileDetails?.lastName,
            zip: profileDetails?.zip,
            website: profileDetails?.website,
          }}
          // validationSchema={RegisterSchema}
          onSubmit={async (values) => {
            console.log("formvalues", values);
            if (!values.contact) values.contact = "";
            if (typeof values.contact === "string")
              values.contact = values.contact;
            if (typeof values.contact === "number")
              values.contact = JSON.stringify(values.contact);
            if (!values.currency) values.currency = "";
            else {
              if (typeof values?.currency === "object")
                values.currency = values.currency;
              if (typeof values?.currency === "string")
                values.currency = JSON.parse(values.currency);
            }
            logo
              ? (values.profileImage = logo)
              : (values.profileImage = profileDetails?.profileImage);
            console.log("formvalues...", values);
            const profileResponse = await dispatch(
              submitProfileDetails(values)
            );
            console.log("profileDetails", profileResponse);
            if (profileResponse.status === 200) {
              toast.success("Profile updated");
              props.handleCloseModal();
            } else {
              toast.error("Something went wrong");
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              {/* <div className="py-1 pt-6 text-sm text-secondary-text"> Name</div> */}
              {props.field === "businessName" ? (
                <CustomInput
                  placeholder={"John Doe"}
                  label="Name"
                  type="text"
                  name="businessName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.businessName}
                  setFormValue={(businessName) => {
                    setFieldValue("businessName", businessName);
                  }}
                />
              ) : null}
              {props.field === "name" ? (
                <>
                  <CustomInput
                    placeholder={"John Doe"}
                    label="First Name"
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    setFormValue={(firstName) => {
                      setFieldValue("firstName", firstName);
                    }}
                  />
                  <CustomInput
                    placeholder={"John Doe"}
                    label="Last Name"
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    setFormValue={(lastName) => {
                      setFieldValue("lastName", lastName);
                    }}
                  />
                </>
              ) : null}
              {props.field === "phone" ? (
                <CustomInput
                  placeholder={8803426165}
                  label="Phone"
                  type="number"
                  allowNumOnly
                  name="contact"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.contact}
                  setFormValue={(contact) => {
                    setFieldValue("phone", contact);
                  }}
                />
              ) : null}
              {touched.name && errors.name && (
                <ValidationErrors errors={`${errors.name}`}></ValidationErrors>
              )}
              {/* <div className="py-1 text-sm">Business Type</div>
                  <input
                    className="w-full outline-none border rounded-sm p-2"
                    placeholder=""
                    type="text"
                    name="businessType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.businessType}
                  />
                  {touched.email && errors.email && (
                    <ValidationErrors
                      errors={`${errors.email}`}
                    ></ValidationErrors>
                  )} */}
              {props.field === "image" ? (
                <>
                  <input
                    id="photo-upload"
                    className="hidden"
                    type="file"
                    name="profileImage"
                    // value={logo}
                    onChange={(e) => setProfileLogo(e)}
                  />
                  <div className="border rounded-sm flex justify-center p-4">
                    <label className="cursor-pointer" htmlFor="photo-upload">
                      {!logo && !imageLoader && (
                        <div className="text-neutralText3 absolute flex justify-center items-center w-1/4 h-40 z-30">
                          <FiEdit2 size={26} />
                        </div>
                      )}
                      {imageLoader ? (
                        <div className="flex justify-center items-center h-40">
                          <Loader color="secondary" />
                        </div>
                      ) : (
                        <img
                          alt="src"
                          className={`h-40 ${!logo && "opacity-30"}`}
                          src={`${
                            logo
                              ? process.env.REACT_APP_BUCKET_URL + logo
                              : values.profileImage
                              ? process.env.REACT_APP_BUCKET_URL + values.profileImage
                              : dummy
                          }`}
                        />
                      )}
                    </label>
                  </div>
                </>
              ) : null}

              {props.field === "email" ? (
                <CustomInput
                  label={"Email"}
                  type="email"
                  name="email"
                  placeholder="mail@abc.com"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  setFormValue={(email) => {
                    setFieldValue("email", email);
                  }}
                />
              ) : null}
              {touched.email && errors.email && (
                <ValidationErrors errors={`${errors.email}`}></ValidationErrors>
              )}

              {props.field === "address" ? (
                <>
                  <div className="text-neutralText3 text-sm py-1 block">
                    Business Address
                  </div>
                  <Autocomplete
                    inputAutocompleteValue={"off"}
                    className="bg-transparent text-sm text-neutralText1 px-2 py-1 w-full outline-none border border-0.5 border-neutralBg4 bg-neutralBg1"
                    id="locationName"
                    name="locationName"
                    value={values.locationName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    apiKey={MAPS_API_KEY}
                    options={{
                      types: [],
                    }}
                    onPlaceSelected={(place) => {
                      setFieldValue("locationName", place.formatted_address);
                      console.log("location[0]", place.geometry.location.lat());
                      setFieldValue("location", [
                        place.geometry.location.lat(),
                        place.geometry.location.lng(),
                      ]);
                    }}
                  />
                </>
              ) : null}

              {props.field === "currency" ? (
                <div className="w-full md:pl-2">
                  <div className="text-neutralText3 text-sm py-1 block">
                    Currency
                  </div>
                  <select
                    type="text"
                    name="currency"
                    // defaultValue={""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.currency}
                    className="bg-transparent text-sm text-neutralText1 p-2 py-1 w-full outline-none border border-0.5 border-neutralBg4 bg-neutralBg1"
                  >
                    <option key="select" value="">
                      {profileDetails?.currency
                        ? ` ${profileDetails.currency.country} (${profileDetails.currency.currency_code})`
                        : "Select"}
                    </option>
                    {countries?.map((item, i) => (
                      <option
                        key={i}
                        value={JSON.stringify({
                          country: item.country,
                          currency_code: item.currency_code,
                        })}
                      >
                        {`${item.country} (${item.currency_code})`}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}

              {/* <div className="flex  flex-col md:flex-row justify-between">
                <div className="w-full md:pr-2">
                  <div className="py-1 pt-6 text-xs text-secondary-text">
                    Website
                  </div>
                  <input
                    type="text"
                    name="website"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.website}
                  />
                </div>
               
              </div> */}

              {/* <div className="flex flex-col md:flex-row justify-between">
                <div className="w-full md:pr-2">
                  <div className="py-1 pt-6 text-xs text-secondary-text">
                    Change Password{" "}
                  </div>
                  <input
                    className="w-full outline-none border rounded-sm text-secondary-text-light  p-2"
                    //   placeholder="Your Name"
                    type="text"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                </div>
                {touched.password && errors.password && (
                  <ValidationErrors
                    errors={`${errors.password}`}
                  ></ValidationErrors>
                )}
                <div className="w-full md:pl-2">
                  <div className="py-1 pt-6 text-xs text-secondary-text">
                    Confirm Password
                  </div>
                  <input
                    className="w-full outline-none border rounded-sm text-secondary-text-light p-2"
                    //   placeholder="Your Name"
                    type="text"
                    name="confirmPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                  />
                  {touched.confirmPassword && errors.confirmPassword && (
                    <ValidationErrors
                      errors={`${errors.confirmPassword}`}
                    ></ValidationErrors>
                  )}
                </div>
              </div> */}
              <div className="pt-8 text-right">
                <BaseButton
                  text={"Save"}
                  // className="bg-primary sm:w-1/3 hover:bg-primary-light px-8 p-2 rounded text-xs font-extrabold "
                  // textColor=""
                  // type="submit"
                />
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};
export default withRouter(SettingsForm);
