import { Formik } from "formik";
import { toast } from "react-toastify";

import { addNewProduct, editProduct } from "../../services/productsApi";
import ValidationErrors from "../common/validationErrors";
import CustomInput from "../common/customInput";
import BaseButton from "../common/baseButton";
import { addProductSchema } from "../validationSchema";
import { useState } from "react";

const ProductForm = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <Formik
      initialValues={{
        name: props?.productDetails?.name || "",
        unit: props?.productDetails?.unit || "",
      }}
      validationSchema={addProductSchema}
      onSubmit={async (values) => {
        setLoading(true);
        let productDetail = {
          name: values.name,
          unit: values.unit,
          id: props?.productDetails?.id,
        };

        const productResponse = props.edit
          ? await editProduct(productDetail)
          : await addNewProduct(productDetail);
        console.log("productResponse", productResponse);

        if (productResponse.message === "product updated successfully") {
          toast.success("Product updated successfully");
          props.handleClose();
        } else if (
          productResponse.data.message === "product added successfully"
        ) {
          toast.success("Product added successfully");
          props.handleClose();
        }
        setLoading(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <CustomInput
            label="Product Name"
            type="text"
            name="name"
            placeholder={
              values.name ||
              "eg. Lays-Small Packet, Tata Salt, Azka Namkeen Dal"
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            setFormValue={(name) => {
              setFieldValue("name", name);
            }}
          />
          {touched.name && errors.name && (
            <ValidationErrors errors={`${errors.name}`} className="mt-1" />
          )}
          <CustomInput
            label="Unit"
            type="text"
            name="unit"
            placeholder={values.unit || "KG, Pack, Ltr, Box"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.unit}
            setFormValue={(name) => {
              setFieldValue("unit", name);
            }}
          />
          {touched.unit && errors.unit && (
            <ValidationErrors errors={`${errors.unit}`} className="mt-1" />
          )}

          <div className=" flex justify-end pt-4">
            <BaseButton
              text={props.edit ? "Save" : "Add"}
              width={"w-36"}
              loading={loading}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
export default ProductForm;
