import {
  CHECK_USER_EXISTANCE,
  REGISTER,
  LOGIN,
  GET_PLANS,
  CHECK_COUPON,
  SUBSCRIBE,
  GET_ALL_SUBSCRIPTION,
  BUY_PLAN,
  GET_SUBSCRIPTION_PLANS,
  FORGOT_PASSWORD,
} from "../actions/actionTypes";

//for checking user exisitance
export const checkUserExistance = (response = " ", action) => {
  switch (action.type) {
    case CHECK_USER_EXISTANCE:
      return action.payload;

    default:
      return response;
  }
};

//for registering a user
export const register = (response = null, action) => {
  switch (action.type) {
    case REGISTER:
      return action.payload;

    default:
      return response;
  }
};

//for user login
export const login = (response = null, action) => {
  switch (action.type) {
    case LOGIN:
      return action.payload;

    default:
      return response;
  }
};

//get razorpay plan
export const getPlans = (plans = null, action) => {
  switch (action.type) {
    case GET_PLANS:
      return action.payload;

    default:
      return plans;
  }
};

//for subscribing a plan
export const subscribe = (response = null, action) => {
  switch (action.type) {
    case SUBSCRIBE:
      return action.payload;

    default:
      return response;
  }
};

export const buyPlan = (response = null, action) => {
  switch (action.type) {
    case BUY_PLAN:
      return action.payload;

    default:
      return response;
  }
};

export const getAllSubscriptions = (subscriptions = null, action) => {
  switch (action.type) {
    case GET_ALL_SUBSCRIPTION:
      return (subscriptions = action.payload);

    default:
      return subscriptions;
  }
};
export const getSubscriptionPlans = (subPlans = null, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_PLANS:

      return action.payload;

    default:
      return subPlans;
  }
};
export const forgotPassword = (response = null, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD:
      return action.payload;

    default:
      return response;
  }
};

export const resetPassword = (response = null, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD:
      return action.payload;

    default:
      return response;
  }
};
export const checkCoupon = (response = null, action) => {
  switch (action.type) {
    case CHECK_COUPON:
      return action.payload;

    default:
      return response;
  }
};
