import React from "react";

const CustomLink = React.forwardRef(({ navigate, ...props }, ref) => {
  return (
    <a
      ref={ref}
      href={props.to}
      {...props}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {props.children}
    </a>
  );
});

export default CustomLink;
