import PlanCard from "./planCard";
import { FiChevronLeft } from "react-icons/fi";
import {
  withRouter,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";

const Plans = (props) => {
  const history = useHistory();
  console.log("form values", props.location.state);
  return (
    <div className="bg-gradient-to-b from-yellow-50">
      <div className="gradient"></div>
      <div className="text-primary-text-light px-6  sm:px-12  sm:w-full md:w-3/4 mx-auto">
        <button
          onClick={history.goBack}
          class="text-sm flex flex-row items-center font-extralight text-secondary-text-light pb-8"
        >
          <FiChevronLeft className="text-lg text-primary-text" />
          <span className="text-lg text-primary-text">Cancel</span>
        </button>
        <div className=" text-center pb-8">
          <div className="text-2xl font-normal ">
            <span className="font-extrabold">Flexible </span>plans to start with
          </div>
          <div className="text-xs 2xl:text-lg py-4">
            Choose a Plan that suits your Business needs best
          </div>
        </div>
        <PlanCard formValues={props.location.state} />
      </div>
    </div>
  );
};
export default withRouter(Plans);
