export const SPStatus = Object.freeze({
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
});

export const VendorStatus = Object.freeze({
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
});

export const OrderStatus = Object.freeze({
  Placed: "placed",
});

export const Theme = Object.freeze({
  Light: "LIGHT",
  Dark: "DARK",
});

export const BannerType = Object.freeze({
  Success: "SUCCESS",
  Warning: "WARNING",
  Error: "ERROR",
  Info: "INFO",
});

export const FilterType = Object.freeze({
  Default: "DEFAULT",
  Autocomplete: "AUTOCOMPLETE",
  DateRange: "DATE_RANGE",
});

export const FilterDateType = Object.freeze({
  LastDay: "Last Day",
  Last7Days: "Last 7 Days",
  LastMonth: "Last Month",
  CustomRange: "Custom Range",
});

export const TerritoriesPhase = Object.freeze({
  ListTerritory: "LIST_TERRITORY",
  AddTerritory: "ADD_TERRITORY",
  ListVendorTerritory: "LIST_VENDOR_TERRITORY",
});

export const BulkImportType = Object.freeze({
  Vendor: "VENDOR",
  Salesperson: "SALESPERSON",
  Product: "PRODUCT",
});

export const BulkImportErrorTypes = Object.freeze({
  Conflict: "conflict",
  Duplicate: "duplicate",
  Validation: "validation",
});
