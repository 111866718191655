import React from "react";
import { FiAlertTriangle } from "react-icons/fi";
import * as Unicons from "@iconscout/react-unicons";

import { BannerType } from "../../../constants/common";

import BaseButton from "../baseButton";

const ActionBanner = ({
  text,
  icon,
  actionText,
  onAction,
  type,
  containerClassName,
}) => {
  const getClasses = () => {
    switch (type) {
      case BannerType.Success:
        return "bg-successBg2 text-successText4";
      case BannerType.Warning:
        return "bg-warningBg2 text-warningText4";
      case BannerType.Error:
        return "bg-errorBg2 text-errorText4";
      case BannerType.Info:
        return "bg-infoBg2 text-infoText4";
      default:
        return "bg-neutralBg1 text-neutralText3";
    }
  };

  const getButtonClasses = () => {
    switch (type) {
      case BannerType.Success:
        return "bg-successText5 hover:bg-successText4";
      case BannerType.Warning:
        return "bg-warningText5 hover:bg-warningText4";
      case BannerType.Error:
        return "bg-errorText5 hover:bg-errorText4";
      case BannerType.Info:
        return "bg-infoText5 hover:bg-infoText4";
      default:
        return "bg-neutralText5 hover:bg-neutralText4";
    }
  };

  const getIcon = () => {
    switch (type) {
      case BannerType.Success:
        return <Unicons.UilCheck size={24} className="text-successText4" />;
      case BannerType.Warning:
        return <FiAlertTriangle size={"24px"} className="text-warningText4" />;
      case BannerType.Error:
        return <Unicons.UilTimes size={24} className="text-errorText4" />;
      case BannerType.Info:
        return <Unicons.UilInfoCircle size={24} className="text-infoText4" />;
      default:
        return (
          <Unicons.UilExclamationCircle
            size={24}
            className="text-neutralText4"
          />
        );
    }
  };

  return (
    <div
      className={`px-6 py-6 flex flex-row justify-between items-center border-[0.5px] border-neutralBg4 ${getClasses()} ${containerClassName}`}
    >
      <div className="flex flex-row items-center">
        {icon || getIcon()}
        <p className="ml-3 text-sm w-4/5">{text}</p>
      </div>
      {actionText && (
        <div>
          <BaseButton
            text={actionText}
            onClick={onAction}
            type={`${getButtonClasses()}`}
            textColor="neutralBg1"
          />
        </div>
      )}
    </div>
  );
};

export default ActionBanner;
