import { APIClient } from "./apiClient";

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const response = await APIClient.post("/org/change/password", {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
    return response.data;
  } catch (err) {
    console.log("error in changePassword...", err);
  }
};
