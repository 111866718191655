import { useEffect, useState } from "react";
import { FiSliders, FiX } from "react-icons/fi";
import * as Unicons from "@iconscout/react-unicons";

import CustomModalTitle from "./common/customModalTitle";
import OuterDiv from "./common/outerDiv";
import { CustomDateRangePicker } from "../components/common/customDatePicker";
import CustomTable from "./common/table";
import {
  getVendorInteraction,
  getVendorsOverview,
} from "../services/vendorsApi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import noVendors from "../assets/noVendors.svg";
import EmptyFallBack from "./common/emptyFallback";
import getCurrency from "../utils/getCurrency";
import millify from "millify";

const columns = [
  { key: "vendor", name: "Retailer", minWidth: 180 },
  { key: "sale", name: "Sale", minWidth: 120, width: 120 },
  { key: "paid", name: "Paid", minWidth: 120, width: 120 },
  { key: "balance", name: "Balance", minWidth: 120, width: 120 },
  { key: "chevron", name: "", minWidth: 50, width: 80 },
];

const rows = [
  { businessName: "Abrar" },
  { businessName: "Abrar" },
  { businessName: "Abrar" },
  { businessName: "Abrar" },
  { businessName: "Abrar" },
];

const VendorInteraction = () => {
  const dateStart = new Date();
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateStart.setHours(0, 0, 0, 0);

  const dateEnd = new Date();
  dateEnd.setHours(23, 59, 59, 99);

  const [date, setDate] = useState([
    {
      startDate: dateStart,
      endDate: dateEnd,
      key: "selection",
    },
  ]);

  const [topVendors, setTopVendors] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modifiedData, setModifiedData] = useState([]);

  console.log("dataein", date);

  const getData = async () => {
    setTableLoading(true);
    const data = await getVendorInteraction(
      parseInt(date[0].startDate / 1000, 10),
      parseInt(date[0].endDate / 1000, 10)
    );
    setTopVendors(data?.data || []);
    setTableLoading(false);
  };

  const clearFilters = () => {
    setDate([
      {
        startDate: dateStart,
        endDate: dateEnd,
        key: "selection",
      },
    ]);
  };

  useEffect(() => {
    getData();
  }, [date]);

  useEffect(() => {
    const temp = topVendors?.map((el) => {
      console.log(el?.status);
      return {
        ...el,
        vendor: (
          <span className="text-neutralText1 font-bold truncate">
            {el?.businessName}
          </span>
        ),
        sale: (
          <span className="text-neutralText1 font-normal truncate">
            {getCurrency()}{" "}
            {millify(el?.sale || 0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </span>
        ),
        paid: (
          <span className="text-successText5 font-normal truncate">
            {getCurrency()}{" "}
            {millify(el?.collection || 0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </span>
        ),
        balance: (
          <span className="text-errorText5 font-normal truncate">
            {getCurrency()}{" "}
            {millify(el?.balance || 0, {
              precision: 2,
              decimalSeparator: ".",
            })}
          </span>
        ),
        chevron: (
          <Unicons.UilAngleRightB className="text-neutralText1" size="20px" />
        ),
      };
    });
    setModifiedData(temp);
  }, [topVendors]);

  return (
    <OuterDiv>
      <div className="p-6 h-[324px]">
        <div className="flex justify-between text-neutralText1">
          <CustomModalTitle
            title="Inactive retailers "
            description={"Retailers that haven’t been visited or ordered"}
          />
          <div className="flex items-center">
            <div className=" mr-2">
              {date[0].startDate / 1000 !== dateStart / 1000 ||
              date[0].endDate / 1000 !== dateEnd / 1000 ? (
                <button onClick={() => clearFilters()}>
                  <div className="flex items-center justify-center text-infoText4 text-xs">
                    <FiX size={14} />
                    Clear
                  </div>
                </button>
              ) : (
                <FiSliders className=" text-neutralText1" size={18} />
              )}
            </div>
            <CustomDateRangePicker
              date={date}
              setDate={setDate}
              changed={
                date[0].startDate / 1000 !== dateStart / 1000 ||
                date[0].endDate / 1000 !== dateEnd / 1000
              }
            />
          </div>
        </div>
        <div className="pt-4 max-h-[230px] overflow-auto scrollbar scrollbar-thumb-neutralText4 scrollbar-track-neutralBg1 scrollbar-thin hover:scrollbar-thumb-neutralBg5">
          <CustomTable
            columns={columns}
            rows={modifiedData}
            emptyFallBack={
              <EmptyFallBack
                image={noVendors}
                text="No Retailers Found"
                containerClass={"mt-4"}
              />
            }
            bodyRowProps={{
              path: "/view-vendor",
              id: "id",
            }}
            loading={tableLoading}
          />
        </div>
      </div>
    </OuterDiv>
  );
};
export default VendorInteraction;
