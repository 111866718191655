import React from 'react';
const CustomCard = function(props){
    return(
            <div className="p-4 shadow hover:shadow-lg w-full bg-neutralBg1 white:bg-gray-800 dark:border-gray-700 mt-4">
                <div>{props.icon}</div>
            <h5 className="mb-1 mt-2 text-2xl sm:text-xl font-bold tracking-tight text-gray-900 dark:text-black">{props.heading}</h5>
            <p className="mb-3 font-normal text-xs text-gray-700 dark:text-black-400">{props.subheading}</p>
            </div>
    );
}
export default CustomCard;