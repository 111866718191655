import { reducer as ReduxFormReducer } from "redux-form";
import { combineReducers } from "redux";
import {
  addNewVendor,
  addExistingVendor,
  // getVendorList,
  vendorToEdit,
  updateVendor,
} from "./vendor";
import {
  getProductList,
  getOrders,
  addNewProduct,
  productToEdit,
  editProduct,
  getQueries,
} from "./productAndOrders";
import { dashboardStats, dashboardRankList } from "./dashboard";
import { getProfileDetails } from "./organizerProfile";
import { getSettings } from "./setting";
import { salesAndRevenue, salesPersonPerformance } from "./reports";
import {
  checkUserExistance,
  register,
  login,
  subscribe,
  getAllSubscriptions,
  getPlans,
  getSubscriptionPlans,
  forgotPassword,
  buyPlan,
  resetPassword,
  checkCoupon,
} from "./loginAndRegister";
import {
  salesPersonList,
  generatePassword,
  // getVendorListBySalesPerson,
  // getAssignedVendorsBySalesPerson,
  addNewSalesPerson,
  updateSalesPerson,
  salesPersonToEdit,
} from "./salesPerson";
import { getActivity, getDashboardActivity } from "./activities";
import { getEnquiries } from "./enquiries";
import { getBillingHistory } from "./billingHistory";
import { theme } from "./general";

const appReducer = combineReducers({
  checkUserExistance,
  getAllSubscriptions,
  subPlans: getSubscriptionPlans,
  salesPersonList,
  getDashboardActivity,
  dashboardStats,
  dashboardRankList,
  addNewProduct,
  productToEdit,
  editProduct,
  addNewVendor,
  addExistingVendor,
  vendorToEdit,
  updateVendor,
  getEnquiries,
  addNewSalesPerson,
  salesPersonToEdit,
  updateSalesPerson,
  getOrders,
  subscribe,
  getPlans,
  forgotResponse: forgotPassword,
  resetResponse: resetPassword,
  getSettings,
  coupon: checkCoupon,
  register,
  login,
  getBillingHistory,
  buyPlan,
  getQueries,
  generatedPassword: generatePassword,
  // VendorListBySalesPerson: getVendorListBySalesPerson,
  // assignedVendorsBySalesPerson: getAssignedVendorsBySalesPerson,
  // vendorList: getVendorList,
  productList: getProductList,
  activities: getActivity,
  getProfileDetails,
  salesAndRevenue,
  salesPersonPerformance,
  form: ReduxFormReducer,
  theme,
});

export default (state, action) => {
  if (action.type === "LOGOUT") {
    // state.login = null;
    state = undefined;
  }
  return appReducer(state, action);
};
