import React, { useEffect, useState } from "react";
import { FiAlertTriangle, FiCheck, FiX } from "react-icons/fi";
import { nanoid } from "nanoid";
import startCase from "lodash/startCase";

import { BulkImportErrorTypes, BulkImportType } from "../../constants/common";
import { checkBulkSalespersons } from "../../services/salespersonApi";
import { checkBulkVendors } from "../../services/vendorsApi";
import BaseButton from "../common/baseButton";
import CustomModal from "../common/customModal";
import CustomModalTitle from "../common/customModalTitle";
import CustomTable from "../common/table";
import { toast } from "react-toastify";

const ReviewData = ({
  matchedFields,
  processedData,
  setProcessedData,
  bulkImportType,
  back,
  next,
}) => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [openConflictModal, setOpenConflictModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [tableLoading, setTableLoading] = useState(true);

  const getAction = (el, index) => {
    const {
      error: { type, message },
    } = el;

    switch (type) {
      case BulkImportErrorTypes.Conflict:
        return {
          action: el.vendorId ? (
            <div key={`index-${index}`} className="flex items-center text-xs">
              <FiAlertTriangle size={16} className="text-warningText4 mr-1" />
              Existing BusyBee Retailer will be added
              <BaseButton
                buttonProps={{ key: `index-${index}` }}
                outline
                onClick={() => {
                  setSelectedItem(el);
                  setOpenConflictModal(true);
                }}
                text="View"
                type="!h-auto ml-1 border-infoText5 hover:bg-infoText5 hover:text-white rounded-full !px-3 !py-0.5 text-[0.6rem]"
                textColor="infoText5"
              />
            </div>
          ) : (
            <div key={`index-${index}`} className="flex items-center text-xs">
              <FiCheck size={16} className="text-successText4 mr-1" />
              New Retailer will be created
              <BaseButton
                buttonProps={{ key: `index-${index}` }}
                outline
                onClick={() => {
                  setSelectedItem(el);
                  setOpenConflictModal(true);
                }}
                text="View"
                type="h-5 ml-1 border-infoText5 hover:bg-infoText5 hover:text-white rounded-full !px-3 !py-0.5 text-[0.6rem]"
                textColor="infoText5"
              />
            </div>
          ),
          message: (
            <p className="bg-warningBg2 text-warningText4 text-xs rounded-full px-3 py-0.5">
              Found in BusyBee DB
            </p>
          ),
        };

      case BulkImportErrorTypes.Duplicate:
        return {
          action: (
            <div className="flex items-center text-xs">
              <FiX size={16} className="text-errorText4 mr-1" />
              Can't be created
            </div>
          ),
          message: (
            <p className="bg-errorBg2 text-errorText4 text-xs rounded-full px-3 py-0.5">
              Duplicate
            </p>
          ),
        };

      case BulkImportErrorTypes.Validation:
        return {
          action: (
            <div className="flex items-center text-xs">
              <FiX size={16} className="text-errorText4 mr-1" />
              Can't be created
            </div>
          ),
          message: (
            <p className="bg-errorBg2 text-errorText4 text-xs rounded-full px-3 py-0.5">
              {message}
            </p>
          ),
        };

      default:
        return {
          action: (
            <div className="flex items-center text-xs">
              <FiCheck size={16} className="text-successText4 mr-1" />
              Will be created
            </div>
          ),
          message: (
            <p className="bg-successBg2 text-successText4 text-xs rounded-full px-3 py-0.5">
              Success
            </p>
          ),
        };
    }
  };

  const checkData = async () => {
    let resp;
    switch (bulkImportType) {
      case BulkImportType.Vendor:
        resp = await checkBulkVendors(
          processedData.map((el) => {
            return {
              ...el,
              tempId: nanoid(),
              __parsed_extra: undefined,
              amount: el.amount ? Number(el.amount) : undefined,
            };
          })
        );
        break;
      case BulkImportType.Salesperson:
        resp = await checkBulkSalespersons(
          processedData.map((el) => {
            return { ...el, tempId: nanoid() };
          })
        );
        break;
      default:
        break;
    }

    const modifiedData = resp.map((el, index) => {
      const temp = {
        ...el,
        vendorId: el?.error?.entity?.id,
      };
      return {
        ...temp,
        id: `index-${index}`,
        ...getAction(temp, index),
      };
    });
    setData(modifiedData);
    setTableLoading(false);
  };

  useEffect(() => {
    setColumns([
      ...Object.values(matchedFields).map((el) => {
        return { key: el, name: startCase(el), minWidth: 120, width: 150 };
      }),
      {
        key: "action",
        name: "Action",
        minWidth: 200,
        width: 350,
        maxWidth: 450,
      },
      { key: "message", name: "Message" },
    ]);

    if (processedData) {
      checkData();
    }
  }, []);

  return (
    <div className="mt-4">
      <CustomModal
        boxStyle={{
          width: 400,
        }}
        bottomContainerStyle="py-2 border-t border-t-neutralBg4"
        title={
          <CustomModalTitle
            title="Conflict found"
            // description="Edit product information and package unit"
          />
        }
        openModal={openConflictModal}
        setOpenModal={setOpenConflictModal}
      >
        <div>
          <p className="text-sm font-bold">{selectedItem?.businessName}</p>

          <p className="text-sm text-neutralText3 mb-3">
            {selectedItem?.locationName}
          </p>
          {selectedItem?.vendorId ? (
            <BaseButton
              outline
              onClick={() => {
                const d = data.map((el, index) => {
                  if (el.tempId === selectedItem.tempId) {
                    const temp = {
                      ...el,
                      vendorId: undefined,
                    };
                    return {
                      ...temp,
                      ...getAction(temp, index),
                    };
                  }
                  return el;
                });
                setData(d);
                setOpenConflictModal(false);
              }}
              type="border-errorText5 hover:bg-errorText5 hover:text-white rounded-full"
              textColor="errorText5"
              text="Don't use from BusyBee"
            />
          ) : (
            <BaseButton
              outline
              onClick={() => {
                const d = data.map((el, index) => {
                  if (el.tempId === selectedItem.tempId) {
                    const temp = {
                      ...el,
                      vendorId: selectedItem?.error?.entity?.id,
                    };
                    return {
                      ...temp,
                      ...getAction(temp, index),
                    };
                  }
                  return el;
                });
                setData(d);
                setOpenConflictModal(false);
              }}
              type="border-successText5 hover:bg-successText5 hover:text-white rounded-full"
              textColor="successText5"
              text="Use this existing retailer"
            />
          )}
        </div>
      </CustomModal>

      <CustomTable
        loading={tableLoading}
        columns={columns}
        rows={data}
        bodyCellClasses={{ root: "!text-[10px]" }}
      />

      <div className="flex justify-end mt-3">
        <div className="mr-2">
          <BaseButton
            text={"Back"}
            type="border-neutralText2 hover:bg-neutralText2 hover:text-white"
            textColor="neutralText2"
            outline
            onClick={back}
            width={"w-40"}
          />
        </div>
        <BaseButton
          text={"Proceed"}
          onClick={() => {
            const d = data.filter(
              (el) =>
                !el.error.type ||
                el.error.type === BulkImportErrorTypes.Conflict
            );

            if (d.length > 0) {
              setProcessedData(
                d.map((el) => {
                  return {
                    ...el,
                    action: undefined,
                    message: undefined,
                    id: undefined,
                  };
                })
              );
              next();
            } else {
              toast.error("At least 1 valid retailer should be entered");
            }
          }}
          width={"w-40"}
        />
      </div>
    </div>
  );
};

export default ReviewData;
