import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      JSON.parse(localStorage.getItem("state"))?.login ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/get-started", state: { from: props.location } }}
        />
      )
    }
  />
);

export default ProtectedRoute;
