import * as Unicons from "@iconscout/react-unicons";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BiTargetLock } from "react-icons/bi";

import BaseButton from "../../components/common/baseButton";
import FeatureCards from "../../components/landingComponents/FeatureCards";
import LandingHeader from "../../components/landingComponents/landingHeader";
import SalespersonFeatureCards from "../../components/landingComponents/SalespersonFeatureCards";
import LandingNav from "../../components/landingComponents/landingNav";
import SmallCard from "../../components/landingComponents/smallCards";
import LandingFooter from "../../components/landingComponents/landingFooter";
import Map from "../../assets/Map.jpg";
import Task from "../../assets/Task.jpg";
import Track from "../../assets/Track.jpg";
import Manage from "../../assets/Manage.jpg";
import Report from "../../assets/Report.jpg";
import SalesMap from "../../assets/SalesMap.png";
import SalesTask from "../../assets/SalesTask.png";
import SalesNote from "../../assets/SalesNote.png";
import SalesReport from "../../assets/SalesReport.png";

const Features = () => {
  const history = useHistory();
  const [isActive, setIsActive] = useState("Map");
  const [salespersonFeature, setSalespersonFeature] = useState("SalesMap");
  const [img, setImg] = useState(Map);
  const [salesImg, setSalesImg] = useState(SalesMap);

  return (
    <>
      <div className="contain bg-backgroundLogoV bg-right-top bg-no-repeat">
        <LandingNav />
        <div className="pt-24 xl:pb-16 text-center px-4 xl:px-16">
          <div className="w-full xl:w-1/2 m-auto">
            <LandingHeader
              header="WELCOME TO BUSYBEE"
              body="One tool to do everything sales !"
            />
          </div>
          <div className="w-full xl:w-5/12 m-auto text-neutralText2 pt-4 pb-10 px-10">
            BusyBee packs powerful sales and retailer management features, all in
            one compact solution
          </div>
          <BaseButton
            text={
              <div className="flex justify-between items-center text-xl text-semibold">
                <div>Try for free</div>
                <Unicons.UilArrowRight size={12} className="mr-1" />
              </div>
            }
            onClick={() => history.push("/register")}
            type="h-[52px] bg-primary"
            width=""
          />
        </div>
        <div className="px-4 xl:px-16 py-10 grid grid-cols-1 xl:grid-cols-2">
          <div>
            <p className="font-black text-xl">BusyBee for Organisations</p>
            <p className="text-neutralText5 text-md max-w-[28rem] pb-6">
              BusyBee helps manage your sales-teams and retailers from one place
              without bugging you with complex programs.
            </p>
            <BaseButton
              text={
                <div className="flex justify-between items-center text-xl text-semibold">
                  <div>Try for free</div>
                  <Unicons.UilArrowRight size={12} className="mr-1" />
                </div>
              }
              onClick={() => history.push("/register")}
              type="h-[52px] bg-primary"
              width=""
            />
            <div className="pt-4">
              <FeatureCards
                header={"Map"}
                isActive={isActive}
                onClick={(e) => {
                  setIsActive("Map");
                  setImg(Map);
                }}
                body={
                  "Visualise your retailer network, have your retailers pinpointed on map. Mark territories and create actions on them."
                }
              />
              <FeatureCards
                isActive={isActive}
                onClick={(e) => {
                  setIsActive("Task");
                  setImg(Task);
                }}
                header={"Task"}
                body={
                  "Set daily tasks like sales visits, collections and more to salespersons and know when they are done."
                }
              />
              <FeatureCards
                isActive={isActive}
                onClick={(e) => {
                  setIsActive("Track");
                  setImg(Track);
                }}
                header={"Track"}
                body={
                  "Track and ensure sales visits are actually completed through automated salesperson location vetting."
                }
              />
              <FeatureCards
                isActive={isActive}
                onClick={(e) => {
                  setIsActive("Manage");
                  setImg(Manage);
                }}
                header={"Manage"}
                body={
                  "Manage and maintain all retailer and salesperson information from one place. Keep record of out outstanding dues, sales transactions and much more."
                }
              />
              <FeatureCards
                isActive={isActive}
                onClick={() => {
                  setIsActive("Report");
                  setImg(Report);
                }}
                header={"Report"}
                body={
                  "View sales and activity reports on your dashboard. Get insights into business growth with vital statistics."
                }
              />
            </div>
          </div>
          <div
            className={`hidden xl:flex w-full ${isActive} mx-4 rounded-2xl  items-center justify-center pl-4`}
          >
            <img src={img} alt="" />
          </div>
        </div>
        <div className="px-4 xl:px-16 py-10 grid grid-cols-1 xl:grid-cols-2">
          <div
            className={` hidden xl:flex w-full ${salespersonFeature}  rounded-2xl  items-center justify-center py-2`}
          >
            <img src={salesImg} alt="" className="" />
          </div>
          <div className="xl:mx-8">
            <p className="font-black text-xl">BusyBee for Salespersons</p>
            <p className="text-neutralText5 text-md max-w-[28rem] pb-6">
              BusyBee assists sales teams out in the field. It works
              unobtrusively in the back- ground so you can focus on your sales.
            </p>
            <BaseButton
              text={
                <div className="flex justify-between items-center text-xl text-semibold">
                  <div>Try for free</div>
                  <Unicons.UilArrowRight size={12} className="mr-1" />
                </div>
              }
              onClick={() => history.push("/register")}
              type="h-[52px] bg-primary"
              width=""
            />
            <div className="pt-4">
              <SalespersonFeatureCards
                header={"Map"}
                SalespersonFeature={salespersonFeature}
                SalespersonHeader={"SalesMap"}
                onClick={(e) => {
                  setSalespersonFeature("SalesMap");
                  setSalesImg(SalesMap);
                }}
                body={
                  "Pin retailer location on map on your next visit. Have all your retailers displayed on map to help you plan and decide on routes."
                }
              />
              <SalespersonFeatureCards
                SalespersonFeature={salespersonFeature}
                SalespersonHeader={"SalesTask"}
                onClick={(e) => {
                  setSalespersonFeature("SalesTask");
                  setSalesImg(SalesTask);
                }}
                header={"Task"}
                body={
                  "Get daily notifications and reminders for sales visits so you don't forget to attend to your tasks."
                }
              />
              <SalespersonFeatureCards
                SalespersonFeature={salespersonFeature}
                SalespersonHeader={"SalesNote"}
                onClick={(e) => {
                  setSalespersonFeature("SalesNote");
                  setSalesImg(SalesNote);
                }}
                header={"Notes"}
                body={"Add handy notes right from your app when closing deals."}
              />
              <SalespersonFeatureCards
                SalespersonFeature={salespersonFeature}
                SalespersonHeader={"SalesReport"}
                onClick={() => {
                  setSalespersonFeature("SalesReport");
                  setSalesImg(SalesReport);
                }}
                header={"Report"}
                body={
                  "Record daily sales and transactions and get a birds-eye view of your performance and growth over time with stats and reports."
                }
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full items-center pb-4">
          <LandingHeader body="All Features" />
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-4 px-4 xl:px-16 gap-x-6">
          <SmallCard
            icon={<Unicons.UilLocationPinAlt size={40} />}
            label="Retailer Mapping"
            info="Plot and view physical locations of retailers"
          />
          <SmallCard
            icon={<Unicons.UilMoneyBill size={40} />}
            label="Sales Registry"
            info="Build and maintain sales records automatically"
          />
          <SmallCard
            icon={<Unicons.UilCoins size={40} />}
            label="Balance Registry"
            info="Don’t forget those balance figures"
          />
          <SmallCard
            icon={<Unicons.UilBusSchool size={40} />}
            label="Sales Visit Tracking"
            info="See whose visiting when and how often."
          />
          <SmallCard
            icon={<Unicons.UilChart size={40} />}
            label="Progress Statistics"
            info="See how well your sales team is performing"
          />
          <SmallCard
            icon={<Unicons.UilFileCheckAlt size={40} />}
            label="Order Tracking"
            info="Make sure your retailers receive their ordered"
          />
          <SmallCard
            icon={<Unicons.UilCalendarAlt size={40} />}
            label="Daily Visits"
            info="Plan your day ahead by finding your daily visits"
          />
          <SmallCard
            icon={<Unicons.UilCoins size={40} />}
            label="Balance log"
            info="Know who owes you and how much"
          />
          <SmallCard
            icon={<Unicons.UilFileCheckAlt size={40} />}
            label="Manage Orders"
            info="Deliver orders, postpone or just cancel anytime"
          />
          <SmallCard
            icon={<Unicons.UilMoneyBill size={40} />}
            label="Sales Monitor"
            info="See all your sales on your   sales dashboard"
          />
          <SmallCard
            icon={<Unicons.UilUsersAlt size={40} />}
            label="Manage Retailers"
            info="Keep your retailer profiles always up to date"
          />
          <SmallCard
            icon={<BiTargetLock size={40} />}
            label="Target Goals"
            info="See you target and track your growth"
          />
        </div>
      </div>
      <LandingFooter />
    </>
  );
};
export default Features;
