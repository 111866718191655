import React from "react";
import { Link, useHistory } from "react-router-dom";

const SideNavItem = (props) => {
  const history = useHistory();
  return (
    <Link to={props.link}>
      <div
        className={`p-2 my-2 flex items-center text-[11px] font-[12px] transition ease-in-out delay-150 duration-300 hover:bg-neutralBg3 hover:text-neutralText1 hover:scale-110 ${
          history.location.pathname === props.link
            ? "text-neutralText1 bg-neutralBg3 font-bold"
            : " text-neutralText5"
        } `}
      >
        <>{props.icon}</>
        <div className="px-2"> {props.name}</div>
      </div>
    </Link>
  );
};

export default SideNavItem;
