import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as Unicons from "@iconscout/react-unicons";
import { Formik } from "formik";

import { LoginSchema } from "../validationSchema";
import { login } from "../../redux store/actions/loginAndRegistration";
import ValidationErrors from "../common/validationErrors";
import CustomInput from "../common/customInput";
import BaseButton from "../common/baseButton";

const LoginForm = () => {
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className="">
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={LoginSchema}
        // validate={(values)
        //   => {
        //   const errors = {};
        //   if (!values.email) {
        //     errors.email = "Required";
        //   } else if (
        //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        //   ) {
        //     errors.email = "Invalid emai••••••l address";
        //   }
        //   if (!values.password) {
        //     errors.password = "Required";
        //   }
        //   return errors;
        // }}
        onSubmit={(values) => {
          setLoader(true);
          dispatch(login(values));
          setTimeout(() => {
            setLoader(false);
          }, 2000);
          // history.push("/dashboard");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <CustomInput
              icon={<Unicons.UilUser className="text-neutralText5=1 h-5 w-5" />}
              containerClasses={"py-2 text-neutralText1"}
              label="Email"
              className="w-full outline-none border rounded-sm p-2"
              placeholder="mail@abc.com"
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {touched.email && errors.email && (
              <ValidationErrors errors={`${errors.email}`}></ValidationErrors>
            )}
            <CustomInput
              icon={<Unicons.UilLock className="text-neutralText5=1 h-5 w-5" />}
              containerClasses={"py-2 text-neutralText1"}
              label="Password"
              className="w-full outline-none border rounded-sm p-2"
              placeholder="********"
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            {touched.password && errors.password && (
              <ValidationErrors
                errors={`${errors.password}`}
              ></ValidationErrors>
            )}
            <Link to="/forgot-password" className="text-infoText4 text-xs">
              Forgot Password?
            </Link>
            <div className="py-8">
              <BaseButton
                text={"Login"}
                type="bg-primary w-full text-neutralText2 p-2 text-sm text-sm hover:bg-primaryLight"
                // onClick={() => handleSubmit()}
                disabled={loader}
                loading={loader}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
