import React from "react";
import * as Unicons from "@iconscout/react-unicons";
import { FiEdit2 } from "react-icons/fi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import Avatar from "./avatar";
import CustomModal from "./customModal";
import CustomModalTitle from "./customModalTitle";
import CreateOrder from "../createOrder";
import { useSelector } from "react-redux";

function OrderDetailsHeader({ order, disableOuterLink, handleClose }) {
  const currentUser = useSelector((state) => state.login);
  console.log("currentUser,,,", order);
  return (
    <>
      <div className="flex items-center justify-between p-4 text-neutralText1">
        <div className="flex text-neutralText3 items-center">
          <Avatar
            imageClassName={"w-12 h-12"}
            image={order?.vendor?.businessLogo}
          />
          <div className="px-2">
            <Link to={`/view-vendor/${order?.vendor?.id}`}>
              <div className="text-sm hover:text-neutralText1">
                {order?.vendor?.businessName}
              </div>
            </Link>
            {order?.vendor?.mapped ? (
              <div className="flex items-center text-neutralText5">
                <Unicons.UilLocationPoint size={13} />
                <div className="text-[10px] text-infoText4 px-1">
                  {order?.vendor?.locationName}
                </div>
              </div>
            ) : (
              <div className="flex items-center text-warningText4 truncate">
                <Unicons.UilExclamationTriangle size={11} />
                <div className="text-[10px] text-neutralText5 px-1 truncate">
                  {order?.vendor?.locationName}(Not Mapped)
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex">
          <CustomModal
            title={
              <CustomModalTitle
                title="Edit Order"
                description="Enter product information and package unit"
              />
            }
            boxStyle={{ p: 0, width: 550 }}
            topContainerStyle={"px-[32px] pt-[32px]"}
            bottomContainerStyle={
              "px-[32px] pb-[32px] bg-neutralBg3 rounded-b mt-2"
            }
            button={
              <FiEdit2
                className={`h-5 w-5 mr-4 text-infoText4 ${
                  ["COMPLETED", "CANCELLED"].includes(order?.status)
                    ? "hidden"
                    : "block"
                }`}
              />
            }
          >
            <CreateOrder orderDetails={order} handleClose={handleClose} />
          </CustomModal>
          {!disableOuterLink && (
            <Link to={`/view-order/${order?.id}`} target="_blank">
              <Unicons.UilExternalLinkAlt size={20} />
            </Link>
          )}
        </div>
      </div>
      <div className="px-4 pb-2 text-sm text-neutralText3">
        Created By :{" "}
        {order?.addedById?.businessName === currentUser?.businessName
          ? "You"
          : order?.addedById?.name}
      </div>
    </>
  );
}

export default OrderDetailsHeader;
