import React, { useEffect, useRef } from "react";
import { BUCKET_URL } from "../../constants/config";

const SliderVideo = ({ isActive, src }) => {
  const videoRef = useRef();
  useEffect(() => {
    if (isActive) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, [isActive]);

  return (
    <video
      controls
      controlsList="nodownload"
      ref={videoRef}
      // src={`${process.env.REACT_APP_BUCKET_URL}assets/${src}`}
      src={`${BUCKET_URL}assets/${src}`}
      autoPlay
      playsInline
      loop
      muted
      alt="vid"
      className={`transition  ease-in-out delay-100 duration-1000 p-4 ${
        isActive ? "h-auto scale-110 -translate-y-1" : "opacity-10"
      }`}
    ></video>
  );
};

export default SliderVideo;
