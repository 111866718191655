import { Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import GoogleAutocomplete from "react-google-autocomplete";
import * as Unicons from "@iconscout/react-unicons";

import { addVendorSchema } from "../validationSchema";
import ValidationErrors from "../common/validationErrors";
import CustomInput from "../common/customInput";
import BaseButton from "../common/baseButton";
import { MAPS_API_KEY } from "../../constants/config";
import { addVendor, addExistingVendor } from "../../services/vendorsApi";
import CustomAutocomplete from "../common/customAutocomplete";
import { getSalesPersons } from "../../services/salespersonApi";
import getCurrency from "../../utils/getCurrency";

const VendorForm = ({
  initialValues,
  existing,
  handleCloseModal,
  back,
  field,
}) => {
  const [loading, setLoading] = useState(false);
  const [salespersonsLoading, setSalespersonsLoading] = useState(false);
  const [salespersons, setSalespersons] = useState([]);
  const [query, setQuery] = useState("");

  const handleSubmitForm = async (values) => {
    if (!values.contact) values.contact = "";
    if (typeof values.contact === "string") values.contact = values.contact;
    if (typeof values.contact === "number")
      values.contact = JSON.stringify(values.contact);
    setLoading(true);
    let vendorDetail = {
      vendorId: initialValues?.id || "",
      businessName: values.businessName,
      contacts: [
        {
          name: values?.contactPerson || "",
          contact: values.contact,
          email: "",
        },
      ],
      location: values?.location || [],
      locationName: values?.locationName,
      amount: values?.openingBalance || 0,
      spId: values.assignSalesperson?.id,
    };

    try {
      const vendorResponse = existing
        ? await addExistingVendor(vendorDetail)
        : await addVendor(vendorDetail);

      if (vendorResponse.data) {
        toast.success("Retailer Updated Successfully");
      } else if (vendorResponse) {
        toast.success("Retailer Added Successfully");
      } else {
      }
      handleCloseModal();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const searchSalespersons = async (query) => {
    setSalespersonsLoading(true);
    const resp = await getSalesPersons({
      offset: 1,
      limit: 5,
      query,
      status: "ACTIVE",
    });
    setSalespersons(resp.result || []);
    setSalespersonsLoading(false);
  };

  useEffect(() => {
    searchSalespersons(query);
  }, [query]);

  return (
    <Formik
      initialValues={{
        businessName: initialValues?.businessName || initialValues || "",
        contact: initialValues?.contact || "",
        assignSalesperson: initialValues?.salesperson || "",
        contactPerson: initialValues?.contactPerson || "",
        openingBalance: initialValues?.balance || "",
        locationName: initialValues?.locationName || "",
        // location:
        //   initialValues?.location?.coordinates?.length === 2
        //     ? [initialValues?.location?.coordinates[1][0]]
        //     : [],
      }}
      validationSchema={addVendorSchema}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <CustomInput
                icon={
                  <Unicons.UilBuilding className="text-neutralText5 h-5 w-5" />
                }
                // containerClasses="py-2"
                label="Name"
                placeholder="John Proprietary"
                type="text"
                name="businessName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.businessName}
                disabled={existing}
              />
              {touched.businessName && errors.businessName && (
                <ValidationErrors
                  errors={`${errors.businessName}`}
                ></ValidationErrors>
              )}
            </div>
            <div>
              <CustomInput
                icon={<Unicons.UilUser className="text-neutralText5 h-5 w-5" />}
                // containerClasses="py-2"
                label="Business Address"
                placeholder="e.g., Srinagar, J&amp;K"
                type="text"
                name="locationName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.locationName}
                disabled={existing}
              />
              {/* <div className="text-neutralText2 text-xs py-1 block font-light">
                Business Address
              </div> */}
              {/* <div
                className={`flex items-center border border-0.5 border-neutralBg4 bg-neutralBg1 rounded-sm px-2 py-1 focus-within:border-infoText5 transition ${
                  existing ? "bg-neutralBg3" : ""
                }`}
              >
                <Unicons.UilUserLocation className="text-neutralText5 h-5 w-5" />
                <GoogleAutocomplete
                  className="bg-transparent text-sm text-neutralText2 px-2 py-0.5 w-full outline-none "
                  inputAutocompleteValue={"off"}
                  id="locationName"
                  name="locationName"
                  placeholder="e.g., Srinagar, J&amp;K"
                  value={values.locationName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  apiKey={MAPS_API_KEY}
                  options={{
                    types: [],
                  }}
                  disabled={existing}
                  onPlaceSelected={(place) => {
                    // console.log("geometry", place.geometry.location);
                    setFieldValue("locationName", place.formatted_address);
                    setFieldValue("location", [
                      place.geometry.location.lat(),
                      place.geometry.location.lng(),
                    ]);
                  }}
                />
              </div> */}
              {touched.locationName && errors.locationName && (
                <ValidationErrors
                  errors={`${errors.locationName}`}
                ></ValidationErrors>
              )}
            </div>
            <div>
              <CustomInput
                icon={
                  <Unicons.UilPhone className="text-neutralText5 h-5 w-5" />
                }
                // containerClasses="py-2"
                label="Contact Number"
                placeholder="+1 (298) 988967"
                type="number"
                allowNumOnly
                name="contact"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contact}
              />
              {touched.contact && errors.contact && (
                <ValidationErrors
                  errors={`${errors.contact}`}
                ></ValidationErrors>
              )}
            </div>
            <div>
              <CustomInput
                icon={<Unicons.UilUser className="text-neutralText5 h-5 w-5" />}
                // containerClasses="py-2"
                label="Contact Person"
                placeholder="John Doe"
                type="text"
                name="contactPerson"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.contactPerson}
              />
              {touched.contactPerson && errors.contactPerson && (
                <ValidationErrors
                  errors={`${errors.contactPerson}`}
                ></ValidationErrors>
              )}
            </div>
            <div>
              <CustomInput
                icon={
                  <div className="text-neutralText5 h-5 w-5 flex justify-center items-center">
                    {getCurrency()}
                  </div>
                }
                // containerClasses="py-2"
                label="Opening Balance"
                placeholder="60090"
                type="number"
                allowNumOnly
                name="openingBalance"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.openingBalance}
              />
              {touched.openingBalance && errors.openingBalance && (
                <ValidationErrors
                  errors={`${errors.openingBalance}`}
                ></ValidationErrors>
              )}
            </div>
            <div>
              <CustomAutocomplete
                label={"Assign Salesperson"}
                icon={
                  <Unicons.UilUserSquare className="text-neutralText5 h-5 w-5" />
                }
                value={values.assignSalesperson?.name}
                onChange={(event, newValue) => {
                  console.log("assignSalesperson", newValue);
                  setFieldValue("assignSalesperson", newValue);
                }}
                filterOptions={(x) => x}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.name
                }
                options={salespersons}
                autoComplete
                type="text"
                includeInputInList
                filterSelectedOptions
                onInputChange={(event, newInputValue) => {
                  setQuery(newInputValue);
                }}
                loading={salespersonsLoading}
              />

              {touched.assignSalesperson && errors.assignSalesperson && (
                <ValidationErrors
                  errors={`${errors.assignSalesperson}`}
                ></ValidationErrors>
              )}
            </div>
          </div>
          <div className=" flex justify-between mt-3">
            <BaseButton
              text={"Back"}
              // outline
              width={"w-36"}
              type={"border border-successText5"}
              textColor="successText5"
              onClick={back}
              // loading={loading}
            />
            <BaseButton
              buttonProps={{ type: "submit" }}
              text={existing ? "Save" : "Add"}
              // outline
              width={"w-36"}
              type={"bg-secondary hover:bg-secondaryLight"}
              textColor="neutralBg1"
              // onClick={onPositiveClick}
              loading={loading}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
export default VendorForm;
