import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

import LandingFooter from "../../components/landingComponents/landingFooter";
import LoginForm from "../../components/forms/loginForm";
import Register from "../../components/forms/registerationForm";
import LandingHeader from "../../components/landingComponents/landingHeader";
import OuterDiv from "../../components/common/outerDiv";
import BusyBeeLogo from "../../assets/BusyBeeLogo.svg";
import GradientBackground from "../../components/common/gradientBackground";

const Login = () => {
  const [toggle, settoggle] = useState(true);
  const handleToggle = () => {
    settoggle(!toggle);
  };
  
  return (
    <div className="flex justify-center items-center h-screen bg-loginBg bg-center bg-cover bg-no-repeat">
      {/* <GradientBackground /> */}
      <OuterDiv className="my-0 mx- w-11/12 sm:w-3/4 md:w-1/2 lg:w-5/12 2xl:w-1/3 z-10 shadow-xl">
        <div className="px-3 sm:px-12 py-8 sm:py-20">
          <div className="flex justify-center sm:pb-6">
            <Link to="/">
              <img src={BusyBeeLogo} />
            </Link>
          </div>
          <div className="py-6">
            <div className="text-xl text-center font-semibold text-neutralText1">
              Login to BusyBee
            </div>
            <div className="text-xs text-neutralText4 text-center py-1">
              See what is going on with your business
            </div>
          </div>
          <LoginForm />
          <div className="text-neutralText1">
            Not Registered Yet?{" "}
            <Link to="/register">
              <span
                onClick={() => handleToggle()}
                className="text-infoText4 cursor-pointer hover:text-primary-light"
              >
                Try for free
              </span>
            </Link>
          </div>
        </div>
      </OuterDiv>
    </div>
  );
};

export default Login;
