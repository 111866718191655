import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";

import ConvertTimestamp from "./convertTimestamp";
import OuterDiv from "./common/outerDiv";
import { getActivity } from "../services/activitiesAndNotificationsApi";

const ActivityCard = () => {
  const [activities, setActivities] = useState([]);

  const stopEvent = (event) => event.stopPropagation();
  const getData = async () => {
    const data = await getActivity(1, 10);
    setActivities(data.result);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <OuterDiv>
      <div className="px-8 py-6">
        <div className="text-md font-sm pb-4">
          <div className="text-neutralText1 font-bold">Activity</div>
          <div className="flex justify-between">
            <div className="text-[12px] text-neutralText3">
              Showing overall business activities
            </div>
            <Link to="/activities">
              <div className="text-infoText4 text-[12px]">View All</div>
            </Link>
          </div>
        </div>

        <div className="overflow-auto p-1  scrollbar scrollbar-thumb-neutralText4 scrollbar-track-neutralBg3 scrollbar-thin hover:scrollbar-thumb-neutralText3">
          {activities?.map((item, index) => (
            <div>
              {(() => {
                switch (item?.activity) {
                  case "created a new order":
                    return (
                      <Link to={`/view-order/${item?.performedOn?.id}`}>
                        <div
                          key={index}
                          className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                        >
                          <div className="text-[14px] text-neutralText3 font-bold">
                            <Link
                              className="text-infoText4"
                              to={`/view-salesperson/${item?.performer?.id}`}
                              id="wfu"
                              onClick={stopEvent}
                            >
                              {item?.performer?.name}
                            </Link>{" "}
                            {item?.activity}.
                            <div className="text-[10px] flex items-center text-neutralText4">
                              <Unicons.UilClockThree
                                size={12}
                                className="mr-1"
                              />
                              <></>
                              <ConvertTimestamp
                                timestamp={item.createdAt}
                                dayjs
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                    break;
                  case "visit completed":
                    return (
                      <Link
                        to={{
                          pathname: "/visits",
                          state: {
                            vendor: item?.performedOn?.vendorId,
                            salesperson: item?.performedOn?.spId,
                            toDate: item?.performedOn?.visitedAt,
                            fromDate: item?.performedOn?.visitedAt,
                          },
                        }}
                      >
                        <div
                          key={index}
                          className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                        >
                          <div className="text-[14px] text-neutralText3 font-bold">
                            Visit Completed by{" "}
                            <Link
                              className="text-infoText4"
                              to={`/view-salesperson/${item?.performer?.id}`}
                              id="wfu"
                              onClick={stopEvent}
                            >
                              {item?.performer?.name}.
                            </Link>
                            <div className="text-[10px] flex items-center text-neutralText4">
                              <Unicons.UilClockThree
                                size={12}
                                className="mr-1"
                              />
                              <></>
                              <ConvertTimestamp
                                timestamp={item.createdAt}
                                dayjs
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                    break;
                  case "logged in":
                    return (
                      <div
                        key={index}
                        className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                      >
                        <div className="text-[14px] text-neutralText3 font-bold">
                          {item?.performedOn?.businessName ? (
                            "You logged in."
                          ) : (
                            <>
                              <Link
                                className="text-infoText4"
                                to={`/view-salesperson/${item?.performer?.id}`}
                                id="wfu"
                                onClick={stopEvent}
                              >
                                {item?.performer?.name}
                              </Link>{" "}
                              logged in.
                            </>
                          )}
                          <div className="text-[10px] flex items-center text-neutralText4">
                            <Unicons.UilClockThree size={12} className="mr-1" />
                            <></>
                            <ConvertTimestamp
                              timestamp={item.createdAt}
                              dayjs
                            />
                          </div>
                        </div>
                      </div>
                    );
                    break;
                  case "transaction added":
                    return (
                      <Link
                        to={{
                          pathname: "/transactions",
                          state: {
                            vendor: item?.performedOn?.vendorId,
                            salesperson: item?.performedOn?.spId,
                            status: "COMPLETED",
                            toDate: item?.performedOn?.date,
                            fromDate: item?.performedOn?.date,
                          },
                        }}
                      >
                        <div
                          key={index}
                          className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                        >
                          <div className="text-[14px] text-neutralText3 font-bold">
                            New transaction added by{" "}
                            {item?.performer?.businessName ? (
                              "You"
                            ) : (
                              <Link
                                className="text-infoText4"
                                to={`/view-salesperson/${item?.performer?.id}`}
                                id="wfu"
                                onClick={stopEvent}
                              >
                                {item?.performer?.name}.
                              </Link>
                            )}
                            <div className="text-[10px] flex items-center text-neutralText4">
                              <Unicons.UilClockThree
                                size={12}
                                className="mr-1"
                              />
                              <></>
                              <ConvertTimestamp
                                timestamp={item.createdAt}
                                dayjs
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                    break;
                  case "completed order":
                    return (
                      <Link to={`/view-order/${item?.performedOn?.id}`}>
                        <div
                          key={index}
                          className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                        >
                          <div className="text-[14px] text-neutralText3 font-bold">
                            Order completed by{" "}
                            <Link
                              className="text-infoText4"
                              to={`/view-salesperson/${item?.performer?.id}`}
                              id="wfu"
                              onClick={stopEvent}
                            >
                              {item?.performer?.name}.
                            </Link>
                            <div className="text-[10px] flex items-center text-neutralText4">
                              <Unicons.UilClockThree
                                size={12}
                                className="mr-1"
                              />
                              <></>
                              <ConvertTimestamp
                                timestamp={item.createdAt}
                                dayjs
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                    break;
                  case "added new retailer":
                    return (
                      <Link to={`/view-vendor/${item?.performedOn?.id}`}>
                        <div
                          key={index}
                          className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                        >
                          <div className="text-[14px] text-neutralText3 font-bold">
                            <Link
                              className="text-infoText4"
                              to={`/view-salesperson/${item?.performer?.id}`}
                              id="wfu"
                              onClick={stopEvent}
                            >
                              {item?.performer?.name}
                            </Link>{" "}
                            added a new retailer{""}{" "}
                            <Link
                              className="text-infoText4"
                              to={`/view-vendor/${item?.performedOn?.id}`}
                              id="wfu"
                              onClick={stopEvent}
                            >
                              {item?.performedOn?.businessName}.
                            </Link>{" "}
                            <div className="text-[10px] flex items-center text-neutralText4">
                              <Unicons.UilClockThree
                                size={12}
                                className="mr-1"
                              />
                              <></>
                              <ConvertTimestamp
                                timestamp={item.createdAt}
                                dayjs
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                    break;
                  case "salesperson updated":
                    return (
                      <Link to={`/view-salesperson/${item?.performedOn?.id}`}>
                        <div
                          key={index}
                          className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                        >
                          <div className="text-[14px] text-neutralText3 font-bold">
                            {item?.performer?.businessName ? (
                              <div>
                                You updated{" "}
                                <Link
                                  className="text-infoText4"
                                  to={`/view-salesperson/${item?.performedOn?.id}`}
                                  id="wfu"
                                  onClick={stopEvent}
                                >
                                  {item?.performedOn?.name}
                                </Link>
                                's profile.
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="text-[10px] flex items-center text-neutralText4">
                              <Unicons.UilClockThree
                                size={12}
                                className="mr-1"
                              />
                              <></>
                              <ConvertTimestamp
                                timestamp={item.createdAt}
                                dayjs
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                    break;
                  case "added new product":
                    return (
                      <Link to={`/products`}>
                        <div
                          key={index}
                          className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                        >
                          <div className="text-[14px] text-neutralText3 font-bold">
                            {item?.performer?.businessName ? (
                              <div>
                                You added a new product{" "}
                                <Link
                                  className="text-infoText4"
                                  to={`/products`}
                                  id="wfu"
                                  onClick={stopEvent}
                                >
                                  {item?.performedOn?.name}
                                </Link>
                                .
                              </div>
                            ) : (
                              <div>
                                <Link
                                  className="text-infoText4"
                                  to={`/view-salesperson/${item?.performer?.id}`}
                                  id="wfu"
                                  onClick={stopEvent}
                                >
                                  {item?.performer?.name}
                                </Link>{" "}
                                added a new product{" "}
                                <Link
                                  className="text-infoText4"
                                  to={`/products`}
                                  id="wfu"
                                  onClick={stopEvent}
                                >
                                  {item?.performedOn?.name}
                                </Link>
                                .
                              </div>
                            )}
                            <div className="text-[10px] flex items-center text-neutralText4">
                              <Unicons.UilClockThree
                                size={12}
                                className="mr-1"
                              />
                              <></>
                              <ConvertTimestamp
                                timestamp={item.createdAt}
                                dayjs
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                    break;
                  case "added new territory":
                    return (
                      <Link>
                        <div
                          key={index}
                          className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                        >
                          <div className="text-[14px] text-neutralText3 font-bold">
                            New territory added.
                            <div className="text-[10px] flex items-center text-neutralText4">
                              <Unicons.UilClockThree
                                size={12}
                                className="mr-1"
                              />
                              <></>
                              <ConvertTimestamp
                                timestamp={item.createdAt}
                                dayjs
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                    break;
                  case "territory deleted":
                    return (
                      <Link>
                        <div
                          key={index}
                          className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                        >
                          <div className="text-[14px] text-neutralText3 font-bold">
                            Territory deleted.
                            <div className="text-[10px] flex items-center text-neutralText4">
                              <Unicons.UilClockThree
                                size={12}
                                className="mr-1"
                              />
                              <></>
                              <ConvertTimestamp
                                timestamp={item.createdAt}
                                dayjs
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                    break;
                  case "new retailer assigned":
                    return (
                      <Link to={`/view-vendor/`}>
                        <div
                          key={index}
                          className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                        >
                          <div className="text-[14px] text-neutralText3 font-bold">
                            New retailer assigned to{" "}
                            <Link
                              className="text-infoText4"
                              to={`/view-salesperson/${item?.performedOn?.id}`}
                              id="wfu"
                              onClick={stopEvent}
                            >
                              {item?.performedOn?.name}.
                            </Link>{" "}
                            <div className="text-[10px] flex items-center text-neutralText4">
                              <Unicons.UilClockThree
                                size={12}
                                className="mr-1"
                              />
                              <></>
                              <ConvertTimestamp
                                timestamp={item.createdAt}
                                dayjs
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                    break;
                  case "new visit assigned":
                    return (
                      <Link
                        to={{
                          pathname: "/visits",
                          state: {
                            vendor: item?.performedOn?.vendorId,
                            salesperson: item?.performedOn?.spId,
                            toDate: item?.performedOn?.visitedAt,
                            fromDate: item?.performedOn?.visitedAt,
                          },
                        }}
                      >
                        <div
                          key={index}
                          className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                        >
                          <div className="text-[14px] text-neutralText3 font-bold">
                            New visit assigned to{" "}
                            <Link
                              className="text-infoText4"
                              to={`/view-salesperson/${item?.performedOn?.id}`}
                              id="wfu"
                              onClick={stopEvent}
                            >
                              {item?.performedOn?.name}.
                            </Link>{" "}
                            <div className="text-[10px] flex items-center text-neutralText4">
                              <Unicons.UilClockThree
                                size={12}
                                className="mr-1"
                              />
                              <></>
                              <ConvertTimestamp
                                timestamp={item.createdAt}
                                dayjs
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                    break;
                  default:
                    return null;
                    break;
                }
              })()}
            </div>
            // </div>
          ))}
        </div>
      </div>
    </OuterDiv>
  );
};
export default ActivityCard;
