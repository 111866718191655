import {
  GET_SALES_PERSON_LIST,
  RESET_STATE,
  GET_VENDOR_LIST_BY_SALES_PERSON,
  GET_ASSIGNED_VENDORS_BY_SALES_PERSON,
  GENERATED_PASSWORD,
  SALESPERSON_TO_EDIT,
  ADD_NEW_SALES_PERSON,
  UPDATE_SALES_PERSON,
} from "../actions/actionTypes";

//for sales person list
export const salesPersonList = (
  response = [{ list: [], isLast: false, status: null }],
  action
) => {
  switch (action.type) {
    case GET_SALES_PERSON_LIST:
      return (response = [
        {
          list: [...response[0].list, ...action.payload.list],
          isLast: action.payload.isLast,
          status: action.payload.responseStatus,
        },
      ]);

    case RESET_STATE:
      return (response = [{ list: [], isLast: false, status: null }]);

    default:
      return response;
  }
};

//for getting vendor list by sales person
// export const getVendorListBySalesPerson = (
//   vendors = [{ list: [], isLast: false, status: null }],
//   action
// ) => {
//   switch (action.type) {
//     case GET_VENDOR_LIST_BY_SALES_PERSON:
//       return (vendors = [
//         {
//           list: [...vendors[0].list, ...action.payload.list],
//           isLast: action.payload.isLast,
//           status: action.payload.responseStatus,
//         },
//       ]);
//     case RESET_STATE:
//       return (vendors = [{ list: [], isLast: false, status: null }]);
//     default:
//       return vendors;
//   }
// };

// export const getAssignedVendorsBySalesPerson = (
//   vendors = [{ list: [], isLast: false, status: null }],
//   action
// ) => {
//   switch (action.type) {
//     case GET_ASSIGNED_VENDORS_BY_SALES_PERSON:
//       return (vendors = [
//         {
//           list: [...vendors[0].list, ...action.payload.list],
//           isLast: action.payload.isLast,
//           status: action.payload.responseStatus,
//         },
//       ]);
//     case RESET_STATE:
//       return (vendors = [{ list: [], isLast: false, status: null }]);
//     default:
//       return vendors;
//   }
// };

//for generating password
export const generatePassword = (password = null, action) => {
  switch (action.type) {
    case GENERATED_PASSWORD:
      return action.payload;

    default:
      return password;
  }
};

export const salesPersonToEdit = (sp = null, action) => {
  switch (action.type) {
    case SALESPERSON_TO_EDIT:
      return action.payload;

    default:
      return sp;
  }
};

//for adding new sales person
export const addNewSalesPerson = (response = null, action) => {
  switch (action.type) {
    case ADD_NEW_SALES_PERSON:
      return action.payload;
    case RESET_STATE:
      return (response = null);

    default:
      return response;
  }
};

//for updating sales person
export const updateSalesPerson = (response = null, action) => {
  switch (action.type) {
    case UPDATE_SALES_PERSON:
      return action.payload;
    case RESET_STATE:
      return (response = null);

    default:
      return response;
  }
};
