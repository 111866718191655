import {
  GET_PRODUCT_LIST,
  RESET_STATE,
  GET_ORDERS,
  GET_QUERIES,
  ADD_NEW_PRODUCT,
  EDIT_PRODUCT,
  PRODUCT_TO_EDIT,
} from "../actions/actionTypes";

//for getting product list
export const getProductList = (
  productList = [{ list: [], isLast: false, status: null }],
  action
) => {
  switch (action.type) {
    case GET_PRODUCT_LIST:
      return (productList = [
        {
          list: [...productList[0].list, ...action.payload.list],
          isLast: action.payload.isLast,
          status: action.payload.responseStatus,
        },
      ]);

    case RESET_STATE:
      return (productList = [{ list: [], isLast: false }]);

    default:
      return productList;
  }
};

//for getting orders
export const getOrders = (
  orders = [{ list: [], isLast: false, status: null }],
  action
) => {
  switch (action.type) {
    case GET_ORDERS:
      return (orders = [
        {
          list: [...orders[0].list, ...action.payload.list],
          isLast: action.payload.isLast,
          status: action.payload.responseStatus,
        },
      ]);

    case RESET_STATE:
      return (orders = [{ list: [], isLast: false, status: null }]);

    default:
      return orders;
  }
};
export const getQueries = (
  queries = [{ list: [], isLast: false, status: null }],
  action
) => {
  switch (action.type) {
    case GET_QUERIES:
      return (queries = [
        {
          list: [...queries[0].list, ...action.payload.list],
          isLast: action.payload.isLast,
          status: action.payload.responseStatus,
        },
      ]);

    case RESET_STATE:
      return (queries = [{ list: [], isLast: false, status: null }]);

    default:
      return queries;
  }
};

//for adding new product
export const addNewProduct = (response = null, action) => {
  switch (action.type) {
    case ADD_NEW_PRODUCT:
      return action.payload;
    case RESET_STATE:
      return (response = null);

    default:
      return response;
  }
};

export const productToEdit = (product = null, action) => {
  switch (action.type) {
    case PRODUCT_TO_EDIT:
      return action.payload;

    default:
      return product;
  }
};

export const editProduct = (response = null, action) => {
  switch (action.type) {
    case EDIT_PRODUCT:
      return action.payload;
    case RESET_STATE:
      return (response = null);
    default:
      return response;
  }
};
