import React, { useState, useEffect, useRef } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";

import Logo from "../../assets/orgLogo.svg";
import FeaturesDropdown from "./featuresDropdown";
import { useSelector } from "react-redux";

const LandingNav = (props) => {
  const token = useSelector((state) => state?.login?.token);
  const history = useHistory();
  const toggle_ref = useRef(null);
  const contentRef = useRef(null);
  const [menu, setMenu] = useState(false);
  const [dropdownFeatures, setDropdownFeatures] = useState(false);

  const toggleDropdown = () => {
    setMenu(!menu);
    console.log("dropdown in toggle finc", menu);
  };

  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
    } else {
      // user click outside toggle and content
      if (contentRef.current && !contentRef.current.contains(e.target)) {
        setMenu(false);
      }
    }
  });

  console.log("dropdown before render", history.location.pathname);
  return token ? null : (
    <>
      {menu && (
        <div className="absolute h-full w-full bg-neutralBg1 py-6 sm:py-12 bg-backgroundLogoV bg-right-top bg-no-repeat transition duration-500 lg:hidden">
          {/* <div
      ref={contentRef}
      className={`text-lg xl:text-xl text-neutralText1 lg:hidden w-full text-center py-2 transform transition ease-linear duration-500  lghidden  text-secondary-text ${
        menu ? "-translate-y-8" : "-translate-y-72"
      }`}
    > */}
          <div className="flex justify-between items-center px-8">
            <img src={Logo} alt="" className="h-8" />
            <button onClick={() => setMenu(false)}>
              <FiX size={25} />
            </button>
          </div>
          <div className="text-center text-xl py-6">
            <div className="py-3 hover:bg-neutralBg2 border-y border-y-neutralBg3">
              <Link to="/features">Features</Link>
            </div>
            <div className="py-3 hover:bg-neutralBg2 border-b border-b-neutralBg3">
              <Link to="/learn">Learn</Link>
            </div>
            <div className="py-3 hover:bg-neutralBg2 border-b border-b-neutralBg3">
              <Link to="/company">Company</Link>
            </div>
            <div className="py-3 hover:bg-neutralBg2 border-b border-b-neutralBg3">
              <Link to="/get-started">Contact Sales</Link>
            </div>
            <div className="py-3 hover:bg-neutralBg2 border-b border-b-neutralBg3">
              <Link to="/get-started">Log in</Link>
            </div>
          </div>{" "}
          <div className="flex justify-center">
            <Link to="/register" className=" sm:hidden text-center">
              <button className="bg-primary text-xl min-w-[149px] text-neutralText1 py-[10px] px-14">
                Try for free
              </button>
            </Link>
          </div>
        </div>
      )}
      <div className="grid mx-auto grid-cols-2 text-lg bg-transparent font-semibold scroll-smooth lg:grid-cols-12 space-4 w-full items-center py-6 sm:py-10 2xl:px-6 z-30">
        <div className="col-span-2">
          <Link to="/">
            <img src={Logo} alt="" className="h-8 ml-8 xl:m-auto 2xl:m-auto" />
          </Link>
        </div>
        {/* <div className="pr-6 xl:pr-10 flex items-center">
            <Link>
              <div
                className={`${
                  history.location.pathname === "/features" ? "font-black" : ""
                }
        `}
              >
                Features
              </div>
            </Link>
            <button onClick={() => {}}>
              {dropdownFeatures ? (
                <Unicons.UilAngleUp size={24} className="ml-2 mt-1" />
              ) : (
                <Unicons.UilAngleDown size={24} className="ml-2 mt-1" />
              )}{" "}
            </button>
          </div>
          <Link>
            <div
              className={`pr-6 xl:pr-10 ${
                history.location.pathname === "/learn" ? "font-black" : ""
              }
        `}
            >
              Learn
            </div>
          </Link> */}
        {/* <Link to={"/company"}>
            <div
              className={`pr-10 ${
                history.location.pathname === "/company" ? "font-black" : ""
              }
        `}
            >
              Company
            </div>
          </Link> */}

        <div className="lg:col-span-10 flex justify-end items-center mr-10 xl:mr-16">
          <div className="text-center text-neutralText4 hidden sm:block px-6 ">
            +91 700 600 1600
          </div>{" "}
          <div className="text-neutralBg4 hidden lg:block">|</div>
          <Link to={"/learn"}>
            <div
              className={`px-6 ${
                history.location.pathname === "/learn" ? "font-black" : ""
              }
        `}
            >
              Learn
            </div>
          </Link>
          <Link to={"/company"}>
            <div
              className={`px-6 ${
                history.location.pathname === "/company" ? "font-black" : ""
              }
        `}
            >
              Company
            </div>
          </Link>
          <div className="text-NeutralText1 hidden lg:block px-3 xl:px-6">
            <Link to="/contact-us" className="text-center hidden sm:block">
              Contact Sales
            </Link>
          </div>
          <div className="text-neutralBg4 hidden lg:block">|</div>
          <div className="text-neutralText1 hidden lg:block px-3 xl:px-6">
            <Link to="/get-started" className="text-center hidden sm:block">
              Login
            </Link>
          </div>
          <div className="flex items-center">
            <Link to="/register" className="hidden sm:block text-center">
              <button className="bg-primary hover:bg-primaryLight min-w-[149px] text-neutralText1 py-[12px] px-6">
                Try for free
              </button>
            </Link>
            <div className="lg:hidden pl-8">
              <button
                ref={toggle_ref}
                onClick={() => {
                  toggleDropdown();
                }}
              >
                <FiMenu height="30" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {dropdownFeatures && (
        <div
          className={`transform transition ease-linear duration-500 ${
            dropdownFeatures
              ? "-translate-y-0 block "
              : "-mt-40 hidden -translate-y-full"
          }`}
        >
          <FeaturesDropdown hidden={dropdownFeatures} />
        </div>
      )}
    </>
  );
};

export default LandingNav;
