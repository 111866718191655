import React from "react";
import CustomTooltip from "./customTooltip";

const StatsCard = (props) => {
  return (
    <div
      className={`bg-neutralBg1 border border-neutralBg3 rounded pr-5 mt-6 h-fit-content p-4
        ${props.className}`}
    >
      <div className="flex items-center text-neutralText4 text-sm font-light">
        <div>{props.text}</div>
        
        {props.tooltip ? (
          <div className="px-2">
            <CustomTooltip text={props.tooltip}> 
              {props.icon}
            </CustomTooltip>
          </div>
        ):(
        <div className="px-2">{props.icon}</div>
        )}
      </div>
      <div className="text-neutralText1 text-xl font-extrabold">
        {props.children}
      </div>
    </div>
  );
};

export default StatsCard;
