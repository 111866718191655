import React from "react";

function InfoCard({ children, className }) {
  return (
    <div
      className={
        className ||
        "bg-neutralBg1 rounded border-[0.5px] border-neutralBg4 p-6"
      }
    >
      {children}
    </div>
  );
}

export default InfoCard;
