import React, { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Link } from "react-router-dom";
import * as Unicons from "@iconscout/react-unicons";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";

import tailwindConfig from "../../theme/tailwindConfig";

import addProduct from "../../assets/addProduct.gif";
import BaseButton from "../common/baseButton";
import CustomModal from "../common/customModal";
import CustomModalTitle from "../common/customModalTitle";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 7,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: tailwindConfig.theme.colors.neutralText1,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: tailwindConfig.theme.colors.successText5,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 12,
    border: 0,
    width: 0,
    display: "flex",
    justifyContent: "center",
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 35,
  height: 35,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: tailwindConfig.theme.colors.successText5,
  }),
  ...(ownerState.completed && {
    backgroundColor: tailwindConfig.theme.colors.successText5,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Unicons.UilCheck />,
    2: <Unicons.UilCheck />,
    3: <Unicons.UilCheck />,
  };
  console.log("icons", props);
  return (
    <div>
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
      <div className="text-neutralText4 text-sm"> Step {props.icon}</div>
    </div>
  );
}

function DashboardSteps({ spExists, vendorExists, productExists }) {
  const steps = [
    {
      image: (
        <video
          src={
            "https://bucket.thebusybee.app/busybee/assets/Add_New_Salesperson.mp4"
          }
          loop
          autoPlay={!spExists}
          muted
          playsInline
          className=""
          alt="add new salesperson"
        />
      ),
      header: "Add a Salesperson from your salesteam",
      text: "You can also bulk import mulitple salespersons at once",
      buttonText: spExists ? "Salesperson Added" : "Add new Salesperson",
      link: "/salespersons",
      done: spExists,
      exists: spExists,
    },
    {
      image: (
        <video
          src={
            "https://bucket.thebusybee.app/busybee/assets/add_new_vendor.mp4"
          }
          loop
          autoPlay={!vendorExists}
          muted
          playsInline
          className=""
          alt="add new retailer"
        />
      ),
      header: "Add a new retailer",
      text: "You can also bulk import mulitple retailers at once",
      buttonText: vendorExists ? "Retailer Added" : "Add new Retailer",
      link: "/vendors",
      done: vendorExists,
      exists: vendorExists,
    },
    {
      image: (
        <img
          src={addProduct}
          loop
          autoPlay={!vendorExists ? false : !productExists ? true : false}
          muted
          playsInline
          className=""
          alt="add new product"
        />
      ),
      header: productExists ? "Product Added" : "Add a Product",
      text: "You can add single product or bulk upload through CSV file.",
      buttonText: productExists ? "Product Added" : "Add a Product",
      link: "/products",
      done: productExists,
      exists: productExists,
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (vendorExists) {
      setActiveStep(2);
    } else if (spExists) {
      setActiveStep(1);
    }
  }, [activeStep]);

  return (
    <div className="py-6 grid grid-cols-3 gap-x-4">
      {steps?.map((el, index) => {
        return (
          <div
            index={index}
            className={`col-span-1 flex border ${
              el.exists
                ? "border-successBg4 bg-successBg1"
                : "border-neutralBg3 bg-neutralBg1"
            } rounded-sm py-2 ${el.exists ? "opacity-50" : "opacity-100"}`}
          >
            <div
              className={`p-6 flex flex-col justify-between w-full ${
                el.exists ? "pointer-events-none" : ""
              }`}
            >
              <div>
                <div className="text-lg text-neutralText1 font-extrabold">
                  {el.header}
                </div>
                <div className=" text-neutralText1 pb-6">{el.text}</div>
              </div>
              <div className="flex items-center w-full">
                <Link
                  to={{
                    pathname: el.link,
                    state: {
                      openModal: true,
                    },
                  }}
                >
                  <BaseButton
                    alter
                    disabled={el.exists}
                    text={el.buttonText}
                    type={`${
                      el.exists ? "bg-successBg5" : "bg-infoText5"
                    } font-bold  w-full ${
                      el.exists ? "hover:bg-infoText4" : ""
                    } text-infoBg1`}
                    textColor=""
                  />
                </Link>

                {!el.exists && (
                  <CustomModal
                    showHow
                    title={
                      <CustomModalTitle
                        title="Add Salesperson"
                        description="Enter salesperson details"
                      />
                    }
                    boxStyle={{ p: 0, width: 550 }}
                    topContainerStyle={"px-[32px] pt-[32px]"}
                    bottomContainerStyle={
                      "px-[32px] pb-[32px] bg-neutralBg3 rounded-b mt-2"
                    }
                    button={
                      <BaseButton
                        alter
                        disabled={el.exists}
                        text={
                          <div className="flex items-center font-bold text-infoText4 justify-center">
                            <Unicons.UilPlay size={16} />
                            <div className="ml-2 text-neutralText5">
                              Show me how
                            </div>
                          </div>
                        }
                        type={`border border-infoText4 ml-4 min-w-36`}
                        textColor=""
                        width={"full"}
                      />
                    }
                  >
                    <div className="pt-6 text-center">{el.image}</div>
                  </CustomModal>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default DashboardSteps;
