import React, { Component } from "react";
// import NavigationBar from "./navBar";

class NotFound extends Component {
  render() {
    return (
      <div>
        {/* <NavigationBar /> */}
        <div
          style={{
            textAlign: "center",
            padding: 50,
            fontSize: 30,
            color: "teal",
          }}
        >
          Alas! The page you are looking for does not exist
        </div>
      </div>
    );
  }
}

export default NotFound;
