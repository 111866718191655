import TabsUnstyled, { tabsUnstyledClasses } from "@mui/base/TabsUnstyled";
import TabsListUnstyled, {
  tabsListUnstyledClasses,
} from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled, {
  tabPanelUnstyledClasses,
} from "@mui/base/TabPanelUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { styled } from "@mui/material/styles";

import tailwindConfig from "../../theme/tailwindConfig";

const StyledTab = styled(TabUnstyled)(() => ({
  [`&.${tabUnstyledClasses.root}`]: {
    backgroundColor: tailwindConfig.theme.colors.neutralBg3,
    width: tailwindConfig.theme.width["52"],
    padding: tailwindConfig.theme.spacing["3"],
    marginRight: 2,
    borderTop: `0.5px solid ${tailwindConfig.theme.colors.neutralBg4}`,
    borderLeft: `0.5px solid ${tailwindConfig.theme.colors.neutralBg4}`,
    borderRight: `0.5px solid ${tailwindConfig.theme.colors.neutralBg4}`,
  },
  [`&.${tabUnstyledClasses.selected}`]: {
    backgroundColor: tailwindConfig.theme.colors.neutralBg1,
    borderBottom: 0,
  },
}));

const StyledDashboardTab = styled(TabUnstyled)(() => ({
  [`&.${tabUnstyledClasses.root}`]: {
    backgroundColor: tailwindConfig.theme.colors.neutralBg1,
    width: tailwindConfig.theme.width["50"],
    padding: tailwindConfig.theme.spacing["5"],
    paddingLeft: tailwindConfig.theme.spacing["5"],
    paddingRight: tailwindConfig.theme.spacing["5"],
    // marginRight: 2,
    // borderTop: `0.5px solid ${tailwindConfig.theme.colors.neutralBg4}`,
    // borderLeft: `0.5px solid ${tailwindConfig.theme.colors.neutralBg4}`,
    borderRight: `0.5px solid ${tailwindConfig.theme.colors.neutralBg4}`,
  },
  [`&.${tabUnstyledClasses.selected}`]: {
    backgroundColor: tailwindConfig.theme.colors.neutralBg3,
    borderBottom: 0,
  },
}));

const StyledTabPanel = styled(TabPanelUnstyled)(() => ({
  [`&.${tabPanelUnstyledClasses.root}`]: {},
}));

export const StyledTabsList = styled(TabsListUnstyled)(() => ({
  [`&.${tabsListUnstyledClasses.root}`]: {
    marginBottom: -1,
  },
  [`&.${tabsListUnstyledClasses.vertical}`]: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
}));

export const StyledTabs = styled(TabsUnstyled)(() => ({
  [`&.${tabsUnstyledClasses.root}`]: {},
  [`&.${tabsUnstyledClasses.vertical}`]: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row-reverse",
  },
}));

export const Tab = (props) => (
  <StyledTab
    className={`font-bold text-xs text-neutralText1 ${props.className}`}
    {...props}
  />
);

export const DashboardTab = (props) => (
  <StyledDashboardTab
    {...props}
    className="font-bold text-xs text-neutralText1"
  />
);

export const TabPanel = (props) => <StyledTabPanel {...props} />;

export const TabsList = (props) => <StyledTabsList {...props} />;

export const Tabs = (props) => <StyledTabs {...props} />;
