import React, { useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";
import startCase from "lodash/startCase";

import ValidationErrors from "../common/validationErrors";
import BaseButton from "../common/baseButton";
import { updateVisit } from "../../services/visitsApi";
import { CustomDateInputPicker } from "../common/customDatePicker";
import { updateVisitSchema } from "../validationSchema";
import SelectInput from "../common/selectInput";

const EditVisitsForm = ({ visit, handleCloseModal }) => {
  const [loading, setLoading] = useState(false);

  const handleUpdateVisit = async (values) => {
    setLoading(true);
    const details = {
      ...values,
      date: parseInt(values.date.getTime() / 1000, 10),
      visitType:
        values.visitType === "Orders"
          ? values.visitType
          : values.visitType.split(" ").join("_").toUpperCase(),
      status: values.status?.toUpperCase(),
    };

    const resp = await updateVisit(details);
    setLoading(false);
    if (resp) {
      toast.success("Successfully updated visits");
      handleCloseModal();
    } else {
      //   toast.error("Something went wrong");
    }
  };

  console.log("disabled", startCase(visit?.visitType?.toLowerCase()));

  return (
    <Formik
      initialValues={{
        visitId: visit?.id,
        visitType: startCase(visit?.visitType?.toLowerCase()),
        date: new Date(visit?.date * 1000),
        status: startCase(visit?.status?.toLowerCase()),
      }}
      validationSchema={updateVisitSchema}
      onSubmit={handleUpdateVisit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <CustomDateInputPicker
            date={values.date}
            minDate={new Date()}
            onChange={(selectedDate) => {
              setFieldValue("date", selectedDate);
            }}
          />
          {touched.date && errors.date && (
            <ValidationErrors errors={`${errors.date}`} className="mt-1" />
          )}

          <label className="text-xs py-1 block font-light mt-2">
            Visit Type
          </label>
          <SelectInput
            disabled={values.visitType === "Orders"}
            label={values.visitType || "Select a visit type"}
            options={["COLLECTION", "NEW_ORDER", "RETURN"].map((el) =>
              el === "NEW_ORDER" ? "Take Order" : startCase(el.toLowerCase())
            )}
            onChange={handleChange("visitType")}
          />
          {touched.visitType && errors.visitType && (
            <ValidationErrors errors={`${errors.visitType}`} className="mt-1" />
          )}

          <label className="text-xs py-1 block font-light mt-2">
            Visit Status
          </label>
          <SelectInput
            disabled={values.visitType === "Orders"}
            label={values.status || "Select a visit status"}
            options={["Active", "Completed", "Cancelled", "Terminated"]}
            onChange={handleChange("status")}
          />
          {touched.status && errors.status && (
            <ValidationErrors errors={`${errors.status}`} className="mt-1" />
          )}

          <div className=" pt-12 flex justify-end  m-auto">
            <BaseButton
              // onClick={handleSubmit}
              // disabled={isSubmitting}
              loading={loading}
              text={"Update Visit"}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
export default EditVisitsForm;
