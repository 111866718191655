export const CHECK_USER_EXISTANCE = "CHECK_USER_EXISTANCE";
export const REGISTER = "REGISTER";
export const GET_ENQUIRIES = "GET_ENQUIRIES";
export const BUY_PLAN = "BUY_PLAN";
export const GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS";
export const CHECK_COUPON = "CHECK_COUPON";
export const LOGIN = "LOGIN";
export const GET_BILLING_HISTORY = "GET_BILLING_HISTORY";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_DASHBOARD_STATS = "GET_DASHBOARD_STATS";
export const ADD_NEW_SALES_PERSON = "ADD_NEW_SALES_PERSON";
export const ADD_NEW_VENDOR = "ADD_NEW_VENDOR";
export const ADD_EXISTING_VENDOR = "ADD_EXISTING_VENDOR";
export const VENDOR_TO_EDIT = "VENDOR_TO_EDIT";
export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const RESET_STATE = "RESET_STATE";
export const GENERATED_PASSWORD = "GENERATED_PASSWORD";
export const SALESPERSON_TO_EDIT = "SALESPERSON_TO_EDIT";
export const UPDATE_SALES_PERSON = "UPDATE_SALES_PERSON";
export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_VENDOR_LIST = "GET_VENDOR_LIST";
export const GET_ORDERS = "GET_ORDERS";
export const GET_QUERIES = "GET_QUERIES";
export const GET_VENDOR_LIST_BY_SALES_PERSON =
  "GET_VENDOR_LIST_BY_SALES_PERSON";
export const GET_ASSIGNED_VENDORS_BY_SALES_PERSON =
  "GET_ASSIGNED_VENDORS_BY_SALES_PERSON";
export const SUBSCRIBE = "SUBSCRIBE";
export const GET_SALES_PERSON_LIST = "GET_SALES_PERSON_LIST";
export const GET_ALL_SUBSCRIPTION = "GET_ALL_SUBSCRIPTION";
export const GET_PLANS = "GET_PLANS";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_DASHBOARD_ACTIVITY = "GET_DSSHBOARD_ACTIVITY";
export const GET_EARNERS = "GET_EARNERS";
export const GET_MAP_DATA = "GET_MAP_DATA";
export const GET_DASHBOARD_RANK_LIST = "GET_DASHBOARD_RANK_LIST";
export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const PRODUCT_TO_EDIT = "PRODUCT_TO_EDIT";
export const GET_PROFILE = "GET_PROFILE";
export const GET_SETTINGS = "GET_SETTINGS";
export const SUBMIT_PROFILE_DETAILS = "SUBMIT_PROFILE_DETAILS";
export const GET_SALESPERSON_PERFORMANCE = "GET_SALESPERSON_PERFORMANCE";
export const GET_SALES_AND_REVENUE = "GET_SALES_AND_REVENUE";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const THEME = "THEME";
