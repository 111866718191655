import { css } from "@emotion/react";

import Loader from "./loader";

const override = css`
  display: block;
  margin: 0 auto;
`;

const BaseButton = ({
  text,
  onClick,
  type,
  textColor,
  className,
  outline,
  loading,
  width,
  height,
  disabled,
  buttonProps,
  alter,
}) => {
  return (
    <button
      {...buttonProps}
      disabled={disabled || loading}
      onClick={(e) => {
        if (loading || disabled) {
          return;
        }
        if (onClick) {
          onClick(e);
        }
      }}
      className={
        className ||
        `${alter ? "px-0 w-40" : "px-8"} text-xs  py-2 font-bold text-${
          textColor ? textColor : "black"
        } ${width} ${height || ""} ${
          outline
            ? `border ${type || "border-primary hover:bg-primary"}`
            : `${
                type ||
                "border border-primary bg-primary hover:bg-primaryLight hover:border-primaryLight"
              } `
        }   ${disabled ? "opacity-50" : "opacity-100"} `
      }
    >
      {loading ? (
        <Loader
          loading={loading}
          color={textColor ? textColor : "neutralText2"}
          css={override}
          size={16}
        />
      ) : (
        <span>{text}</span>
      )}
    </button>
  );
};
export default BaseButton;
