import {
  RESET_STATE,
  GET_ACTIVITY,
  GET_DASHBOARD_ACTIVITY,
} from "../actions/actionTypes";

export const getActivity = (
  response = [{ list: [], isLast: false, status: null }],
  action
) => {
  switch (action.type) {
    case GET_ACTIVITY:
      return (response = [
        {
          list: [...response[0].list, ...action.payload.list],
          isLast: action.payload.isLast,
          status: action.payload.responseStatus,
        },
      ]);

    case RESET_STATE:
      return (response = [{ list: [], isLast: false, status: null }]);
    default:
      return response;
  }
};

export const getDashboardActivity = (
  response = [{ list: [], isLast: false, status: null }],
  action
) => {
  switch (action.type) {
    case GET_DASHBOARD_ACTIVITY:
      return (response = [
        {
          list: [...response[0].list, ...action.payload.list],
          isLast: action.payload.isLast,
          status: action.payload.responseStatus,
        },
      ]);
    case RESET_STATE:
      console.log("inside reset reducer");
      return (response = [{ list: [], isLast: false, status: null }]);
    default:
      return response;
  }
};
