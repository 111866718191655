import React from "react";

function CustomTitle({ title, description, titleClasses, descriptionClasses }) {
  return (
    <div>
      <div className={titleClasses || "text-neutralText1 font-black text-lg"}>
        {title}
      </div>
      <div className={descriptionClasses || "text-neutralText3"}>
        {description}
      </div>
    </div>
  );
}

export default CustomTitle;
