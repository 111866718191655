import React, { useEffect, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { FiSliders, FiX } from "react-icons/fi";

import { getOrders } from "../../services/ordersApi";
import CustomTable from "../../components/common/table";
import ConvertTimestamp from "../../components/convertTimestamp";
import CustomStatus from "../../components/common/customStatus";
import EmptyFallBack from "../../components/common/emptyFallback";
import noOrders from "../../assets/noOrders.svg";
import { CustomDateRangePicker } from "../../components/common/customDatePicker";

const columns = [
  { key: "orderNo", name: "ORDER NO." },
  { key: "createdAt", name: "ORDER DATE" },
  { key: "createdBy", name: "CREATED BY" },
  { key: "status", name: "Status" },
  { key: "chevron", name: "", minWidth: 50, width: 50 },
];

const Orders = ({ vendorId, refresh }) => {
  const dataGridRef = React.useRef(null);
  const [orders, setOrders] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);

  const [tableLoading, setTableLoading] = useState(false);
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dateStart = new Date();
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateStart.setHours(0, 0, 0, 0);

  const dateEnd = new Date();
  dateEnd.setHours(23, 59, 59, 99);

  const [date, setDate] = useState([
    {
      startDate: dateStart,
      endDate: dateEnd,
      key: "selection",
    },
  ]);

  const getData = async ({ page, rowsPerPage }) => {
    setTableLoading(true);
    setOffset(page - 1);

    const data = await getOrders({
      offset: page,
      limit: rowsPerPage,
      vendorId,
      startDate: parseInt(date[0].startDate / 1000, 10),
      endDate: parseInt(date[0].endDate / 1000, 10),
    });
    setCount(data.count);
    setOrders(data?.result || []);

    setTableLoading(false);
  };

  const clearFilters = () => {
    setDate([
      {
        startDate: dateStart,
        endDate: dateEnd,
        key: "selection",
      },
    ]);
  };

  useEffect(() => {
    getData({ page: 1 });
  }, [rowsPerPage, date, refresh]);

  useEffect(() => {
    const temp = orders?.map((el) => {
      return {
        ...el,
        createdBy: (
          <div className="text-neutralText3 font-normal truncate">
            {el.addedById.businessName || el.addedById.name}
          </div>
        ),
        orderNo: (
          <div className="text-neutralText3 font-normal truncate">
            {el.id.slice(0, 8)}
          </div>
        ),
        createdAt: (
          <ConvertTimestamp
            timestamp={el.createdAt}
            className=" text-neutralText1 font-medium truncate"
          />
        ),
        status: <CustomStatus status={el.status} time={el.updatedAt} />,
        chevron: (
          <Unicons.UilAngleRightB className="text-neutralText1" size="20px" />
        ),
      };
    });
    setModifiedData(temp);
  }, [orders]);

  return (
    <div
      className="bg-neutralBg1 border-x-[0.5px] border-b-[0.5px] border-neutralBg4 px-6 py-6"
      onClick={() => {
        // Fix for `cellFocus (select) breaks input functionality`
        dataGridRef.current.selectCell(
          {
            idx: 0,
            rowIdx: -1,
            mode: "SELECT",
          },
          true
        );
      }}
    >
      <div className="flex flex-row justify-between items-center pb-4">
        <div className="text-base text-neutralText1 font-medium">
          List of Orders
        </div>
        <div className="flex items-center">
          <div className=" mr-2">
            {date[0].startDate / 1000 !== dateStart / 1000 ||
            date[0].endDate / 1000 !== dateEnd / 1000 ? (
              <button onClick={() => clearFilters()}>
                <div className="flex items-center justify-center text-infoText4 text-xs">
                  <FiX size={14} />
                  Clear
                </div>
              </button>
            ) : (
              <FiSliders className=" text-neutralText1" size={18} />
            )}
          </div>
          <CustomDateRangePicker
            date={date}
            setDate={setDate}
            changed={
              date[0].startDate / 1000 !== dateStart / 1000 ||
              date[0].endDate / 1000 !== dateEnd / 1000
            }
          />
        </div>
      </div>
      <CustomTable
        dataGridRef={dataGridRef}
        columns={columns}
        rows={modifiedData}
        bodyRowProps={{
          path: "/view-order",
        }}
        count={count}
        page={offset}
        onPageChange={(_, page) => getData({ page: page + 1 })}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
        loading={tableLoading}
        emptyFallBack={
          <EmptyFallBack
            image={noOrders}
            text="Sorry cound’t find any orders"
            containerClass={"mt-14"}
          />
        }
      />
    </div>
  );
};
export default Orders;
