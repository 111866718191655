import React from 'react';
import {Link} from 'react-router-dom';

const Articles = function(){
    return(
        <div className='article flex flex-col my-7 justify-center w-fit'>
        <h5 className='title m-0 font-["Encode sans"] text-grey2-0 font-bold text-lg'>Articles</h5>
        <ul className=" text-[#60A5FA] hover:cursor-pointer text-base 2xl:text-lg">
            <li className="my-3 hover:text-[#999999]">
                 <Link to='/learn/Account/1' className='underline underline-offset-4 '>How do I view / edit my profile and account details and settings?</Link>
            </li>
            <li className="my-3 hover:text-[#999999]">
                 <Link to='/learn/Registration/1' className='underline underline-offset-4'>How do I register to use BusyBee for my Business?</Link>
            </li>
            <li className="my-3 hover:text-[#999999]">
                <Link to='/learn/Salesperson/1' className='underline underline-offset-4'>How do I add new salespersons?</Link>
            </li>
            <li className="my-3 hover:text-[#999999]">
                <a href='/learn/Retailer/1' className='underline underline-offset-4'>How do I add new retailers?</a>
            </li>
        </ul>
    </div>
    );
}
export default Articles;