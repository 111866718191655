import * as Unicons from "@iconscout/react-unicons";
import { Link } from "react-router-dom";

import BaseButton from "../common/baseButton";
import LandingHeader from "./landingHeader";
import confusedBee from "../../assets/confusedBee.png";

const Help = () => {
  return (
    <>
      <div className="w-11/12 2xl:w-10/12 m-auto pb-14 pt-8 sm:py-14 xl:py-36">
        <div className="bg-neutralBg3 rounded-3xl">
          <div className=" bg-backgroundLogoV rounded-3xl bg-[right_-20rem_bottom] bg-no-repeat">
            <div className="w-11/12 md:w-8/12 xl:w-6/12 2xl:w-7/12 m-auto py-10 md:py-20 text-center">
              <LandingHeader body="Not sure if BusyBee is the right tool for you?" />
              <div className="text-neutralText4 font-black text-3xl py-6">
                Get free trial for 30 days{" "}
              </div>
              <Link to={"/register"}>
                <BaseButton
                  text={
                    <div className="flex justify-between text-neutralBg1 items-center text-xl font-semibold">
                      <div>Try for free</div>
                      <Unicons.UilArrowRight size={24} className="mr-1" />
                    </div>
                  }
                  type="h-[52px] bg-gradient-radial-sm-text from-successBg5 to-neutralText1 "
                  width=""
                />
              </Link>
              <div className="pt-4 text-neutralText3">
                No credit card required, no software to install.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-secondaryLight">
        <div className="lg:w-11/12 ml-auto py-9 xl:py-14">
          <div className="sm:w-10/12 md:w-8/12 xl:w-7/12 sm:pb-10 px-4 sm:px-0 mx-auto lg:mx-0 flex flex-col-reverse lg:flex-row text-center lg:text-left">
            <LandingHeader body="Still confused whether BusyBee is good for your organisation?" />
            <div className="">
              <img
                src={confusedBee}
                className="lg:absolute m-auto lg:-mt-20 xl:-mt-32 2xl:-mt-48 w-4/12 lg:w-3/12"
              />
            </div>
          </div>

          <div className="w-11/12 lg:w-full ml-auto flex bg-[#C9E9DE] p-4 sm:p-6 items-center rounded-l-3xl ">
            <div className="bg-successText5 text-neutralBg1 rounded-full p-2 sm:p-6 w-fit">
              <Unicons.UilPhonePause className="w-6 h-6 sm:w-16 sm:h-16" />
            </div>
            <div className="px-4">
              <div className="text-neutralText4 text-xl sm:text-2xl xl:text-3xl">
                Give us a call?
              </div>
              <div className="xl:text-[2.5rem] sm:text-[2.5rem] text-[1.2rem] text-transparent bg-clip-text font-black bg-gradient-radial-sm-text from-successBg5 to-neutralText1 sm:leading-[70px]">
                +91 700 600 1600
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Help;
