import { APIClient } from "../../services/apiClient";
import { GET_PROFILE } from "./actionTypes";

export const getProfileDetails = (id) => async (dispatch) => {
  try {
    const res = await APIClient.get("/org/profile");
    dispatch({
      type: GET_PROFILE,
      payload: res.data,
    });
    console.log("getProfileDetails,,,", res);
    return res;
  } catch (err) {
    console.log("Error getting profile", err);
  }
};

export const submitProfileDetails =
  (profileDetails) => async (dispatch, getState) => {
    // const currentUser = getState().login;
    // profileDetails.id = currentUser.id;
    const res = await APIClient.put("/org/profile", profileDetails);
    return res;
  };
