import React, { useEffect } from "react";
import { FiCheck, FiArrowRight } from "react-icons/fi";
import { getSubscriptionPlans } from "../redux store/actions/loginAndRegistration";
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import _ from "lodash";

const PlanCard = (props) => {
  const currentUser = useSelector((state) => state.login);
  const subscriptionPlans = useSelector((state) => state.subPlans);
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(props.limit);
    props.limit !== undefined
      ? dispatch(getSubscriptionPlans(props.limit))
      : dispatch(getSubscriptionPlans());

    // console.log("props", props.limit);
  }, []);
  const confirmPlan = (data, plan, userInfo, currencyDetails, orgId) => {
    console.log("confirm...", data);
    // props.history.push({
    //   pathname: "/confirmPlan",
    //   state: {
    //     details: data,
    //     plan: plan,
    //     userInfo: userInfo,
    //     currencyDetails,
    //     orgId,
    //   },
    // });
  };
  // console.log("plancard", props);
  return (
    <div class="py-6 sm:px-8 mx-auto  flex  flex-wrap  justify-center gap-4 w-full 2xl:w-full">
      {subscriptionPlans?.list.map((item, index) => {
        const planCategory = _.lowerCase(item.planName);
        // console.log("length", subscriptionPlans.list);
        return (
          <div
            key={index}
            className={`w-full  min-w-max lg:w-64 2xl:w-80  py-3 px-3  sm:p-0 text-secondary-text-light text-center  hover:shadow-primary hover:border-0 hover:ease-in transition duration-200 rounded-md justify-self-center 
          ${
            props.limit
              ? index === 1
                ? "bg-gradient-to-r from-gradient-via to-gradient-from shadow-xl shadow-yellow-100 border-transparent"
                : "bg-white border border-gray-200"
              : "bg-white border border-gray-200"
          }`}
          >
            <div
              className={`py-2 sm:py-6 2xl:text-lg text-xs sm:text-sm  font-bold font-mulish ${
                props.limit
                  ? index === 1
                    ? "text-white"
                    : "text-gray-600"
                  : "text-gray-600"
              }`}
            >
              {_.startCase(planCategory)}
            </div>
            <div className="py-4 sm:py-8 text-xs sm:text-sm">
              <span
                className={`text-2xl sm:text-3xl  font-bold font-mulish ${
                  props.limit
                    ? index === 1
                      ? "text-white"
                      : "ext-gray-600"
                    : "text-gray-600"
                }`}
              >
                {`$${item.monthlyRate}`}
              </span>
              <span
                className={`text-sm  font-normal font-mulish ${
                  props.limit
                    ? index === 1
                      ? "text-white"
                      : "ext-gray-600"
                    : "text-gray-600"
                }`}
              >
                / month
              </span>
            </div>
            <div className="flex flex-col justify-center mx-auto w-3/4 sm:w-5/6 mt-6">
              <div className="sm:py-2 flex items-center justify-self-start px-4 space-x-2 mb-2">
                <FiCheck className="text-green-400 text-md" />
                <span
                  className={`text-sm whitespace-nowrap 2xl:text-lg font-medium font-mulish ${
                    props.limit
                      ? index === 1
                        ? "text-white"
                        : "text-secondary-text-light"
                      : "text-secondary-text-light"
                  }`}
                >
                  {`Upto ${item.spLimit} Salespersons`}
                </span>
              </div>
              <div className="sm:py-2 flex items-center justify-self-start px-4 space-x-2 mb-2">
                <FiCheck className="text-green-400 text-md" />
                <span
                  className={`text-sm whitespace-nowrap 2xl:text-lg font-medium font-mulish ${
                    props.limit
                      ? index === 1
                        ? "text-white"
                        : "text-secondary-text-light"
                      : "text-secondary-text-light"
                  }`}
                >
                  {`Upto ${item.vendorLimit} retailers`}
                </span>
              </div>
              <div className="sm:py-2 flex items-center justify-self-start px-4 space-x-2">
                <FiCheck className="text-green-400 text-md" />
                <span
                  className={`text-sm whitespace-nowrap 2xl:text-lg font-medium font-mulish ${
                    props.limit
                      ? index === 1
                        ? "text-white"
                        : "text-secondary-text-light"
                      : "text-secondary-text-light"
                  }`}
                >
                  {`Value Offer $${item.annualRate} / yr`}
                </span>
              </div>
            </div>
            <div className="pt-24 pb-6 2xl:pt-28 2xl:pb-12">
              <Link
                to={{
                  pathname: currentUser ? "/buy-plan" : "getStarted",
                  state: { plandetails: item, user: props.formValues },
                }}
              >
                <button
                  className={`border-0 hover:border group ${
                    props.limit
                      ? index === 1
                        ? "bg-white text-primary"
                        : "bg-primary text-white"
                      : "bg-primary text-white"
                  } hover:border-primary hover:text-primary px-5 py-3 rounded-md hover:bg-white 2xl:w-1/2`}
                >
                  <div className="flex space-x-2 items-center justify-center">
                    <span
                      className={`text-md ${
                        props.limit
                          ? index === 1
                            ? "text-primary"
                            : "text-white"
                          : "text-white"
                      } font-normal font-mulish group-hover:text-primary`}
                    >
                      {props.limit ? "Get Started" : "Details"}
                    </span>
                    <FiArrowRight
                      className={` text-md group-hover:text-primary ${
                        props.limit
                          ? index === 1
                            ? "text-primary"
                            : "text-white"
                          : "text-white"
                      }`}
                    />
                  </div>
                </button>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
    // <Col lg={12} sm={12}>
    //   <CardDiv
    //     style={{
    //       background: "linear-gradient(to bottom, #3767ea, #151b2b)",
    //       width: "200px",
    //       marginBottom: "70px",
    //       color: "white",
    //       position: "relative",
    //       // left:"-15px"
    //     }}
    //   >
    //     <div>
    //       <br />
    //       <p style={{ fontSize: 20 }}>{this.props.item.planName} </p>
    //       {/* <div className="d-flex justify-content-around">
    //           <p> ${this.props.monthlyRate}/month</p>
    //         </div>
    //         <div className="d-flex justify-content-around">
    //           <p>${this.props.annualRate}/month</p>
    //         </div> */}
    //       <hr />
    //       <div>When you pay anually you get two months free </div>
    //     </div>
    //     {/* <p>
    //         You can add <b>{this.props.spLimit} Sales Persons</b> & <br />
    //         <b>{this.props.vendorLimit} Vendors</b>
    //       </p> */}
    //     <hr />
    //     <div style={{ textAlign: "left", paddingLeft: 20, fontSize: 12 }}>
    //       <p>
    //         <FaCheckSquare /> You can add{" "}
    //         <b>{this.props.item.spLimit} Sales Persons</b> & <br />
    //         <b>{this.props.item.vendorLimit} Vendors</b>
    //       </p>
    //       <p>
    //         <FaCheckSquare /> Yaaran hai tadpeavnas
    //       </p>
    //       <p>
    //         <FaCheckSquare /> dupte gomay aabas
    //       </p>
    //       <p>
    //         <FaCheckSquare /> Derayavas taraan traan
    //       </p>
    //     </div>
    //     {/* <div className="form-check d-flex justify-content-start">
    //         <input
    //           className="form-check-input"
    //           type="radio"
    //           name="exampleRadios"
    //           id="exampleRadios1"
    //           value="option1"
    //         />
    //         <label className="form-check-label" for="exampleRadios1">
    //           Choose monthly plan
    //         </label>
    //       </div>
    //       <div className="form-check d-flex justify-content-start">
    //         <input
    //           className="form-check-input"
    //           type="radio"
    //           name="exampleRadios"
    //           id="exampleRadios2"
    //           value="option2"
    //         />
    //         <label className="form-check-label" for="exampleRadios2">
    //           Choose annual plan
    //         </label>
    //       </div> */}
    //     <hr />
    //     <div
    //       style={{
    //         textAlign: "center",
    //         display: "flex",
    //         flex: "auto",
    //         width: "100%",
    //         position: "absolute",
    //         left: 0,
    //         height: "50px",
    //       }}
    //     >
    //       <Button
    //         style={{
    //           flex: "auto",
    //           borderRadius: "0 0 0 10px",
    //           fontSize: "10px",
    //         }}
    //         // this.props.loader?
    //         variant="secondary"
    //         onClick={() =>
    //           this.confirmPlan(
    //             this.props.item,
    //             "MONTHLY",
    //             this.props.userInfo,
    //             this.props.currencyDetails,
    //             this.props.orgId
    //           )
    //         }
    //       >
    //         {this.props.loader ? (
    //           <Loader type="ThreeDots" color="#343a40" height="10" width="50" />
    //         ) : (
    //           <>
    //             {this.props.currencyDetails.currency}{" "}
    //             {this.props.item.monthlyRate} /month
    //           </>
    //         )}
    //       </Button>
    //       <Button
    //         style={{
    //           flex: "auto",
    //           borderRadius: "0 0 10px 0",
    //           borderLeft: "1px #172036 solid",
    //           fontSize: "10px",
    //         }}
    //         variant="secondary"
    //         onClick={() =>
    //           this.confirmPlan(
    //             this.props.item,
    //             "ANNUALLY",
    //             this.props.userInfo,
    //             this.props.currencyDetails,
    //             this.props.orgId
    //           )
    //         }
    //       >
    //         {this.props.loader ? (
    //           <Loader type="ThreeDots" color="#343a40" height="10" width="50" />
    //         ) : (
    //           <>
    //             {this.props.currencyDetails.currency}{" "}
    //             {this.props.item.annualRate} /year
    //           </>
    //         )}
    //       </Button>
    //     </div>
    //   </CardDiv>
    // </Col>
  );
};
export default withRouter(PlanCard);
