import { APIClient } from "./apiClient";

export const assignVisit = async (visitType, date, list) => {
  try {
    let details = {
      visitType: visitType,
      date: date,
      list: list,
    };
    const res = await APIClient.post("/org/bulk_action/assign", details);
    console.log("response" + res);
    //no need to dispatch any action as we do not store this in store coz there is no need
    return res;
  } catch (err) {
    console.log("assign visit action error ", err.response);
  }
};

export const createVisit = async (details) => {
  try {
    const res = await APIClient.post("/org/visit", details);
    return res.data;
  } catch (err) {
    console.log("Error assignOrderVisit ", err.response);
  }
};
export const updateVisit = async (details) => {
  try {
    const res = await APIClient.put("/org/visit", details);
    return res.data;
  } catch (err) {
    console.log("Error assignOrderVisit ", err.response);
  }
};

export const getAssignedVisits = async ({
  offset = 0,
  limit = 10,
  startDate = null,
  endDate = null,
  status = "",
  visitType = "",
  spId = "",
  vendorId = "",
  sortField = "",
  sortOrder = "",
}) => {
  try {
    const res = await APIClient.get("/org/visits", {
      params: {
        offset,
        limit,
        startDate,
        endDate,
        status,
        visitType,
        spId,
        vendorId,
        sortField,
        sortOrder,
      },
    });
    return res.data;
  } catch (err) {
    console.log("Error getAssignedVisits ", err.response);
  }
};

export const getAssignedVisitsMap = async ({
  startDate = null,
  endDate = null,
  status = "",
  spId = "",
  vendorId = "",
}) => {
  try {
    const res = await APIClient.get("/org/visits/map", {
      params: {
        startDate,
        endDate,
        status,
        spId,
        vendorId,
      },
    });
    return res.data;
  } catch (err) {
    console.log("Error getAssignedVisits ", err.response);
  }
};

export const getVisitStats = async () => {
  try {
    const res = await APIClient.get("/org/visits/stats");
    console.log("response" + res);
    //no need to dispatch any action as we do not store this in store coz there is no need
    return res.data;
  } catch (err) {
    console.log("visitStats action error ", err.response);
  }
};

export const deleteVisit = async (id) => {
  const details = { visitId: id };
  try {
    const res = await APIClient.delete("/org/visit", { params: details });
    console.log("response" + res);
    //no need to dispatch any action as we do not store this in store coz there is no need
    return res.data;
  } catch (err) {
    console.log("visitStats action error ", err.response);
  }
};
