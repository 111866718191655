import { RESET_STATE, GET_BILLING_HISTORY } from "../actions/actionTypes";


export const getBillingHistory = (response = {}, action) => {
    switch (action.type) {
      case GET_BILLING_HISTORY:
        return action.payload;
      default:
        return response;
    }
  };