import { forwardRef, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import DataGrid from "react-data-grid";
import { Row } from "react-data-grid";
import * as Unicons from "@iconscout/react-unicons";

import noDataLogo from "../../assets/NoData.svg";
import tailwindConfig from "../../theme/tailwindConfig";
import CustomLink from "./customLinkForwardRef";
import Loader from "../common/loader";

function rowKeyGetter(row) {
  return row.id;
}

function SortIcon({ sortDirection }) {
  return (
    // sortDirection !== undefined ? (
    <>
      {sortDirection === "ASC" ? (
        <Unicons.UilAngleUp />
      ) : (
        <Unicons.UilAngleDown />
      )}{" "}
    </>
  );
  //  : null;
}

const CheckboxFormatter = forwardRef(function CheckboxFormatter(
  { disabled, onChange, ...props },
  ref
) {
  function handleChange(e) {
    onChange(e.target.checked, e.nativeEvent.shiftKey);
  }

  return (
    <input
      type="checkbox"
      ref={ref}
      disabled={disabled}
      {...props}
      onChange={handleChange}
    />
  );
});

const CustomTable = ({
  dataGridRef,
  columns,
  rows,
  bodyRowProps,
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  loading,
  selectedRows,
  setSelectedRows,
  emptyFallBack,

  headRowClasses,
  bodyRowClasses,
  headCellClasses,
  bodyCellClasses,
}) => {
  return (
    <>
      <DataGrid
        ref={dataGridRef}
        columns={columns.map((el) => {
          el.resizable = true;
          // el.minWidth = 100;
          // el.maxWidth = 300;
          el.headerCellClass =
            " font-light text-neutralText1 text-[10px] border-neutralBg3 border-[0.5px] last:border-r-0  first:border-l-0 !shadow-none ";
          el.cellClass =
            " border-0 font-light text-sm text-neutralText1 inline-flex items-center !shadow-none ";
          return el;
        })}
        className="border-0"
        rows={rows}
        headerRowHeight={40}
        style={{
          // blockSize: "100vh",
          // OR
          height: "100%",
          contain: "none",
        }}
        rowHeight={60}
        rowClass={(row) => "border-b-[0.5px] border-neutralBg4 "}
        rowKeyGetter={rowKeyGetter}
        selectedRows={selectedRows}
        enableCellAutoFocus={false}
        onSelectedRowsChange={setSelectedRows}
        components={{
          sortIcon: SortIcon,
          checkboxFormatter: CheckboxFormatter,
          rowRenderer: (props) =>
            bodyRowProps?.path ? (
              <CustomLink
                className="contents border-b-[0.5px] border-neutralBg4"
                to={`${bodyRowProps?.path}/${
                  props?.row[bodyRowProps?.id || "id"]
                }`}
              >
                <Row {...props} />
              </CustomLink>
            ) : bodyRowProps?.onClick ? (
              <Row
                {...props}
                rowClass={(row) => {
                  return ` ${bodyRowProps?.onClick ? "cursor-pointer " : ""} ${
                    bodyRowProps?.isSelected
                      ? bodyRowProps?.isSelected(row)
                        ? "bg-neutralBg3"
                        : ""
                      : ""
                  }`;
                }}
                onRowClick={(row) => bodyRowProps?.onClick(row)}
              />
            ) : (
              <Row {...props} />
            ),
          noRowsFallback: (
            <div className="col-span-full text-center pb-10">
              {loading ? <Loader color="secondary" /> : emptyFallBack}
            </div>
          ),
        }}
      />
      {count ? (
        <div className="flex justify-end">
          <TablePagination
            count={count}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            classes={{
              root: "bg-neutralBg1 !text-neutralText4 !border-0 ",
              list: "!bg-neutralBg1 !text-neutralText4",
            }}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CustomTable;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    padding: "24px 16px",
    color: tailwindConfig.theme.colors.neutralText1,
    fontSize: 10,
    fontWeight: 100,
    minWidth: 130, // TODO - 130
  },
  [`&.${tableCellClasses.body}`]: {
    color: tailwindConfig.theme.colors.neutralText1,
    fontSize: 13,
    fontWeight: 100,
    borderColor: tailwindConfig.theme.colors.neutralBg4,
  },
}));

export const CustomMuiTable = ({
  columns,
  rows,
  bodyRowProps,
  count,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  headRowClasses,
  bodyRowClasses,
  headCellClasses,
  bodyCellClasses,
}) => {
  return (
    <TableContainer>
      <Table
        style={{
          borderCollapse: "separate",
          // borderSpacing: "0px 8px",
        }}
      >
        <TableHead>
          <TableRow classes={headRowClasses}>
            {columns?.map((item) => (
              <StyledTableCell key={item.id} classes={headCellClasses}>
                {item.label.toUpperCase()}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        {rows?.length === "0" ? (
          <StyledTableCell colSpan={8}>
            <img className="h-44 w-full" src={noDataLogo} alt="img"></img>
            <div className="text-center">Nothing to show here</div>
          </StyledTableCell>
        ) : (
          <TableBody>
            {rows?.map((row) => {
              return (
                <TableRow
                  key={row.id}
                  classes={bodyRowClasses}
                  component={bodyRowProps?.path ? CustomLink : TableRow}
                  to={
                    bodyRowProps?.path ? bodyRowProps.path + "/" + row.id : ""
                  }
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <StyledTableCell
                        key={column.id}
                        classes={bodyCellClasses}
                      >
                        {value}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      <TablePagination
        count={count}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        classes={{
          root: "bg-neutralBg1 !text-neutralText4 !border-0 ",
          list: "!bg-neutralBg1 !text-neutralText4",
        }}
      />
    </TableContainer>
  );
};
