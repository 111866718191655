import dayjs from "dayjs";

const ConvertTimestamp = (props) => {
  const d = new Date(props.timestamp * 1000);
  const date = d.toLocaleDateString("en-GB", {
    dateStyle: "medium",
  });
  const fullDate = dayjs(props.timestamp * 1000).format("DD-MM-YYYY hh:mm A");
  return (
    <div className={props.className}> {props.dayjs ? fullDate : date}</div>
  );
};
export default ConvertTimestamp;
