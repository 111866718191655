import { APIClient } from "./apiClient";

export const forgotPassword = async (emailId) => {
  try {
    const response = await APIClient.post("/org/send-reset-password-link", {
      email: emailId,
    });
    return response.data;
  } catch (err) {
    console.log("Error in Forgot", err);
  }
};

export const resetPassword = async (token, password) => {
  try {
    const response = await APIClient.post("/org/recover-account", {
      token: token,
      newPassword: password,
    });
    return response.data;
  } catch (err) {
    console.log("Error in Reset", err);
  }
};

export const contactUs = async (details) => {
  try {
    const response = await APIClient.post("/contact-us", details);
    return response.data;
  } catch (err) {
    console.log("Error in contactUs", err);
  }
};
