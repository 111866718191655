import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import * as Unicons from "@iconscout/react-unicons";
// import { CircularProgress } from "@mui/material";

import { SetPasswordSchema } from "../validationSchema";
import { Formik, Form, Field } from "formik";
import ValidationErrors from "../common/validationErrors";
import CustomInput from "../common/customInput";
import BaseButton from "../common/baseButton";
import { setSalespersonPassword } from "../../services/salespersonApi";

const SetSalespersonPasswordForm = ({ token }) => {
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const registerResponse = useSelector((state) => state.register);
  const dispatch = useDispatch();

  console.log("sdsds", loader);
  return (
    <div className="">
      <Formik
        initialValues={{
          confirmPassword: "",
          password: "",
        }}
        validationSchema={SetPasswordSchema}
        onSubmit={async (values) => {
          setLoader(true);
          console.log("formvalues1", token, values.password);
          const res = await setSalespersonPassword(token, values.password);
          if (res === "Successful") {
            history.replace("/password-set");
            toast.success("Password Set Successfully");
          }
          setLoader(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <CustomInput
              icon={<Unicons.UilLock className="text-neutralText5=1 h-5 w-5" />}
              containerClasses={"py-2 text-neutralText1"}
              label="Password"
              className="w-full outline-none border rounded-sm p-2"
              placeholder="********"
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            {touched.password && errors.password && (
              <ValidationErrors
                errors={`${errors.password}`}
              ></ValidationErrors>
            )}
            <CustomInput
              icon={<Unicons.UilLock className="text-neutralText5=1 h-5 w-5" />}
              containerClasses={"py-2 text-neutralText1"}
              label="Re-enter Password"
              className="w-full outline-none border rounded-sm p-2"
              placeholder="********"
              type="password"
              name="confirmPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
            />
            {touched.confirmPassword && errors.confirmPassword && (
              <ValidationErrors
                errors={`${errors.confirmPassword}`}
              ></ValidationErrors>
            )}
            <div className="py-8">
              <BaseButton
                text="Set Password"
                type="bg-primary w-full text-neutralText2 p-2 text-sm text-sm hover:bg-primaryLight"
                onClick={() => handleSubmit()}
                disabled={loader}
                loading={loader}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SetSalespersonPasswordForm;
