import * as Unicons from "@iconscout/react-unicons";
import getSymbolFromCurrency from "currency-symbol-map";
import axios from "axios";
import { data } from "autoprefixer";
import { useEffect, useState } from "react";
import BaseButton from "../../components/common/baseButton";
import LandingFooter from "../../components/landingComponents/landingFooter";
import LandingHeader from "../../components/landingComponents/landingHeader";

import LandingNav from "../../components/landingComponents/landingNav";
import { getPlans } from "../../services/plansAndBilling";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../components/common/loader";

const Pricing = () => {
  const history = useHistory();
  const fromBills = history.location.state?.fromBills;
  const [plans, setPlans] = useState([]);
  // const [currency, setCurrency] = useState("");
  const [loader, setLoader] = useState(true);

  const getPlansList = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      console.log(res.data);

      console.log("currencyCode", res.data.country_name);
      let currency = res.data.country_name === "India" ? "INR" : "USD";
      // console.log("currencyCode,,,", currency);
      const data = await getPlans(currency);
      if (currency) setLoader(false);
      console.log("plans", data);
      setPlans(data.data);
    } catch (e) {
      const data = await getPlans("INR");
      if (data) setLoader(false);
      console.log("plans", data);
      setPlans(data.data);
    }
  };

  useEffect(() => {
    getPlansList();
  }, []);

  console.log("fromBills", fromBills);

  return (
    <>
      <div className="contain bg-backgroundLogoV bg-right-top bg-no-repeat">
        {!fromBills && (
          <div className="top-0 w-full bg-transparent bg-center">
            <LandingNav />
          </div>
        )}
        <div className="flex flex-col-reverse lg:flex-row justify-evenly py-20">
          <div className="bg-neutralBg1 rounded-2xl mx-auto lg:mx-0 w-11/12 md:w-10/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12 px-4 sm:px-10 py-6">
            <div className=" grid grid-cols-5 sm:grid-cols-6 items-center border-b ">
              <div className="py-6 col-span-2  sm:col-span-3 text-transparent text-xl sm:text-3xl font-black bg-clip-text bg-gradient-radial-sm-text from-successBg5 to-neutralText1">
                Features
              </div>
              {/* <div className="py-7 border-r text-neutralText5 text-sm sm:text-xl font-semibold text-center">
                Free
              </div>
              <div className="py-7 border-r text-neutralText5 text-sm sm:text-xl font-semibold text-center">
                Monthly
              </div>
              <div className="py-7 text-neutralText5 text-sm sm:text-xl font-semibold text-center">
                Annually
              </div> */}
            </div>

            <div className="  text-sm sm:text-lg  items-center">
              <div className="text-neutralText2">
                <div className="py-3 sm:pt-6 flex items-center justify-between">
                  Retailer Mapping{" "}
                  <div className="text-neutralBg1">
                    <Unicons.UilCheck
                      size={25}
                      className="bg-infoText4 rounded-full p-1 m-auto"
                    />
                  </div>
                </div>
                <div className="py-3 flex items-center justify-between">
                  Balance Log{" "}
                  <div className="text-neutralBg1">
                    <Unicons.UilCheck
                      size={25}
                      className="bg-infoText4 rounded-full p-1 m-auto"
                    />
                  </div>
                </div>
                <div className="py-3 flex items-center justify-between">
                  Balance Registry{" "}
                  <div className="text-neutralBg1">
                    <Unicons.UilCheck
                      size={25}
                      className="bg-infoText4 rounded-full p-1 m-auto"
                    />
                  </div>
                </div>
                <div className="py-3 flex items-center justify-between">
                  Order Tracking{" "}
                  <div className="text-neutralBg1">
                    <Unicons.UilCheck
                      size={25}
                      className="bg-infoText4 rounded-full p-1 m-auto"
                    />
                  </div>
                </div>
                <div className="py-3 flex items-center justify-between">
                  Sales Visit Tracking{" "}
                  <div className="text-neutralBg1">
                    <Unicons.UilCheck
                      size={25}
                      className="bg-infoText4 rounded-full p-1 m-auto"
                    />
                  </div>
                </div>
                <div className="py-3 flex items-center justify-between">
                  Retailer Mapping{" "}
                  <div className="text-neutralBg1">
                    <Unicons.UilCheck
                      size={25}
                      className="bg-infoText4 rounded-full p-1 m-auto"
                    />
                  </div>
                </div>
                {/* <div className="py-3 flex items-center justify-between">
                  Vendor Mapping{" "}
                  <div className="text-neutralBg1">
                    <Unicons.UilCheck
                      size={25}
                      className="bg-infoText4 rounded-full p-1 m-auto"
                    />
                  </div>
                </div>
                <div className="py-3 flex items-center justify-between">
                  Vendor Mapping{" "}
                  <div className="text-neutralBg1">
                    <Unicons.UilCheck
                      size={25}
                      className="bg-infoText4 rounded-full p-1 m-auto"
                    />
                  </div>
                </div>
                <div className="py-3 flex items-center justify-between">
                  Vendor Mapping{" "}
                  <div className="text-neutralBg1">
                    <Unicons.UilCheck
                      size={25}
                      className="bg-infoText4 rounded-full p-1 m-auto"
                    />
                  </div>
                </div>
                <div className="py-3 flex items-center justify-between">
                  Vendor Mapping{" "}
                  <div className="text-neutralBg1">
                    <Unicons.UilCheck
                      size={25}
                      className="bg-infoText4 rounded-full p-1 m-auto"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="2xl:py-6 mx-auto pb-12 lg:pb-0 lg:mx-0 w-11/12 md:w-10/12 lg:w-4/12 xl:w-5/12">
            <div className="w-9/12">
              <LandingHeader
                body="Simple plans, no limitations"
                bodyClass="pb-0"
              />
            </div>

            {loader ? (
              <div className="flex justify-center pt-28">
                <Loader />
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 2xl:grid-cols-2 gap-y-8 gap-x-8 pt-5 2xl:pt-16">
                {plans?.map((el, index) => (
                  <div key={index} className="shadow-2xl rounded-xl min-w-[]">
                    <div className="bg-neutralText5 text-center rounded-t-xl text-neutralBg1 font-black py-1">
                      {el.planName}
                    </div>
                    <div className="flex pt-4 pb-8 px-4 rounded-b-xl items-end justify-between bg-neutralBg1">
                      <div className="px-2">
                        <div className="text-[42px] font-black">
                          {getSymbolFromCurrency(el.currency)} {el.price}
                        </div>
                        <div className="text-neutralText5 text-xl leading-[1]">
                          per user/
                        </div>{" "}
                        <div className="text-neutralText5 text-xl leading-[1]">
                          per month
                        </div>
                      </div>
                      <Link
                        // to={{
                        //   pathname: "register",
                        //   state: { el },
                        // }}
                        to={
                          fromBills
                            ? {
                                pathname: "/buy-plan",
                                state: { buyPlan: el },
                              }
                            : { pathname: "register", state: { el } }
                        }
                      >
                        <BaseButton
                          text="Subscribe"
                          className={
                            "text-lg bg-primary text-neutralText2 px-6 py-2 hover:bg-primaryLight"
                          }
                        />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        )
      </div>
      {!fromBills && <LandingFooter />}
    </>
  );
};
export default Pricing;
