import React, { useState } from "react";
import UpdateVendorForm from "../../forms/updateVendorForm";

import BaseButton from "../baseButton";
import CustomModal from "../customModal"; 
import CustomModalTitle from "../customModalTitle";

function InfoCardItem({
  field,
  lock,
  vendorDetails,
  text,
  icon,
  TextComponent,
  buttonProps,
  containerClassName,
  textClassName,
  onUpdate,
}) {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div
      className={`flex flex-row justify-between items-center border-b border-neutralBg3 py-3 ${containerClassName}`}
    >
      {TextComponent || (
        <div className="flex flex-row items-center  ">
          {icon}
          <p className={`text-xs text-neutralText3 ml-3 ${textClassName}`}>
            {text}
          </p>
        </div>
      )}
      <div>
        {field === "salesperson" ? (
          <BaseButton
            // outline
            text="Change"
            type={
              "border border-infoText5 hover:bg-infoText5 hover:text-white rounded-full !px-3 !py-0.5 text-[10px]"
            }
            textColor={"infoText5"}
            height=" "
            {...buttonProps}
          />
        ) : (
          <>
            <CustomModal
              title={
                <CustomModalTitle
                  title="Update Retailer"
                  description="Add new retailer from busybee database or create new"
                />
              }
              boxStyle={{ p: 0, width: 650 }}
              topContainerStyle={"px-[32px] pt-[32px]"}
              bottomContainerStyle={
                "px-[32px] pb-[32px] pt-[25px] bg-neutralBg2 rounded-b mt-2"
              }
              // button={

              // }
              openModal={openModal}
              setOpenModal={setOpenModal}
            >
              <UpdateVendorForm
                field={field}
                initialValues={vendorDetails}
                locked={vendorDetails?.locked}
                handleCloseModal={() => {
                  onUpdate();
                  setOpenModal((o) => !o);
                }}
              />
            </CustomModal>
            <BaseButton
              // outline
              disabled={lock}
              onClick={() => setOpenModal(true)}
              text="Change"
              type={`${
                lock
                  ? "border border-neutralBg3 text-neutralBg5 cursor-default"
                  : "border border-infoText5 hover:bg-infoText5 hover:text-white"
              } rounded-full !px-3 !py-0.5 text-[10px]`}
              textColor={lock ? "neutralBg3" : "infoText5"}
              height=" "
              {...buttonProps}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default InfoCardItem;
