import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiArrowLeft, FiSliders, FiX } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import _debounce from "lodash/debounce";
import * as Unicons from "@iconscout/react-unicons";
import TablePagination from "@mui/material/TablePagination";

import OuterDiv from "../components/common/outerDiv";
import { resetState } from "../redux store/actions/commonActions";
import { FilterType } from "../constants/common";
import CustomBreadcrumbs from "../components/common/customBreadcrumbs";
import ConvertTimestamp from "../components/convertTimestamp";
import Filters from "../components/common/filters";
import { getActivity } from "../services/activitiesAndNotificationsApi";
import { CustomDateRangePicker } from "../components/common/customDatePicker";
import { getSalesPersons } from "../services/salespersonApi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// const columns = [
//   { key: "activity", name: "Description" },
//   { key: "createdOn", name: "Date" },
// ];

const Activities = () => {
  const dateStart = new Date();
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateStart.setHours(0, 0, 0, 0);

  const dateEnd = new Date();
  dateEnd.setHours(23, 59, 59, 99);

  const [date, setDate] = useState([
    {
      startDate: dateStart,
      endDate: dateEnd,
      key: "selection",
    },
  ]);

  const history = useHistory();
  const [modifiedData, setModifiedData] = useState([]);
  const [activities, setActivities] = useState([]);
  const [status, setStatus] = useState();
  const [selectedSalesperson, setSelectedSalesperson] = useState();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [searchStatus, setSearchStatus] = useState();
  const [searchText, setSearchText] = useState();
  const [productID, setProductID] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [searchedSalespersons, setSearchedSalespersons] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [salespersonsLoading, setSalespersonsLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);

  const isLast = useSelector((state) => state.activities[0].isLast);
  const dispatch = useDispatch();

  const searchSalespersons = async (query) => {
    setSalespersonsLoading(true);
    const resp = await getSalesPersons({
      offset: 1,
      limit: 5,
      query,
    });
    setSearchedSalespersons(resp.result || []);
    setSalespersonsLoading(false);
  };

  const filters = [
    {
      filterKey: "salesperson",
      filterLabel: "Salesperson",
      filterType: FilterType.Autocomplete,
      selectedFilter: selectedSalesperson,
      onFilterChange: setSelectedSalesperson,
      onFilterInputChange: searchSalespersons,
      filterLoading: salespersonsLoading,
      optionValue: "name",
      filterPlaceholder: "Salesperson",
      filterIcon: <Unicons.UilUser size={24} />,
      filterValues: searchedSalespersons,
    },
  ];

  const handleCalendar = () => {
    setOpenCalendar((o) => !o);
    if (openCalendar) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflow = "auto";
    }
  };
  const clearFilters = () => {
    setSelectedSalesperson(null);
    setSearchedSalespersons([]);
    setActiveFilters([]);
    setDate([
      {
        startDate: dateStart,
        endDate: dateEnd,
        key: "selection",
      },
    ]);
  };

  const stopEvent = (event) => event.stopPropagation();
  const getData = async ({ refresh = true, page, ...filters }) => {
    setTableLoading(true);
    setOffset(page - 1);
    const data = await getActivity({
      offset: page,
      limit: rowsPerPage,
      spId: selectedSalesperson?.id,
      startDate: parseInt(date[0].startDate / 1000, 10),
      endDate: parseInt(date[0].endDate / 1000, 10),
      ...filters,
    });
    setCount(data.count);
    setActivities(data.result);
    setTableLoading(false);
  };

  const applyFilters = ({ refresh = true, page }) => {
    const filterBuilder = {};

    if (activeFilters.length > 0) {
      for (const item of activeFilters) {
        filterBuilder[item.filterKey] = item.filterValue;
      }
    }
    getData({ refresh, page, ...filterBuilder });
  };

  useEffect(() => {
    console.log(filters, "filters");
    applyFilters({ page: 1 });
  }, [activeFilters, rowsPerPage, selectedSalesperson, date]);

  return (
    <>
      <div className="flex flex-row">
        <div className="w-full lg:w-10/12 pt-12 px-6">
          <CustomBreadcrumbs
            links={[
              { title: "Dashboard", url: "/dashboard" },
              { title: "Activity", url: "/activities" },
            ]}
          />
          <div className="flex flex-row items-center -mb-4">
            <FiArrowLeft
              className="text-neutralText1 stroke-3 cursor-pointer mr-2"
              // size={12}
              onClick={() => history.goBack()}
            />
            <div className="text-neutralText1 text-lg font-black">Activity</div>
          </div>
          <OuterDiv>
            <div className="px-8 py-6">
              <div className="text-md font-sm pb-4 flex justify-between items-center">
                <div>
                  <div className="text-neutralText1 font-bold">
                    Activity throughout organisation
                  </div>
                  <div className="text-[12px] text-neutralText3">
                    Showing overall business activities
                  </div>
                </div>
                <div className="flex items-center">
                  <div className=" mr-2">
                    {filters.map((el) => !!el.selectedFilter).includes(true) ||
                    date[0].startDate / 1000 !== dateStart / 1000 ||
                    date[0].endDate / 1000 !== dateEnd / 1000 ? (
                      <button onClick={() => clearFilters()}>
                        <div className="flex items-center justify-center text-infoText4 text-xs">
                          <FiX size={14} />
                          Clear
                        </div>
                      </button>
                    ) : (
                      <FiSliders className=" text-neutralText1" size={18} />
                    )}
                  </div>
                  <CustomDateRangePicker
                    date={date}
                    setDate={setDate}
                    changed={
                      date[0].startDate / 1000 !== dateStart / 1000 ||
                      date[0].endDate / 1000 !== dateEnd / 1000
                    }
                  />
                  <Filters
                    noLabel
                    setFilters={setActiveFilters}
                    activeFilters={activeFilters}
                    filters={filters}
                  />
                </div>
              </div>

              {activities?.map((item, index) => (
                <div>
                  {(() => {
                    switch (item?.activity) {
                      case "created a new order":
                        return (
                          <Link to={`/view-order/${item?.performedOn?.id}`}>
                            <div
                              key={index}
                              className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                            >
                              <div className="text-[14px] text-neutralText3 font-bold">
                                <Link
                                  className="text-infoText4"
                                  to={`/view-salesperson/${item?.performer?.id}`}
                                  id="wfu"
                                  onClick={stopEvent}
                                >
                                  {item?.performer?.name}
                                </Link>{" "}
                                {item?.activity}.
                                <div className="text-[10px] flex items-center text-neutralText4">
                                  <Unicons.UilClockThree
                                    size={12}
                                    className="mr-1"
                                  />
                                  <></>
                                  <ConvertTimestamp
                                    timestamp={item.createdAt}
                                    dayjs
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                        break;
                      case "visit completed":
                        return (
                          <Link
                            to={{
                              pathname: "/visits",
                              state: {
                                vendor: item?.performedOn?.vendorId,
                                salesperson: item?.performedOn?.spId,
                                toDate: item?.performedOn?.visitedAt,
                                fromDate: item?.performedOn?.visitedAt,
                              },
                            }}
                          >
                            <div
                              key={index}
                              className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                            >
                              <div className="text-[14px] text-neutralText3 font-bold">
                                Visit Completed by{" "}
                                <Link
                                  className="text-infoText4"
                                  to={`/view-salesperson/${item?.performer?.id}`}
                                  id="wfu"
                                  onClick={stopEvent}
                                >
                                  {item?.performer?.name}.
                                </Link>
                                <div className="text-[10px] flex items-center text-neutralText4">
                                  <Unicons.UilClockThree
                                    size={12}
                                    className="mr-1"
                                  />
                                  <></>
                                  <ConvertTimestamp
                                    timestamp={item.createdAt}
                                    dayjs
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                        break;
                      case "logged in":
                        return (
                          <div
                            key={index}
                            className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                          >
                            <div className="text-[14px] text-neutralText3 font-bold">
                              {item?.performedOn?.businessName ? (
                                "You logged in."
                              ) : (
                                <>
                                  <Link
                                    className="text-infoText4"
                                    to={`/view-salesperson/${item?.performer?.id}`}
                                    id="wfu"
                                    onClick={stopEvent}
                                  >
                                    {item?.performer?.name}
                                  </Link>{" "}
                                  logged in.
                                </>
                              )}
                              <div className="text-[10px] flex items-center text-neutralText4">
                                <Unicons.UilClockThree
                                  size={12}
                                  className="mr-1"
                                />
                                <></>
                                <ConvertTimestamp
                                  timestamp={item.createdAt}
                                  dayjs
                                />
                              </div>
                            </div>
                          </div>
                        );
                        break;
                      case "transaction added":
                        return (
                          <Link
                            to={{
                              pathname: "/transactions",
                              state: {
                                vendor: item?.performedOn?.vendorId,
                                salesperson: item?.performedOn?.spId,
                                status: "COMPLETED",
                                toDate: item?.performedOn?.date,
                                fromDate: item?.performedOn?.date,
                              },
                            }}
                          >
                            <div
                              key={index}
                              className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                            >
                              <div className="text-[14px] text-neutralText3 font-bold">
                                New transaction added by{" "}
                                {item?.performer?.businessName ? (
                                  "You"
                                ) : (
                                  <Link
                                    className="text-infoText4"
                                    to={`/view-salesperson/${item?.performer?.id}`}
                                    id="wfu"
                                    onClick={stopEvent}
                                  >
                                    {item?.performer?.name}.
                                  </Link>
                                )}
                                <div className="text-[10px] flex items-center text-neutralText4">
                                  <Unicons.UilClockThree
                                    size={12}
                                    className="mr-1"
                                  />
                                  <></>
                                  <ConvertTimestamp
                                    timestamp={item.createdAt}
                                    dayjs
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                        break;
                      case "completed order":
                        return (
                          <Link to={`/view-order/${item?.performedOn?.id}`}>
                            <div
                              key={index}
                              className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                            >
                              <div className="text-[14px] text-neutralText3 font-bold">
                                Order completed by{" "}
                                <Link
                                  className="text-infoText4"
                                  to={`/view-salesperson/${item?.performer?.id}`}
                                  id="wfu"
                                  onClick={stopEvent}
                                >
                                  {item?.performer?.name}.
                                </Link>
                                <div className="text-[10px] flex items-center text-neutralText4">
                                  <Unicons.UilClockThree
                                    size={12}
                                    className="mr-1"
                                  />
                                  <></>
                                  <ConvertTimestamp
                                    timestamp={item.createdAt}
                                    dayjs
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                        break;
                      case "added new retailer":
                        return (
                          <Link to={`/view-vendor/${item?.performedOn?.id}`}>
                            <div
                              key={index}
                              className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                            >
                              <div className="text-[14px] text-neutralText3 font-bold">
                                <Link
                                  className="text-infoText4"
                                  to={`/view-salesperson/${item?.performer?.id}`}
                                  id="wfu"
                                  onClick={stopEvent}
                                >
                                  {item?.performer?.name}
                                </Link>{" "}
                                Added a new retailer{""}{" "}
                                <Link
                                  className="text-infoText4"
                                  to={`/view-vendor/${item?.performedOn?.id}`}
                                  id="wfu"
                                  onClick={stopEvent}
                                >
                                  {item?.performedOn?.businessName}.
                                </Link>{" "}
                                <div className="text-[10px] flex items-center text-neutralText4">
                                  <Unicons.UilClockThree
                                    size={12}
                                    className="mr-1"
                                  />
                                  <></>
                                  <ConvertTimestamp
                                    timestamp={item.createdAt}
                                    dayjs
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                        break;
                      case "salesperson updated":
                        return (
                          <Link
                            to={`/view-salesperson/${item?.performedOn?.id}`}
                          >
                            <div
                              key={index}
                              className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                            >
                              <div className="text-[14px] text-neutralText3 font-bold">
                                {item?.performer?.businessName ? (
                                  <div>
                                    You updated{" "}
                                    <Link
                                      className="text-infoText4"
                                      to={`/view-salesperson/${item?.performedOn?.id}`}
                                      id="wfu"
                                      onClick={stopEvent}
                                    >
                                      {item?.performedOn?.name}
                                    </Link>
                                    's profile.
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="text-[10px] flex items-center text-neutralText4">
                                  <Unicons.UilClockThree
                                    size={12}
                                    className="mr-1"
                                  />
                                  <></>
                                  <ConvertTimestamp
                                    timestamp={item.createdAt}
                                    dayjs
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                        break;
                      case "added new product":
                        return (
                          <Link to={`/products`}>
                            <div
                              key={index}
                              className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                            >
                              <div className="text-[14px] text-neutralText3 font-bold">
                                {item?.performer?.businessName ? (
                                  <div>
                                    You added a new product{" "}
                                    <Link
                                      className="text-infoText4"
                                      // to={`/view-vendor/${item?.performedOn?.id}`}
                                      to={`/products`}
                                      id="wfu"
                                      onClick={stopEvent}
                                    >
                                      {item?.performedOn?.name}
                                    </Link>
                                    .
                                  </div>
                                ) : (
                                  <div>
                                    <Link
                                      className="text-infoText4"
                                      to={`/view-salesperson/${item?.performer?.id}`}
                                      id="wfu"
                                      onClick={stopEvent}
                                    >
                                      {item?.performer?.name}
                                    </Link>{" "}
                                    Added a new product{" "}
                                    <Link
                                      className="text-infoText4"
                                      // to={`/view-vendor/${item?.performedOn?.id}`}
                                      to={`/products`}
                                      id="wfu"
                                      onClick={stopEvent}
                                    >
                                      {item?.performedOn?.name}
                                    </Link>
                                    .
                                  </div>
                                )}
                                <div className="text-[10px] flex items-center text-neutralText4">
                                  <Unicons.UilClockThree
                                    size={12}
                                    className="mr-1"
                                  />
                                  <></>
                                  <ConvertTimestamp
                                    timestamp={item.createdAt}
                                    dayjs
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                        break;
                      case "added new territory":
                        return (
                          <Link>
                            <div
                              key={index}
                              className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                            >
                              <div className="text-[14px] text-neutralText3 font-bold">
                                New territory added.
                                <div className="text-[10px] flex items-center text-neutralText4">
                                  <Unicons.UilClockThree
                                    size={12}
                                    className="mr-1"
                                  />
                                  <></>
                                  <ConvertTimestamp
                                    timestamp={item.createdAt}
                                    dayjs
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                        break;
                      case "territory deleted":
                        return (
                          <Link>
                            <div
                              key={index}
                              className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                            >
                              <div className="text-[14px] text-neutralText3 font-bold">
                                Territory deleted.
                                <div className="text-[10px] flex items-center text-neutralText4">
                                  <Unicons.UilClockThree
                                    size={12}
                                    className="mr-1"
                                  />
                                  <></>
                                  <ConvertTimestamp
                                    timestamp={item.createdAt}
                                    dayjs
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                        break;
                      case "new retailer assigned":
                        return (
                          <Link to={`/view-vendor/`}>
                            <div
                              key={index}
                              className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                            >
                              <div className="text-[14px] text-neutralText3 font-bold">
                                New retailer assigned to{" "}
                                <Link
                                  className="text-infoText4"
                                  to={`/view-salesperson/${item?.performedOn?.id}`}
                                  id="wfu"
                                  onClick={stopEvent}
                                >
                                  {item?.performedOn?.name}.
                                </Link>{" "}
                                <div className="text-[10px] flex items-center text-neutralText4">
                                  <Unicons.UilClockThree
                                    size={12}
                                    className="mr-1"
                                  />
                                  <></>
                                  <ConvertTimestamp
                                    timestamp={item.createdAt}
                                    dayjs
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                        break;
                      case "new visit assigned":
                        return (
                          <Link
                            to={{
                              pathname: "/visits",
                              state: {
                                vendor: item?.performedOn?.vendorId,
                                salesperson: item?.performedOn?.spId,
                                toDate: item?.performedOn?.visitedAt,
                                fromDate: item?.performedOn?.visitedAt,
                              },
                            }}
                          >
                            <div
                              key={index}
                              className="pl-3 py-2 hover:bg-neutralBg2 border-t-2 border-neutralBg3"
                            >
                              <div className="text-[14px] text-neutralText3 font-bold">
                                New visit assigned to
                                <Link
                                  className="text-infoText4"
                                  to={`/view-salesperson/${item?.performedOn?.id}`}
                                  id="wfu"
                                  onClick={stopEvent}
                                >
                                  {item?.performedOn?.name}.
                                </Link>{" "}
                                <div className="text-[10px] flex items-center text-neutralText4">
                                  <Unicons.UilClockThree
                                    size={12}
                                    className="mr-1"
                                  />
                                  <></>
                                  <ConvertTimestamp
                                    timestamp={item.createdAt}
                                    dayjs
                                  />
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                        break;
                      default:
                        return null;
                        break;
                    }
                  })()}
                </div>
                // </div>
              ))}
            </div>

            <TablePagination
              count={count}
              page={offset}
              onPageChange={(_, page) => applyFilters({ page: page + 1 })}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
              classes={{
                root: "bg-neutralBg1 !text-neutralText4 !border-0 ",
                list: "!bg-neutralBg1 !text-neutralText4",
              }}
            />
          </OuterDiv>
        </div>
      </div>
    </>
  );
};
export default Activities;
