import { Details } from "@mui/icons-material";
import { APIClient } from "./apiClient";

export const OrdersByVendor = async (vendorId) => {
  try {
    const response = await APIClient.get(
      `/org/vendor/orders?vendorId=${vendorId}&offset=0&limit=10`
    );

    return response.data;
  } catch (err) {
    console.log("error login..", err);
  }
};

export const getOrders = async ({
  offset = 0,
  limit = 10,
  status = "",
  query = "",
  startDate = "",
  endDate = "",
  spId = "",
  vendorId = "",
}) => {
  try {
    const response = await APIClient.get("/org/order/list", {
      params: {
        offset,
        limit,
        status,
        name: query,
        startDate,
        endDate,
        spId,
        vendorId,
      },
    });
    return response.data;
  } catch (err) {
    console.log("get order list error", err);
  }
};

export const OrderStats = async (currentUser) => {
  try {
    const response = await APIClient.get(`/org/order/stats`);

    return response.data;
  } catch (err) {
    console.log("error login..", err);
  }
};

export const getOrderDetails = async (id) => {
  try {
    const response = await APIClient.get(`/org/order/details?orderId=${id}`);

    return response.data;
  } catch (err) {
    console.log("error in order details..", err);
  }
};

export const createOrder = async (selectedVendor, products) => {
  const details = {
    vendorId: selectedVendor,
    products: products,
  };
  try {
    const response = await APIClient.post("/org/products/order", details);

    return response.data;
  } catch (err) {
    console.log("error in order create..", err);
  }
};

export const editOrder = async (selectedVendor, products, id) => {
  const details = {
    vendorId: selectedVendor,
    products: products,
    id: id,
  };
  try {
    const response = await APIClient.put("/org/order/details", details);

    return response.data;
  } catch (err) {
    console.log("error in order create..", err);
  }
};

export const cancelOrCompleteOrder = async (details) => {
  try {
    const response = await APIClient.post("/org/order/action", details);

    return response.data;
  } catch (err) {
    console.log("error in cancelOrCompleteOrder", err);
  }
};
