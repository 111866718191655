import {
  useHistory,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import * as Unicons from "@iconscout/react-unicons";
import { toast } from "react-toastify";
import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FiX } from "react-icons/fi";
import { FiHelpCircle } from "react-icons/fi";
import { FiBell } from "react-icons/fi";
import { FiSearch } from "react-icons/fi";
import { logout } from "../redux store/actions/loginAndRegistration";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { Switch } from "@mui/material";
import styled from "@emotion/styled";

import defaultLogo from "../assets/user.png";
import OrgLogo from "../assets/orgLogo.svg";
import { getProfileDetails } from "../redux store/actions/organizerProfile";
import { getNotifications } from "../services/activitiesAndNotificationsApi";
import tailwindConfig from "../theme/tailwindConfig";
import { updateTheme } from "../redux store/actions/general";
import { Theme } from "../constants/common";
import CustomInput from "./common/customInput";
import Avatar from "./common/avatar";
import { startSocket } from "../services/socketIO";
import ConvertTimestamp from "./convertTimestamp";

const notificationData = [
  {
    name: "Abrar",
    description: "please send a slaesperson for visit",
    notification: "sent you a message",
  },
  {
    name: "Abrar",
    description: "please send a slaesperson for visit",
    notification: "requested a visit",
  },
  {
    name: "Abrar",
    description: "please send a slaesperson for visit",
    notification: "sent you a message",
  },
];

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: tailwindConfig.theme.colors.neutralText4,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: tailwindConfig.theme.colors.infoText5,
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: tailwindConfig.theme.colors.neutralText4,
    borderRadius: 20 / 2,
  },
}));

const TopBar = (props) => {
  const theme = useSelector((state) => state.theme);

  const [socket, setSocket] = useState();
  const [notification, setNotification] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [profileMenu, setProfileMenu] = useState(false);
  const currentUser = useSelector((state) => state.getProfileDetails);
  const dispatch = useDispatch();
  const history = useHistory();

  const profile_ref = useRef(null);
  const profileContent = useRef(null);
  const notificationRef = useRef(null);
  const notificationContent = useRef(null);

  const getData = async () => {
    dispatch(getProfileDetails());
    const data = await getNotifications("", 10, "NOT_READ");
    console.log("notifications", data);

    setNotificationList(data?.data);
    // getOrgNotifications(currentUser.id);
  };

  const getNotificationData = async () => {
    const socket = startSocket();
    setSocket(socket);
    socket.on("notification", (data) => {
      console.log("check notification", data);
      toast.info("Notification received");
      // updateActivities();
    });
  };

  useEffect(() => {
    setProfileMenu(false);
    getData();
    getNotificationData();
  }, [history.location.pathname]);

  const handleNotification = () => {
    setNotification(!notification);
  };
  const handleProfileMenu = () => {
    setProfileMenu(!profileMenu);
  };
  const stopEvent = (event) => event.stopPropagation();

  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (profile_ref.current && profile_ref.current.contains(e.target)) {
    } else {
      // user click outside toggle and content
      if (
        profileContent.current &&
        !profileContent.current.contains(e.target)
      ) {
        // setNotification(false);
        setProfileMenu(false);
      }
    }
  });
  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (notificationRef.current && notificationRef.current.contains(e.target)) {
    } else {
      // user click outside toggle and content
      if (
        notificationContent.current &&
        !notificationContent.current.contains(e.target)
      ) {
        setNotification(false);
      }
    }
  });

  const logoutt = () => {
    // console.log("LOGOUT", this.props.history);
    dispatch(logout())
      .then(() => {
        history.push("/");
      })
      .catch((e) => console.error(e));
  };

  console.log("notif", notificationList);

  return (
    <div className="z-50 bg-neutralBg1 w-full ">
      <div className="flex fixed h-16 px-8 py-3 z-50 bg-neutralBg1 flex-row w-full justify-between items-center border-b border-b-neutralBg3">
        <div className="text-secondary-text-light w-3/4 flex items-center text-xs font-light">
          <Link to="/dashboard">
            <img src={OrgLogo} alt="img" height={115} width={115} />
          </Link>
          <div className="ml-20 w-9/12">
            {/* <CustomInput
              icon={<FiSearch className="text-neutralText1 h-5 w-5" />}
              placeholder="Search Anything"
              containerClasses="mr-2"
              // onChange={onSearch}
            /> */}
          </div>
        </div>

        <div className=" flex flex-row items-center text-neutralText4 justify-end">
          <div className="flex justify-end px-4">
            <MaterialUISwitch
              sx={{ m: 1 }}
              // defaultChecked
              checked={theme === Theme.Dark}
              onChange={(event) => {
                console.log("Theme", event.target.checked);
                dispatch(
                  updateTheme(event.target.checked ? Theme.Dark : Theme.Light)
                );
              }}
            />
            <button>
              <div className="px-4">
                <Link to="/learn" target={"_blank"}>
                  <FiHelpCircle />
                </Link>
              </div>
            </button>
            <button ref={notificationRef} onClick={() => handleNotification()}>
              <div className="">
                <FiBell />
              </div>
            </button>
          </div>
          <div>
            <div
              ref={notificationContent}
              className={`absolute bg-neutralBg1 bg-opacity-90 -mt-14 shadow-xl mr-20 right-0 transform transition ease-linear duration-300 
              ${notification ? "translate-y-24" : "-translate-y-full"}
              `}
            >
              <div className="overflow-auto p-1 h-[22rem] scrollbar scrollbar-thumb-neutralText4 scrollbar-track-neutralBg3 scrollbar-thin hover:scrollbar-thumb-neutralText3">
                {!notificationList ? (
                  <div className="h-full m-auto px-6 flex items-center justify-center text-neutralText1">
                    <div>No new notifications.</div>
                  </div>
                ) : (
                  notificationList?.map((item, index) => (
                    <div>
                      {(() => {
                        switch (item?.activity) {
                          case "created a new order":
                            return (
                              <Link to={`/view-order/${item?.performedOn?.id}`}>
                                <div
                                  key={index}
                                  className="flex items-center text-neutralText4 pl-3 py-2 hover:bg-neutralBg2 border-b-2 border-neutralBg3"
                                >
                                  <Unicons.UilFileCheckAlt className="mr-1" />
                                  <div className="text-[14px] text-neutralText3 font-bold">
                                    <Link
                                      className="text-infoText4"
                                      to={`/view-salesperson/${item?.performer?.id}`}
                                      id="wfu"
                                      onClick={stopEvent}
                                    >
                                      {item?.performer?.name}
                                    </Link>{" "}
                                    {item?.activity}.
                                    <div className="text-[10px] flex items-center text-neutralText4">
                                      <Unicons.UilClockThree
                                        size={12}
                                        className="mr-1"
                                      />
                                      <></>
                                      <ConvertTimestamp
                                        timestamp={item.createdAt}
                                        dayjs
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                            break;
                          case "visit completed":
                            return (
                              <Link
                                to={{
                                  pathname: "/visits",
                                  state: {
                                    vendor: item?.performedOn?.vendorId,
                                    salesperson: item?.performedOn?.spId,
                                    toDate: item?.performedOn?.visitedAt,
                                    fromDate: item?.performedOn?.visitedAt,
                                  },
                                }}
                              >
                                <div
                                  key={index}
                                  className="flex items-center text-neutralText4 pl-3 py-2 hover:bg-neutralBg2 border-b-2 border-neutralBg3"
                                >
                                  <Unicons.UilLocationPoint className="mr-1" />
                                  <div className="text-[14px] text-neutralText3 font-bold">
                                    Visit Completed by{" "}
                                    <Link
                                      className="text-infoText4"
                                      to={`/view-salesperson/${item?.performer?.id}`}
                                      id="wfu"
                                      onClick={stopEvent}
                                    >
                                      {item?.performer?.name}.
                                    </Link>
                                    <div className="text-[10px] flex items-center text-neutralText4">
                                      <Unicons.UilClockThree
                                        size={12}
                                        className="mr-1"
                                      />
                                      <></>
                                      <ConvertTimestamp
                                        timestamp={item.createdAt}
                                        dayjs
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                            break;
                          case "logged in":
                            return (
                              <div
                                key={index}
                                className="flex items-center text-neutralText4 pl-3 py-2 hover:bg-neutralBg2 border-b-2 border-neutralBg3"
                              >
                                <Unicons.UilFileCheckAlt className="mr-1" />
                                <div className="text-[14px] text-neutralText3 font-bold">
                                  You logged in.
                                  <div className="text-[10px] flex items-center text-neutralText4">
                                    <Unicons.UilClockThree
                                      size={12}
                                      className="mr-1"
                                    />
                                    <></>
                                    <ConvertTimestamp
                                      timestamp={item.createdAt}
                                      dayjs
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                            break;
                          case "transaction added":
                            return (
                              <Link
                                to={{
                                  pathname: "/transactions",
                                  state: {
                                    vendor: item?.performedOn?.vendorId,
                                    salesperson: item?.performedOn?.spId,
                                    status: "COMPLETED",
                                    toDate: item?.performedOn?.date,
                                    fromDate: item?.performedOn?.date,
                                  },
                                }}
                              >
                                <div
                                  key={index}
                                  className="flex items-center text-neutralText4 pl-3 py-2 hover:bg-neutralBg2 border-b-2 border-neutralBg3"
                                >
                                  <Unicons.UilTransaction className="mr-1" />
                                  <div className="text-[14px] text-neutralText3 font-bold">
                                    New transaction added by{" "}
                                    <Link
                                      className="text-infoText4"
                                      to={`/view-salesperson/${item?.performer?.id}`}
                                      id="wfu"
                                      onClick={stopEvent}
                                    >
                                      {item?.performer?.name}.
                                    </Link>{" "}
                                    <div className="text-[10px] flex items-center text-neutralText4">
                                      <Unicons.UilClockThree
                                        size={12}
                                        className="mr-1"
                                      />
                                      <></>
                                      <ConvertTimestamp
                                        timestamp={item.createdAt}
                                        dayjs
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                            break;
                          case "completed order":
                            return (
                              <Link to={`/view-order/${item?.performedOn?.id}`}>
                                <div
                                  key={index}
                                  className="flex items-center text-neutralText4 pl-3 py-2 hover:bg-neutralBg2 border-b-2 border-neutralBg3"
                                >
                                  <Unicons.UilFileCheckAlt className="mr-1" />
                                  <div className="text-[14px] text-neutralText3 font-bold">
                                    Order completed by{" "}
                                    <Link
                                      className="text-infoText4"
                                      to={`/view-salesperson/${item?.performer?.id}`}
                                      id="wfu"
                                      onClick={stopEvent}
                                    >
                                      {item?.performer?.name}.
                                    </Link>
                                    <div className="text-[10px] flex items-center text-neutralText4">
                                      <Unicons.UilClockThree
                                        size={12}
                                        className="mr-1"
                                      />
                                      <></>
                                      <ConvertTimestamp
                                        timestamp={item.createdAt}
                                        dayjs
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                            break;
                          case "added new retailer":
                            return (
                              <Link
                                to={`/view-vendor/${item?.performedOn?.id}`}
                              >
                                <div
                                  key={index}
                                  className="flex items-center text-neutralText4 pl-3 py-2 hover:bg-neutralBg2 border-b-2 border-neutralBg3"
                                >
                                  <Unicons.UilUsersAlt className="mr-1" />
                                  <div className="text-[14px] text-neutralText3 font-bold">
                                    <Link
                                      className="text-infoText4"
                                      to={`/view-salesperson/${item?.performer?.id}`}
                                      id="wfu"
                                      onClick={stopEvent}
                                    >
                                      {item?.performer?.name}
                                    </Link>{" "}
                                    added a new retailer{""}{" "}
                                    <Link
                                      className="text-infoText4"
                                      to={`/view-vendor/${item?.performedOn?.id}`}
                                      id="wfu"
                                      onClick={stopEvent}
                                    >
                                      {item?.performedOn?.businessName}.
                                    </Link>{" "}
                                    <div className="text-[10px] flex items-center text-neutralText4">
                                      <Unicons.UilClockThree
                                        size={12}
                                        className="mr-1"
                                      />
                                      <></>
                                      <ConvertTimestamp
                                        timestamp={item.createdAt}
                                        dayjs
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                            break;
                          case "salesperson updated":
                            return (
                              <Link
                                to={`/view-salesperson/${item?.performedOn?.id}`}
                              >
                                <div
                                  key={index}
                                  className="flex items-center text-neutralText4 pl-3 py-2 hover:bg-neutralBg2 border-b-2 border-neutralBg3"
                                >
                                  <Unicons.UilUser className="mr-1" />
                                  <div className="text-[14px] text-neutralText3 font-bold">
                                    {item?.performer?.businessName ? (
                                      <div>
                                        You updated{" "}
                                        <Link
                                          className="text-infoText4"
                                          to={`/view-vendor/${item?.performedOn?.id}`}
                                          id="wfu"
                                          onClick={stopEvent}
                                        >
                                          {item?.performedOn?.name}
                                        </Link>
                                        's profile.
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="text-[10px] flex items-center text-neutralText4">
                                      <Unicons.UilClockThree
                                        size={12}
                                        className="mr-1"
                                      />
                                      <></>
                                      <ConvertTimestamp
                                        timestamp={item.createdAt}
                                        dayjs
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                            break;
                          case "added new product":
                            return (
                              <Link to={`/products:`}>
                                <div
                                  key={index}
                                  className="flex items-center text-neutralText4 pl-3 py-2 hover:bg-neutralBg2 border-b-2 border-neutralBg3"
                                >
                                  <Unicons.UilLayerGroup className="mr-1" />
                                  <div className="text-[14px] text-neutralText3 font-bold">
                                    {item?.performer?.businessName ? (
                                      <div>
                                        You added a new product{" "}
                                        <Link
                                          className="text-infoText4"
                                          to={`/view-vendor/${item?.performedOn?.id}`}
                                          id="wfu"
                                          onClick={stopEvent}
                                        >
                                          {item?.performedOn?.name}
                                        </Link>
                                        .
                                      </div>
                                    ) : (
                                      <div>
                                        <Link
                                          className="text-infoText4"
                                          to={`/view-salesperson/${item?.performer?.id}`}
                                          id="wfu"
                                          onClick={stopEvent}
                                        >
                                          {item?.performer?.name}
                                        </Link>{" "}
                                        added a new product{" "}
                                        <Link
                                          className="text-infoText4"
                                          to={`/view-vendor/${item?.performedOn?.id}`}
                                          id="wfu"
                                          onClick={stopEvent}
                                        >
                                          {item?.performedOn?.name}
                                        </Link>
                                        .
                                      </div>
                                    )}
                                    <div className="text-[10px] flex items-center text-neutralText4">
                                      <Unicons.UilClockThree
                                        size={12}
                                        className="mr-1"
                                      />
                                      <></>
                                      <ConvertTimestamp
                                        timestamp={item.createdAt}
                                        dayjs
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                            break;
                          case "added new territory":
                            return (
                              <Link to={`/view-order/:`}>
                                <div
                                  key={index}
                                  className="flex items-center text-neutralText4 pl-3 py-2 hover:bg-neutralBg2 border-b-2 border-neutralBg3"
                                >
                                  <Unicons.UilFileCheckAlt className="mr-1" />
                                  <div className="text-[14px] text-neutralText3 font-bold">
                                    {item?.activity}
                                    <div className="text-[10px] flex items-center text-neutralText4">
                                      <Unicons.UilClockThree
                                        size={12}
                                        className="mr-1"
                                      />
                                      <></>
                                      <ConvertTimestamp
                                        timestamp={item.createdAt}
                                        dayjs
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                            break;
                          case "territory deleted":
                            return (
                              <Link>
                                <div
                                  key={index}
                                  className="flex items-center text-neutralText4 pl-3 py-2 hover:bg-neutralBg2 border-b-2 border-neutralBg3"
                                >
                                  <Unicons.UilFileCheckAlt className="mr-1" />
                                  <div className="text-[14px] text-neutralText3 font-bold">
                                    Territory deleted.
                                    <div className="text-[10px] flex items-center text-neutralText4">
                                      <Unicons.UilClockThree
                                        size={12}
                                        className="mr-1"
                                      />
                                      <></>
                                      <ConvertTimestamp
                                        timestamp={item.createdAt}
                                        dayjs
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                            break;
                          case "new retailer assigned":
                            return (
                              <Link to={`/view-vendor/`}>
                                <div
                                  key={index}
                                  className="flex items-center text-neutralText4 pl-3 py-2 hover:bg-neutralBg2 border-b-2 border-neutralBg3"
                                >
                                  <Unicons.UilUsersAlt className="mr-1" />
                                  <div className="text-[14px] text-neutralText3 font-bold">
                                    New retailer assigned to{" "}
                                    <Link
                                      className="text-infoText4"
                                      to={`/view-salesperson/${item?.performedOn?.id}`}
                                      id="wfu"
                                      onClick={stopEvent}
                                    >
                                      {item?.performedOn?.name}.
                                    </Link>{" "}
                                    <div className="text-[10px] flex items-center text-neutralText4">
                                      <Unicons.UilClockThree
                                        size={12}
                                        className="mr-1"
                                      />
                                      <></>
                                      <ConvertTimestamp
                                        timestamp={item.createdAt}
                                        dayjs
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                            break;
                          case "new visit assigned":
                            return (
                              <Link
                                to={{
                                  pathname: "/visits",
                                  state: {
                                    vendor: item?.performedOn?.vendorId,
                                    salesperson: item?.performedOn?.spId,
                                    toDate: item?.performedOn?.visitedAt,
                                    fromDate: item?.performedOn?.visitedAt,
                                  },
                                }}
                              >
                                <div
                                  key={index}
                                  className="flex items-center text-neutralText4 pl-3 py-2 hover:bg-neutralBg2 border-b-2 border-neutralBg3"
                                >
                                  <Unicons.UilLocationPoint className="mr-1" />
                                  <div className="text-[14px] text-neutralText3 font-bold">
                                    New visit assigned to{" "}
                                    <Link
                                      className="text-infoText4"
                                      to={`/view-salesperson/${item?.performedOn?.id}`}
                                      id="wfu"
                                      onClick={stopEvent}
                                    >
                                      {item?.performedOn?.name}.
                                    </Link>{" "}
                                    <div className="text-[10px] flex items-center text-neutralText4">
                                      <Unicons.UilClockThree
                                        size={12}
                                        className="mr-1"
                                      />
                                      <></>
                                      <ConvertTimestamp
                                        timestamp={item.createdAt}
                                        dayjs
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            );
                            break;
                          default:
                            return null;
                            break;
                        }
                      })()}
                    </div>
                  ))
                )}
              </div>{" "}
              <Link to="/notifications" onClick={() => setNotification(false)}>
                <div className="py-2 text-infoText4 justify-end px-4 flex">
                  view all
                  <Unicons.UilArrowRight />
                </div>
              </Link>
            </div>
          </div>
          <div className="flex flex-row items-center justify-end">
            {/* <Link to="/account-settings"> */}
            <div
              ref={profile_ref}
              className="flex flex-row items-center justify-end cursor-pointer"
              onClick={() => handleProfileMenu()}
            >
              <Avatar
                imageClassName={"w-10 h-10"}
                image={currentUser?.profileImage}
              />
            </div>
            {/* </Link> */}
            <div
              ref={profileContent}
              className={`absolute z-100 bg-neutralBg1 transform transition ease-linear duration-300 border border-neutralBg3 shadow-2xl ${
                profileMenu
                  ? "translate-y-36 "
                  : "-translate-y-full  lg:-translate-x-0"
              }`}
            >
              <div className="flex items-start text-sm px-6 py-3">
                <Avatar
                  imageClassName={"w-10 h-10"}
                  image={currentUser?.profileImage}
                />
                <hr />
                <div className="px-2">
                  <div className="font-extrabold text-neutralText1">
                    {currentUser?.name}
                  </div>
                  <div className="text-xs text-neutralText5">
                    {currentUser?.email}
                  </div>
                  <Link to="/overview" className="underline text-infoText4">
                    View Account
                  </Link>
                </div>
              </div>
              <div className="text-center text-sm text-neutralText1">
                <Link to="/plans-and-billing">
                  <div className="py-3 border-t-2 border-t-neutralBg3  cursor-pointer hover:bg-neutralBg2">
                    Plans and Billing
                  </div>
                </Link>
                <Link to="sign-in-and-security">
                  <div className="py-3 border-y-2 border-y-neutralBg3 cursor-pointer hover:bg-neutralBg2">
                    Sign In and Security
                  </div>
                </Link>
                <div
                  className="py-3 cursor-pointer text-infoText4 hover:bg-neutralBg2"
                  onClick={() => {
                    logoutt();
                  }}
                >
                  Sign Out
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-16"></div>
    </div>
  );
};
export default withRouter(TopBar);
