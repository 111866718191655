// import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { FiChevronLeft, FiCheck } from "react-icons/fi";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FiArrowUp } from "react-icons/fi";

import SideNav from "../components/sideNav";
import TopBar from "../components/topBar";
import getCurrency from "../utils/getCurrency";

const SubscriptionDetails = (props) => {
  const userDetails = useSelector((state) => state.getProfileDetails);
  const planDetails = props.location.state?.el;
  const planName = _.lowerCase(planDetails.planName);
  const history = useHistory();

  return (
    <div className="flex flex-row">
      <SideNav />
      {0 ? (
        <div className="m-auto h-screen flex items-center">
          {/* <CircularProgress color="white" size={60} /> */}
        </div>
      ) : (
        <div className="w-full lg:w-10/12">
          <div className="w-full lg:w-10/12 pb-12">
            <TopBar value="My Subscription" />
          </div>
          <div className=" px-6 pb-14 items-center sm:px-12 relative mx-auto">
            <button
              onClick={history.goBack}
              className="text-sm flex flex-row -mb-7 items-center font-extralight text-secondary-text-light"
            >
              <FiChevronLeft />
              <span>Cancel</span>
            </button>
            <div className="w-full text-primary-text-light text-2xl font-bold text-center">
              Subscription Details
            </div>
          </div>
          <div className="flex justify-between flex-col sm:flex-row py-4 w-10/12 sm:w-8/12 m-auto">
            <div className="flex justify-between">
              <div className=" text-primary-text-light font-bold">
                Business Name
              </div>
              <div className="ml-4 text-secondary-text-light font-semibold">
                {userDetails.name}
              </div>
            </div>
            <div className="flex justify-between">
              <div className=" text-primary-text-light font-bold">Email Id</div>
              <div className="ml-4 text-secondary-text-light font-semibold">
                {userDetails.email ? userDetails.email : "-"}
              </div>
            </div>
          </div>
          <div className="flex justify-between  flex-col sm:flex-row  w-10/12 sm:w-8/12 m-auto">
            <div className="flex justify-between">
              <div className=" text-primary-text-light font-bold">Phone</div>
              <div className="ml-4 text-secondary-text-light font-semibold">
                {userDetails.contact ? userDetails.contact : "-"}
              </div>
            </div>
            <div className="flex justify-between">
              <div className=" text-primary-text-light font-bold">Website</div>
              <div className="ml-4 text-secondary-text-light font-semibold">
                {userDetails.website ? userDetails.website : "-"}
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-center py-16">
            <div className="px-5 text-center">Plan Validity Ending On</div>
            <div className="px-5 text-center">12 Aug 2022</div>
          </div>
          <div className="w-11/12 sm:w-9/12 border m-auto rounded p-2">
            <div className="flex justify-between px-2 flex-col sm:flex-row py-4  m-auto">
              <div className="flex">
                <div className=" text-primary-text-light font-bold">Plan</div>
                <div className="ml-4 text-secondary-text-light font-semibold">
                  {_.startCase(planName)} {getCurrency()}
                  {planDetails.amount}
                </div>
              </div>
              <div className="flex">
                <div className=" text-primary-text-light font-bold">
                  Duration
                </div>
                <div className="ml-4 text-secondary-text-light font-semibold">
                  Abrar
                </div>
              </div>
            </div>
            <div className="flex justify-between px-2 flex-col sm:flex-row py-4  m-auto">
              <div className="flex">
                <div className=" text-primary-text-light font-bold">
                  Total Salespersons
                </div>
                <div className="ml-4 text-secondary-text-light font-semibold">
                  {planDetails.spLimit}
                </div>
              </div>
              <div className="flex">
                <div className=" text-primary-text-light font-bold">
                  Remaining Salespersons
                </div>
                <div className="ml-4 text-secondary-text-light font-semibold">
                  {planDetails.spLimit - userDetails.spCount}
                </div>
              </div>
            </div>
            <div className="flex justify-between px-2 flex-col sm:flex-row py-4  m-auto">
              <div className="flex">
                <div className=" text-primary-text-light font-bold">
                  Total Retailers
                </div>
                <div className="ml-4 text-secondary-text-light font-semibold">
                  {planDetails.vendorLimit}
                </div>
              </div>
              <div className="flex ">
                <div className=" text-primary-text-light font-bold">
                  Remaining Retailers
                </div>
                <div className="ml-4 text-secondary-text-light font-semibold">
                  {planDetails.vendorLimit - userDetails.vendorCount}
                </div>
              </div>
            </div>
          </div>
          <div className="text-center py-12">
            <Link to="/plans">
              <button className="bg-primary text-white py-2 px-4 rounded">
                <div className="flex items-center">
                  <span className="px-2 text-sm "> Upgrade</span> <FiArrowUp />
                </div>
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
export default SubscriptionDetails;
