import { APIClient } from "./apiClient";

export const salesByVendor = async (vendorId) => {
  try {
    const response = await APIClient.get(
      `/vendor/sales?vendorId=${vendorId}&offset=0&limit=10`
    );

    return response.data;
  } catch (err) {
    console.log("error login..", err);
  }
};

export const transactionsByVendor = async (vendorId) => {
  try {
    const response = await APIClient.get(
      `/org/vendor/transactions?vendorId=${vendorId}&offset=0&limit=10`
    );

    return response.data;
  } catch (err) {
    console.log("error login..", err);
  }
};

export const queriesByVendor = async (vendorId) => {
  try {
    const response = await APIClient.get(
      `/org/vendor/queries?vendorId=${vendorId}&offset=0&limit=10`
    );
    console.log("query data", response);
    return response.data;
  } catch (err) {
    console.log("error login..", err);
  }
};

// export const SalesPersonsTransactions = async (spId) => {
//   try {
//     const response = await APIClient.get(
//       `/sp/transactions?spid=${spId}&offset=0&limit=10`
//     );

//     return response.data;
//   } catch (err) {
//     console.log("error login..", err);
//   }
// };

// export const AssignedVendorsBySalesPerson = async (spId) => {
//   try {
//     const response = await APIClient.get(
//       `/org/sp/vendor/list?spid=${spId}&offset=0&limit=10`
//     );

//     return response.data;
//   } catch (err) {
//     console.log("error login..", err);
//   }
// };

export const Transactions = async (currentUser) => {
  try {
    const response = await APIClient.get(
      `/org/transaction/list?orgId=${currentUser}`
    );

    return response.data;
  } catch (err) {
    console.log("error login..", err);
  }
};

export const getVendorDetails = async (vendorId, orgId) => {
  try {
    const res = await APIClient.get(`/org/vendor/profile?vendorId=${vendorId}`);
    return res.data;
  } catch (err) {
    console.log("Error getting profile", err);
  }
};

export const SalesPersonStats = async (currentUser) => {
  try {
    const response = await APIClient.get(`/org/sp/stats`);

    return response.data;
  } catch (err) {
    console.log("error login..", err);
  }
};

export const UploadProfileLogo = async (name, image) => {
  try {
    let details = {
      id: name,
      imageData: image,
    };

    const response = await APIClient.post("/image/upload", details);
    console.log("response image data", response);
    return response.data;
  } catch (err) {
    console.log("error login..", err);
  }
};

export const getProfileDetails = async (id) => {
  try {
    const res = await APIClient.get("/org/profile");
    return res;
  } catch (err) {
    console.log("Error getting profile", err);
  }
};

export const outForVisits = async (id) => {
  try {
    const res = await APIClient.get("/org/sp/visitstoday");
    console.log("visits details", res);
    return res;
  } catch (err) {
    console.log("Error getting profile", err);
  }
};

export const salesAndRevenueChart = async (id, startDate, endDate) => {
  console.log("filter.,", startDate, endDate);
  try {
    const res = await APIClient.get(
      `/org/overall-chart?orgId=${id}&startDate=${startDate}&endDate=${endDate}`
    );
    console.log("visits details", res);
    return res;
  } catch (err) {
    console.log("Error getting profile", err);
  }
};

export const salespersonPerformanceChart = async (id, startDate, endDate) => {
  try {
    const res = await APIClient.get(
      `/org/sp-chart?startDate=${startDate}&endDate=${endDate}&spId=${id}`
    );
    console.log("sp chart", res);
    return res;
  } catch (err) {
    console.log("Error getting profile", err);
  }
};

export const getDailyVisits = async (id, startDate, endDate) => {
  try {
    const res = await APIClient.get(`/org/visits-calender`, {
      params: { startDate: startDate, endDate: endDate, orgId: id },
    });

    return res.data;
  } catch (err) {
    console.log("Error getting dailyVisits", err);
  }
};

export const getQueryDetails = async (id) => {
  console.log("getQueryDetails", id);
  try {
    const res = await APIClient.get(`/org/query?query_id=${id}`);
    console.log("getQueryDetails", res);
    return res;
  } catch (err) {
    console.log("getQueryDetails", err);
  }
};

export const getOrgNotifications = async (id) => {
  try {
    const res = await APIClient.get(
      `/org/notification/list?read=${true}&offset=${0}&limit=${10}`
    );
    return res.data;
  } catch (err) {
    console.log("getOrgNotifications", err);
  }
};
