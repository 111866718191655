import React, { useState } from "react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import { FiBarChart } from "react-icons/fi";
import * as Unicons from "@iconscout/react-unicons";
import { DateRangePicker } from "react-date-range";
import dayjs from "dayjs";
import Popover from "@mui/material/Popover";

import tailwindConfig from "../../theme/tailwindConfig";
import CustomInput from "./customInput";
import BaseButton from "./baseButton";

const CustomDatePicker = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...props}
        PaperProps={{
          classes: {
            root: "!bg-neutralBg1 !text-neutralText1  ",
            selected: "",
          },
          sx: {
            "& .MuiTypography-root": {
              fontFamily: tailwindConfig.theme.fontFamily.mulish,
              color: tailwindConfig.theme.colors.neutralText1,
              "&:disabled": {
                color: tailwindConfig.theme.colors.neutralText5,
              },
              "&.Mui-selected": {
                backgroundColor: tailwindConfig.theme.colors.successBg5,
              },
            },
            "& .PrivatePickersYear-yearButton": {
              fontFamily: tailwindConfig.theme.fontFamily.mulish,
              "&.Mui-selected": {
                backgroundColor: `${tailwindConfig.theme.colors.successBg5} !important`,
              },
            },
            "& .MuiButtonBase-root": {
              fontFamily: tailwindConfig.theme.fontFamily.mulish,
              color: tailwindConfig.theme.colors.neutralText1,
              backgroundColor: tailwindConfig.theme.colors.neutralBg1,
              "&.Mui-selected": {
                backgroundColor: `${tailwindConfig.theme.colors.successBg5} !important`,
              },
            },
          },
        }}
        componentsProps={{
          switchViewButton: { classes: { root: "!text-neutralText1" } },
        }}
      />
    </LocalizationProvider>
  );
};

export function CustomMonthYearPicker({
  date,
  setDate,
  renderInput,
  ...props
}) {
  return (
    <CustomDatePicker
      {...props}
      views={["month", "year"]}
      maxDate={new Date()}
      value={date}
      renderInput={(params) => {
        return (
          renderInput || (
            <div
              {...params}
              ref={params.inputRef}
              className="flex text-neutralText1 text-sm"
            >
              <div className="py-2 flex">
                <FiBarChart className="-rotate-90 text-infoText4 w-5 h-5" />
                <div className="pr-3 pl-1">Period</div>
              </div>
              <div className="flex">
                <div
                  onClick={() =>
                    params.InputProps?.endAdornment?.props?.children?.props?.onClick()
                  }
                  className="flex  bg-neutralBg1 items-center border border-neutralBg5 p-2 py-1 cursor-pointer"
                >
                  <Unicons.UilCalendarAlt className="text-neutralText1 h-5 w-5" />
                  <div className="px-2 text-xs">
                    {params.inputProps?.value}
                    {/* {params.InputProps?.endAdornment} */}
                  </div>
                  {/* {showFilters && currentFilter === el.filterKey ? (
                        <Unicons.UilAngleUp />
                      ) : (
                        <Unicons.UilAngleDown />
                      )} */}
                </div>
              </div>
            </div>
          )
        );
      }}
    />
  );
}
export function CustomDateInputPicker({
  date,
  setDate,
  renderInput,
  ...props
}) {
  return (
    <CustomDatePicker
      {...props}
      value={date}
      inputFormat="dd/MM/yyyy"
      renderInput={({ inputRef, inputProps, InputProps }) => {
        return (
          renderInput || (
            <CustomInput
              label="Date"
              inputRef={inputRef}
              icon={
                <Unicons.UilCalendarAlt
                  className="text-neutralText5 h-5 w-5 cursor-pointer"
                  onClick={() =>
                    InputProps?.endAdornment?.props?.children?.props?.onClick()
                  }
                />
              }
              {...inputProps}
            />
          )
        );
      }}
    />
  );
}

// export function CustomDateRangePicker({ ...props }) {
//   const [value, setValue] = React.useState([null, null]);

//   return (
//     <LocalizationProvider dateAdapter={AdapterDateFns}>
//       <DateRangePicker
//         // label="Advanced keyboard"
//         value={value}
//         {...props}
//         onChange={(newValue) => setValue(newValue)}
//         renderInput={(startProps, endProps) => (
//           <React.Fragment>
//             <input ref={startProps.inputRef} {...startProps.inputProps} />
//             <Box sx={{ mx: 1 }}> to </Box>
//             <input ref={endProps.inputRef} {...endProps.inputProps} />
//           </React.Fragment>
//         )}
//       />
//     </LocalizationProvider>
//   );
// }

export function CustomDateRangePicker({ date, setDate, changed, ...props }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dateStart = new Date();
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateStart.setHours(0, 0, 0, 0);

  const dateEnd = new Date();
  dateEnd.setHours(23, 59, 59, 99);

  // const [value, setValue] = React.useState(0);
  const [localDate, setLocalDate] = useState(
    date || [
      {
        startDate: dateStart,
        endDate: dateEnd,
        key: "selection",
      },
    ]
  );

  const [localRange, setLocalRange] = useState(
    date || [
      {
        startDate: dateStart,
        endDate: dateEnd,
        key: "selection",
      },
    ]
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div
        aria-describedby={id}
        onClick={handleClick}
        className={`flex text-neutralText1 items-center border p-2 py-1 cursor-pointer ${
          changed
            ? "bg-infoBg1 border-infoText4 "
            : "bg-neutralBg1 border-neutralBg5"
        } `}
      >
        <Unicons.UilCalendarAlt size={20} />
        <span className="px-2 text-xs">
          {dayjs(date[0].startDate).format("DD MMM YYYY")} -{" "}
          {dayjs(date[0].endDate).format("DD MMM YYYY")}
        </span>
        {open ? <Unicons.UilAngleUp /> : <Unicons.UilAngleDown />}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="flex flex-col items-end">
          <DateRangePicker
            onChange={(item) => {
              setLocalRange([item.selection]);
              setLocalDate([
                {
                  startDate: item.selection.startDate.setHours(0, 0, 0, 0),
                  endDate: item.selection.endDate.setHours(23, 59, 59, 99),
                  key: "selection",
                },
              ]);
            }}
            months={1}
            // minDate={addDays(new Date(), -300)}
            // maxDate={addDays(new Date(), 900)}
            direction="vertical"
            // scroll={{ enabled: true }}
            ranges={localRange}
          />
          <div className="p-4">
            <BaseButton
              text={"OK"}
              textColor="neutralBg1"
              type="bg-successText4 hover:bg-successText5"
              onClick={() => {
                setDate(localDate);
                handleClose();
              }}
            />
          </div>
        </div>
      </Popover>
    </>
  );
}

export default CustomDatePicker;
