import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProductList } from "../../services/productsApi";
import { resetState } from "../../redux store/actions/commonActions";
import BaseButton from "../common/baseButton";
import CustomInput from "../common/customInput";
import { FiSearch } from "react-icons/fi";
import Avatar from "../common/avatar";
import SelectVendorCard from "../selectVendorCard";
import CustomModal from "../common/customModal";
import CustomModalTitle from "../common/customModalTitle";
import ProductForm from "../forms/productForm";
import Loader from "../common/loader";

const AddProducts = ({
  next,
  prev,
  vendorDetails,
  setSelectedProducts,
  selectedProducts,
}) => {
  const [loader, setLoader] = useState(true);
  const [products, setProducts] = useState();
  const [openAddProductModal, setOpenAddProductModal] = useState(false);

  const getProducts = async () => {
    setLoader(true);
    const data = await getProductList({});
    setProducts(data?.result);
    setLoader(false);
  };

  useEffect(() => {
    getProducts();
  }, []);

  const handleSelectedProducts = (id, name, unit, action, val) => {
    let arr = [...selectedProducts];
    if (!arr.map((el) => el.id).includes(id)) {
      arr.push({ id, name, unit, value: 1 });
    } else {
      arr.map((el) => {
        if (el.id === id) {
          {
            val
              ? (el.value = val)
              : action === "add"
              ? (el.value = el.value + 1)
              : (el.value = el.value - 1);
          }
        }
        return el;
      });
    }
    setSelectedProducts(arr);
  };
  console.log("selectedProducts...", selectedProducts);

  return (
    <div className="mt-4 px-2">
      <CustomInput
        icon={<FiSearch className="text-neutralText1 h-5 w-5" />}
        placeholder="Search"
        containerClasses="mr-2"
        // onChange={onSearch}
      />
      <div className="flex text-xs px-2 justify-between mt-4">
        <div>Add Product</div>
        <div className="flex">
          Not in the list?{" "}
          <CustomModal
            buttonText="Add New Product"
            title={
              <CustomModalTitle
                title="Add new Product"
                description="Enter product information and package unit"
              />
            }
            button={
              <div className="text-infoText4 hover:text-infoText5">
                Add new product
              </div>
            }
            openModal={openAddProductModal}
            setOpenModal={setOpenAddProductModal}
          >
            <ProductForm
              handleClose={() => {
                setLoader(true);
                setTimeout(() => {
                  getProducts();
                }, 1000);
                setOpenAddProductModal(false);
              }}
            />
          </CustomModal>
          {/* <Link to="/products" className="text-infoText4 hover:text-infoText5">
            Go to products
          </Link> */}
        </div>
      </div>
      <div className="overflow-auto p-1 max-h-48 scrollbar scrollbar-thumb-neutralText4 scrollbar-track-neutralBg3 scrollbar-thin hover:scrollbar-thumb-neutralText3">
        {loader ? (
          <div className="h-48 flex justify-center items-center">
            <Loader color={"secondary"} />
          </div>
        ) : (
          products?.map((el, index) => (
            <div key={index} className="py-1">
              <div className="flex items-center justify-between text-sm bg-neutralBg1 px-3 p-2">
                <div className="text-xs">
                  <div className="text-neutralText2">{el.name}</div>
                  <div className="text-neutralText4">{el.unit}</div>
                </div>
                <>
                  {selectedProducts
                    ?.map((item, index) => item.value > 0 && item.id)
                    .includes(el.id) ? (
                    selectedProducts.map((item) =>
                      item.id === el.id ? (
                        <div
                          key={index}
                          className="flex text-neutralText2 jusify-between"
                        >
                          <button
                            className="bg-primary px-2 text-black"
                            onClick={() =>
                              handleSelectedProducts(
                                el.id,
                                el.name,
                                el.unit,
                                "sub"
                              )
                            }
                          >
                            -
                          </button>
                          <input
                            onChange={(e) =>
                              handleSelectedProducts(
                                item.id,
                                item.name,
                                el.unit,
                                "",
                                Number(e.target.value)
                              )
                            }
                            className="px-2 outline-none w-10 text-neutralText-1 bg-neutralBg1 text-center"
                            value={item.value}
                          />

                          <button
                            className="bg-primary px-2 text-black"
                            onClick={() =>
                              handleSelectedProducts(
                                el.id,
                                el.name,
                                el.unit,
                                "add"
                              )
                            }
                          >
                            +
                          </button>
                        </div>
                      ) : null
                    )
                  ) : (
                    <button
                      className="bg-primary px-3 py-1 text-xs"
                      onClick={() =>
                        handleSelectedProducts(el.id, el.name, el.unit, "add")
                      }
                    >
                      Add
                    </button>
                  )}
                </>
              </div>
            </div>
          ))
        )}
      </div>
      <div
        className={`flex ${
          vendorDetails ? "justify-end" : "justify-between"
        } px-1 pt-4`}
      >
        {!vendorDetails && (
          <BaseButton
            text={"Back"}
            onClick={prev}
            type={"bg-transparent font-black border border-successText5"}
            textColor="successText1"
            width={"w-40"}
          />
        )}
        <BaseButton
          disabled={
            !selectedProducts.filter((item) => item.value > 0).length > 0
          }
          text={"Add Products"}
          onClick={next}
          type={`${
            !selectedProducts.filter((item) => item.value > 0).length > 0
              ? "bg-neutralText4"
              : "bg-successText5"
          }`}
          textColor="neutralBg1"
          width={"w-40"}
        />
      </div>
    </div>
  );
};

export default AddProducts;
