import { THEME } from "../actions/actionTypes";

export const theme = (initialState = "", { payload, type }) => {
  switch (type) {
    case THEME:
      return payload;

    default:
      return initialState;
  }
};
