import React, { useEffect, useState } from "react";
import * as Unicons from "@iconscout/react-unicons";
import { toast } from "react-toastify";

import CustomAutocomplete from "./common/customAutocomplete";
import CustomModal from "./common/customModal";
import CustomModalTitle from "./common/customModalTitle";
import { getSalesPersons } from "../services/salespersonApi";
import BaseButton from "./common/baseButton";
import { assignSalesperson } from "../services/vendorsApi";
import ValidationErrors from "./common/validationErrors";

function AssignSalespersonModal({
  button,
  openModal,
  setOpenModal,
  vendors,
  onClose,
}) {
  const [salespersonsLoading, setSalespersonsLoading] = useState(false);
  const [salespersons, setSalespersons] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedSalesperson, setSelectedSalesperson] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const searchSalespersons = async (query) => {
    setSalespersonsLoading(true);
    const resp = await getSalesPersons({
      offset: 1,
      limit: 5,
      query,
    });
    setSalespersons(resp.result || []);
    setSalespersonsLoading(false);
  };

  const handleAssignment = async () => {
    if (selectedSalesperson?.id) {
      setLoading(true);
      const resp = await assignSalesperson({
        vendors,
        spId: selectedSalesperson?.id,
      });

      if (resp) {
        toast.success("Successfully assigned salesperson");
        onClose();
        setOpenModal(false);
      }
      setLoading(false);
    } else {
      setError("Please select salesperson first");
    }
  };

  useEffect(() => {
    searchSalespersons(query);
  }, [query]);

  return (
    <CustomModal
      title={
        <CustomModalTitle
          title="Assign Salesperson to Retailer/s"
          description="Search a salesperson from dropdown"
        />
      }
      boxStyle={{ p: 0, width: 550 }}
      topContainerStyle={"px-[32px] pt-[32px]"}
      bottomContainerStyle={
        "px-[32px] pb-[32px] pt-4 bg-neutralBg3 rounded-b mt-2"
      }
      button={button}
      openModal={openModal}
      setOpenModal={setOpenModal}
    >
      <CustomAutocomplete
        label={"Assign Salesperson"}
        icon={<Unicons.UilUserSquare className="text-neutralText5 h-5 w-5" />}
        value={selectedSalesperson}
        onChange={(event, newValue) => {
          setError("");
          setSelectedSalesperson(newValue);
        }}
        filterOptions={(x) => {
          console.log(x, "NAVID");

          return x.filter((el) => el.status === "ACTIVE");
        }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        options={salespersons}
        autoComplete
        includeInputInList
        filterSelectedOptions
        onInputChange={(event, newInputValue) => {
          setQuery(newInputValue);
        }}
        loading={salespersonsLoading}
      />
      <ValidationErrors errors={error} className="mt-1" />
      <div className="flex justify-end pt-6">
        <BaseButton
          text="Assign"
          type="bg-infoText4 hover:bg-infoText5"
          textColor="neutralBg1"
          width="w-32"
          loading={loading}
          onClick={handleAssignment}
        />
      </div>
    </CustomModal>
  );
}

export default AssignSalespersonModal;
