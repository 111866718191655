import React, { useEffect, useState } from "react";

import { SPStatus } from "../../constants/common";
import BaseButton from "../common/baseButton";
import StatusPill from "../common/statusPill";
import CustomTable from "../common/table";

const columns = [
  { key: "csvColumnHeader", name: "CSV COLUMN HEADER" },
  { key: "busybeeFieldCol", name: "BUSYBEE FIELD" },
  { key: "fieldPreview", name: "FIELD PREVIEW FROM CSV" },
  { key: "status", name: "STATUS" },
];

function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

const MatchFields = ({
  busybeeFields,
  back,
  next,
  parsed,
  matchedFields,
  setMatchedFields,
  setProcessedData,
}) => {
  const [rows, setRows] = useState([]);
  const [someFieldsMatched, setSomeFieldsMatched] = useState(false);
  const [csvFileFields, setCsvFileFields] = useState(
    Object.entries(parsed?.data[0]).filter((el) => el[0] !== "__parsed_extra")
  );
  const [options, setOptions] = useState(() => {
    if (matchedFields) {
      setSomeFieldsMatched(true);
      return matchedFields;
    }
    return csvFileFields.reduce((acc, [key, _]) => {
      const m = busybeeFields.find((el) => key === el);
      if (m) {
        setSomeFieldsMatched(true);
      }
      return m ? { ...acc, [m]: m } : acc;
    }, {});
  });

  const getRowsData = () => {
    const t = csvFileFields.map(([key, value], index) => {
      return {
        id: `index-${index}`,
        busybeeFieldValue: busybeeFields[index],
        csvColumnHeader: key,
        busybeeFieldCol: (
          <select
            value={options[key]}
            className="bg-transparent text-sm text-neutralText1 p-2 py-1 w-full outline-none border border-0.5 border-neutralBg4 bg-neutralBg1"
            onChange={(e) => {
              setOptions((o) => {
                return {
                  ...o,
                  [key]: e.target.value,
                };
              });
            }}
          >
            <option key="select" value="">
              {"Select"}
            </option>
            {options[key] && (
              <option value={options[key]}>{options[key]}</option>
            )}
            {busybeeFields
              .filter((el) => !Object.values(options).includes(el) && el)
              .map((el, index) => (
                <option key={`index-${index}`} value={el}>
                  {el}
                </option>
              ))}
          </select>
        ),
        fieldPreview: value,
        status: options[key] ? (
          <StatusPill
            status={SPStatus.ACTIVE}
            label="Match"
            className="cursor-auto"
          />
        ) : (
          <StatusPill
            // status={SPStatus.INACTIVE}
            label="Ignore"
            className="cursor-auto"
          />
        ),
      };
    });

    return t;
  };

  useEffect(() => {
    setCsvFileFields(
      Object.entries(parsed?.data[0]).filter((el) => el[0] !== "__parsed_extra")
    );
  }, [parsed]);

  useEffect(() => {
    const t = getRowsData();

    setRows(t);
  }, [parsed, options]);

  return (
    <div>
      {someFieldsMatched ? (
        <p className="text-successText3 font-light text-xs my-2">
          Good News! We were able to match some field with our database.
        </p>
      ) : (
        <p className="text-errorText3 font-light text-xs my-2">
          No fields were matched.
        </p>
      )}

      <CustomTable
        columns={columns}
        rows={rows}
        // bodyCellClasses={{ root: "!text-[10px]" }}
      />
      <div className="flex justify-end mt-3">
        <div className="mr-2">
          <BaseButton
            text={"Back"}
            type="border-neutralText2 hover:bg-neutralText2 hover:text-white"
            textColor="neutralText2"
            outline
            onClick={back}
            width={"w-40"}
          />
        </div>
        <BaseButton
          text={"Proceed"}
          onClick={() => {
            let renamedKeysData = parsed.data.map((el) => {
              return renameKeys(el, options);
            });
            setProcessedData(renamedKeysData);
            setMatchedFields(options);
            next();
          }}
          width={"w-40"}
        />
      </div>
    </div>
  );
};

export default MatchFields;
