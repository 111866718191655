import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Calendar } from "react-date-range";

import { getDailyVisits } from "../services/api";
import "../../node_modules/react-date-range/dist/styles.css";
import "../../node_modules/react-date-range/dist/theme/default.css";
import { endOfMonth, startOfMonth } from "date-fns";
import OuterDiv from "./common/outerDiv";

const CalendarComponent = () => {
  let prev = "";
  const [pendingVisits, setPendingVisits] = useState([]);
  const [visited, setVisited] = useState([]);
  const [cancelledVisits, setCancelledVisits] = useState([]);
  const contentRef = useRef(null);
  const currentUser = useSelector((state) => state.login);

  const [date, setDate] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  });

  const getData = async () => {
    const startDate = parseInt(date.startDate.getTime() / 1000, 10);
    const endDate = parseInt(date.endDate.getTime() / 1000, 10);
    const res = await getDailyVisits(currentUser.id, startDate, endDate);

    const unvisited = res[0]?.pendingVisits;
    const modifiedPendingVisits = unvisited?.map((item) => {
      return {
        count: item.count,
        timestamp: new Date(item.timestamp * 1000).toDateString(),
      };
    });
    setPendingVisits(modifiedPendingVisits);

    const visits = res[0]?.completedVisits;
    const modifiedVisits = visits?.map((item) => {
      return {
        count: item.count,
        timestamp: new Date(item.timestamp * 1000).toDateString(),
      };
    });
    setVisited(modifiedVisits);

    const cancelled = res[0]?.cancelledVisits;
    const modifiedCancelledVisits = cancelled?.map((item) => {
      return {
        count: item.count,
        timestamp: new Date(item.timestamp * 1000).toDateString(),
      };
    });
    setCancelledVisits(modifiedCancelledVisits);
  };

  useEffect(() => {
    getData();
  }, [date]);

  useEffect(() => {
    const prevButton = document.querySelector(".rdrPprevButton");
    const nextButton = document.querySelector(".rdrNextButton");

    //previous button
    prevButton.addEventListener("click", (e) => {
      const eventScheduler = document.querySelector(".scheduler");
      if (eventScheduler !== null) {
        eventScheduler.parentNode.removeChild(eventScheduler);
      }
      // console.log("event", e);
      const prevStartDate = startOfMonth(
        date.startDate.setMonth(date.startDate.getMonth() - 1)
      );
      const prevEndDate = endOfMonth(prevStartDate);

      setDate({ endDate: prevEndDate, startDate: prevStartDate });
    });

    //next button
    nextButton.addEventListener("click", (e) => {
      const eventScheduler = document.querySelector(".scheduler");
      if (eventScheduler !== null) {
        eventScheduler.parentNode.removeChild(eventScheduler);
      }

      const newStartDate = startOfMonth(
        date.startDate.setMonth(date.startDate.getMonth() + 1)
      );
      const newEndDate = endOfMonth(newStartDate);

      setDate({ endDate: newEndDate, startDate: newStartDate });
    });
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (contentRef.current && !contentRef.current.contains(e.target)) {
        const event = document.querySelector(".scheduler");
        if (event !== null) {
          event.parentNode.removeChild(event);
        }
      }
    });
  }, []);

  const filtering = (item) => {
    const selectedDate = item.toDateString();
    const visits = {
      pendingVisits: 0,
      visitedVisits: 0,
      cancelledVisits: 0,
    };
    const pendingVisit = pendingVisits?.find(
      (e) => e.timestamp === selectedDate
    );
    const visitedVisit = visited?.find((e) => e.timestamp === selectedDate);
    const cancelled = cancelledVisits?.find(
      (e) => e.timestamp === selectedDate
    );

    if (pendingVisit !== undefined) {
      visits.pendingVisits = pendingVisit.count;
    }
    if (visitedVisit !== undefined) {
      visits.visitedVisits = visitedVisit.count;
    }
    if (cancelled) {
      visits.cancelledVisits = cancelled.count;
    }

    return visits;
  };

  const handleCalendarChange = (e) => {
    // console.log("item", e);
    // console.log("previous", prev);
    const data = filtering(e);
    const eventScheduler = document.querySelector(".scheduler");
    if (prev !== e.getTime()) {
      eventScheduler?.parentNode?.removeChild(eventScheduler);
      createEvents(data);
      prev = e.getTime();
    } else {
      if (eventScheduler === null) {
        createEvents(data);
      } else {
        // console.log("else");
        eventScheduler.parentNode.removeChild(eventScheduler);
      }
      prev = e.getTime();
    }
  };

  const createEvents = (data) => {
    let span;
    let vendorVisit = document.createElement("span");
    let vendorVisitText = document.createElement("p");
    let vendorVisitIcon = document.createElement("img");

    let scheduleVisit = document.createElement("span");
    let scheduleVisitText = document.createElement("p");
    let scheduleVisitIcon = document.createElement("img");

    let cancelledVisit = document.createElement("span");
    let cancelledVisitText = document.createElement("p");
    let cancelledVisitIcon = document.createElement("img");

    vendorVisit.classList.add(
      "flex",
      "space-x-2",
      "items-center",
      "text-neutralBg1",
      "w-full"
    );
    scheduleVisit.classList.add(
      "flex",
      "space-x-2",
      "items-center",
      "text-neutralBg1",
      "w-full"
    );
    cancelledVisit.classList.add(
      "flex",
      "space-x-2",
      "items-center",
      "text-neutralBg1",
      "w-full"
    );

    vendorVisitText.classList.add(
      "font-mulish",
      "text-xs",
      "text-neutralBg1",
      "font-bold"
    );
    scheduleVisitText.classList.add(
      "font-mulish",
      "text-xs",
      "text-neutralBg1",
      "font-bold"
    );
    cancelledVisitText.classList.add(
      "font-mulish",
      "text-xs",
      "text-neutralBg1",
      "font-bold"
    );

    vendorVisitText.innerHTML = `${data?.pendingVisits || 0} Pending Visits`;
    scheduleVisitText.innerHTML = `${data?.visitedVisits || 0} Visited`;
    cancelledVisitText.innerHTML = `${data?.cancelledVisits || 0} Cancelled`;

    vendorVisitIcon.src = "images/users.svg";
    scheduleVisitIcon.src = "images/calendar.svg";
    cancelledVisitIcon.src = "images/calendar.svg";

    vendorVisit.appendChild(vendorVisitIcon);
    vendorVisit.appendChild(vendorVisitText);

    scheduleVisit.appendChild(scheduleVisitIcon);
    scheduleVisit.appendChild(scheduleVisitText);

    cancelledVisit.appendChild(cancelledVisitIcon);
    cancelledVisit.appendChild(cancelledVisitText);

    const selectedDate = document.querySelector(".rdrDayHovered");
    // selectedDate.classList.add("relative");

    if (selectedDate.classList.contains("rdrDayEndOfWeek")) {
      span = document.createElement("span");
      span.classList.add(
        "bg-yellow-400",
        "w-36",
        "h-24",
        "z-50",
        "rounded-md",
        "flex",
        "flex-col",
        "space-y-2",
        "justify-center",
        "p-2",
        "scheduler",
        "endDay"
      );
      selectedDate?.appendChild(span);
      span?.appendChild(vendorVisit);
      span?.appendChild(scheduleVisit);
      span?.appendChild(cancelledVisit);
    } else {
      span = document.createElement("span");
      span.classList.add(
        "bg-yellow-400",
        "w-36",
        "h-24",
        "z-50",
        "rounded-md",
        "flex",
        "flex-col",
        "space-y-2",
        "justify-center",
        "p-2",
        "scheduler",
        "allDays"
      );
      selectedDate?.appendChild(span);
      span?.appendChild(vendorVisit);
      span?.appendChild(scheduleVisit);
      span?.appendChild(cancelledVisit);
    }
  };
  // console.log("dailyVisits...", dailyVisits);
  return (
    <OuterDiv ref={contentRef}>
      <Calendar
        classNames={{
          // nextPrevButton: "bg-red-500",
          prevButton: "bg-yellow-300",
          nextButton: "bg-yellow-300",
        }}
        className="w-full bg-neutralBg1 text-neutralText1 "
        showMonthAndYearPickers={false}
        showMonthArrow={true}
        onChange={(item) => handleCalendarChange(item)}
        color="#FFBD39"
      />
    </OuterDiv>
  );
};

export default CalendarComponent;
