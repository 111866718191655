export function applyTheme(theme) {
  const root = document.documentElement;
  Object.keys(theme).forEach((cssVar) => {
    root.style.setProperty(cssVar, theme[cssVar]);
  });
}

export function createTheme({
  infoText1,
  infoText2,
  infoText3,
  infoText4,
  infoText5,
  infoBg1,
  infoBg2,
  infoBg3,
  infoBg4,
  infoBg5,
  primaryDark,
  primary,
  primaryLight,
  successText1,
  successText2,
  successText3,
  successText4,
  successText5,
  successBg1,
  successBg2,
  successBg3,
  successBg4,
  successBg5,
  warningText1,
  warningText2,
  warningText3,
  warningText4,
  warningText5,
  warningBg1,
  warningBg2,
  warningBg3,
  warningBg4,
  warningBg5,
  errorBg1,
  errorBg2,
  errorBg3,
  errorBg4,
  errorBg5,
  errorText1,
  errorText2,
  errorText3,
  errorText4,
  errorText5,
  neutralText1,
  neutralText2,
  neutralText3,
  neutralText4,
  neutralText5,
  neutralBg1,
  neutralBg2,
  neutralBg3,
  neutralBg4,
  neutralBg5,
  black,
  white,
  secondaryDark,
  secondary,
  secondaryLight,
}) {
  return {
    "--theme-info-text-1": infoText1,
    "--theme-info-text-2": infoText2,
    "--theme-info-text-3": infoText3,
    "--theme-info-text-4": infoText4,
    "--theme-info-text-5": infoText5,
    "--theme-info-bg-1": infoBg1,
    "--theme-info-bg-2": infoBg2,
    "--theme-info-bg-3": infoBg3,
    "--theme-info-bg-4": infoBg4,
    "--theme-info-bg-5": infoBg5,
    "--theme-primary-dark": primaryDark,
    "--theme-primary": primary,
    "--theme-primary-light": primaryLight,
    "--theme-success-text-1": successText1,
    "--theme-success-text-2": successText2,
    "--theme-success-text-3": successText3,
    "--theme-success-text-4": successText4,
    "--theme-success-text-5": successText5,
    "--theme-success-bg-1": successBg1,
    "--theme-success-bg-2": successBg2,
    "--theme-success-bg-3": successBg3,
    "--theme-success-bg-4": successBg4,
    "--theme-success-bg-5": successBg5,
    "--theme-warning-text-1": warningText1,
    "--theme-warning-text-2": warningText2,
    "--theme-warning-text-3": warningText3,
    "--theme-warning-text-4": warningText4,
    "--theme-warning-text-5": warningText5,
    "--theme-warning-bg-1": warningBg1,
    "--theme-warning-bg-2": warningBg2,
    "--theme-warning-bg-3": warningBg3,
    "--theme-warning-bg-4": warningBg4,
    "--theme-warning-bg-5": warningBg5,
    "--theme-error-bg-1": errorBg1,
    "--theme-error-bg-2": errorBg2,
    "--theme-error-bg-3": errorBg3,
    "--theme-error-bg-4": errorBg4,
    "--theme-error-bg-5": errorBg5,
    "--theme-error-text-1": errorText1,
    "--theme-error-text-2": errorText2,
    "--theme-error-text-3": errorText3,
    "--theme-error-text-4": errorText4,
    "--theme-error-text-5": errorText5,
    "--theme-neutral-text-1": neutralText1,
    "--theme-neutral-text-2": neutralText2,
    "--theme-neutral-text-3": neutralText3,
    "--theme-neutral-text-4": neutralText4,
    "--theme-neutral-text-5": neutralText5,
    "--theme-neutral-bg-1": neutralBg1,
    "--theme-neutral-bg-2": neutralBg2,
    "--theme-neutral-bg-3": neutralBg3,
    "--theme-neutral-bg-4": neutralBg4,
    "--theme-neutral-bg-5": neutralBg5,
    "--theme-black": black,
    "--theme-white": white,
    "--theme-secondary-dark": secondaryDark,
    "--theme-secondary": secondary,
    "--theme-secondary-light": secondaryLight,
  };
}
