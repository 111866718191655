import React from "react";

function ProductList({ listClassName, order }) {
  return (
    <div>
      <div className="text-sm text-neutralText1 mb-3 font-bold">Products</div>
      {order?.products?.map((items,index) => (
        <div
        key={index}
          className={`bg-neutralBg1 p-4 rounded-sm flex justify-between items-center
            ${listClassName}`}
        >
          <div>
            <div className="text-neutralText2 text-sm font-extrabold">
              {items?.product?.name}
            </div>
            <div className="text-xs text-neutralText5">
              {items?.product?.unit}
            </div>
          </div>
          <div className="text-xs bg-neutralBg3 px-3 py-2 rounded-full font-extrabold text-neutralText2">
            {items.quantity}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductList;
