import * as Unicons from "@iconscout/react-unicons";
import BaseButton from "../common/baseButton";

import LandingHeader from "./landingHeader";
import SmallCard from "./smallCards";

const FeaturesDropdown = () => {
  return (
    <>
      <div className="px-12 pt-6 flex bg-neutralBg1">
        <div className="w-4/12">
          <div className="text-[28px] pt-4 w-3/4 font-black text-transparent bg-clip-text bg-gradient-radial-text from-successBg5 to-neutralText1 leading-[30px]">
            BusyBee packs handy tools for overseeing outdoor sales
          </div>
          <div className="py-20">
            <div className="text-secondary font-extrabold text-xl">
              For Organisations
            </div>
            <div className="text-neutralText5 w-1/2">
              Plot and view physical locations of retailers
            </div>
          </div>
          <div className="">
            <div className="text-secondary font-extrabold text-xl">
              For Salespersons
            </div>
            <div className="text-neutralText5 w-1/2">
              Plot and view physical locations of retailers
            </div>
          </div>
        </div>
        <div className="pt-14">
          <div className="text-neutralText2 font-roboto font-extrabold text-[13px] tracking-ultra-wide py-8 ">
            WELCOME TO BUSYBEE
          </div>
          <div className="w-full grid grid-cols-3 gap-x-8">
            <SmallCard
              icon={<Unicons.UilBringBottom size={40} />}
              label="Retailer Mapping"
              info="Plot and view physical locations of retailers"
            />
            <SmallCard
              icon={<Unicons.UilBringBottom size={40} />}
              label="Sales Registry"
              info="Build and maintain sales records automatically"
            />
            <SmallCard
              icon={<Unicons.UilBringBottom size={40} />}
              label="Balance Registry"
              info="Don’t forget those balance figures"
            />
            <SmallCard
              icon={<Unicons.UilBringBottom size={40} />}
              label="Sales Visit Tracking"
              info="See whose visiting when and how often."
            />
            <SmallCard
              icon={<Unicons.UilBringBottom size={40} />}
              label="Progress Statistics"
              info="See how well your sales team is performing"
            />
            <SmallCard
              icon={<Unicons.UilBringBottom size={40} />}
              label="Order Tracking"
              info="Make sure your retailers receive their ordered"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default FeaturesDropdown;
