import React from "react";
import { useEffect, useState } from "react";
import startCase from "lodash/startCase";
import { Link } from "react-router-dom";
import { FiEdit2, FiSliders, FiTrash2, FiX } from "react-icons/fi";

import CustomTable from "../../components/common/table";
import { deleteVisit, getAssignedVisits } from "../../services/visitsApi";
import ConvertTimestamp from "../../components/convertTimestamp";
import CustomModal from "../../components/common/customModal";
import CustomModalTitle from "../../components/common/customModalTitle";
import ConfirmationModal from "../../components/common/confirmationModal";
import EmptyFallBack from "../../components/common/emptyFallback";
import noVisits from "../../assets/noVisits.svg";
import EditVisitsForm from "../../components/forms/visitFormEdit";
import { CustomDateRangePicker } from "../../components/common/customDatePicker";

const columns = [
  { key: "date", name: "Date" },
  { key: "visitType", name: "Visit Type" },
  { key: "vendor", name: "Retailer" },
  { key: "status", name: "Status" },
  { key: "actions", name: "" },
];

export const AssignedVisits = ({ spId }) => {
  const dataGridRef = React.useRef(null);

  const [assignedVisits, setAssignedVisits] = useState([]);
  const [modifiedData, setModifiedData] = useState([]);
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableLoading, setTableLoading] = useState(false);
  const [visitToEdit, setVisitToEdit] = useState({});
  const [openEditVisitModal, setOpenEditVisitModal] = useState(false);

  const dateStart = new Date();
  dateStart.setMonth(dateStart.getMonth() - 1);
  dateStart.setHours(0, 0, 0, 0);

  const dateEnd = new Date();
  dateEnd.setHours(23, 59, 59, 99);

  const [date, setDate] = useState([
    {
      startDate: dateStart,
      endDate: dateEnd,
      key: "selection",
    },
  ]);

  const getData = async ({ refresh = true, page }) => {
    setTableLoading(refresh);
    const data = await getAssignedVisits({
      offset: page,
      limit: rowsPerPage,
      spId,
      startDate: parseInt(date[0].startDate / 1000, 10),
      endDate: parseInt(date[0].endDate / 1000, 10),
    });
    setOffset(page - 1);
    setAssignedVisits(data?.result || []);
    setCount(data?.count);
    setTableLoading(false);
  };

  const clearFilters = () => {
    setDate([
      {
        startDate: dateStart,
        endDate: dateEnd,
        key: "selection",
      },
    ]);
  };

  useEffect(() => {
    getData({ page: 1 });
  }, [rowsPerPage, date]);

  useEffect(() => {
    const temp = assignedVisits?.map((el, index) => {
      return {
        // ...el,
        id: `index-${index}`,
        date: (
          <ConvertTimestamp
            timestamp={el.createdAt}
            className="font-normal text-neutralText1 truncate"
          />
        ),
        visitType: (
          <p
            className={`px-2 py-0.5 border-l-4 ${
              el?.visitType === "NEW_ORDER"
                ? "bg-infoBg2 text-infoText4 border-infoText5"
                : el?.visitType === "COLLECTION"
                ? "bg-successBg2 text-successText5 border-successText5"
                : el?.visitType === "RETURN"
                ? "bg-errorBg2 text-errorText5 border-errorText5"
                : el?.visitType === "Orders"
                ? "bg-warningBg2 text-warningText5 border-warningText5"
                : ""
            }`}
          >
            {startCase(el.visitType?.toLowerCase())}
          </p>
        ),
        vendor: (
          <div className="truncate">
            <Link
              className="text-infoText4"
              to={`/view-vendor/${el.vendor?.id}`}
            >
              {el.vendor?.businessName}
            </Link>
          </div>
        ),
        status: (
          <p
            className={`px-2 py-0.5 rounded-full ${
              el?.status === "ACTIVE"
                ? "bg-infoBg2 text-infoText3"
                : el?.status === "COMPLETED"
                ? "bg-successBg2 text-successText3"
                : el?.status === "CANCELLED"
                ? "bg-errorBg2 text-errorText3"
                : el?.status === "TERMINATED"
                ? "bg-errorBg2 text-errorText3"
                : ""
            }`}
          >
            {startCase(el?.status?.toLowerCase())}
          </p>
        ),
        actions:
          el.status === "COMPLETED" || el.visitType === "Orders" ? (
            <div className="flex items-center text-neutralText4 ">
              <div className="flex items-bottom pt-[5px] ">
                <FiEdit2 className="h-5 w-5" />
                <div className="px-1 pt-[2px]">Edit</div>
              </div>
              <div className="text-neutralText5 px-2">
                <FiTrash2 size={18} />
              </div>
            </div>
          ) : (
            <div
              className="flex items-center
          "
            >
              <div
                className="flex items-bottom text-neutralText1 pt-[5px] cursor-pointer"
                onClick={() => {
                  setVisitToEdit(el);
                  setOpenEditVisitModal(true);
                }}
              >
                <FiEdit2 className="h-5 w-5" />
                <div className="px-1 pt-[2px]">Edit</div>
              </div>

              <div className="flex items-center text-errorText5 px-2">
                <ConfirmationModal
                  title="Are your sure?"
                  subtitle="Are you sure you want to delete this visit."
                  positiveLabel="Delete"
                  negativeLabel="Cancel"
                  onPositiveClick={async () => {
                    await deleteVisit(el.id);
                    getData({ refresh: false, page: 1 });
                  }}
                  button={(onClick) => (
                    <FiTrash2
                      size={18}
                      status={el.status}
                      className="cursor-pointer"
                      onClick={onClick}
                    />
                  )}
                />
              </div>
            </div>
          ),
      };
    });
    setModifiedData(temp);
  }, [assignedVisits]);

  return (
    <div className="bg-neutralBg1 border-x-[0.5px] border-b-[0.5px] border-neutralBg4 px-6 py-6">
      <CustomModal
        title={
          <CustomModalTitle
            title="Edit Visit"
            description="Edit product information and package unit"
          />
        }
        openModal={openEditVisitModal}
        setOpenModal={setOpenEditVisitModal}
      >
        <EditVisitsForm
          visit={visitToEdit}
          handleCloseModal={() => {
            getData({ refresh: false, page: 1 });
            setOpenEditVisitModal(false);
          }}
        />
      </CustomModal>

      <div
        className="flex flex-row justify-between items-center pb-4"
        onClick={() => {
          // Fix for `cellFocus (select) breaks input functionality`
          dataGridRef.current.selectCell(
            {
              idx: 0,
              rowIdx: -1,
              mode: "SELECT",
            },
            true
          );
        }}
      >
        <div className="text-base text-neutralText1 font-medium">
          List of Visits
        </div>
        <div className="flex items-center">
          <div className=" mr-2">
            {date[0].startDate / 1000 !== dateStart / 1000 ||
            date[0].endDate / 1000 !== dateEnd / 1000 ? (
              <button onClick={() => clearFilters()}>
                <div className="flex items-center justify-center text-infoText4 text-xs">
                  <FiX size={14} />
                  Clear
                </div>
              </button>
            ) : (
              <FiSliders className=" text-neutralText1" size={18} />
            )}
          </div>
          <CustomDateRangePicker
            date={date}
            setDate={setDate}
            changed={
              date[0].startDate / 1000 !== dateStart / 1000 ||
              date[0].endDate / 1000 !== dateEnd / 1000
            }
          />
        </div>
      </div>
      <CustomTable
        dataGridRef={dataGridRef}
        columns={columns}
        rows={modifiedData}
        count={count}
        page={offset}
        onPageChange={(_, page) => getData({ page: page + 1 })}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
        loading={tableLoading}
        emptyFallBack={
          <EmptyFallBack
            image={noVisits}
            text="No Visits Found"
            containerClass={"mt-14 mb-4"}
            imageClass={"h-44"}
          />
        }
      />
    </div>
  );
};
export default AssignedVisits;
